import { StatusPhase } from 'app/shared/models/status-phase';
import { Rating } from 'app/shared/models/rating';
import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { CommentResource } from 'app/shared/models/comment.resource';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { FileAttachment } from 'app/shared/models/file-attachment.resource';
import { User } from 'app/core/user';

export class SpotCheckData {

    public deficitValues: DeficitValueResponse[];
    public id: string;
    public rating: Rating;
    public checkTypeConfigurationId: string;
    public contractPositionConfigurationId: string;
    public tradeConfigurationId: string;
    public createdUtc: string;
    public createdBy: UserResponse;
    public modifiedUtc: string;
    public modifiedBy: UserResponse;
    public appendix: string;
    public ticketNumber: string;
    public statusPhaseOne: StatusPhase = StatusPhase.InProgress;
    public statusPhaseTwo: StatusPhase;
    public statusPhaseThree: StatusPhase;
    public ticketDescription: string;
    public tenderPosition: string;
    public plant: string;
    public roomNumber: string;
    public comments: CommentResource[];
    public attachementLink: string;
    public deficitFixed: DeficitFixed;
    public controlId: string;
    public attachments: FileAttachment[] = [];
    public isWithdrawn: boolean;
    public dedicatedUser: UserResponse;
    
    public isFormDataValid(): boolean {
        if (!this.contractPositionConfigurationId || !this.checkTypeConfigurationId || !this.tradeConfigurationId) {
            return false;
        }
        return this.isValidRatingCombination();
    }

    private isValidRatingCombination() {
        if (this.rating && this.rating !== Rating['0']) {
            return true;
        } else if (this.statusPhaseOne === StatusPhase.InProgress) {
            return true;
        }
        return false;
    }

}
