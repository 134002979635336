

<div class="modal-header">
    <h4 class="modal-title">{{deficit.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeForm()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!-- <app-loading-spinner class="loading-spinner" *ngIf="isLoading"></app-loading-spinner> -->
<form novalidate [formGroup]="deficitProposalForm" (ngSubmit)="onSubmit()">
<div class="modal-body">
        <div class="form-group row">
            <label class="col-xl-2 col-form-label">{{ "shared.deficit-group-proposals-request.user-proposal" | translate }}</label>
            <div class="col-xl-10">
                <textarea [ngxSummernote]="descriptionConfig" formControlName="descriptionProposal"></textarea>    
            </div>
        </div>
        <div class="form-group row">
            <label class="col-xl-2 col-form-label">{{ "shared.deficit-group-proposals-request.user-comment" | translate }}</label>
            <div class="col-xl-10">
                <textarea [ngxSummernote]="descriptionConfig" formControlName="comment"></textarea>    
            </div>
        </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closeForm()">
        <i class="icon" class="fas fa-ban"></i>
        {{ "shared.deficit-group-proposals-request.cancel" | translate }}
    </button>
    <button class="btn btn-dark" type="submit">
        <i class="icon" class="fas fa-save"></i>
        {{ "shared.deficit-group-proposals-request.submit" | translate }}
    </button>
</div>
</form>
