import { Component, OnInit } from '@angular/core';
import { OfflineSyncService } from 'app/local-cache/services/offline-sync.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalIsSyncingComponent } from 'app/shared/components/modal-is-syncing/modal-is-syncing.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  constructor(private _offineSyncService: OfflineSyncService, private _modal: NgbModal) {
  }

  ngOnInit() {
    this._offineSyncService.dataIsSynching.subscribe(res => {
      if (res === true) {
        this._modal.open(ModalIsSyncingComponent, { size: 'md', backdrop: 'static', keyboard: false });
        return;
      }
      if (this._modal.hasOpenModals()) {
        this._modal.dismissAll();
      }
    });
  }
}
