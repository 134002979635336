import { Pipe, PipeTransform } from '@angular/core';
import { DeficitService } from './deficit.service';

@Pipe({
    name: 'resolveDeficitGroup'
})
export class DeficitGroupNamePipe implements PipeTransform {

    constructor(private deficitService: DeficitService) { }

    transform(value: any, args?: any): any {
        return this.deficitService.getDeficitGroup(value);
    }
}
