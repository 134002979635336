import { Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderBy, OrderDirection } from 'app/admin/allgemein/shared/order-by';
import { User } from 'app/core/user';
import { UserProfileService } from 'app/core/user-profile.service';
import { Contract } from 'app/shared/masterData/contract/contract.resource';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { DeficitGroupProposalService } from 'app/shared/services/deficit-group-proposal.service';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { Subscription } from 'rxjs';
import { BaseFilterPagingComponent } from '../../base-filter-paging.component';
import { DeficitGroupProposalData, DeficitGroupProposalStatus } from '../../deficit-proposal-request/deficit-group-proposal-data';
import { EntityHistoryComponent } from '../../entity-history/entity-history.component';
import { DeficitGroupProposalHistoryHelper } from '../deficit-group-proposal-history.helper';
import { DeficitProposalLoadFilter } from '../deficit-proposal-load-filter.resource';

@Component({
  selector: 'epo-deficit-group-proposals',
  templateUrl: './deficit-group-proposals.component.html',
  styleUrls: ['./deficit-group-proposals.component.scss']
})
export class DeficitGroupProposalsComponent extends BaseFilterPagingComponent<DeficitGroupProposalData> implements OnDestroy {
  public isLoading: boolean = true;
  public sorting: { column: 'DeficitGroup' | 'CreatedOn' | 'Number', direction: OrderDirection } = { column: 'DeficitGroup', direction: OrderDirection.Asc };
  subscription: Subscription;
  public loadFilter: DeficitProposalLoadFilter = new DeficitProposalLoadFilter();
  public isUserLocalOrGlobalAdmin: boolean = false;
  private GASubscription: Subscription;
  public proposalStatusSelection = ['all', 'pending', 'approved', 'rejected', 'promoted'];

  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData();
  }
  get page() {
    return this.currentPage;
  }
  
  constructor(private _deficitProposalService: DeficitGroupProposalService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _userProfileService: UserProfileService,
    private _contractService: ContractService,
    private modalService: NgbModal) {
    super();
    this.GASubscription = this._userProfileService.currentUser.subscribe((user: User) => {
      // console.log(user)
      this._contractService.selectedContract.subscribe((contract:Contract) => {
        let isUserLocalAdmin: boolean = false;
        if(contract !== null ){
          if (user.contractUsers.find(rs => rs.contractId == contract.id && rs.contractRole == 0)) {
            isUserLocalAdmin = true;
          }
        }      
        this.isUserLocalOrGlobalAdmin = ((user as User).role === GlobalUserRole.GlobalAdmin || (user as User).role === GlobalUserRole.Admin) 
                                          || isUserLocalAdmin;

      })      
    });
  }

  onFilterChange() {
    this.currentPage = 1;
    this.loadData();
  }

  isStatusSelected(value) {
    this.loadFilter.isStatus = value;
    this.onFilterChange();
  }


  loadData() {
    this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    this.load();
  }

  private load() {
    this.isLoading = true;
    this.subscription = this._deficitProposalService.getDeficitGroupProposalForContract(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter)
    .subscribe( res => {
        this.items = res.items;
        this.items.map((prop: DeficitGroupProposalData) => {
          prop.createdUtc = this.resolveDate(prop.createdUtc);
        });
        this.isLoading = false;
      }
    );
  }

  public sort(column: 'DeficitGroup' | 'CreatedOn' | 'Number') {
    if (column === this.sorting.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  private resolveDate(date:string):string{ 
    let dateTime:Date = new Date(date);
    return dateTime.toLocaleString();
  }

  public isActiveSorting(column: 'DeficitGroup' | 'CreatedOn' | 'Number', direction: 'Asc' | 'Desc') {
    return this.sorting.column == column && this.sorting.direction == OrderDirection[direction];
  }

  public approveItem(value: string): void {
    this._deficitProposalService.approveDeficitGroupProposal(value)
      .subscribe(() => {
        this.items.map(proposal => {
          if (proposal.id === value) {
            proposal.status = DeficitGroupProposalStatus.Approved;
          }
        }) 
      });
  }

  public rejectItem(value: string): void {
    this._deficitProposalService.rejectDeficitGroupProposal(value)
    .subscribe(() => {
      this.items.map(proposal => {
        if (proposal.id === value) {
          proposal.status = DeficitGroupProposalStatus.Rejected;
        }
      }) 
    });
  }

  public promoteItem(value: string): void {
    this._deficitProposalService.promoteDeficitGroupProposal(value)
    .subscribe(() => {
      this.items.map(proposal => {
        if (proposal.id === value) {
          proposal.status = DeficitGroupProposalStatus.Promoted;
        }
      }) 
    });
  }

  public showHistory(value: string): void {
    this._deficitProposalService.getDeficitGroupProposalHistory(value)
    .subscribe((history) => {
      // console.log(res);
      let changeHistory = new DeficitGroupProposalHistoryHelper(history).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.GASubscription.unsubscribe();
  }
}
