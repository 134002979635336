import { Rating } from './models/rating';
import { DeficitValueResponse } from './models/deficit-value-response';
import { CommentResource } from './models/comment.resource';
import { DeficitFixed } from './models/deficit-fixed';
import { BaseExistingResource } from './models/base-existing.resource';
import { FileAttachment } from './models/file-attachment.resource';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class BaseSampleResponse extends BaseExistingResource {
    id: string;
    rating: Rating;
    contractPositionConfigurationId: string;
    tradeConfigurationId: string;
    appendix: string;
    ticketNumber: string;
    ticketDescription: string;
    tenderPosition: string;
    attachementLink: string;
    plant: string;
    roomNumber: string;
    deficitValues: DeficitValueResponse[] = [];
    comments: CommentResource[];
    attachments: FileAttachment[] = [];
    contractEntityNumber: string;
    deficitFixed: DeficitFixed;
    isEditable: boolean;
    isWithdrawn: boolean = false;
    dedicatedUser: UserResponse;
}
