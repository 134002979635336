import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epo-modal-is-syncing',
  templateUrl: './modal-is-syncing.component.html',
  styleUrls: ['./modal-is-syncing.component.scss']
})
export class ModalIsSyncingComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
