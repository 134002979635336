import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContractService } from '../../contract/contract.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { TradeConfiguration } from './trade-configuration.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';

@Injectable({
    providedIn: 'root'
})

export class TradeConfigurationService {
    constructor(private _http: HttpClient, private contractService: ContractService) {
    }

    getTradeConfigurationsForCurrentContract(active?: boolean): Observable<PagedResultResponse<TradeConfiguration>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                let url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations`;
                if (contract.contractLanguageId) {
                    url = `${url}?languageId=${contract.contractLanguageId}`;
                }
                if (active != undefined) {
                    url = `${url}${url.indexOf('?') > -1 ? '&' : '?'}configurationActive=${active}`;
                }
                return this._http.get<PagedResultResponse<TradeConfiguration>>(url)
            })
        );
    }

    createTradeConfiguration(tradeId: string): Observable<TradeConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations`;
                return this._http.post<TradeConfiguration>(url, { tradeId: tradeId, isActive: true });
            }));
    }

    deleteTradeConfiguration(tradeConfigurationId: string): Observable<any> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations/${tradeConfigurationId}`;
                return this._http.delete(url);
            }));
    }

    setTradeConfigurationActive(tradeConfigurationId: string, isActive: boolean): Observable<TradeConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations/${tradeConfigurationId}/active`;
                return this._http.patch<TradeConfiguration>(url, { isActive });
            }));
    }

    public getTradeConfigurationHistory(id: string): Observable<TradeConfiguration[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                return this._http.get<TradeConfiguration[]>(`${environment.apiUrl}/api/${contract.id}/tradeconfigurations/${id}/history`);
            }));
    }
}