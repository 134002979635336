import { EInterval } from '../../shared/models/interval';
import { ExtrapolationHelper } from '../../shared/extrapolation-helper';
import { Karte } from '../../shared/components/card/card';
import { TargetResponse } from 'app/random-sample/shared/target-response';
import { AcceptanceResource } from 'app/acceptance/shared/acceptance-resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';

export class AcceptanceCardsCreator {

    constructor(private selectedIntervalType: EInterval,
        private contractPositions: ContractPositionConfiguration[],
        private targets: TargetResponse[],
        private samples: AcceptanceResource[]) { }


    public createCards(): Karte[] {
        const karten = [];

        // Karten vorbereiten.
        let currentColorIndex = 0;
        this.targets.forEach((target) => {
            // Target.
            const filteredContractPosition = this.findContractPosition(this.contractPositions, target);
            if (!filteredContractPosition) {
                return;
            }
            // Menge.
            const acceptancesCount = this.getSumOfFilteredSamples(this.samples, filteredContractPosition.id);

            // Karten erstellen und in Array übergeben.
            karten.push({
                id: filteredContractPosition.id,
                name: filteredContractPosition.contractPosition.name,
                backgroundColor: this._colors[currentColorIndex],
                interval: this.selectedIntervalType,
                sollWert: ExtrapolationHelper.extrapolateValueByIntervalType(target.amount, target.interval, this.selectedIntervalType),
                istWert: acceptancesCount,
                spTargetUrl: null
            });

            if (currentColorIndex < this._colors.length) {
                // Solange der currentColorIndex nicht größer ist als die Gesamtanzahl
                // der verfügbaren Farben (_colors), kann inkrementiert werden.
                currentColorIndex++;
            }
        });

        return karten;
    }

    private getSumOfFilteredSamples(abnahmen: AcceptanceResource[], contractPositionId: string): number {
        const stichprobenFilterByStichprobenart = abnahmen.filter((abnahme) => {

            if (abnahme.contractPositionConfigurationId === contractPositionId) {
                return true;
            } return false;
        });

        return stichprobenFilterByStichprobenart.length;
    }

    private findContractPosition(contractPositions: ContractPositionConfiguration[], target: TargetResponse) {
        const foundContractPosition = contractPositions.find((cp) => {
            if (cp.id === target.contractPositionConfigurationId) {
                return true;
            }
        });

        return foundContractPosition;
    }


    private _colors = [
        '#17a2b8',
        '#795548',
        '#ce93d8',
        '#ec6f6f',
        '#03a9f4',
        '#ffc107',
        // Fallback.
        '#677593',
        '#aac107'
    ];

}
