import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'epo-column-filter',
    templateUrl: './column-filter.component.html',
    styleUrls: ['./column-filter.component.scss'],
    outputs: ['selectionChange', 'searchTextChange']
})
export class ColumnFilterComponent<T> implements OnInit {
    public selectionChange: EventEmitter<T> = new EventEmitter<T>();
    public searchTextChange: EventEmitter<string> = new EventEmitter<string>();
    private _selection: T;
    @Input() set selection(selection: T) {
        this.searchTerm = this.getDisplayString(selection);
        this._selection = selection;
        this.resultsShown = false;
        this.results = [];
    }
    get selection(): T {
        return this._selection;
    }

    @Input() set searchText(text: string) {
        this.searchTerm = text;
    }
    get searchText(): string {
        return this.searchTerm;
    }
    
    @Input() getValuesFunction: (term, filter?, isActive?, contractId?, configurationActive?) => Observable<PagedResultResponse<T>>;
    @Input() displayProperty: string;
    @Input() placeholder: string = 'Start typing...';
    @Input() minCharacterNumber: number = 1;
    @Input() autocomplete: boolean = true;
    // TODO: This is a temporaty solution for checking the deficits 
    @Input() set languageId(value: string) {
        this.contractLanguage = value;
    }
    @Input() isActive: boolean;
    
    @Input() contractId: string;

    @Input() configurationActive: boolean;

    searchTerm: string;
    isSearching = false;
    resultsShown = false;
    contractLanguage: string;
    

    results: T[] = [];
    private typeTimeoutId: number;

    constructor(private contractService: ContractService, translate: TranslateService) {
        translate.get('shared.start-typing').subscribe(t => this.placeholder = t);
    }

    ngOnInit(): void {
        this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
            if (contract) {
                this.contractLanguage = contract.contractLanguageId;
            }
        });
    }

    select(result: T) {
        this.selection = result;
        this.selectionChange.emit(result)
    }

    clearSelection() {
        this.selection = null;
        this.searchTerm = null;
        this.selectionChange.emit(null);
        this.searchTextChange.emit(null);
        this.results = [];
    }

    search() {
        if (this.typeTimeoutId != null) {
            window.clearTimeout(this.typeTimeoutId);
        }
        if (this.searchTerm.length < this.minCharacterNumber && this.autocomplete) {
            this.results = [];
            return;
        }

        this.typeTimeoutId = window.setTimeout(() => {
            this.isSearching = true;
            if (!this.autocomplete) {
                this.searchTextChange.emit(this.searchTerm);
                this.typeTimeoutId = null;
                return;
            }
            this.getValuesFunction(this.searchTerm, this.contractLanguage, this.isActive, this.contractId, this.configurationActive).subscribe(
                res => {
                    this.isSearching = false;
                    this.results = res.items;
                    if (this.results.length > 0) {
                        this.resultsShown = true;
                    } else {
                        this.resultsShown = false;
                    }
                }
            )
            this.typeTimeoutId = null;
        }, this.autocomplete ? 200 : 1000);
    }

    formatDisplayText(text) {
        var regEx = new RegExp(this.searchTerm, 'ig');
        return text.replace(regEx, `<strong>$&</strong>`);
    }

    getDisplayString(val) {
        if (this.displayProperty != null) {
            return val[this.displayProperty];
        }
        else {
            return val;
        }
    }

    closeResults() {
        setTimeout(() => {
            this.resultsShown = false;
        }, 200);
    }
}