import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StatisticsComponent } from 'app/core/statistics/statistics.component';
import { BaseFilterData, allId } from 'app/shared/components/filter/filter';
import { Subscription, forkJoin } from 'rxjs';
import { StatisticFigures } from 'app/core/statistics/statistic-figures';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { ControlSampleViewModel } from '../control-sample/shared/control-sample-view-model';
import { HeaderColumn } from 'app/shared/models/header-column';
import { Leistungsergebniscluster } from 'app/shared/models/leistungsergebniscluster';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ControlService } from '../shared/control.service';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { UserProfileService } from 'app/core/user-profile.service';
import { KontrollenFormularComponent } from '../control-form/control-form.component';
import { StatisticType } from 'app/core/statistics/statistic-type.enum';
import { ControlSampleCreator } from '../control-sample/shared/control-sample-creator';
import { EpoHelper } from 'app/shared/epo-helper';
import { ControlResponse } from '../shared/control-response';
import { ControlPhase } from 'app/shared/models/controlPhase';
import { Rating } from 'app/shared/models/rating';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { ControlHistoryHelper } from '../shared/control-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'epo-control-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class ControlOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('stats', { static: false })
  private _statsComponent: StatisticsComponent;
  private _currentFilter: BaseFilterData;
  private filterSubscription: Subscription;
  public statsHeader: string;
  public figures: StatisticFigures;
  public showStats: boolean;
  private _selectedStatsType: StatisticType;
  public filterSettings = new FilterSettings(true, true, true, true, true, 'Control');
  public kontrollen: ControlSampleViewModel[];
  public currentHeaderColumns: HeaderColumn[];
  public leistungsergebnisclusterDistinct: Leistungsergebniscluster[] = [];
  public showFilterPanel = false;
  public showAllGewerk = false;
  public showAllKontrollarten = true;
  public showContractPositionColumn = false;
  public closeResult: string;
  public isLoading = true;

  public requiredRolesForCreation = [ContractUserRole.ContractorEmployee, ContractUserRole.ContractorManager];

  public contractPositions: ContractPositionConfiguration[];
  public checkTypes: CheckTypeConfiguration[];
  public deficitGroupConfigurations: DeficitGroupConfiguration[];
  public tradeConfigurations: TradeConfigurationResponse[];

  public pagingGlobalCount = 0;
  public pageSize: number = 65535;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData(true);
  }
  get page() {
    return this.currentPage;
  }

  constructor(private modalService: NgbModal,
    private _controlSampleService: ControlService,
    private _filterService: FilterService,
    private userService: UserProfileService,
    private _changeDetector: ChangeDetectorRef,
    private translate: TranslateService) {
    this.pageSize = environment.pageSize;
  }

  ngOnInit() {
    this.filterSubscription = this._filterService.getFilter().subscribe((filter) => {
      this.filterChanged(filter as BaseFilterData);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public add() {
    const modalRef = this.modalService.open(KontrollenFormularComponent, { size: 'lg' });
    modalRef.componentInstance.filterData = this._currentFilter;
    modalRef.result.then(() => {
      this.loadData(false);
    },
      () => { });
  }

  public edit(kontrolle: ControlSampleViewModel) {
    const modalRef = this.modalService.open(KontrollenFormularComponent, { size: 'lg' });
    modalRef.componentInstance.controlId = kontrolle.id;

    modalRef.result.then(() => {
      this.loadData(false);
    },
      () => { });
  }

  public filterChanged(filter: BaseFilterData) {
    if (!filter) {
      return;
    }
    this.currentPage = 1;
    this._statsComponent.resetSelection();
    this._currentFilter = filter;

    if (this._currentFilter.selectedTrade) {
      // Prüfen ob 'Alle' bei Gewerk ausgewählt worden ist, da entsprechend Spalte angezeigt wird.
      this.showAllGewerk = this._currentFilter.selectedTrade.id === allId;
    }

    if (this._currentFilter.selectedCheckType) {
      // Prüfen ob 'Alle' bei Kontrollart ausgewählt worden ist (entsprechend werden Spalten ausgeblendet);
      this.showAllKontrollarten = this._currentFilter.selectedCheckType.id === allId;
    }

    if (this._currentFilter.selectedContractPosition) {
      this.showContractPositionColumn = this._currentFilter.selectedContractPosition.id === allId;
    }
    this.loadData(true);
  }

  public statsChanged(statsType: StatisticType) {
    this.isLoading = true;
    this._selectedStatsType = statsType;
    this.getData().subscribe((responses) => {
      this.pagingGlobalCount = responses[0].count;
      const controlSamples = responses[0].items;
      this.tradeConfigurations = responses[1].items;
      this.checkTypes = responses[2];
      this.contractPositions = responses[4].items;

      const creator = new ControlSampleCreator(controlSamples, this.tradeConfigurations, this.checkTypes, this.contractPositions, this.translate);
      this.kontrollen = creator.create();

      this._changeDetector.detectChanges();
    }, () => {
      this.currentHeaderColumns = [];
      this.kontrollen = [];
    }, () => {
      this.isLoading = false;
    });
  }

  public groupByRandomGroup(currentHeaderColumns: HeaderColumn[]) {
    return EpoHelper.groupBy(currentHeaderColumns, c => c.inhaltsgruppe);
  }

  public version(control: ControlResponse) {
    this._controlSampleService.getControlHistory(control.id).subscribe(history => {
      let changeHistory = new ControlHistoryHelper(history, this.checkTypes, this.tradeConfigurations,
        this.contractPositions, this.deficitGroupConfigurations).generateHistoryEntries();
        console.log(changeHistory);
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: control.contractEntityNumber };
    })
  }

  /**
   * Finde anhand von ergebnisCluster den entsprechenden Cluster in Kontrolle
   * @param kontrolle Entspricht Reihe.
   * @param column Entspricht der Spalte.
   */
  getCssByLCluster(kontrolle: ControlSampleViewModel, column: HeaderColumn) {
    if (!kontrolle || !kontrolle.deficitValues) {
      return;
    }
    // Finde das erste Element anhand der Stichprobenart und ContractPosition.
    const foundCluster = kontrolle.deficitValues.find((lc) => {
      return column.deficitId == lc.deficitId;
    });

    if (!foundCluster) {
      // Kein Cluster gefunden.
      return;
    }

    // Nur Kontrolliert -> check
    if (!foundCluster.defizitExists) {
      return 'fa-times';
    }

    // Kontrolliert und Defizit -> times
    if (foundCluster.defizitExists) {
      return 'fa-check';
    }
  }

  private getData() {
    return forkJoin([
      this._controlSampleService.getKontrollen(this.buildLoadFilter()),
      this._controlSampleService.getTradeConfigurations(),
      this._controlSampleService.getTypes(),
      this._controlSampleService.getDeficitGroups(),
      this._controlSampleService.getContractPositions()
    ]);
  }

  private buildLoadFilter() {
    return {
      selectedDateRange: this._currentFilter.selectedDateRange,
      contractPositionId: this._currentFilter.selectedContractPosition.id,
      typeId: this._currentFilter.selectedCheckType.id,
      tradeId: this._currentFilter.selectedTrade.id,
      userId: this._currentFilter.selectedUser.id,
      skipNr: this.pageSize * (this.currentPage - 1),
      takeNr: this.pageSize,
      statsType: this._selectedStatsType
    };
  }

  private loadData(forceReload: boolean, manualRunChangeDetection?: boolean) {
    this.isLoading = true;
    if (forceReload) {
      this.currentHeaderColumns = [];
      this._controlSampleService.cleanCache();
    }

    this.getData().subscribe((responses) => {
      this.pagingGlobalCount = responses[0].count;
      const controlSamples = responses[0].items;
      this.tradeConfigurations = responses[1].items;
      this.checkTypes = responses[2];
      this.deficitGroupConfigurations = responses[3];
      this.contractPositions = responses[4].items;

      const filteredColumns =
        this.filterDistinctContentSamplesByNameOfStichprobenart(this.deficitGroupConfigurations, this._currentFilter.selectedCheckType.checkTypeId);
      this.currentHeaderColumns = EpoHelper.sortBy(filteredColumns, 'inhaltsgruppe');

      const creator = new ControlSampleCreator(controlSamples, this.tradeConfigurations, this.checkTypes, this.contractPositions, this.translate);
      this.kontrollen = creator.create();

      if (this._currentFilter.selectedUser.id === allId) {
        this.statsHeader = 'controls.all-controls';
      } else {
        this.statsHeader = 'controls.my-controls';
      }
      this._controlSampleService.getStatistics(this.buildLoadFilter()).subscribe(res => {
        const fig = new StatisticFigures(
          true,
          res.noClaimCount,
          res.claimCount,
          res.reclamationCount,
          0,
          0,
          0,
          res.inProgressCount,
          res.notFixedCount,
          0
        );
        this.figures = fig;
      });
      this._statsComponent.resetSelection();

      if (manualRunChangeDetection) {
        this._changeDetector.detectChanges();
      }

      this.isLoading = false;
    }, (err) => {
      console.error('Erorr in getting data: ');
      console.error(err);
      this.currentHeaderColumns = [];
      this.kontrollen = [];
    });
  }

  public isOl(kontrolle: ControlSampleViewModel) {
    return kontrolle.status === ControlPhase.DoneOl;
  }

  public isTl(kontrolle: ControlSampleViewModel) {
    return kontrolle.status === ControlPhase.DoneTl;
  }

  public isKvp(kontrolle: ControlSampleViewModel) {
    return kontrolle.status === ControlPhase.DoneKVP;
  }

  public showDefizit(kontrolle: ControlSampleViewModel) {
    return kontrolle.rating === Rating.Reclamation || kontrolle.rating === Rating.Claim;
  }

  public getCssForDefizit(kontrolle: ControlSampleViewModel) {
    if (kontrolle.deficitFixed === DeficitFixed.Ja) {
      return 'fa-thumbs-up';
    }
    if (kontrolle.deficitFixed === DeficitFixed.Nein) {
      return 'fa-thumbs-down';
    }
    if (kontrolle.deficitFixed === DeficitFixed.ZurKlaerung) {
      return 'fa-question';
    }
  }

  public getCssForGrade(rating: Rating) {
    if (rating === Rating.NoClaim) {
      return 'fa-thumbs-up green';
    }
    if (rating === Rating.Claim) {
      return 'fa-thumbs-down yellow';
    }
    if (rating === Rating.Reclamation) {
      return 'fa-thumbs-down red';
    }
  }

  private filterDistinctContentSamplesByNameOfStichprobenart(deficitGroups: DeficitGroupConfiguration[], kontrollArtId: string): HeaderColumn[] {
    const columns: HeaderColumn[] = [];

    deficitGroups.forEach(deficitGroup => {
      deficitGroup.deficitConfigurations.forEach(deficit => {
        if (deficit.deficit.mappings.some(map => map.checkTypeId === kontrollArtId)) {
          // Finde Duplikat anhand von Titel.
          const duplicate = columns.find((uniqueColumn) => {
            return uniqueColumn.title === deficit.deficit.title;
          });
          // Prüfe auf Duplikat.
          if (!duplicate) {
            // Finde alle Ids, welche den selben Titel haben.
            columns.push(new HeaderColumn(deficit.deficit.title, deficit.deficit.description, deficit.id, deficitGroup.name));
          }
        }
      });
    });

    return columns;
  }
}
