import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { StatusPhase } from 'app/shared/models/status-phase';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { Rating } from 'app/shared/models/rating';
import { ControlResponse } from './control-response';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class ControlHistoryHelper{

    constructor(private spotChecks: ControlResponse[], private checkTypeConfigurations: CheckTypeConfiguration[],
        private tradeConfigurations: TradeConfigurationResponse[], private contractPositionConfigurations: ContractPositionConfiguration[],
        private deficitConfigurations: DeficitGroupConfiguration[]){
    }

    private checkProperties : PropertyDisplayMapping[] = [
        {propertyName: 'checkTypeConfigurationId', displayName: 'controls.type', displayValueTransformation: (id) => this.resolveCheckType(id)},
        {propertyName: 'statusPhaseOne', displayName: 'controls.form.state', displayValueTransformation: this.resolveStatusPhase },
        {propertyName: 'rating', displayName: 'controls.form.rating', displayValueTransformation: this.resolveRating},
        {propertyName: 'contractPositionConfigurationId', displayName: 'controls.contract-position', displayValueTransformation: (id) => this.resolveContractPos(id)},
        {propertyName: 'tradeConfigurationId', displayName: 'controls.trade', displayValueTransformation: (id) => this.resolveTrade(id)},
        {propertyName: 'appendix', displayName: 'controls.annotation'},
        {propertyName: 'ticketNumber', displayName: 'controls.form.ticket-nr'},
        {propertyName: 'ticketDescription', displayName: 'controls.form.description'},
        {propertyName: 'tenderPosition', displayName: 'controls.form.tender-position'},
        {propertyName: 'deviceShortName', displayName: 'controls.form.plant'},
        {propertyName: 'deviceUsageKind', displayName: 'controls.form.usage-kind'},
        {propertyName: 'attachementLink', displayName: 'controls.form.attachment-link'},
        {propertyName: 'plant', displayName: 'controls.form.system'},
        {propertyName: 'roomNumber', displayName: 'controls.form.room'},
        {propertyName: 'deficitFixed', displayName: 'controls.form.deficit-fixed', type: 'boolean', displayValueTransformation: this.resolveDeficitfixed},
        {propertyName: 'isWithdrawn', displayName: 'controls.form.withdrawn-control', type: 'boolean' },
        { propertyName: 'dedicatedUser', displayName: 'controls.form.dedicated-user', displayValueTransformation: this.resolveDedicatedUserName }

    ]
    
    public generateHistoryEntries() : HistoryEntryModel[]{
        const historyResources = [new ControlResponse(), ...this.spotChecks];
        let history = [];
        for(let i = historyResources.length - 1; i > 0; --i){
            const entry = this.createHistoryEntry(historyResources[i-1], historyResources[i]);
            if(entry){
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: ControlResponse, newValues: ControlResponse) : HistoryEntryModel{
        let historyEntry = new HistoryEntryModel();
        for(const prop of this.checkProperties){
            if(newValues[prop.propertyName] != oldValues[prop.propertyName]){
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if(prop.displayValueTransformation != undefined){
                    if (prop.propertyName === 'dedicatedUser') {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    } else {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    }
                }else{
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if(changedProperty.newValue != null || changedProperty.oldValue != null){
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }
        this.handleDeficits(newValues, oldValues, historyEntry);
        if(historyEntry.changedProperties.length > 0){
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc+ 'Z';
            return historyEntry;
        }
        return null;
    }

    private resolveDedicatedUserName(usr: UserResponse): string {
        if (usr) {
            if (usr.firstname && usr.lastname) {
                return `${usr.firstname} ${usr.lastname}`;
            } else {
                return `${usr.loginEmail}`;
            }
        } else {
            return "";
        }
    }

    private handleDeficits(newValues: ControlResponse, oldValues: ControlResponse, historyEntry: HistoryEntryModel){
        let changedDeficits = new ChangedProperty();
        changedDeficits.propertyName = 'shared.deficits';
        for(const newDeficit of newValues.deficitValues){
            const oldDeficit = oldValues.deficitValues && oldValues.deficitValues.find(old => old.deficitId == newDeficit.deficitId);
            if(oldDeficit){
                if(newDeficit.defizitExists != oldDeficit.defizitExists){
                    changedDeficits.subProperties.push({
                        propertyName: this.resolveDeficitName(newDeficit.deficitId),
                        newValue: newDeficit.defizitExists,
                        oldValue: oldDeficit.defizitExists,
                        viewType: 'boolean'
                    })
                }
            }else{
                changedDeficits.subProperties.push({
                    propertyName: this.resolveDeficitName(newDeficit.deficitId),
                    newValue: newDeficit.defizitExists,    
                    viewType: 'boolean'
                })
            }
        }
        const removedDeficits = oldValues.deficitValues.filter(oldDef => newValues.deficitValues.find(newDef => newDef.deficitId == oldDef.deficitId) == undefined);
        for(const def of removedDeficits){
            changedDeficits.subProperties.push({
                propertyName: this.resolveDeficitName(def.deficitId),
                oldValue: def.defizitExists,
                viewType: 'boolean'
            })
        }
        if(changedDeficits.subProperties.length > 0){
            historyEntry.changedProperties.push(changedDeficits);
        }
    }

    private resolveStatusPhase(phase: StatusPhase){
        switch (phase){
            case StatusPhase.Accepted:
                return 'status.accepted';
            case StatusPhase.Conflict:
                return 'status.conflict';
            case StatusPhase.ConflictCancelled:
                return 'status.objection-cancelled';
            case StatusPhase.Done:
                return 'status.performed';
            case StatusPhase.ClaimCancelled:
                return 'status.claim-reclamation-cancelled';
            case StatusPhase.InProgress:
                return 'status.in-progress'
        }
    }

    private resolveDeficitfixed(deficitFixed: DeficitFixed) : boolean{
        switch(deficitFixed){
            case DeficitFixed.Ja: return true;
            case DeficitFixed.Nein: return false;
            default: return null;
        }
    }

    private resolveRating(rating: Rating) : string{
        switch(rating){
            case Rating.Claim: return 'rating.claim';
            case Rating.Reclamation: return 'rating.reclamation';
            case Rating.NoClaim: return 'rating.no-claim';
            case Rating.InProgress: return 'rating.in-progress';
            default: return null;
        }
    }

    private resolveContractPos(id: string) : string{
        const pos = this.contractPositionConfigurations.find(p => p.id == id);
        return pos && pos.contractPosition.name || id;
    }

    private resolveCheckType(id: string): string{
        const type = this.checkTypeConfigurations.find(type => type.id == id);
        return type && type.checkType.name || id;
    }

    private resolveTrade(id: string) : string{
        const trade = this.tradeConfigurations.find(trade => trade.id == id);
        return trade && trade.trade.name || id;
    }

    private resolveDeficitName(deficitId: string) : string{
        for(const grp of this.deficitConfigurations){
            const deficit = grp.deficitConfigurations.find(def => def.id == deficitId);
            if(deficit){
                return deficit.deficit.title;
            }
        }
        return deficitId;
    }

    private discardEmptyString(input: string) : string{
        if(!this.isString(input)) return input;
        if(input.trim()) return input;
        return null;
    }

    private isString(value) {
        return typeof value === 'string';
    }
}

