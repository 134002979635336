import { Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ToasterMessage } from './toaster-message';
import { ToasterMessageModel } from './toaster-message-model';
import { ToasterService } from './toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'epo-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnDestroy {
  newElementUniqueId = this.generateRandomString();
  containerElementId = this.generateRandomString();
  messages: ToasterMessageModel[] = [];
  newMessage: ToasterMessageModel = new ToasterMessageModel();

  messagesToAnimate: ToasterMessageModel[] = [];
  isAnimatingMessage: boolean = false;

  private subscription: Subscription;

  @ViewChild('newMessage', { static: false }) newMessageElement: ElementRef;

  constructor(private toasterService: ToasterService) { 
    this.subscription = this.toasterService.getSubject().subscribe((message) =>{
      if (this.messages.find(m => m.description === message.description)) {
        return;
      }
      this.showParams(message);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showParams(toasterMessage: ToasterMessage) {
    let messageModel = this.mapToModel(toasterMessage);

    this.messagesToAnimate.push(messageModel);

    if(!this.isAnimatingMessage) {
      this.isAnimatingMessage = true;
      this.animateWaitingMessages();
    }
  }

  messageClick(toasterMessage: ToasterMessageModel) {
    if(toasterMessage.intervalId != null) {
      clearTimeout(toasterMessage.intervalId);
    }

    this.hideMessage(toasterMessage);
  }

  hideMessage(toasterMessage: ToasterMessageModel) {
    toasterMessage.isShown = false;

    setTimeout(() => {
      this.messages.splice(this.messages.indexOf(toasterMessage), 1);
    }, 601);
  }

  private animateWaitingMessages() {
    if(this.messagesToAnimate.length == 0) {
      this.isAnimatingMessage = false;
      return;
    }

    this.animateMessage(this.messagesToAnimate.shift(), () => {
      this.animateWaitingMessages();
    });
  }

  private animateMessage(messageModel: ToasterMessageModel, callback) {
    this.newMessage = messageModel;

    let container = document.getElementById(this.containerElementId);
    window.setTimeout(() => {
      let animationId = null;
      if(this.messages.length > 0) {
        let height = document.getElementById(this.newElementUniqueId).offsetHeight;
        animationId = this.animate(container, height);
      }

      this.newMessage.isShown = true;

      setTimeout(() => {
        this.newMessage =  new ToasterMessageModel();
        this.messages.push(messageModel);

        if(animationId != null) {
          clearInterval(animationId);
        }
        container.style.bottom = null;

        if (messageModel.autoDismiss) {
          messageModel.intervalId = setTimeout(() => {
            this.hideMessage(messageModel);
          }, messageModel.timeout);
        }

        if(callback != null) {
          callback();
        }
      }, 750);
    }, 10);
  }


  private generateRandomString() {
    return Math.random().toString(36).substr(2, 9);
  }

  private animate(container, newPosition) {
    let pos = 0;
    let step = newPosition / 10;
    let id = setInterval(() => {
      if (pos == 10) {
        clearInterval(id);
      } else {
        pos++;
        container.style.bottom = pos * step + 'px'; 
      }
    }, 28);
  }

  private mapToModel(toasterMessage) : ToasterMessageModel {
    return {
      heading: toasterMessage.heading,
      description: toasterMessage.description,
      technical: toasterMessage.technical,
      autoDismiss: toasterMessage.autoDismiss != null ? toasterMessage.autoDismiss : true,
      timeout: toasterMessage.timeout != null ? toasterMessage.timeout : 5000,
      isShown: false,
      type: toasterMessage.type || 'info'
    };
  }
}
