import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MsalModule, MsalService, MsalGuard, MsalRedirectComponent, MsalBroadcastService } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

import { MomentModule } from 'ngx-moment';

/* i18n */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* Bootstrap UI */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* App Root */
import { AppComponent } from './app.component';

/* Feature Modules */
import { CoreModule } from './core/core.module';
import { ControlModule } from './control/control.module';
import { SpotCheckModule } from './random-sample/spot-check.module';
import { AcceptanceModule } from './acceptance/acceptance.module';
import { AdminModule } from './admin/admin.module';
// tslint:disable-next-line: max-line-length
import { ModalSamplePlanningEditComponent } from './random-sample/random-sample-targets/modal-sample-target-edit/modal-sample-target-edit.component';
import { ModalControlTargetsEditComponent } from './control/control-targets/modal-control-target-edit/modal-control-target-edit.component';
// tslint:disable-next-line: max-line-length
import { TradesComponent } from './admin/allgemein/trades/trades.component';
import { UsersComponent } from './admin/allgemein/users/users.component';
import { ModalTradeEditComponent } from './admin/allgemein/trades/modal-trade-edit/modal-trade-edit.component';
import { DeficitGroupsComponent } from './admin/allgemein/deficit-groups/deficit-groups.component';
// tslint:disable-next-line: max-line-length
import { ModalDeficitGroupsEditComponent } from './admin/allgemein/deficit-groups/modal-deficit-groups-edit/modal-deficit-groups-edit.component';
import { AcceptanceTargetsComponent } from './acceptance/acceptance-targets/acceptance-targets.component';
// tslint:disable-next-line: max-line-length
import { ModalAcceptanceTargetEditComponent } from './acceptance/acceptance-targets/modal-acceptance-target-edit/modal-acceptance-target-edit.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tokenProvider } from './shared/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { ToasterService } from './shared/components/toaster/toaster.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}


@NgModule({
  declarations: [
    AppComponent,
    ModalSamplePlanningEditComponent,
    ModalControlTargetsEditComponent,
    TradesComponent,
    UsersComponent,
    ModalTradeEditComponent,
    DeficitGroupsComponent,
    ModalDeficitGroupsEditComponent,
    AcceptanceTargetsComponent,
    ModalAcceptanceTargetEditComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    CoreModule,
    ControlModule,
    SpotCheckModule,
    AcceptanceModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    SharedModule,
    MomentModule,
    MsalModule.forRoot( new PublicClientApplication({ // MSAL Configuration
      auth: {
          clientId: environment.authConfig.clientId,
          authority: `https://login.microsoftonline.com/${environment.authConfig.tenant}`,
          redirectUri: '/',
          postLogoutRedirectUri: '/'
      },
      cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
  }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['User.Read']
      },
      loginFailedRoute: "/unauthorized" 
  },null),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
      isolate: false
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    MsalService,
    MsalGuard,
    tokenProvider,
    ToasterService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: []
})

export class AppModule { }
