import { EInterval } from 'app/shared/models/interval';
import { UserResponse } from '../../admin/allgemein/users/user-response';

export class AcceptanceTarget {
    id: string;
    contractPositionConfigurationId: string;
    tradeConfigurationId: string;
    interval: EInterval;
    amount: number;
    createdUtc: string;
    modifiedUtc: string;
    createdBy: UserResponse;
    modifiedBy: UserResponse;
}
