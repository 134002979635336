<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<app-loading-spinner class="loading-spinner" *ngIf="isLoading"></app-loading-spinner>
<div class="modal-body" [ngClass]="{ transparent: isLoading == true }">
  <fieldset [disabled]="disableForm">
    <form novalidate #f="ngForm">
      <fieldset [disabled]="controlWithdrawn">
        <div class="form-group row" *ngIf="controlFormData.ticketNumber">
          <label for="ticketNumber" class="col-sm-2 col-form-label">
            {{ "controls.form.archibus-link" | translate }}</label>
          <div class="col-xl-10 link">
            <a href="{{ ticketUrl }}" target="_blank">{{ "controls.form.archibus-link" | translate }}
              {{ controlFormData.ticketNumber }}</a>
          </div>
        </div>
  
        <ng-container *ngIf="!isNewControlSample">
          <div *hideIfMissingRole="requiredRolesForSpotCheckCreation" class="form-group row">
            <label for="ticketNumber" class="col-sm-2 col-form-label">{{
              "controls.form.create-spot-check" | translate
              }}</label>
            <div class="col-xl-10 link">
              <a href="{{ createSpotCheckLink }}" class="btn btn-secondary" target="_blank">
                <i class="fas fa-plus"></i>
                {{ "controls.form.create-spot-check-details" | translate }}</a>
            </div>
          </div>
        </ng-container>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.contract-position" | translate }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="controlFormData.contractPositionConfigurationId" #inputContract="ngModel"
              class="custom-select" name="dropdownContract" id="selectContractPosition" [disabled]="contractDisabled"
              (change)="updateDefizite()" required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!controlFormData.contractPositionConfigurationId"></option>
              <!---->
              <option *ngFor="let contractPositionConfig of contractPositionConfigs"
                [ngValue]="contractPositionConfig.id">
                {{ contractPositionConfig.contractPosition.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputContract.invalid" class="alert alert-danger" role="alert">
              {{ "controls.form.select-contract-position" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.type" | translate }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="controlFormData.checkTypeConfigurationId" #inputSample="ngModel" class="custom-select"
              name="dropdownSample" id="selectKontrollart" [disabled]="controlTypeDisabled" (change)="updateDefizite()"
              required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!controlFormData.checkTypeConfigurationId"></option>
              <!---->
              <option *ngFor="let checkTypeConfig of checkTypeConfigs" [ngValue]="checkTypeConfig.id">
                {{ checkTypeConfig.checkType.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputSample.invalid" class="alert alert-danger" role="alert">
              {{ "controls.form.select-type" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.trade" | translate }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="controlFormData.tradeConfigurationId" #inputGewerk="ngModel" class="custom-select"
              name="dropdownGewerk" id="selectGewerke" [disabled]="gewerkDisabled" required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!controlFormData.tradeConfigurationId"></option>
              <!---->
              <option *ngFor="let tradeConfig of tradeConfigs" [ngValue]="tradeConfig.id">
                {{ tradeConfig.trade.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputGewerk.invalid" class="alert alert-danger" role="alert">
              {{ "controls.form.select-trade" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.ticket-nr" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.ticketNumber" type="text" class="form-control" id="inputAnforderungen"
              name="inAnforderungen" [disabled]="requirementIdDisabled" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.description" | translate }}</label>
          <div class="col-xl-10">
            <textarea [(ngModel)]="controlFormData.ticketDescription" name="inputDescription" class="form-control"
              id="inputDescription" [disabled]="descriptionDisabled" rows="3"></textarea>
            <p class="subtext">{{ "controls.form.import-ticket" | translate }}</p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.tender-position" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.tenderPosition" type="text" class="form-control" id="lvPos"
              [disabled]="lvPositionDisabled" name="lvPos" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.device" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.deviceShortName" [disabled]="deviceShortNameDisabled" type="text"
              class="form-control" id="kurzzeichenGerät" name="kurzzeichenGerät" />
            <p class="subtext">{{ "controls.form.plant-details" | translate }}</p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.usage-kind" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.deviceUsageKind" [disabled]="usageTypeDisabled" type="text"
              class="form-control" id="nutzungsArt" name="nutzungsArt" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.attachment-link" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.plant" type="text" class="form-control" name="textAnlageStandard"
              id="anlageStandard" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.room" | translate }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="controlFormData.roomNumber" type="text" class="form-control" name="room" id="room"
              [disabled]="raumDisabled" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.deficits" | translate }}</label>
          <div class="col-xl-10">
            <epo-defizite (selectedDeficits)="onSelectedDefiziteChanged($event)" [disableDefizite]="defiziteDisabled"
              [deficitGroups]="filteredDeficitGroups" [hasDefizite]="deficiteExistInSelectedCombination">
            </epo-defizite>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label"></label>
          <div class="col-xl-10">
            <div *ngIf="f.submitted && noDefiziteSelected && deficiteExistInSelectedCombination"
              class="alert alert-danger" role="alert">
              {{ "controls.form.select-deficit" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.deficit-note" | translate }}</label>
          <div class="col-xl-10">
            <textarea [(ngModel)]="controlFormData.appendix" [disabled]="commentDisabled" name="inputDefizitComment"
              class="form-control" id="textAreaAnmerkung" [disabled]="commentDisabled" rows="3"></textarea>
            <p class="subtext">
              {{ "controls.form.deficit-subtext" | translate }}
            </p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.state" | translate }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="statusPhase1Value" class="custom-select" name="controlStatus" id="selectStatus"
              [disabled]="controlStateDisabled" (change)="onChangeStatus()">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!statusPhase1Value"></option>
              <!---->
              <option *ngFor="let status of sampleStatusPhase1" [value]="status.value" [disabled]="status.disabled">
                {{ status.dispayName | translate }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.dedicated-user" | translate}}</label>
          <div class="col-xl-10">
            <select #dedicatedUser [(ngModel)]="assignedUserGuid" class="custom-select" name="dedicated-user">
              <option *ngFor="let dedicatedUser of dedicatedUsers" [value]="dedicatedUser.id" [disabled]="!dedicatedUser.isActive">{{printNameOfContractUser(dedicatedUser)}}</option>
            </select>
          </div>
        </div>
  
        <!---->
        <fieldset class="form-group" [disabled]="controlRatingDisabled">
          <div class="row">
            <legend class="col-form-label col-xl-2 pt-0">
              {{ "controls.form.rating" | translate }}
            </legend>
            <div class="col-xl-10">
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="controlFormData.rating" #inputRating="ngModel"
                  [required]="statusPhase1Value != 1" [value]="rating.NoClaim" type="radio" name="rating"
                  id="radioNoClaim" [attr.disabled]="hasExistingDeficit" />
                <div class="radio-label">
                  <h6>
                    {{ "controls.form.no-claim" | translate }}
                  </h6>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="controlFormData.rating" [required]="statusPhase1Value != 1"
                  [value]="rating.Claim" type="radio" name="rating" id="radioClaim" value="claim"
                  [attr.disabled]="hasNoDeficits" />
                <div class="radio-label">
                  <h6>
                    {{ "controls.form.claim" | translate }}
                  </h6>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="controlFormData.rating" [required]="statusPhase1Value != 1"
                  [value]="rating.Reclamation" type="radio" name="rating" id="radioReklamation" value="reklamation" />
                <div class="radio-label">
                  <h6>
                    {{ "controls.form.reclamation" | translate }}
                  </h6>
                </div>
              </div>
              <div *ngIf="f.submitted && inputRating.invalid" class="alert alert-danger" role="alert">
                {{ "controls.form.please-rate" | translate }}
              </div>
            </div>
          </div>
        </fieldset>
  
        <!---->
  
        <div class="form-group row" *ngIf="controlFormData?.comments?.length > 0">
          <label class="col-xl-2 col-form-label">{{ "controls.form.comments" | translate }}</label>
          <div class="col-xl-10">
            <epo-sample-comments [comments]="controlFormData.comments"></epo-sample-comments>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.comment" | translate }}</label>
          <div class="col-xl-10">
            <textarea class="form-control" name="comment" rows="5" type="text" [(ngModel)]="controlFormData.comment">
            </textarea>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "files.attachments" | translate }}</label>
          <div class="col-xl-10 file-link">
            <p *ngFor="let attachment of controlFormData.attachments">
              <a href="{{ attachment.url }}" target="_blank" download="{{ attachment.fileName }}">
                {{ attachment.fileName }} </a><i class="fa fa-times-circle" (click)="removeAttachment(attachment)"></i>
            </p>
            <p *ngFor="let file of filesToUpload">
              <span class="">{{ file.name }} ({{ (file.size / 1000000).toFixed(2) }} Mb)</span>
              <i class="fa fa-times-circle" (click)="removeFile(file)"></i>
            </p>
            <div *ngIf="fileError" class="alert alert-danger" role="alert">
              {{ "files.upload-error" | translate }}
            </div>
            <div class="progress form-group" *ngIf="progress > 0">
              <ngb-progressbar [type]="fileError ? 'danger' : 'primary'" [value]="progress" class="file-progress">
                <span>{{ progress }} %</span>
              </ngb-progressbar>
            </div>
            <div>
              <input type="file" accept=".jpg" id="controlFileInput" #controlFileInput
                (change)="selectFile($event.target.files)" multiple [hidden]="true" />
              <button class="btn btn-outline-dark" (click)="openFileSelect($event)">
                {{ "files.upload-files" | translate }}
              </button>
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "controls.form.deficit-fixed" | translate }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="controlFormData.deficitFixed" #inputDeficite="ngModel" class="custom-select"
              name="dropdownDeficite" id="selectDeficite" [disabled]="fixedDisabled">
              <option *ngFor="let state of deficitFixedStatus" [ngValue]="state.value">
                {{ getControlStateName(state.displayName) }}
              </option>
            </select>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label"></label>
          <div class="col-xl-10">
            <div
              *ngIf="f.submitted && (inputContract.invalid || noDefiziteSelected || inputGewerk.invalid || inputSample.invalid)"
              class="alert alert-danger" role="alert">
              {{ "controls.form.required" | translate }}
            </div>
          </div>
        </div>
  
        <epo-created-modified [createdBy]="controlFormData.createdBy" [modifiedBy]="controlFormData.modifiedBy"
          [createdUtc]="controlFormData.createdUtc" [modifiedUtc]="controlFormData.modifiedUtc"></epo-created-modified>
  
      </fieldset>
      <div class="modal-footer">
        <button *ngIf="isUserGlobalAdmin && controlSample" type="button" class="btn btn-outline-dark" (click)="withdrawControl()" [disabled]="isSaving">
          <i *ngIf="!controlWithdrawn" class="fas fa-trash"></i>
          <span *ngIf="!controlWithdrawn">{{ "shared.dialog.withdraw" | translate }}</span>
          <i *ngIf="controlWithdrawn" class="fas fa-trash-restore"></i>
          <span *ngIf="controlWithdrawn">{{ "shared.dialog.restore" | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
          <i class="icon" class="fas fa-ban"></i>
          {{ "shared.dialog.cancel" | translate }}
        </button>
        <button class="btn btn-dark" (click)="onSubmit()" [disabled]="isSaving">
          <i class="icon" class="fas fa-save"></i>
          {{ "shared.dialog.save" | translate }}
        </button>
      </div>
    </form>
  </fieldset>
</div>