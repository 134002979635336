import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { Rating } from 'app/shared/models/rating';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { ControlPhase } from 'app/shared/models/controlPhase';
import { CommentResource } from 'app/shared/models/comment.resource';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { FileAttachment } from 'app/shared/models/file-attachment.resource';

export class Control {
    public rating: Rating;
    public checkTypeConfigurationId: string;
    public contractPositionConfigurationId: string;
    public tradeConfigurationId: string;
    public createdUtc: string;
    public modifiedUtc: string;
    public createdBy: UserResponse;
    public modifiedBy: UserResponse;
    public appendix: string;
    public ticketNumber: string;
    public ticketDescription: string;
    public tenderPosition: string;
    public plant: string;
    public roomNumber: string;
    public deviceShortName: string;
    public deviceUsageKind: string;
    public deficitFixed: DeficitFixed;
    public statusPhaseOne: ControlPhase;
    public statusPhaseTwo: ControlPhase;
    public statusPhaseThree: ControlPhase;
    public deficitValues: DeficitValueResponse[];
    public comment: string;
    public comments: CommentResource[];
    public attachments: FileAttachment[] = [];
    public id: string;
    public isWithdrawn:boolean;
    public dedicatedUser: UserResponse;

    public isFormDataValid(currentStatus: ControlPhase): boolean {
        if (!this.contractPositionConfigurationId || !this.checkTypeConfigurationId || !this.tradeConfigurationId) {
            return false;
        }
        return this.isValidRatingCombination(currentStatus);
    }

    private isValidRatingCombination(status: ControlPhase) {
        if (this.rating && this.rating !== Rating['0']) {
            return true;
        } else if (status === ControlPhase.InProgress) {
            return true;
        }
        return false;
    }

}
