import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ControlTargetResponse } from 'app/shared/models/control-targets-response';
import { ControlTarget } from 'app/shared/models/control-target';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, mergeMap } from 'rxjs/operators';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlTargetsService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public getControlTargets(skipNr?: number, takeNr?: number, filter?: any) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {

        let url = `${this.createEpoUrl(`api/${contract.id}/controls/targets`)}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
          url = `${url}&take=${takeNr}`;
        }
        if (filter) {
          url = LoadFilterHelper.addFilterParameter(url, contract.contractLanguageId, 'languageId');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractId, 'contractId');
          url = LoadFilterHelper.addFilterParameter(url, filter.checkType, 'checkType');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractPosition, 'contractPosition');
          url = LoadFilterHelper.addFilterParameter(url, filter.trade, 'trade');
          url = LoadFilterHelper.addFilterParameter(url, filter.orderBy, 'orderBy');
        }
        return this._httpClient.get<PagedResultResponse<ControlTargetResponse>>(url);
      }));
  }

  public createTarget(target: ControlTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/targets`);
        return this._httpClient.post(url, target);
      }));
  }

  public updateTarget(target: ControlTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/targets/${target.id}`);
        return this._httpClient.put(url, target);
      }));
  }

  public deleteTarget(id: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/targets/${id}`);
        return this._httpClient.delete(url);
      }));
  }

  public getTargetHistory(targetId: string): Observable<ControlTarget[]> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/targets/${targetId}/history`);
        return this._httpClient.get<ControlTarget[]>(url);
      }));
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}





