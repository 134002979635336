import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { Rating } from 'app/shared/models/rating';
import { ControlPhase } from 'app/shared/models/controlPhase';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';

export class ControlSampleViewModel {
    id: string;
    createdDateTimeUtc: Date;
    contractPositionId: string;
    contractPositionName: string;
    realEstateId: string;
    realEstateName: string;
    typeId: string;
    typeName: string;
    ticketNumber: string;
    appendix: string;
    ratingName: string;
    deficitValues: DeficitValueResponse[];
    rating: Rating;
    status: ControlPhase;
    deficitFixed: DeficitFixed;
    contractEntityNumber: string;
    isWithdrawn:boolean;
}
