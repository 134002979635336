import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, shareReplay, take, mergeMap } from 'rxjs/operators';
import { TargetResponse } from 'app/random-sample/shared/target-response';
import { Observable } from 'rxjs';
import { ControlResponse } from './control-response';
import { BaseSampleService } from 'app/shared/base-sample-service';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { allId } from 'app/shared/components/filter/filter';
import { CheckLoadFilter } from 'app/shared/models/check-load-filter';
import { ControlStatistics } from '../control-sample/control-statistics';

@Injectable()
export class ControlService extends BaseSampleService {
  private cachedDeficitGroups$: Observable<Array<DeficitGroupConfiguration>>;
  private cachedContractPositions$: Observable<PagedResultResponse<ContractPositionConfiguration>>;
  private cachedTrades$: Observable<PagedResultResponse<TradeConfigurationResponse>>;
  private cachedTargets$: Observable<PagedResultResponse<TargetResponse>>;
  private cachedTypes$: Observable<Array<CheckTypeConfiguration>>;
  private controlSample: Observable<ControlResponse>;

  protected _deficitGroupStore: DeficitGroupConfiguration[] = [];
  constructor(private _http: HttpClient, private contractService: ContractService) {
    super();
    this.initializeDeficitGroupStore();
  }

  public getDeficitStore() {
    return this._deficitGroupStore;
  }

  public getKontrollen(filter: CheckLoadFilter): Observable<PagedResultResponse<ControlResponse>> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/controls?skip=${filter.skipNr > 0 ? filter.skipNr : 0}`;
        if (filter.takeNr) {
          url = `${url}&take=${filter.takeNr}`;
        }
        url = this.addFilterToUrl(url, filter);
        if (filter.statsType !== null && filter.statsType !== undefined) {
          url = `${url}&statisticType=${filter.statsType}`;
        }
        return this._http.get<PagedResultResponse<ControlResponse>>(url);
      }));
  }

  public getControlSampleById(controlSampleId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = `${environment.apiUrl}/api/${contract.id}/controls/${controlSampleId}`;
        this.controlSample = this._http.get<ControlResponse>(url)
          .pipe(shareReplay());
        return this.controlSample;
      }));
  }

  public getControlHistory(controlId: string): Observable<ControlResponse[]> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        return this._http.get<ControlResponse[]>(`${environment.apiUrl}/api/${contract.id}/controls/${controlId}/history`);
      }));
  }

  public getContractPositions() {
    if (this.cachedContractPositions$) {
      return this.cachedContractPositions$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations`;
        this.cachedContractPositions$ = this._http.get<PagedResultResponse<ContractPositionConfiguration>>(url)
          .pipe(shareReplay());
        return this.cachedContractPositions$;
      }));
  }

  public getTradeConfigurations() {
    if (this.cachedTrades$) {
      return this.cachedTrades$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        this.cachedTrades$ = this._http.get<PagedResultResponse<TradeConfigurationResponse>>(url)
          .pipe(shareReplay());
        return this.cachedTrades$;
      }));
  }

  public getTargets() {
    if (this.cachedTargets$) {
      return this.cachedTargets$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = `${environment.apiUrl}/api/${contract.id}/controls/targets`;
        this.cachedTargets$ = this._http.get<PagedResultResponse<TargetResponse>>(url);
        return this.cachedTargets$;
      }));
  }

  public getTargetsBy(realEstateId: string, contractPositionId: string) {
    return this.getTargets()
      .pipe(
        map(targets => targets.items.filter(target => (realEstateId === allId || target.tradeConfigurationId === realEstateId)
          && (contractPositionId === allId || target.contractPositionConfigurationId === contractPositionId)))
      );
  }

  public getTypes() {
    if (this.cachedTypes$) {
      return this.cachedTypes$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/controls/types`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        this.cachedTypes$ = this._http.get<CheckTypeConfiguration[]>(url);
        return this.cachedTypes$;
      }));
  }

  public getDeficitGroups(active: boolean = true) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/deficitgroupconfigurations/controls?isactive=${active}`;
        if (contract.contractLanguageId) {
          url = `${url}&languageId=${contract.contractLanguageId}`;
        }
        this.cachedDeficitGroups$ = this._http.get<DeficitGroupConfiguration[]>(url);
        this.cachedDeficitGroups$.subscribe((v) => {
          if (v) {
            this._deficitGroupStore = v;
          }
        });
        return this.cachedDeficitGroups$;
      }));
  }

  public getDeficitsForControl(controlId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/deficitgroupconfigurations/controls/${controlId}`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        return this._http.get<Array<DeficitGroupConfiguration>>(url);
      }));
  }

  public getStatistics(filter: CheckLoadFilter) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/controls/statistics`;
        url = this.addFilterToUrl(url, filter);
        return this._http.get<ControlStatistics>(url);
      }));
  }

  public cleanCache() {
    this.cachedTypes$ = undefined;
    this.cachedContractPositions$ = undefined;
    this.cachedTrades$ = undefined;
  }

  protected initializeDeficitGroupStore() {
    this.getDeficitGroups().subscribe(_ => { });
  }
}
