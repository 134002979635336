export enum StatusPhase {
    None = 0,
    InProgress = 1,
    Done = 2,
    Conflict = 3,
    Accepted = 4,
    ClaimCancelled = 5,
    ConflictCancelled = 6,
    // ConflictDlDls = 7
    Claim = 7
}
