export class HeaderColumn {
  title: string;
  description: string;
  deficitId: string;
  inhaltsgruppe: string;

  constructor(title, description, includedContentSampleIds, inhaltsgruppe) {
    this.title = title;
    this.description = description;
    this.deficitId = includedContentSampleIds;
    this.inhaltsgruppe = inhaltsgruppe;
  }
}
