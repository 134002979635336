import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { UserProfileService } from './core/user-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { ContractService } from './shared/masterData/contract/contract.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><app-update-notification></app-update-notification>'
})
export class AppComponent implements OnInit {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private translate: TranslateService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userProfileService: UserProfileService,
    private contractService:ContractService,
    private router: Router) {
  }

  ngOnInit() {
    this.translate.addLangs(['en', 'de']);
    let lang = localStorage.getItem('lang');
    if (!lang) {
      lang = 'en';
    }
    this.translate.setDefaultLang(lang);

    this.setLoginDisplay();
    
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        catchError(() => {
          return this.router.navigate(['unauthorized']);
        })
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
                const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        catchError(() => {
          return this.router.navigate(['unauthorized']);
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
                this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.userProfileService.login();
        this.contractService.buildCache();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
      }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
