import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseFilterData } from './filter';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor() {
    this._subject = new Subject();
  }

  update(filter: BaseFilterData) {
    this._subject.next(filter);
  }

  getFilter() {
    return this._subject.asObservable();
  }

  private _subject: Subject<BaseFilterData>;
}
