import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { take, mergeMap } from 'rxjs/operators';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { Observable } from 'rxjs';
import { DeficitGroupProposalData } from '../components/deficit-proposal-request/deficit-group-proposal-data';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { DeficitProposalLoadFilter } from '../components/deficit-proposals/deficit-proposal-load-filter.resource';

@Injectable({
  providedIn: 'root'
})
export class DeficitGroupProposalService {

  constructor(private _httpClient: HttpClient,private contractService: ContractService) { }

  public createDeficitGroupProposal(body: any): Observable<any> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals`);
        return this._httpClient.post<DeficitGroupProposalData>(url,body);
      }));
  }

  public getDeficitGroupProposalForContract(skipNr?: number, takeNr?: number, filter?: DeficitProposalLoadFilter): Observable<PagedResultResponse<DeficitGroupProposalData>> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        filter.languageId = contract.contractLanguageId;
        let url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals?skip=${skipNr > 0 ? skipNr : 0}`);
        if (takeNr) {
          url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter.name) {
                url = `${url}&name=${filter.name}`;
            }
            if (filter.deficitGroup) {
                url = `${url}&deficitGroup=${filter.deficitGroup}`;
            }
            if (filter.description) {
                url = `${url}&description=${filter.description}`;
            }
            if (filter.suggestedDescription) {
                url = `${url}&suggesteddescription=${filter.suggestedDescription}`;
            }
            if (filter.comment) {
                url = `${url}&comment=${filter.comment}`;
            }
            if (filter.isActive !== undefined) {
                url = `${url}&isactive=${filter.isActive}`;
            }
            if (filter.isStatus != 'all') {
                url = `${url}&isstatus=${filter.isStatus}`;
            }
            if (filter.languageId) {
                url = `${url}&languageId=${filter.languageId}`;
            }
            if (filter.entityNumber) {
                url = `${url}&entityNumber=${filter.entityNumber}`;
            }
            if (!!filter.orderBy) {
                url = `${url}&orderBy=${filter.orderBy}`;
            }
        }
        return this._httpClient.get<PagedResultResponse<DeficitGroupProposalData>>(url);
      })
    )
  }

  public approveDeficitGroupProposal(deficitGroupProposalId) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals/${deficitGroupProposalId}/approve`);
        return this._httpClient.put(url, {});
      }));
  }

  public rejectDeficitGroupProposal(deficitGroupProposalId) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals/${deficitGroupProposalId}/reject`);
        return this._httpClient.put(url, {});
      }));
  }
  
  public promoteDeficitGroupProposal(deficitGroupProposalId) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals/${deficitGroupProposalId}/promote`);
        return this._httpClient.put(url, {});
      }));
  }

  public getDeficitGroupProposalHistory(id:string){
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals/${id}/history`);
        return this._httpClient.get<Array<DeficitGroupProposalData>>(url);
      })
    )
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }

}
