<div class="container more-width-container">
  <div class="col-sm-12 stats-header mt-5">
    <div class="row">
      <div class="col">
        <h3 class="brd bre pull-right pointer mt-5 add-btn" (click)="openCreateTargetModal()">
          <i class="fa fa-plus"></i>{{ 'targets.new-targets' | translate }}
        </h3>
      </div>
    </div>
  </div>
</div>

<div class="container more-width-container pt-5 table-outter-wrapper">
  <table class="table table-hover">
    <thead class="thead">
      <th></th>
      <th style="width: 35%">{{ 'shared.trade' | translate }}
        <span class="sorting-button" (click)="sort('Function')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('Function', 'Asc') && !isActiveSorting('Function', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Function', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Function', 'Desc')"></i>
        </span>
      </th>
      <th style="width: 10%">{{ 'shared.interval' | translate }}
        <span class="sorting-button" (click)="sort('Interval')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('Interval', 'Asc') && !isActiveSorting('Interval', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Interval', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Interval', 'Desc')"></i>
        </span>
      </th>
      <th style="width: 10%">{{ 'shared.amount' | translate }}
        <span class="sorting-button" (click)="sort('Amount')">
          <i class="fas fa-sort" *ngIf="!isActiveSorting('Amount', 'Asc') && !isActiveSorting('Amount', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Amount', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Amount', 'Desc')"></i>
        </span>
      </th>
      <th style="width: 35%">{{ 'shared.contract-position' | translate }}
        <span class="sorting-button" (click)="sort('Service')">
          <i class="fas fa-sort" *ngIf="!isActiveSorting('Service', 'Asc') && !isActiveSorting('Service', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Service', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Service', 'Desc')"></i>
        </span>
      </th>
      <th class="no-mobile"></th>
      <th class="no-mobile"></th>
    </thead>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.trade" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingTradeNames" [languageId]="languageId">
      </epo-column-filter>
    </th>
    <th></th>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.contractPosition" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingContractPositionNames" [languageId]="languageId">
      </epo-column-filter>
    </th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="5">
          <app-loading-spinner></app-loading-spinner>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngIf="!hasTargets">
        <td colspan="5">
          <p class="no-targets">{{ 'targets.no-targets' | translate }}</p>
        </td>
      </tr>
      <tr *ngFor="let target of acceptanceTargets">
        <td class="history-icon">
          <div (click)="showHistory(target)" class="ml-1 fa-border history-button"
            title="{{ 'shared.history' | translate }}">
            <i class="fa fa-lg fa-history" aria-hidden="true"></i>
          </div>
        </td>
        <td class="contract-position-name">{{target.tradeName}}</td>
        <td class="contract-position-name">{{target.intervalName | translate}}</td>
        <td class="contract-position-name">{{target.amount}}</td>
        <td class="contract-position-name">{{target.contractPositionName}}</td>
        <td class="edit"><button type="button" class="btn btn-outline-primary float-lg-right"
            (click)="openEditTargetModal(target)">
            {{ 'shared.buttons.edit' | translate }}</button></td>
        <td class="delete"><button type="button" class="btn btn-outline-danger float-lg-right"
            (click)="openDeleteModal(target)">
            {{ 'shared.buttons.delete' | translate }}</button></td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>