import { NameLoadFilter } from "app/admin/allgemein/shared/name-load-filter";

export class DeficitGlobalProposalLoadFilter extends NameLoadFilter {
    deficitGroup?: string;
    description?: string;
    suggestedDescription?: string;
    comment?: string;
    isActive?: boolean = true;
    isStatus?: 'all' | 'pending' | 'approved' | 'rejected' = 'all';
    entityNumber?: number;
}