import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DisplayStatus } from './display-status';

@Component({
  selector: 'epo-display-status',
  // tslint:disable-next-line: max-line-length
  template: '<i *ngIf="displayStatus" class="fa" [class.fa-oneAndAHalf]="isBig"[ngClass]="displayStatus.cssClass" [attr.title]="displayStatus.description | translate" aria-hidden="true"></i>',
  styles: [`
    fa-oneAndAHalf: 1.5em;
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class DisplayStatusComponent {
  @Input() isBig: boolean;
  @Input() displayStatus: DisplayStatus;
}
