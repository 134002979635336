import { Component, Input } from '@angular/core';
import { HistoryEntryModel } from './history-entry.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'entity-history',
  templateUrl: './entity-history.component.html',
  styleUrls: ['./entity-history.component.scss']
})
export class EntityHistoryComponent {
  @Input() history: HistoryEntryModel[];
  @Input() title: string;
  constructor(public modal: NgbActiveModal){}

  
}
