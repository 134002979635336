import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptanceTargetsService } from './acceptance-targets.service';
import { EInterval } from 'app/shared/models/interval';
import { forkJoin } from 'rxjs';
import { AcceptanceTarget } from './acceptance-target';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { ResolveIdHelper } from 'app/admin/shared/resolveIdHelper';
import { ModalAcceptanceTargetEditComponent } from './modal-acceptance-target-edit/modal-acceptance-target-edit.component';
import { AcceptanceTargetViewModel } from './acceptanceTargetViewModel';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { TradeConfigurationService } from 'app/shared/masterData/trade/configuration/trade-configuration.service';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { TargetLoadFilter } from 'app/admin/allgemein/shared/target-load-filter';
import { OrderBy, OrderDirection } from 'app/admin/allgemein/shared/order-by';
import { CheckTargetHistoryHelper } from 'app/shared/helper/check-target-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';

@Component({
  selector: 'epo-acceptance-targets',
  templateUrl: './acceptance-targets.component.html',
  styleUrls: ['./acceptance-targets.component.scss']
})
export class AcceptanceTargetsComponent extends BaseFilterPagingComponent<AcceptanceTarget> implements OnInit {

  public hasTargets = false;
  loadFilter = new TargetLoadFilter();
  public sorting: { column: 'Function' | 'Service' | 'Amount' | 'Interval', direction: OrderDirection };

  constructor(private tradeConfigService: TradeConfigurationService,
    private contractPositionConfigService: ContractPositionConfigurationService,
    private modalService: NgbModal,
    private acceptanceTargetService: AcceptanceTargetsService,
    private translate: TranslateService,
    public globalAutocompleteService: GolbalAutoCompleteService) {
    super();
  }

  public target: AcceptanceTarget;

  acceptanceTargetsResponse: AcceptanceTarget[];
  acceptanceTargets: AcceptanceTargetViewModel[];
  private contractPositions: ContractPositionConfiguration[];
  private tradeConfigs: TradeConfiguration[];

  public intervalTypes = [{ value: EInterval.monthly, displayName: 'shared.monthly' },
  { value: EInterval.quarterly, displayName: 'shared.quarterly' },
  { value: EInterval.yearly, displayName: 'shared.yearly' }];

  ngOnInit() {
    this.loadData();
  }


  isActiveSorting(column: 'Function' | 'Service' | 'Amount' | 'Interval', direction: 'Asc' | 'Desc') {
    return this.sorting?.column == column && this.sorting.direction == OrderDirection[direction];
  }

  sort(column: 'Function' | 'Service' | 'Amount' | 'Interval') {
    if (column === this.sorting?.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  public loadData() {
    this.isLoading = true;
    if (this.sorting) {
      this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    }
    forkJoin([
      this.tradeConfigService.getTradeConfigurationsForCurrentContract(),
      this.acceptanceTargetService.getAcceptanceTargets(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter),
      this.contractPositionConfigService.getContractPositionConfigurationsForCurrentContract()
    ]).subscribe((response) => {
      this.tradeConfigs = response[0].items;
      const targets = response[1];
      this.contractPositions = response[2].items;

      this.hasTargets = targets.items.length > 0;
      this.items = targets.items;
      this.pagingGlobalCount = targets.count;
      this.acceptanceTargets = [];

      targets.items.forEach((target) => {
        const viewModel = new AcceptanceTargetViewModel();
        viewModel.tradeName = ResolveIdHelper.getTradeName(target.tradeConfigurationId, this.tradeConfigs, this.translate.instant('shared.unknown-trade'));
        viewModel.contractPositionName = ResolveIdHelper.getContractPositionName(target.contractPositionConfigurationId, this.contractPositions, this.translate.instant('shared.unknown-contract-position'));
        viewModel.amount = target.amount;
        viewModel.interval = target.interval;
        viewModel.intervalName = ResolveIdHelper.getIntervalName(target.interval, this.intervalTypes, this.translate.instant('shared.unknown-interval'));
        viewModel.id = target.id;
        viewModel.contractPositionConfigurationId = target.contractPositionConfigurationId;
        viewModel.tradeConfigurationId = target.tradeConfigurationId;
        viewModel.createdUtc = target.createdUtc;
        viewModel.modifiedUtc = target.modifiedUtc;
        viewModel.createdBy = target.createdBy;
        viewModel.modifiedBy = target.modifiedBy;

        this.acceptanceTargets.push(viewModel);
      });
      this.isLoading = false;
    });
  }

  public openCreateTargetModal() {
    const target = new AcceptanceTarget();
    const modalRef = this.modalService.open(ModalAcceptanceTargetEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs.filter(c => c.isActive === true && c.trade.isActive);
    modalRef.componentInstance.contractPositionConfigs = this.contractPositions.filter(c => c.isActive === true && c.contractPosition.isActive);

    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this.acceptanceTargetService.createTarget(result).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public openEditTargetModal(target: AcceptanceTarget) {

    const modalRef = this.modalService.open(ModalAcceptanceTargetEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.inNewMode = false;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs;
    modalRef.componentInstance.contractPositionConfigs = this.contractPositions;

    modalRef.result.then((result) => {
      this.acceptanceTargetService.updateTarget(result).subscribe(() => {
        this.loadData();

      }, () => { });
    }, () => { });
  }

  public openDeleteModal(target: AcceptanceTarget) {
    const modalRef = this.modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = target;
    modalRef.result.then((tar) => {
      this.acceptanceTargetService.deleteTarget(tar.id).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public showHistory(checkTarget: AcceptanceTarget) {
    this.acceptanceTargetService.getHistory(checkTarget.id).subscribe(history => {
      const changeHistory = new CheckTargetHistoryHelper(history).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
    });
  }
}









