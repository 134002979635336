import { Component, Input } from '@angular/core';
import { ChangedProperty } from './changed-property.model';

@Component({
  selector: 'changed-property',
  templateUrl: './changed-property.component.html',
  styleUrls: ['./changed-property.component.scss'],
})
export class ChangedPropertyComponent {
  @Input() changedProperty: ChangedProperty;
}
