<div class="fixed-position" [attr.id]="containerElementId">
    <div *ngFor="let message of messages" class="toast-container" [class.hideMessage]="!message.isShown"
        (click)="messageClick(message)">
        <div class="toaster">
            <div class="heading">
                {{message.heading | translate}}
            </div>
            <div class="description">
                {{message.description | translate}}
            </div>
            <div class="technical">
                {{message.technical}}
            </div>
            <div class="bottomLine"
                [ngClass]="{ 'line-error': message.type === 'error', 'line-info': message.type === 'info' }">
            </div>
        </div>
    </div>
</div>
<div class="toast-container fixed-position" [class.showRight]="newMessage.isShown" [attr.id]="newElementUniqueId"
    style="right: -400px;">
    <div class="toaster">
        <div class="heading">
            {{newMessage.heading | translate}}
        </div>
        <div class="description">
            {{newMessage.description | translate}}
        </div>
        <div class="technical">
            {{newMessage.technical}}
        </div>
        <div class="bottomLine"
            [ngClass]="{ 'line-error': newMessage.type === 'error', 'line-info': newMessage.type === 'info' }"></div>
    </div>
</div>