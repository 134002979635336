import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ContractPositionsComponent } from './allgemein/contract-positions/contract-positions.component';
import { CheckTypesComponent } from './allgemein/check-types/check-types.component';
// tslint:disable-next-line: max-line-length
import { ModalEditContractPositionComponent } from './allgemein/contract-positions/modal-contract-position-edit/modal-contract-position-edit.component';
// tslint:disable-next-line: max-line-length
import { ModalDeleteComponent } from '../shared/components/modal-delete/modal-delete.component';
import { ControlTargetsComponent } from '../control/control-targets/control-targets.component';
// tslint:disable-next-line: max-line-length
import { ModalSamplePlanningEditComponent } from '../random-sample/random-sample-targets/modal-sample-target-edit/modal-sample-target-edit.component';
// tslint:disable-next-line: max-line-length
import { ModalControlTargetsEditComponent } from '../control/control-targets/modal-control-target-edit/modal-control-target-edit.component';
import { ModalTradeEditComponent } from './allgemein/trades/modal-trade-edit/modal-trade-edit.component';
import { ModalDeficitGroupsEditComponent } from './allgemein/deficit-groups/modal-deficit-groups-edit/modal-deficit-groups-edit.component';
// tslint:disable-next-line: max-line-length
import { ModalAcceptanceTargetEditComponent } from '../acceptance/acceptance-targets/modal-acceptance-target-edit/modal-acceptance-target-edit.component';
import { CustomMaxDirective } from './shared/custom-max-validator.directive';
import { DeficitesComponent } from './allgemein/deficites/deficites.component';
import { ModalDeficitEditComponent } from './allgemein/deficites/modal-deficite-edit/modal-deficit-edit.component';
import { ContractsManagementComponent } from './allgemein/contracts-management/contracts-management.component';
import { ModalEditContractComponent } from './allgemein/contracts-management/create-edit/modal-edit-contract.component';
import { ModalCheckTypeEditComponent } from './allgemein/check-types/modal-edit/modal-check-type-edit.component';
import { DeficiteConfigurationsComponent } from './contract/deficit-configuration/deficit-configuration.component';
import { TradeConfigurationsComponent } from './contract/trade-configuration/trade-configuration.component';
import { ContractPositionConfigurationComponent } from './contract/contract-position-configuration/contract-position-configuration.component';
import { CheckTypeConfigurationsComponent } from './contract/check-type-configuration/check-type-configuration.component';
import { ModalUserEditComponent } from './allgemein/users/modal-user-edit/modal-user-edit.component';
import { ContractUserConfigurationComponent } from './contract/contract-user-configuration/contract-user-configuration.component';

import { NgxSummernoteModule } from 'ngx-summernote';
import { LanguagesComponent } from './allgemein/languages/languages.component';
import { ModalLanguageEditComponent } from './allgemein/languages/modal-language-edit/modal-language-edit.component';
import { DeficitTagsComponent } from './allgemein/deficit-tags/deficit-tags.component';
import { ModalDeficitTagsEditComponent } from './allgemein/deficit-tags/modal-deficit-tags-edit/modal-deficit-tags-edit.component';
import { DeficitGlobalProposalsComponent } from './allgemein/deficit-global-proposals/deficit-global-proposals.component';
import { DeficitGlobalProposalService } from 'app/shared/services/deficit-global-proposal.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        FormsModule,
        RouterModule,
        NgxSummernoteModule
    ],
    declarations: [
        ModalDeleteComponent,
        ModalEditContractPositionComponent,
        CheckTypesComponent,
        ContractPositionsComponent,
        ControlTargetsComponent,
        ModalCheckTypeEditComponent,
        CustomMaxDirective,
        DeficitesComponent,
        ModalDeficitEditComponent,
        ContractsManagementComponent,
        ModalEditContractComponent,
        DeficiteConfigurationsComponent,
        TradeConfigurationsComponent,
        ContractPositionConfigurationComponent,
        CheckTypeConfigurationsComponent,
        ModalUserEditComponent,
        ContractUserConfigurationComponent,
        LanguagesComponent,
        ModalLanguageEditComponent,
        DeficitTagsComponent,
        ModalDeficitTagsEditComponent,
        DeficitGlobalProposalsComponent
    ],
    providers: [DeficitGlobalProposalService],
    entryComponents: [ModalDeleteComponent, ModalEditContractPositionComponent,
        ModalCheckTypeEditComponent, ModalSamplePlanningEditComponent,
        ModalControlTargetsEditComponent, ModalLanguageEditComponent,
        ModalTradeEditComponent, ModalDeficitGroupsEditComponent, ModalAcceptanceTargetEditComponent, ModalDeficitEditComponent,
        ModalEditContractComponent, ModalUserEditComponent],
})
export class AdminModule { }
