import { EInterval } from './models/interval';

export class ExtrapolationHelper {

    public static extrapolateValueByIntervalType(value: number,
        actualIntervalForPassedValue: EInterval, desiredInterval: EInterval): number {
        if (value <= 0) {
            return 0;
        }
        if (actualIntervalForPassedValue === desiredInterval) {
            return Math.round(value);
        }
        if (actualIntervalForPassedValue === EInterval.monthly && desiredInterval === EInterval.quarterly) {
            return Math.round(value * 3);
        } if (actualIntervalForPassedValue === EInterval.monthly && desiredInterval === EInterval.yearly) {
            return Math.round(value * 12);
        } if (actualIntervalForPassedValue === EInterval.quarterly && desiredInterval === EInterval.monthly) {
            return Math.round(value / 3);
        } if (actualIntervalForPassedValue === EInterval.quarterly && desiredInterval === EInterval.yearly) {
            return Math.round(value * 4);
        } if (actualIntervalForPassedValue === EInterval.yearly && desiredInterval === EInterval.monthly) {
            return Math.round(value / 12);
        } if (actualIntervalForPassedValue === EInterval.yearly && desiredInterval === EInterval.quarterly) {
            return Math.round(value / 4);
        }
    }

}
