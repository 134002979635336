import { UserResponse } from "app/admin/allgemein/users/user-response";

export class DeficitGroupProposalData{
    comment: string;
    createdBy: UserResponse;
    createdUtc: string;
    modifiedBy: UserResponse;
    modifiedUtc: string;
    descriptionProposal: string;
    id: string;
    previousDescription: string;
    status: DeficitGroupProposalStatus;
    entityNumber?: number;
}

export enum DeficitGroupProposalStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    Promoted = 3
}