import { EInterval } from '../../models/interval';
import { DateRange } from '../../date-range';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { ContractUserResource } from 'app/core/contract-user.resource';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';


export const descriptionAll = 'filter.all';
export const allId = '-1';

export class SimpleUser {
    id: string;
    name: string;
    role?: ContractUserRole;

    constructor(user?: ContractUserResource) {
        if (user) {
            this.id = user.id;
            this.name = user.firstname + ' ' + user.lastname;
            this.role = user.contractRole;
        }
    }
}

export class BaseFilterData {
    selectedIntervalType: EInterval;
    selectedDateRange: DateRange;
    selectedUser: SimpleUser;
    selectedTrade: TradeConfiguration;
    selectedCheckType: CheckTypeConfiguration;
    selectedContractPosition: ContractPositionConfiguration;

    get tradeId() {
        if (this.selectedTrade && this.selectedTrade.id) {
            return this.selectedTrade.id;
        }

        return null;
    }

    get contractPositionId() {
        if (this.selectedContractPosition && this.selectedContractPosition.id) {
            return this.selectedContractPosition.id;
        }

        return null;
    }
}
