import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessages } from './errors/error.messages';
import { ToasterService } from './components/toaster/toaster.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private toaster: ToasterService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    console.warn('interceptor is calling an error:');

                    let errorMessage = '';

                    if (err.error && err.error.hasOwnProperty('ErrorCode')) {
                        const message = ErrorMessages[err.error.ErrorCode];
                        if (message) {
                            errorMessage = `errors.${message}`;
                        }
                        else {
                            errorMessage = `errors.${ErrorMessages.ERR0000}`;
                        }
                    }
                    else if (err.error instanceof Error) {
                        // A client-side or network error occurred. Handle it accordingly.
                        errorMessage = `An error occurred: ${err.error.message}`;
                    } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
                    }
                    this.toaster.show({ heading: 'errors.heading', description: errorMessage, autoDismiss: false, type: 'error' });
                    // LOGGING KANN HIER STATTFINDEN:
                    console.warn(errorMessage);
                    return throwError(err);
                }
            })
        );
    }
}
