import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Language } from './language.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { Observable } from 'rxjs/internal/Observable';
import { take, map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private url = `${environment.apiUrl}/api/languages`;
    private languages: Language[];
    private languagesMap: Map<string, Language> = new Map<string, Language>();
    private newValues = new ReplaySubject(1);

    constructor(private _http: HttpClient) {
        this.buildCache();
    }

    private buildCache() {
        this._http.get<PagedResultResponse<Language>>(this.url)
            .pipe(take(1)).subscribe((languages) => {
                this.languages = languages.items;
                languages.items.map(language => {
                    this.languagesMap.set(language.id, language);
                });
                this.newValues.next(true);
            });
    }

    public get languagesObservable(): Observable<Language[]> {
        return this.newValues.pipe(map(() => this.languages));
    }

    public getLanguage(id: string): Observable<Language> {
        return this.newValues.pipe(
            map(() => this.languagesMap.get(id))
        )
    }

    public getLanguages(skipNr?: number, takeNr?: number): Observable<PagedResultResponse<Language>> {
        return this._http.get<PagedResultResponse<Language>>(this.url);
    }

    public createLanguage(lang: Language) {
        return this._http.post<Language>(this.url, lang)
    }

    public updateLanguage(lang: Language) {
        return this._http.put<Language>(`${this.url}/${lang.id}`, lang);
    }

    public deleteLanguage(langId: string) {
        return this._http.delete(`${this.url}/${langId}`);
    }
}