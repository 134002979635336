import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContractPosition } from './contract-position.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';
import { ConfigLoadFilter } from 'app/admin/allgemein/shared/config-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';

@Injectable({
    providedIn: 'root'
})
export class ContractPositionService {

    private url = `${environment.apiUrl}/api/contractPositions`;

    private _contractPositionMap: Map<string, ContractPosition> = new Map<string, ContractPosition>();
    private _contractPositions: ContractPosition[];
    private _newValues = new ReplaySubject(1);

    constructor(private _http: HttpClient) {
        this.buildCache();
    }

    private buildCache() {
        this._http.get<PagedResultResponse<ContractPosition>>(this.url).
            pipe(
                take(1)
            ).subscribe((contractPositions) => {
                contractPositions.items.map((contractPosition) => this._contractPositionMap.set(contractPosition.id, contractPosition));
                this._contractPositions = contractPositions.items;
                this._newValues.next(true);
            });
    }

    public getContractPositions(skipNr?: number, takeNr?: number, filter?: NameLoadFilter | ConfigLoadFilter): Observable<PagedResultResponse<ContractPosition>> {
        let url = `${this.url}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter['isSelected'] !== undefined) {
                url = `${url}&isselected=${filter['isSelected']}`;
            }
            url = LoadFilterHelper.addBaseFilterValues(url, filter);
        }
        return this._http.get<PagedResultResponse<ContractPosition>>(url);
    }

    public getContractPosition(id: string): Observable<ContractPosition> {
        return this._newValues.pipe(
            map(() => this._contractPositionMap.get(id))
        )
    }

    public getContractPositionHistory(id: string): Observable<ContractPosition[]> {
        return this._http.get<ContractPosition[]>(`${this.url}/${id}/history`);
    }

    public get contractPositionsObservable(): Observable<ContractPosition[]> {
        return this._newValues.pipe(
            map(() => this._contractPositions)
        )
    }

    public createContractPosition(contractPosition: ContractPosition) {
        return this._http.post<ContractPosition>(this.url, contractPosition);
    }

    public updateContractPosition(contractPosition: ContractPosition) {
        return this._http.put<ContractPosition>(`${this.url}/${contractPosition.id}`, contractPosition);
    }

    public deleteContractPosition(contractPositionId: string) {
        return this._http.delete(`${this.url}/${contractPositionId}`);
    }
}