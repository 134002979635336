import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { AcceptanceTarget } from '../acceptance-target';

@Component({
  selector: 'epo-modal-acceptance-target-edit',
  templateUrl: './modal-acceptance-target-edit.component.html',
  styleUrls: ['./modal-acceptance-target-edit.component.scss'],
})
export class ModalAcceptanceTargetEditComponent {
  public target: AcceptanceTarget;
  public inNewMode: boolean;
  public inEditMode: boolean;
  public tradeConfigs: TradeConfiguration[];
  public contractPositionConfigs: ContractPositionConfiguration[];
  public intervalTypes;

  constructor(public activeModal: NgbActiveModal) {}

  formIsFilled() {
    return (
      this.target.amount !== undefined &&
      this.target.interval !== undefined &&
      this.target.contractPositionConfigurationId !== undefined &&
      this.target.tradeConfigurationId !== undefined
    );
  }
}
