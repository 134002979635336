import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Karte } from '../../shared/components/card/card';
import { AcceptanceService } from 'app/acceptance/acceptance.service';
import { AcceptanceCardsCreator } from './acceptance-card-creator';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { BaseFilterData } from 'app/shared/components/filter/filter';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbnahmenFormularComponent } from '../acceptance-form/acceptance-form.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'epo-acceptance-planing',
    templateUrl: './acceptance-planing.component.html',
    styleUrls: ['./acceptance-planing.component.scss']
})
export class AcceptancePlaningComponent implements OnInit, OnDestroy {
    public filterSettings = new FilterSettings(false, false, false, false, false);
    public cards: Karte[];
    private _currentFilter: BaseFilterData;
    private filterSubscription: Subscription;

    constructor(
        private acceptanceService: AcceptanceService,
        private _filterService: FilterService,
        private contractPositionConfigurationService: ContractPositionConfigurationService,
        private modalService: NgbModal,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.filterSubscription = this._filterService.getFilter().subscribe((filter) => {
            this.filterChanged(filter as BaseFilterData);
        });
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
    }

    public addAbnahme(contractPositionId) {
        const modalRef = this.modalService.open(AbnahmenFormularComponent, { size: 'lg' });
        modalRef.componentInstance.contractPositionId = contractPositionId;
        modalRef.componentInstance.filterData = this._currentFilter;
    }

    public editTargets() {
        this.router.navigate(['../acceptance-targets'], { relativeTo: this.activatedRoute });
    }

    private filterChanged(filter: BaseFilterData) {
        this._currentFilter = filter;
        this.loadData(true);
    }

    private loadData(forceReload: boolean) {
        if (forceReload) {
            this.acceptanceService.cleanCache();
        }
        forkJoin([
            this.contractPositionConfigurationService.getContractPositionConfigurationsForCurrentContract(),
            this.acceptanceService.getAbnahmen(
                this._currentFilter.selectedDateRange,
                null,
                this._currentFilter.selectedTrade.id,
                this._currentFilter.selectedUser.id),
            this.acceptanceService.getTargets(this._currentFilter.selectedTrade.id)
        ]).subscribe((responses) => {
            const contractPositions = responses[0].items.filter(x => x.isActive === true || responses[2].find(t => t.contractPositionConfigurationId === x.id));
            const abnahmen = responses[1];
            const targets = responses[2];
            const selectedIntervalType = this._currentFilter.selectedIntervalType;

            const cardCreator = new AcceptanceCardsCreator(selectedIntervalType, contractPositions, targets, abnahmen.items);
            this.cards = cardCreator.createCards();
        });
    }
}
