import { UserResponse } from "app/admin/allgemein/users/user-response";
import { ContractUserResource } from "app/core/contract-user.resource";
import { DedicatedUser } from "../masterData/user/dedicated-user";

export function transformContractUserToUserResponse(usr:ContractUserResource) {
    let tempUser = new UserResponse();
    tempUser.firstname = usr.firstname;
    tempUser.lastname = usr.lastname;
    tempUser.loginEmail = usr.loginEmail;
    tempUser.id = usr.userId;
    return tempUser;
}

export function transformUserResponseToContractUser(usr:UserResponse) {
    let tempUser = new ContractUserResource();
    tempUser.firstname = usr.firstname;
    tempUser.lastname = usr.lastname;
    tempUser.loginEmail = usr.loginEmail;
    tempUser.userId = usr.id;
    return tempUser;
}

export function transformContractUserToDedicatedUser(usr:ContractUserResource) {
    let tempUser = new DedicatedUser();
    tempUser.firstname = usr.firstname;
    tempUser.lastname = usr.lastname;
    tempUser.loginEmail = usr.loginEmail;
    tempUser.id = usr.userId;
    tempUser.isActive = usr.isActive;
    return tempUser;
}

export function transformNullToDedicatedUser() {
    let tempUser = new DedicatedUser();
    tempUser.firstname = null;
    tempUser.lastname = null;
    tempUser.loginEmail = "None";
    tempUser.id = "00000000-0000-0000-0000-000000000000";
    return tempUser;
}

export function transformUserResponseToDedicatedUser(usr:UserResponse) {
    let tempUser = new DedicatedUser();
    tempUser.firstname = usr.firstname;
    tempUser.lastname = usr.lastname;
    tempUser.loginEmail = usr.loginEmail;
    tempUser.id = usr.id;
    return tempUser;
}
