import { Component, Input } from '@angular/core';
import { CommentResource } from 'app/shared/models/comment.resource';

@Component({
  selector: 'epo-sample-comments',
  templateUrl: './sample-comments.component.html',
  styleUrls: ['./sample-comments.component.scss']
})
export class SampleCommentsComponent {
    @Input() comments: CommentResource[];
}
