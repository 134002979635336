import { Environment } from './model/environment.resource';

// The file contents for the current environment will overwrite these during release.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export function loadJSON(filePath) {
//   const json = loadTextFileAjaxSync(filePath, 'application/json');
//   const jsonParsed = JSON.parse(json);
//   if (jsonParsed.production === 'true') {
//     jsonParsed.production = true;
//   } else {
//     jsonParsed.production = false;
//   }
//   return jsonParsed;
// }

// export function loadTextFileAjaxSync(filePath, mimeType) {
//   const xmlhttp = new XMLHttpRequest();
//   xmlhttp.open('GET', filePath, false);
//   if (mimeType != null) {
//     if (xmlhttp.overrideMimeType) {
//       xmlhttp.overrideMimeType(mimeType);
//     }
//   }
//   xmlhttp.send();
//   if (xmlhttp.status === 200) {
//     return xmlhttp.responseText;
//   } else {
//     return null;
//   }
// }

// export const environment: Environment = loadJSON('/assets/config.txt');

export const environment = {
  production: true,
  apiUrl: 'https://as-ccp-backend-prod01-21.azurewebsites.net',
  archibusUrl: 'https://cafm.internal.epo.org/archibus/epo-bldgops-cost-report.axvw',
  authConfig: {
    tenant: 'epocloud.onmicrosoft.com',
    clientId: '4eddf965-5278-4db5-9c0d-c1230d8c2b7d',
    anonymousEndpoints: ['./assets', './assets/i18n']
  },
  pageSize: 50
};