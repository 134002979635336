<div class="modal-header">
  <h4 *ngIf="inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.trades.dialog.title-new" | translate }}
  </h4>
  <h4 *ngIf="!inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.trades.dialog.title-edit" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>{{ "shared.lang-default" | translate }}</a>
      <ng-template ngbNavContent>
        <input class="form-control form-control-lg" type="text" [(ngModel)]="trade.nameDefault" required />
      </ng-template>
    </li>
    <li ngbNavItem *ngFor="let trans of translations">
      <a ngbNavLink>{{ trans.language.longName }}</a>
      <ng-template ngbNavContent>
        <input class="form-control form-control-lg" type="text" [(ngModel)]="trans.name" required />
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
  <div class="form-group mt-3">
    <div class="active-select">
      <label class="checkbox-container">{{ "shared.is-active" | translate }}
        <div class="active-check">
          <input type="checkbox" [(ngModel)]="trade.isActive" name="isActiveBox" [disabled]="inNewMode" />
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">
    <i class="icon" class="fas fa-ban"></i>
    {{ "shared.dialog.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-dark" [disabled]="!canSave()" (click)="save()">
    <i class="icon" class="fas fa-save"></i>
    {{ "shared.dialog.save" | translate }}
  </button>
</div>