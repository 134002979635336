import { Component, OnInit, OnDestroy } from '@angular/core';
import { TradeViewModel } from './trade-view-model';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { TradeService } from 'app/shared/masterData/trade/trade.service';
import { TradeConfigurationService } from 'app/shared/masterData/trade/configuration/trade-configuration.service';
import { Subscription } from 'rxjs';
import { ConfigLoadFilter } from 'app/admin/allgemein/shared/config-load-filter';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take } from 'rxjs/operators';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { ConfigHistoryHelper } from 'app/admin/shared/config-history.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';

@Component({
  selector: 'epo-trade-configuration',
  templateUrl: './trade-configuration.component.html',
  styleUrls: ['./trade-configuration.component.scss']
})
export class TradeConfigurationsComponent extends BaseFilterPagingComponent<TradeConfiguration> implements OnDestroy {
  private subscription: Subscription;
  public loadFilter: ConfigLoadFilter = new ConfigLoadFilter();

  public isSaving = false;
  public trades: TradeViewModel[] = [];

  constructor(
    private tradeService: TradeService,
    private tradeConfigService: TradeConfigurationService,
    private contractService: ContractService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _modalService: NgbModal
  ) {
    super();
  }

  isSelectedFilterChanged(value) {
    this.loadFilter.isSelected = value;
    this.onFilterChange();
  }

  isActiveConfigSelected(value) {
    this.loadFilter.configurationActive = value;
    this.onFilterChange();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public loadData() {
    const $configs = this.tradeConfigService.getTradeConfigurationsForCurrentContract();
    this.subscription = $configs.subscribe(configs => {
      this.items = configs.items;
      this.loadTrades();
    });
  }

  private loadTrades() {
    this.isLoading = true;
    this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
      this.loadFilter.contractId = contract.id;
      this.loadFilter.languageId = contract.contractLanguageId;
      this.load();
    });
  }

  private load() {
    const $trades = this.tradeService.getTrades(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    $trades.subscribe(trades => {
      this.pagingGlobalCount = trades.count;
      this.trades = trades.items.map(d => {
        const config = this.items.find(g => g.trade.id === d.id);
        return {
          id: d.id,
          name: d.name,
          isSelected: (config && config.isActive) ? true : false,
          isActive: config ? config.isActive : d.isActive,
          tradeConfigurationId: config ? config.id : null
        };
      });
      this.isLoading = false;
    });
  }

  saveConfiguration(trade: TradeViewModel) {
    if (trade.isSelected) {
      this.isSaving = true;
      this.tradeConfigService.createTradeConfiguration(trade.id).subscribe(() => {
        this.tradeConfigService.getTradeConfigurationsForCurrentContract().subscribe((res) => {
          this.items = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err);
        this.isSaving = false;
      })
    } else {
      this.isSaving = true;
      this.tradeConfigService.deleteTradeConfiguration(trade.tradeConfigurationId).subscribe(() => {
        this.tradeConfigService.getTradeConfigurationsForCurrentContract().subscribe((res) => {
          this.items = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err);
        this.isSaving = false;
      })
    }
  }

  setIsActiveConfig(trade: TradeViewModel) {
    if (!trade.isSelected) {
      return;
    }
    this.isSaving = true;
    this.tradeConfigService.setTradeConfigurationActive(trade.tradeConfigurationId, trade.isActive).subscribe(() => {
      this.isSaving = false;
    }, err => {
      console.log(err);
      this.isSaving = false;
    })
  }

  public showHistory(trade: TradeViewModel) {
    this.tradeConfigService.getTradeConfigurationHistory(trade.tradeConfigurationId).subscribe(history => {
      const config: PropertyDisplayMapping[] = [
        { propertyName: 'isActive', displayName: 'shared.active', type: 'boolean' }
      ]
      const changeHistory = new ConfigHistoryHelper(history, config).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: trade?.name };
    });
  }
}
