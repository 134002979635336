
import { BaseSampleCreator } from 'app/shared/base-sample-creator';
import { Acceptance } from './Acceptance';
import { AcceptanceResource } from './acceptance-resource';
import { WorkOwner } from './work-owner';
import { WorkControlOwner } from './work-control-owner';
import { WorkMainOwner } from './work-main-owner';
import { AcceptanceStatusCalculator } from '../acceptance-status-calculator';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';


export class AcceptanceCreator extends BaseSampleCreator {
    constructor(private acceptanceResources: AcceptanceResource[],
        realEstates: TradeConfigurationResponse[]) {
        super(realEstates, null, null);
    }

    public create(): Acceptance[] {
        const abnahmen = [];
        const calculator = new AcceptanceStatusCalculator();

        for (const acceptanceResource in this.acceptanceResources) {
            if (this.acceptanceResources.hasOwnProperty(acceptanceResource)) {
                const response = this.acceptanceResources[acceptanceResource];
                const yearIntervalAmount =
                    this.calculateYearIntervalAmount(response.startAcceptanceDateTimeUtc, response.endAcceptanceDateTimeUtc);

                const abnahme = {
                    contractPositionConfigurationId: response.contractPositionConfigurationId,
                    tradeConfigurationId: response.tradeConfigurationId,
                    id: response.id,
                    work_preperation: response.workPreperation,
                    work_owner: WorkOwner[response.workOwner],
                    work_completed: response.workCompleted,
                    work_control_owner: WorkControlOwner[response.workControlOwner],
                    work_main_owner: WorkMainOwner[response.workMainOwner],
                    work_control_docuemnts: response.workControlDocuments,
                    zuordnungsnachweis: { value: response.zuordnungsnachweis, weighting: 1 },
                    leistungsvollstaendigkeit: { value: response.leistungsvollstaendigkeit, weighting: 1 },
                    leistungsqualitaet: { value: response.leistungsqualitaet, weighting: 10 },
                    leistungstermintreue: { value: response.leistungstermintreue, weighting: 5 },
                    vertragliche_Vereinbarung: { value: response.vertraglicheVereinbarung, weighting: 10 },
                    status_Phase1: response.statusPhaseOne,
                    status_Phase2: response.statusPhaseTwo,
                    status_Phase3: response.statusPhaseThree,
                    year: yearIntervalAmount[0],
                    interval: yearIntervalAmount[1],
                    amount: yearIntervalAmount[2],
                    av_documents: response.avDocuments,
                    work_performance_documents: response.workPerformanceDocuments,
                    comments: response.comments,
                    acceptance_Status: null,
                    acceptanceResponsible: null,
                    statusDienstleister: calculator.getDisplayStatusForPhase1(response.statusPhaseOne, response.statusPhaseTwo, response.statusPhaseThree),
                    statusDienstleistersteuerer: calculator.getDisplayStatusForPhase2(response.statusPhaseOne, response.statusPhaseTwo),
                    contractEntityNumber: response.contractEntityNumber,
                    createdUtc: response.createdUtc,
                    modifiedUtc: response.modifiedUtc,
                    createdBy: response.createdBy,
                    modifiedBy: response.modifiedBy,
                    isEditable: response.isEditable,
                    isWithdrawn: response.isWithdrawn,
                    dedicatedUser: response.dedicatedUser
                };

                abnahmen.push(abnahme);
            }
        }

        return abnahmen;
    }

    private calculateYearIntervalAmount(startDate: string, endDate: string): number[] {
        const numberArray: number[] = [];
        numberArray.push(new Date(startDate + 'Z').getFullYear());
        const startMonth = new Date(startDate + 'Z').getMonth();
        const endMonth = new Date(endDate + 'Z').getMonth();

        if ((endMonth - startMonth) === 0) {
            numberArray.push(0);
            numberArray.push(startMonth);
        } else {
            numberArray.push(1);
            switch (startMonth) {
                case 0:
                case 1:
                case 2:
                    numberArray.push(0);
                    break;
                case 3:
                case 4:
                case 5:
                    numberArray.push(1);
                    break;
                case 6:
                case 7:
                case 8:
                    numberArray.push(2);
                    break;
                case 9:
                case 10:
                case 11:
                    numberArray.push(3);
                    break;
                default:
                    numberArray.push(0);
                    break;
            }
        }


        return numberArray;
    }
}
