import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EInterval } from '../../models/interval';
import { Karte } from './card';

@Component({
  selector: 'epo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() topic: string;
  @Input() card: Karte;
  @Output() onAddClicked: EventEmitter<string> = new EventEmitter();
  @Output() onEditTargetsClicked = new EventEmitter<any>();
  get topicParam() {
    return { 'topic': this.topic };
  }

  public add(id) {
    this.onAddClicked.emit(id);
  }

  public editTargets() {
    this.onEditTargetsClicked.emit();
  }

  public getProgress(sollWert: number, istWert: number) {
    const progressInPercent = (istWert / sollWert) * 100;
    // Sofern Fortschritt größer 100%, dann limitiere auf 100.
    return progressInPercent > 100 ? 100 : progressInPercent;
  }

  public convertIntervalToDisplayValue(interval: number) {
    switch (interval) {
      case EInterval.monthly:
        return 'shared.planing.per-right-month';
      case EInterval.quarterly:
        return 'shared.planing.per-right-quarter';
      case EInterval.yearly:
        return 'shared.planing.per-right-year';
      default:
        return 'Unknown';
    }
  }
}
