import { DeficitMappingBase } from '../masterData/deficit/deficitMapping';

export class DeficitMappingsSortingMapper {
    public static sortMappings(array: DeficitMappingBase[], languageId: string): DeficitMappingBase[] {
        return array.sort((a, b) => {
            const contractPositionA = a.contractPosition.translations?.find(t => t.languageId === languageId)?.name ?? a.contractPosition.name;
            const contractPositionB = b.contractPosition.translations?.find(t => t.languageId === languageId)?.name ?? b.contractPosition.name;
            const checkTypeA = a.checkType.translations?.find(t => t.languageId === languageId)?.name ?? a.checkType.name;
            const checkTypeB = b.checkType.translations?.find(t => t.languageId === languageId)?.name ?? b.checkType.name;
            return this.sortingFunction(contractPositionA, contractPositionB, checkTypeA, checkTypeB);
        });
    }

    public static sortMaps(array: { checkType: string, contractPosition: string }[]): { checkType: string, contractPosition: string }[] {
        return array.sort((a, b) => {
            return this.sortingFunction(a.contractPosition, b.contractPosition, a.checkType, b.checkType);
        });
    }

    private static sortingFunction(contractPositionA: string, contractPositionB: string, checkTypeA: string, checkTypeB: string): number {
        if (contractPositionA > contractPositionB) {
            return 1;
        } else if (contractPositionA < contractPositionB) {
            return -1;
        }
        return checkTypeA > checkTypeB ? 1 : -1;
    }
}