<div class="paging-container">
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item" [ngClass]="{ 'disabled': currentPage == 1 }">
        <a class="page-link" (click)="setPage(1)">
          <i class="fa fa-angle-double-left"></i>
        </a>
      </li>

      <li class="page-item" [ngClass]="{ 'disabled': currentPage == 1 }">
        <a class="page-link" (click)="prev()">
          <i class="fa fa-chevron-left"></i>
        </a>
      </li>

      <li class="page-item" *ngFor="let page of pages"
        [ngClass]="{ 'active': page == currentPage, 'disabled': page == '...' }">
        <a class="page-link" (click)="setPage(page)">{{page}}</a>
      </li>

      <li class="page-item" [ngClass]="{ 'disabled': currentPage == amountPages }">
        <a class="page-link" (click)="next()">
          <i class="fa fa-chevron-right"></i>
        </a>
      </li>
      <li class="page-item" [ngClass]="{ 'disabled': currentPage == amountPages }">
        <a class="page-link" (click)="setPage(amountPages)">
          <i class="fa fa-angle-double-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>