import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RandomSampleTargetsResponse } from 'app/shared/models/targets-response';
import { RandomSampleTarget } from 'app/shared/models/random-sample-target';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, mergeMap } from 'rxjs/operators';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { TargetLoadFilter } from 'app/admin/allgemein/shared/target-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RandomSampleTargetsService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public getRandomSampleTargets(skipNr?: number, takeNr?: number, filter?: TargetLoadFilter) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `api/${contract.id}/spotchecks/targets?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
          url = `${url}&take=${takeNr}`;
        }
        if (filter) {
          url = LoadFilterHelper.addFilterParameter(url, contract.contractLanguageId, 'languageId');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractId, 'contractId');
          url = LoadFilterHelper.addFilterParameter(url, filter.checkType, 'checkType');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractPosition, 'contractPosition');
          url = LoadFilterHelper.addFilterParameter(url, filter.trade, 'trade');
          url = LoadFilterHelper.addFilterParameter(url, filter.orderBy, 'orderBy');
        }
        url = this.createEpoUrl(url);
        return this._httpClient.get<PagedResultResponse<RandomSampleTargetsResponse>>(url);
      })
    );
  }

  public createTarget(target: RandomSampleTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/targets`);
        return this._httpClient.post(url, target);
      }));
  }

  public updateTarget(target: RandomSampleTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/targets/${target.id}`);
        return this._httpClient.put(url, target);
      }));
  }

  public deleteTarget(id: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/targets/${id}`);
        return this._httpClient.delete(url);
      }));
  }

  public getTargetHistory(targetId: string): Observable<RandomSampleTarget[]> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/targets/${targetId}/history`);
        return this._httpClient.get<RandomSampleTarget[]>(url);
      }));
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}





