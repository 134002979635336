import { Component } from '@angular/core';
import { ContractService } from 'app/shared/masterData/contract/contract.service';

@Component({
  selector: 'epo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent { 
  constructor(public contractService: ContractService){}
}
