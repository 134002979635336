import { allId } from './components/filter/filter';
import { LoadFilterHelper } from './helper/load-filter-helper';
import { CheckLoadFilter } from './models/check-load-filter';

export class BaseSampleService {

    protected isInDateRange(createdDate: string, filterByDateRange: { from: Date; to: Date; }): any {
        if (!filterByDateRange) {
            return true;
        }
        const createdDateTimeUtc = new Date(createdDate);
        return createdDateTimeUtc >= filterByDateRange.from && createdDateTimeUtc <= filterByDateRange.to;
    }

    protected isContractPositionMatching(contractPositionsIdOfSample: string, filterByContractPositionId?: string) {
        if (this.isStringNullUndefinedOrEmpty(filterByContractPositionId) || filterByContractPositionId === allId) {
            return true;
        }
        return contractPositionsIdOfSample === filterByContractPositionId;
    }

    protected isTypeMatching(typeIdOfSample: string, filterByTypeId?: string) {
        if (this.isStringNullUndefinedOrEmpty(filterByTypeId) || filterByTypeId === allId) {
            return true;
        }
        return typeIdOfSample === filterByTypeId;
    }

    protected isTradeMatching(tradeIdOfCheck: string, filterByTradeId?: string) {
        if (this.isStringNullUndefinedOrEmpty(filterByTradeId) || filterByTradeId === allId) {
            return true;
        }
        return tradeIdOfCheck === filterByTradeId;
    }

    protected isUserMatching(createdByOfSample: string, filterByUserId?: string) {
        if (this.isStringNullUndefinedOrEmpty(filterByUserId) || filterByUserId === allId) {
            return true;
        }
        return createdByOfSample === filterByUserId;
    }

    protected isStringNullUndefinedOrEmpty(stringToTest: string) {
        if (stringToTest && /\S/.test(stringToTest)) {
            return false;
        } return true;
    }


    protected addFilterToUrl(url: string, filter: CheckLoadFilter) {
        url = LoadFilterHelper.addFilterParameter(url, (filter.selectedDateRange && filter.selectedDateRange.from) ? filter.selectedDateRange.from.toISOString() : null, 'from');
        url = LoadFilterHelper.addFilterParameter(url, (filter.selectedDateRange && filter.selectedDateRange.to) ? filter.selectedDateRange.to.toISOString() : null, 'until');
        url = LoadFilterHelper.addFilterParameter(url, filter.contractPositionId, 'contractPositionConfigurationId');
        url = LoadFilterHelper.addFilterParameter(url, filter.tradeId, 'tradeConfigurationId');
        url = LoadFilterHelper.addFilterParameter(url, filter.typeId, 'checkTypeConfigurationId');
        url = LoadFilterHelper.addFilterParameter(url, filter.userId, 'userId');
        return url;
    }
}
