export enum GlobalUserRole {
    Admin = 0,
    User = 1,
    GlobalAdmin = 2
}

export namespace GlobalUserRole {
    
    export function isRoleEqualOrHigher(userRole: GlobalUserRole, requiredRole: GlobalUserRole): boolean {
        if (requiredRole === GlobalUserRole.Admin) {
            return userRole === GlobalUserRole.Admin || userRole === GlobalUserRole.GlobalAdmin;
        }
        return userRole === GlobalUserRole.Admin || userRole === GlobalUserRole.User;
    }
}