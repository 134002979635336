<div class="container pt-5">
  <h3 class="pb-2">{{ 'global-admin.trades.title-configure' | translate }}</h3>
  <table class="table table-hover">
    <thead class="thead">
      <th></th>
      <th>{{ 'global-admin.trades.name' | translate }}</th>
      <th>{{ 'shared.selection' | translate }}</th>
    </thead>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingTradeNames" [isActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>{{loadFilter.isSelected |
          boolToString:'shared.all' | translate }}</button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let selected of boolSelection" class="dropdown-item" (click)="isSelectedFilterChanged(selected)"
            ngbDropdownItem>{{selected | boolToString:'shared.all' | translate }}</a>
        </div>
      </div>
    </th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="3">
          {{ 'shared.loading' | translate }}...
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let trade of trades">
        <td style="width: 3%;" class="table-item-name">
          <div (click)="showHistory(trade)" class="ml-1 fa-border history-button"
            title="{{ 'shared.history' | translate }}" *ngIf="trade.tradeConfigurationId">
            <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
          </div>
        </td>
        <td class="table-item-name">{{trade.name}}</td>
        <td class="edit">
          <label *ngIf="!isSaving" class="checkbox-container">
            <input type="checkbox" [(ngModel)]="trade.isSelected" [checked]="trade.isSelected"
              (change)="saveConfiguration(trade)">
            <span class="checkmark"></span>
          </label>
          <div *ngIf="isSaving" class="loader"></div>
        </td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>