<div style="position: relative;">
  <input id="textbox" class="form-control" [placeholder]="placeholder" [(ngModel)]="searchTerm" (input)="search()"
    (focusin)="resultsShown=true" (focusout)="closeResults()" autocomplete="off"
    [disabled]="autocomplete && selection != null" />
  <div class="iconContainer">
    <div class="clearIcon" *ngIf="selection != null || (!autocomplete && searchTerm && searchTerm.length > 0)"
      (click)="clearSelection()"><i class="fa fa-times-circle"></i></div>
  </div>
  <div *ngIf="autocomplete" class="resultsContainer" [ngStyle]="{'visibility': resultsShown ? 'visible' : 'hidden'}">
    <div class="loader" *ngIf="isSearching"></div>
    <div *ngIf="!isSearching">
      <div class="resultItem" *ngFor="let result of results" (click)="select(result)"
        [innerHTML]="formatDisplayText(getDisplayString(result))">
      </div>
    </div>
  </div>
</div>