<epo-filter [filterSettings]="filterSettings"></epo-filter>

<div class="more-width-container mt-5">
  <div class="row mt-10">
    <main class="col-sm-12">
      <div class="dashboard">
        <epo-statistics #stats [header]="statsHeader" [sampleFigures]="figures" (onStatsChanged)="statsChanged($event)">
        </epo-statistics>
      </div>
      <div class="mb-3">
        <div class="stats-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <h3 class="brd bre">{{ "controls.overview" | translate }}</h3>
            </div>
            <div class="col">
              <h3 *hideIfMissingRole="requiredRolesForCreation" class="brd bre pull-right pointer" (click)="add()">
                <i class="fa fa-plus"></i> {{ "controls.new" | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

      <div class="col-sm-12 mt-4 mb-4 total-count" *ngIf="kontrollen && kontrollen.length > 0 && !isLoading">
        <p>
          {{ "controls.count.left" | translate }}
          <span class="count">{{ pagingGlobalCount }}</span>
          {{ "controls.count.right" | translate }}
        </p>
      </div>

      <div *ngIf="(!kontrollen || kontrollen.length == 0) && !isLoading">
        <p class="no-results">{{ "controls.no-controls" | translate }}</p>
      </div>

      <div class="table-outter-wrapper mb-5" *ngIf="kontrollen && kontrollen.length > 0 && !isLoading">
        <table class="table table-hover">
          <thead>
            <tr class="rotated">
              <th></th>
              <th class="rotate-45">
                <div class="id-column">
                  <span>Id</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{ "shared.created-at" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 bewertung">
                <div>
                  <span>{{
                    "controls.rating-property-mnager" | translate
                    }}</span>
                </div>
              </th>
              <th class="rotate-45 reaktion">
                <div>
                  <span>{{ "controls.rating-team-manager" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 reaktion-dark">
                <div>
                  <span>{{ "controls.rating-cip" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 ergebnis">
                <div>
                  <span>{{ "controls.deficit-fixed" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile" *ngIf="showContractPositionColumn">
                <div>
                  <span>{{ "controls.contract-position" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile" *ngIf="showAllGewerk">
                <div>
                  <span>{{ "controls.trade" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 art-header no-mobile" *ngIf="showAllKontrollarten">
                <div>
                  <span>{{ "controls.type" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{ "controls.ticket-nr" | translate }}</span>
                </div>
              </th>
              <ng-container *ngIf="!showAllKontrollarten">
                <th class="rotate-45 no-mobile" *ngFor="let headerColumn of currentHeaderColumns">
                  <div class="no-mobile">
                    <span>{{ headerColumn.title }}</span>
                  </div>
                </th>
              </ng-container>
              <th class="rotate-45 rotate-3x no-mobile">
                <div class="appendix">
                  <span>{{ "controls.annotation" | translate }}</span>
                </div>
              </th>
            </tr>
            <tr *ngIf="!showAllKontrollarten">
              <th>
                <!--Platzhalter Edit-->
              </th>
              <th>
                <!--Platzhalter Id-->
              </th>
              <th class="no-mobile">
                <!--Platzhalter Erstellt am-->
              </th>
              <th class="bewertung">
                <!--Platzhalter Bewertung durch OL-->
              </th>
              <th class="reaktion">
                <!--Platzhalter Bewertung durch TL-->
              </th>
              <th class="reaktion-dark">
                <!--Platzhalter Bewertung durch KVP-->
              </th>
              <th class="ergebnis">
                <!--Platzhalter Defizit behoben-->
              </th>
              <th class="no-mobile" *ngIf="showContractPositionColumn">
                <!--Platzhalter Vertragsposition-->
              </th>
              <th class="no-mobile" *ngIf="showAllGewerk">
                <!--Platzhalter Gewerk-->
              </th>
              <th class="no-mobile">
                <!--Platzhalter Anforderungsnummer-->
              </th>
              <ng-container class="no-mobile" *ngFor="
                  let grouppedHeaderColumn of groupByRandomGroup(
                    currentHeaderColumns
                  )
                ">
                <th [attr.colspan]="grouppedHeaderColumn.members.length" class="inhaltsgruppe" class="no-mobile">
                  <div>
                    <span>{{ grouppedHeaderColumn.key }}</span>
                  </div>
                </th>
              </ng-container>
              <th class="no-mobile">
                <!--Platzhalter Appendix-->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let kontrolle of kontrollen" [ngClass]="{'withdrawn-control': kontrolle.isWithdrawn}">
              <td class="button-container">
                <div class="ml-1 fa-border history-button" (click)="edit(kontrolle)" data-toggle="modal"
                  data-target="#modalEditKontrolle" title="{{ 'shared.edit' | translate }}">
                  <i class="fa fa-edit icon-in-border icon-edit"></i>
                </div>

                <div class="ml-1 fa-border history-button" (click)="version(kontrolle)"
                  title="{{ 'shared.history' | translate }}">
                  <i class="fa fa-history fa-lg icon-in-border icon-history" aria-hidden="true"></i>
                </div>
              </td>
              <td>{{ kontrolle.contractEntityNumber }}</td>
              <td class="no-mobile">
                {{ kontrolle.createdDateTimeUtc | date: "dd.MM.yyyy" }}
              </td>
              <td class="bewertung">
                <i *ngIf="isOl(kontrolle)" class="fa" [ngClass]="getCssForGrade(kontrolle.rating)"
                  [attr.title]="kontrolle.rating | translate" aria-hidden="true"></i>
              </td>
              <td class="reaktion">
                <i *ngIf="isTl(kontrolle)" class="fa" [ngClass]="getCssForGrade(kontrolle.rating)"
                  [attr.title]="kontrolle.rating | translate" aria-hidden="true"></i>
              </td>
              <td class="reaktion-dark">
                <i *ngIf="isKvp(kontrolle)" class="fa" [ngClass]="getCssForGrade(kontrolle.rating)"
                  [attr.title]="kontrolle.rating | translate" aria-hidden="true"></i>
              </td>
              <td class="ergebnis">
                <i *ngIf="showDefizit(kontrolle)" class="fa" [ngClass]="getCssForDefizit(kontrolle)"></i>
              </td>
              <td class="no-mobile" *ngIf="showContractPositionColumn">
                {{ kontrolle.contractPositionName }}
              </td>
              <td class="no-mobile" *ngIf="showAllGewerk">
                {{ kontrolle.realEstateName }}
              </td>
              <td class="no-mobile" *ngIf="showAllKontrollarten">
                {{ kontrolle.typeName }}
              </td>
              <td class="no-mobile">{{ kontrolle.ticketNumber }}</td>
              <ng-container class="no-mobile" *ngIf="!showAllKontrollarten">
                <td *ngFor="let headerColumn of currentHeaderColumns" class="no-mobile">
                  <i class="fa" [ngClass]="getCssByLCluster(kontrolle, headerColumn)" aria-hidden="true"></i>
                </td>
              </ng-container>
              <td class="appendix no-mobile" [innerHtml]="kontrolle.appendix"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>