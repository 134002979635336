import { Injectable } from '@angular/core';
import { ContractService } from '../masterData/contract/contract.service';
import { map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserProfileService } from 'app/core/user-profile.service';
import { ContractUserRole } from '../masterData/user/contract-user-role';
import { GlobalUserRole } from '../masterData/user/global-user-role';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

    constructor(private _contractService: ContractService, private _userProfileService: UserProfileService, private router: Router) { }

    public hasRequiredRole(requiredRoles: ContractUserRole[]) : Observable<Boolean>{
        const user = this._userProfileService.cachedUser;
        if(user.role === GlobalUserRole.Admin || user.role === GlobalUserRole.GlobalAdmin){
            return of(true);
        }
        return this._contractService.selectedContract.pipe(
            take(1),
            map(contract => {
                const contractUser = user.contractUsers.find(contractUser => contractUser.contractId == contract.id);
                if(contractUser == undefined){
                    return false;
                }
                return contractUser.contractRole === ContractUserRole.Admin || 
                    requiredRoles.some(role => role === contractUser.contractRole);
            })
        );
    }

    public redirectIfUserIsMissingRoles(requiredRoles: ContractUserRole[], unauthorizedCallback?: () => void){
        this.hasRequiredRole(requiredRoles).pipe(take(1)).subscribe(
            hasRole => {
                if(!hasRole){
                    if(unauthorizedCallback)
                        unauthorizedCallback();
                    this.router.navigate(['unauthorized']);
                }
            }
        )
    }

    public get ContractRole(){
        const user = this._userProfileService.cachedUser;
        if(user.role === GlobalUserRole.Admin){
            return of(ContractUserRole.Admin);
        }
        return this._contractService.selectedContract.pipe(
            take(1),
            map(contract => {
                    const contractUser = user.contractUsers.find(contractUser => contractUser.contractId == contract.id);
                    if(contractUser == undefined){
                        return null;
                    }
                    return contractUser.contractRole;
                }
            )
        );
    }
}
