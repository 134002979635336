import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './core/layout/layout.component';
import { AcceptanceComponent } from './acceptance/acceptance.component';
import { MonitoringComponent } from './random-sample/overview/monitoring.component';
import { SchedulingComponent } from './random-sample/scheduling/scheduling.component';
import { ControlOverviewComponent } from './control/overview/overview.component';
import { ControlPlanningComponent } from './control/control-planning/control-planning.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { AcceptancePlaningComponent } from './acceptance/acceptance-planing/acceptance-planing.component';
import { ContractPositionsComponent } from './admin/allgemein/contract-positions/contract-positions.component';

import { CheckTypesComponent } from './admin/allgemein/check-types/check-types.component';
// tslint:disable-next-line: max-line-length
import { RandomSampleTargetsComponent } from './random-sample/random-sample-targets/random-sample-targets.component';
import { ControlTargetsComponent } from './control/control-targets/control-targets.component';
//import { AdalGuard } from 'adal-angular4';
import {MsalGuard} from '@azure/msal-angular';
import { TradesComponent } from './admin/allgemein/trades/trades.component';
import { UsersComponent } from './admin/allgemein/users/users.component';
import { DeficitGroupsComponent } from './admin/allgemein/deficit-groups/deficit-groups.component';
import { DeficitesComponent } from './admin/allgemein/deficites/deficites.component';
import { ContractsManagementComponent } from './admin/allgemein/contracts-management/contracts-management.component';
import { DeficiteConfigurationsComponent } from './admin/contract/deficit-configuration/deficit-configuration.component';
import { TradeConfigurationsComponent } from './admin/contract/trade-configuration/trade-configuration.component';
import { ContractPositionConfigurationComponent } from './admin/contract/contract-position-configuration/contract-position-configuration.component';
import { CheckTypeConfigurationsComponent } from './admin/contract/check-type-configuration/check-type-configuration.component';
import { ContractUrlGuard } from './shared/masterData/contract/contractUrl.guard';
import { AuthorizationGuard } from './core/authorization.guard';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { GlobalUserRole } from './shared/masterData/user/global-user-role';
import { ContractUserRole } from './shared/masterData/user/contract-user-role';
import { ContractUserConfigurationComponent } from './admin/contract/contract-user-configuration/contract-user-configuration.component';
import { AcceptanceTargetsComponent } from './acceptance/acceptance-targets/acceptance-targets.component';
import { SpotCheckEditEntryComponent } from './random-sample/spot-check-edit-entry/spot-check-edit-entry.component';
import { ControlEditEntryComponent } from './control/control-edit-entry/control-edit-entry.component';
import { AcceptanceEditEntryComponent } from './acceptance/acceptance-edit-entry/acceptance-edit-entry.component';
import { LanguagesComponent } from './admin/allgemein/languages/languages.component';
import { DeficitTagsComponent } from './admin/allgemein/deficit-tags/deficit-tags.component';
import { DeficitGroupProposalsComponent } from './shared/components/deficit-proposals/deficit-group-proposals/deficit-group-proposals.component';
import { DeficitGlobalProposalsComponent } from './admin/allgemein/deficit-global-proposals/deficit-global-proposals.component';

const routes: Routes = [
    {
        path: 'admin',
        component: LayoutComponent,
        canActivate: [MsalGuard, AuthorizationGuard],
        data: { requiredGlobalRole: GlobalUserRole.Admin },
        children: [
            { path: 'deficit-groups', component: DeficitGroupsComponent, data: { header: "global-admin.title" } },
            { path: 'deficit-global-proposals', component: DeficitGlobalProposalsComponent, data: { header: "global-admin.title" } },
            { path: 'deficites', component: DeficitesComponent, data: { header: "global-admin.title" } },
            { path: 'users', component: UsersComponent, data: { header: "global-admin.title" } },
            { path: 'trades', component: TradesComponent, data: { header: "global-admin.title" } },
            { path: 'contract-positions', component: ContractPositionsComponent, data: { header: "global-admin.title" } },
            { path: 'check-types', component: CheckTypesComponent, data: { header: "global-admin.title" } },
            { path: 'languages', component: LanguagesComponent, data: { header: "global-admin.title" } },
            { path: 'deficittags', component: DeficitTagsComponent, data: { header: "global-admin.title" } },
            { path: '', redirectTo: 'contracts', pathMatch: 'full' }
        ]
    },
    {
        path: 'contracts',
        canActivate: [MsalGuard, AuthorizationGuard],
        data: { requiredGlobalRole: GlobalUserRole.User },
        component: LayoutComponent,
        children: [
            { path: '', component: ContractsManagementComponent, data: { header: "contract-list" } },
        ]
    },
    {
        path: 'unauthorized', component: UnauthorizedComponent
    },
    {
        path: 'contract/:contractUrlComponent',
        component: LayoutComponent,
        canActivate: [MsalGuard, AuthorizationGuard, ContractUrlGuard],
        data: {
            contractRoles: [
                ContractUserRole.Admin,
                ContractUserRole.ContractorEmployee,
                ContractUserRole.ContractorManager,
                ContractUserRole.OrdererEmployee,
                ContractUserRole.OrdererManager]
        },
        children: [
            {
                path: 'deficit-proposals',
                component: DeficitGroupProposalsComponent
            },
            {
                path: 'spot-check',
                canActivate: [AuthorizationGuard],
                data: {
                    contractRoles: [
                        ContractUserRole.Admin,
                        ContractUserRole.ContractorEmployee,
                        ContractUserRole.ContractorManager,
                        ContractUserRole.OrdererEmployee,
                        ContractUserRole.OrdererManager]
                },
                children: [
                    { path: 'overview', component: MonitoringComponent, data: { header: "spot-checks.header", subheader: "spot-checks.subheader-overview" } },
                    { path: 'scheduling', component: SchedulingComponent, data: { header: "spot-checks.header", subheader: "spot-checks.subheader-planing" } },
                    { path: 'spot-check-targets', component: RandomSampleTargetsComponent },
                    { path: 'edit-form', component: SpotCheckEditEntryComponent },
                    { path: 'edit-form/:systemname', component: SpotCheckEditEntryComponent }
                ]
            },
            {
                path: 'control',
                canActivate: [AuthorizationGuard],
                data: {
                    contractRoles: [
                        ContractUserRole.Admin,
                        ContractUserRole.ContractorEmployee,
                        ContractUserRole.ContractorManager,
                        ContractUserRole.OrdererEmployee,
                        ContractUserRole.OrdererManager]
                },
                children: [
                    { path: 'overview', component: ControlOverviewComponent, data: { header: "controls.header", subheader: "controls.subheader-overview" } },
                    { path: 'scheduling', component: ControlPlanningComponent, data: { header: "controls.header", subheader: "controls.subheader-planing" } },
                    { path: 'control-targets', component: ControlTargetsComponent, data: { header: "controls.header", subheader: "controls.subheader-targets" } },
                    { path: 'edit-form', component: ControlEditEntryComponent },
                    { path: 'edit-form/:systemname', component: ControlEditEntryComponent }
                ]
            },
            {
                path: 'acceptance',
                canActivate: [AuthorizationGuard],
                data: {
                    contractRoles: [
                        ContractUserRole.Admin,
                        ContractUserRole.ContractorEmployee,
                        ContractUserRole.ContractorManager,
                        ContractUserRole.OrdererEmployee,
                        ContractUserRole.OrdererManager]
                },
                children: [
                    { path: 'overview', component: AcceptanceComponent, data: { header: "acceptances.header", subheader: "acceptances.subheader-overview" } },
                    { path: 'scheduling', component: AcceptancePlaningComponent, data: { header: "acceptances.header", subheader: "acceptances.subheader-planing" } },
                    { path: 'acceptance-targets', component: AcceptanceTargetsComponent, data: { header: "acceptances.header", subheader: "acceptances.subheader-targets" } },
                    { path: 'edit-form', component: AcceptanceEditEntryComponent }
                ]
            },
            {
                path: 'admin',
                canActivate: [AuthorizationGuard],
                data: { contractRoles: [ContractUserRole.Admin] },
                children: [
                    { path: 'contract-position-configurations', component: ContractPositionConfigurationComponent },
                    { path: 'deficite-configurations', component: DeficiteConfigurationsComponent },
                    { path: 'trade-configurations', component: TradeConfigurationsComponent },
                    { path: 'check-type-configurations', component: CheckTypeConfigurationsComponent },
                    { path: 'contract-user-configurations', component: ContractUserConfigurationComponent }
                ]
            },
            {
                path: '',
                canActivate: [AuthorizationGuard],
                data: {
                    data: { header: "Dashboard" },
                    contractRoles: [
                        ContractUserRole.Admin,
                        ContractUserRole.ContractorEmployee,
                        ContractUserRole.ContractorManager,
                        ContractUserRole.OrdererEmployee,
                        ContractUserRole.OrdererManager]
                },
                component: DashboardComponent
            }
        ]
    },
    {
        path: '**', 
        redirectTo: 'contracts'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [
        ContractUrlGuard
    ]
})
export class AppRoutingModule { }
