import { Injectable } from '@angular/core';
import { Contract } from './contract.resource';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { ContractLoadFilter } from 'app/admin/allgemein/contracts-management/contract-load-filter';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ContractService {

    private url = `${environment.apiUrl}/api/contracts`;
    public language: string = 'en';

    private _contractMap: Map<string, Contract> = new Map<string, Contract>();
    private _contracts: Contract[] = [];

    private _newValues = new ReplaySubject(1);
    private _selectedContractSubject = new ReplaySubject<Contract>(1);

    constructor(private _http: HttpClient, private _translate: TranslateService) {
        // this.buildCache();
    }

    public buildCache() {
        this._http.get<PagedResultResponse<Contract>>(this.url)
            .pipe(
                take(1)
            ).subscribe((contracts) => {
                contracts.items.map((contract) => this._contractMap.set(contract.urlPath, contract));
                this._contracts = contracts.items;
                this._newValues.next(true);
            }, () => { });
    }

    public getContracts(skipNr?: number, takeNr?: number, filter?: ContractLoadFilter): Observable<PagedResultResponse<Contract>> {
        let url = `${this.url}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter.name) {
                url = `${url}&name=${filter.name}`;
            }
            if (filter.url) {
                url = `${url}&urlpath=${filter.url}`;
            }
        }
        return this._http.get<PagedResultResponse<Contract>>(url);
    }

    public getContractHistory(id: string): Observable<Contract[]> {
        return this._http.get<Contract[]>(`${this.url}/${id}/history`);
    }

    public get selectedContract(): Observable<Contract> {
        return this._selectedContractSubject.asObservable();
    }

    public selectContractByUrl(url: string) {
        const contract = this._contractMap.get(url);
        if (contract && contract.contractLanguageCode) {
            if (this._translate.langs.find(l => l === contract.contractLanguageCode.toLowerCase())) {
                this._translate.use(contract.contractLanguageCode.toLowerCase());
            } else {
                this._translate.use(this._translate.defaultLang);
            }
        } else {
            this._translate.use(this.language);
        }
        this._selectedContractSubject.next(contract);
    }

    public deselectContract() {
        this._translate.use(this._translate.defaultLang);
        this._selectedContractSubject.next(null);
    }

    public getContract(id: string): Observable<Contract> {
        return this._newValues.pipe(
            map(() => this._contracts.find(contract => contract.id == id))
        )
    }

    public getContractByUrl(url: string): Observable<Contract> {
        return this._newValues.pipe(
            map(() => this._contractMap.get(url))
        )
    }

    public get contractsObservable(): Observable<Contract[]> {
        return this._newValues.pipe(
            map(() => this._contracts)
        )
    }

    public createContract(contract: Contract) {
        return this._http.post<Contract>(this.url, contract);
    }

    public updateContract(contract: Contract) {
        return this._http.put<Contract>(`${this.url}/${contract.id}`, contract);
    }

    public deleteContract(contractId: string) {
        return this._http.delete(`${this.url}/${contractId}`);
    }
}