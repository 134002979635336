import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { UserProfileService } from './user-profile.service';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, map } from 'rxjs/operators';
import { User } from './user';
import { Contract } from 'app/shared/masterData/contract/contract.resource';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

    constructor(private router: Router, private userProfileService: UserProfileService, private contractService: ContractService) { }

    canActivate(routerSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (routerSnapshot.data.contractRoles) {
            const user$ = this.userProfileService.currentUser.pipe(take(1));
            const contract$ = this.contractService.selectedContract.pipe(take(1));
            return forkJoin(user$, contract$).pipe(map(([user, contract]) => {
                return this.isAuthorizedForContract(routerSnapshot.data.contractRoles, user as User, contract)
            }));
        }
        else {
            return this.userProfileService.currentUser.pipe(map((user) => {
                if (user === 'Unauthorized')
                {
                    return this.redirectToNotAuthorized();
                }
                if (!user) {
                    return this.redirectToNotAuthorized();
                }
                if ((user as User).role === GlobalUserRole.Admin || (user as User).role === GlobalUserRole.GlobalAdmin) {
                    return true;
                }
                else if (routerSnapshot.data.requiredGlobalRole !== undefined) {
                    return this.isAuthorizedGlobal(routerSnapshot.data.requiredGlobalRole, user as User);
                }
                return false;
            }));
        }
    }

    private isAuthorizedGlobal(requiredRole: GlobalUserRole, user: User): boolean {
        const isAuthorized = GlobalUserRole.isRoleEqualOrHigher(user.role, requiredRole);
        if (isAuthorized) {
            return true;
        }
        else {
            return this.redirectToNotAuthorized();
        }
    }

    private isAuthorizedForContract(contractRoles: ContractUserRole[], user: User, contract: Contract): boolean {
        if (user.role === GlobalUserRole.Admin || user.role === GlobalUserRole.GlobalAdmin) {
            return true;
        }
        if (!user.contractUsers) {
            return this.redirectToNotAuthorized();
        }
        const contractUserRole = user.contractUsers.find(r => r.contractId === contract.id);
        if (!contractUserRole || contractRoles.indexOf(contractUserRole.contractRole) == -1) {
            return this.redirectToNotAuthorized();
        }
        return true;
    }

    private redirectToNotAuthorized() : boolean {
        this.router.navigate(['unauthorized']);
        return false;
    }
}
