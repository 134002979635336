<div class="modal-header">
  <h4 *ngIf="inNewMode" class="modal-title" id="modal-basic-title">
    {{ "targets.dialog.new" | translate }}
  </h4>
  <h4 *ngIf="!inNewMode" class="modal-title" id="modal-basic-title">
    {{ "targets.dialog.edit" | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group row">
      <label class="col-xl-4 col-form-label">{{
        "shared.trade" | translate
      }}</label>
      <div class="col-xl-8">
        <select
          [(ngModel)]="target.tradeConfigurationId"
          class="custom-select"
          name="tradeId"
          id="trade"
          [disabled]="!inNewMode"
        >
          <option *ngFor="let trade of tradeConfigs" [ngValue]="trade.id">
            {{ trade.trade.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-4 col-form-label">{{
        "shared.spot-check-type" | translate
      }}</label>
      <div class="col-xl-8">
        <select
          [(ngModel)]="target.checkTypeConfigurationId"
          class="custom-select"
          name="checkTypeId"
          id="checkType"
          [disabled]="!inNewMode"
        >
          <option
            *ngFor="let checkType of checkTypeConfigs"
            [ngValue]="checkType.id"
          >
            {{ checkType.checkType.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-4 col-form-label">{{
        "shared.contract-position" | translate
      }}</label>
      <div class="col-xl-8">
        <select
          [(ngModel)]="target.contractPositionConfigurationId"
          class="custom-select"
          name="contractPositionId"
          id="contractPosition"
          [disabled]="!inNewMode"
        >
          <option
            *ngFor="let contractPosition of contractPositionConfigs"
            [ngValue]="contractPosition.id"
          >
            {{ contractPosition.contractPosition.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label>{{ "shared.interval" | translate }}</label>
      <select
        [(ngModel)]="target.interval"
        class="custom-select"
        name="intervalTypesValue"
        id="selectInterval"
      >
        <option
          *ngFor="let interval of intervalTypes"
          [ngValue]="interval.value"
        >
          {{ interval.displayName | translate }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label>{{ "shared.amount" | translate }}</label>
      <input
        class="form-control form-control-lg mb-2"
        type="number"
        [(ngModel)]="target.amount"
        name="Anzahl"
      />
    </div>

    <epo-created-modified
      [createdBy]="target.createdBy"
      [modifiedBy]="target.modifiedBy"
      [createdUtc]="target.createdUtc"
      [modifiedUtc]="target.modifiedUtc"
    ></epo-created-modified>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.dismiss('Close click')"
  >
    <i class="icon" class="fas fa-ban"></i>
    {{ "shared.dialog.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-dark"
    (click)="activeModal.close(target)"
    [disabled]="!formIsFilled()"
  >
    <i class="icon" class="fas fa-save"></i>
    {{ "shared.dialog.save" | translate }}
  </button>
</div>
