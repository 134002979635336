import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject } from 'rxjs';

import { map, take } from 'rxjs/operators';
import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';
import { ConfigLoadFilter } from 'app/admin/allgemein/shared/config-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';
import { DeficitTag } from '../deficit/deficit-tag.resource';

@Injectable({
    providedIn: 'root'
})

export class DeficitTagsService {
    private _url = `${environment.apiUrl}/api/deficittags`;

    constructor(private _http: HttpClient) { }

    public getAllDeficitTags(skipNr?: number, takeNr?: number, filter?: NameLoadFilter):
        Observable<PagedResultResponse<DeficitTag>> {
        let url = `${this._url}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            url = LoadFilterHelper.addBaseFilterValues(url, filter);
        }
        return this._http.get<PagedResultResponse<DeficitTag>>(url);
    }

    public createDeficitTag(deficitTag: DeficitTag) {
        return this._http.post<DeficitTag>(this._url, deficitTag);
    }

    public updateDeficitTag(deficitTag: DeficitTag) {
        return this._http.put<DeficitTag>(`${this._url}/${deficitTag.id}`, deficitTag);
    }

    public deleteDeficitTagById(id: string) {
        return this._http.delete(`${this._url}/${id}`);
    }

    
}