<div class="more-width-container">
  <div class="header-cntainer">
    <h3 class="pb-2">
      {{ "global-admin.deficits.title-configure" | translate }}
    </h3>
    <div class="item-counter" *ngIf="!isLoading">
      {{ "shared.count" | translate }} {{ pagingGlobalCount }}
    </div>
  </div>
  <table class="table table-hover">
    <thead class="thead">
      <th></th>
      <th>
        {{ "global-admin.deficits.deficit-group" | translate }}
        <span (click)="sort('DeficitGroup')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('DeficitGroup', 'Asc') && !isActiveSorting('DeficitGroup', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('DeficitGroup', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('DeficitGroup', 'Desc')"></i>
        </span>
      </th>
      <th>
        {{ "global-admin.deficits.name" | translate }}
        <span (click)="sort('Name')">
          <i class="fas fa-sort" *ngIf="!isActiveSorting('Name', 'Asc') && !isActiveSorting('Name', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Name', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Name', 'Desc')"></i>
        </span>
      </th>
      <th>{{ "global-admin.deficits.description" | translate }}</th>
      <th>{{ "shared.selection" | translate }}</th>
      <th>{{ "global-admin.deficits.spot-checks" | translate }}</th>
      <th>{{ "global-admin.deficits.controls" | translate }}</th>
      <th>{{ "global-admin.deficits.contract-positions" | translate }}</th>
      <th>{{ "global-admin.deficits.types" | translate }}</th>
      <th>
        <div>
          ID
          <span (click)="sort('Number')">
            <i class="fas fa-sort" *ngIf="!isActiveSorting('Number', 'Asc') && !isActiveSorting('Number', 'Desc')"></i>
            <i class="fas fa-sort-up" *ngIf="isActiveSorting('Number', 'Asc')"></i>
            <i class="fas fa-sort-down" *ngIf="isActiveSorting('Number', 'Desc')"></i>
          </span>
        </div>
      </th>
      <th></th>
    </thead>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.deficitGroup" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingDeficitGroupNames" [isActive]="true"
        [contractId]="loadFilter.contractId" [configurationActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingDeficitNamesForConfiguration">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(searchText)]="loadFilter.description" (searchTextChange)="onFilterChange()"
        [autocomplete]="false">
      </epo-column-filter>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
          {{ loadFilter.isSelected | boolToString: "shared.all" | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isControl of boolSelection" class="dropdown-item" (click)="isSelectedFilterChanged(isControl)"
            ngbDropdownItem>{{ isControl | boolToString: "shared.all" | translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
          {{ loadFilter.isSpotCheck | boolToString: "shared.all" | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isSpotCheck of boolSelection" class="dropdown-item"
            (click)="isSpotCheckFilterChanged(isSpotCheck)" ngbDropdownItem>{{ isSpotCheck | boolToString: "shared.all"
            | translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
          {{ loadFilter.isControl | boolToString: "shared.all" | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isControl of boolSelection" class="dropdown-item" (click)="isControlFilterChanged(isControl)"
            ngbDropdownItem>{{ isControl | boolToString: "shared.all" | translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.contractPosition" (selectionChange)="onFilterChange()"
        [getValuesFunction]="
          globalAutocompleteService.getMatchingContractPositionNames
        " [isActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.checkType" (selectionChange)="onFilterChange()" [getValuesFunction]="
          globalAutocompleteService.getMatchingCheckTypeNames
        " [isActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.entityNumber" (selectionChange)="onFilterChange()"
        [getValuesFunction]="
          globalAutocompleteService.getMatchingDeficitNumbersForConfiguration
        ">
      </epo-column-filter>
    </th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="9">
          {{ "shared.loading" | translate }}...
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let deficit of deficits">
        <td class="table-item-name">
          <div (click)="showHistory(deficit)" class="ml-1 history-button fa-border"
            title="{{ 'shared.history' | translate }}" *ngIf="deficit.deficitConfigurationId">
            <i class="fa fa-history fa-lg" aria-hidden="true"></i>
          </div>
        </td>
        <td style="width: 15%">{{ deficit.deficitGroupName }}</td>
        <td style="width: 15%">{{ deficit.title }}</td>
        <td style="width: 20%">
          <div *ngIf="!deficit.isEditing">
            <div class="description-container" [innerHTML]="deficit.editedDescription"></div>
            <div class="global-description-container" *ngIf="deficit.editedDescription !== deficit.globalDescription">
              <div class="description-hint alert-dark">{{ "global-admin.deficits.global-description" | translate }}:
              </div>
              <div class="description-container" [innerHTML]="deficit.globalDescription"></div>
            </div>
          </div>
          <textarea [ngxSummernote]="descriptionConfig" [(ngModel)]="deficit.editedDescription"
            *ngIf="deficit.isEditing"></textarea>
          <button type="button" *ngIf="deficit.isEditing" class="btn btn-outline-danger reset-description"
            (click)="deficit.resetDescription()">
            {{ "global-admin.deficits.reset-description" | translate }}
          </button>
        </td>
        <td class="edit" style="width: 5%">
          <label class="checkbox-container" *ngIf="!deficit.isEditing">
            <input type="checkbox" [(ngModel)]="deficit.isSelected" (change)="setIsActiveConfig(deficit)" />
            <span class="checkmark"></span>
          </label>
        </td>
        <td style="width: 5%" class="icon-container">
          <i class="{{ deficit.isSpotCheckDeficit | toBoolSymbol: 2 }}"></i>
        </td>
        <td style="width: 5%" class="icon-container">
          <i class="{{ deficit.isControlDeficit | toBoolSymbol: 2 }}"></i>
        </td>
        <td class="no-mobile" colspan="2" style="width: 22%">
          <div *ngFor="let map of deficit.mappings" class="mapping-row">
            <div class="mapping-column">
              <p>
                {{ map.contractPosition }}
              </p>
            </div>
            <div class="mapping-column">
              <p>
                {{ map.checkType }}
              </p>
            </div>
          </div>
        </td>
        <td style="width: 5%">{{ deficit.entityNumber }}</td>
        <td class="no-mobile">
          <div *ngIf="isSaving" class="loader"></div>

          <button *ngIf="!deficit.isEditing && !isSaving" type="button" class="btn btn-outline-primary"
            (click)="deficit.isEditing = true">
            <div class="flex-gap">
              <div><i class="fas fa-edit"></i></div>
              <div>{{ "shared.buttons.edit" | translate }}</div>
            </div>
          </button>

          <button *ngIf="deficit.isEditing && !isSaving" type="button" class="btn btn-outline-dark"
            style="margin-bottom: 5px" (click)="deficit.cancelEdit()">
            <div class="flex-gap">
              <div><i class="fas fa-ban"></i></div>
              <div>{{ "shared.dialog.cancel" | translate }}</div>
            </div>
          </button>

          <button *ngIf="deficit.isEditing && !isSaving" type="button" class="iconButton" class="btn btn-dark"
            (click)="saveConfiguration(deficit)">
            <div class="flex-gap">
              <div><i class="fas fa-save"></i></div>
              <div>{{ "shared.dialog.save" | translate }}</div>
            </div>
          </button>

        </td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>