export class DisplayStatus {
    constructor(public cssClass: string, public description: string) { }

    static createWaitingIcon(description: string): DisplayStatus {
        return new this('fa-hourglass', description);
    }

    static createAllrightIcon(description: string): DisplayStatus {
        return new this('fa-thumbs-up green', description);
    }

    static createMediumNotOkIcon(description: string): DisplayStatus {
        return new this('fa-thumbs-down yellow', description);
    }

    static createBadNotOkIcon(description: string): DisplayStatus {
        return new this('fa-thumbs-down red', description);
    }

    static createMediumConflictIcon(description: string): DisplayStatus {
        return new this('fa-bolt yellow', description);
    }

    static createBadConflictIcon(description: string): DisplayStatus {
        return new this('fa-bolt red', description);
    }

    static createBadRedoIcon(description: string): DisplayStatus {
        return new this('fa-redo red', description);
    }

    static createGoodRedoIcon(description: string): DisplayStatus {
        return new this('fa-redo green', description);
    }

    static createUnknownStatus(): DisplayStatus {
        return { cssClass: 'fa-question-circle', description: 'status.unknown' };
    }
}
