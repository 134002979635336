export enum OrderBy {
    DeficitGroupAsc = 'DeficitGroupAsc',
    DeficitGroupDesc = 'DeficitGroupDesc',
    NumberAsc = 'NumberAsc',
    NumberDesc = 'NumberDesc',
    NameAsc = 'NameAsc',
    NameDesc = 'NameDesc',
    FunctionAsc = 'FunctionAsc',
    FunctionDesc = 'FunctionDesc',
    ServiceAsc = 'ServiceAsc',
    ServiceDesc = 'ServiceDesc',
    CheckTypeAsc = 'CheckTypeAsc',
    CheckTypeDesc = 'CheckTypeDesc',
    AmountAsc = 'AmountAsc',
    AmountDesc = 'AmountDesc',
    IntervalAsc = 'IntervalAsc',
    IntervalDesc = 'IntervalDesc',
    CreatedOnAsc = 'CreatedOnAsc',
    CreatedOnDesc = 'CreatedOnDesc'
}

export enum OrderDirection {
    Asc = 0,
    Desc = 1
}
