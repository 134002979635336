import { StatusPhase } from 'app/shared/models/status-phase';
import { WorkOwner } from './work-owner';
import { WorkControlOwner } from './work-control-owner';
import { WorkMainOwner } from './work-main-owner';
import { CommentResource } from 'app/shared/models/comment.resource';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { DedicatedUser } from 'app/shared/masterData/user/dedicated-user';

export class AcceptanceResource {
    id: string;
    contractPositionConfigurationId: string;
    contractEntityNumber: string;
    tradeConfigurationId: string;
    workPreperation: boolean;
    workOwner: WorkOwner;
    workCompleted: boolean;
    workControlOwner: WorkControlOwner;
    workMainOwner: WorkMainOwner;
    workPerformanceDocuments: boolean;
    workControlDocuments: boolean;
    avDocuments: boolean;
    startAcceptanceDateTimeUtc: string;
    endAcceptanceDateTimeUtc: string;
    statusPhaseOne: StatusPhase;
    statusPhaseTwo: StatusPhase;
    statusPhaseThree: StatusPhase;
    zuordnungsnachweis: number;
    leistungsvollstaendigkeit: number;
    leistungsqualitaet: number;
    leistungstermintreue: number;
    vertraglicheVereinbarung: number;
    createdUtc: string;
    createdBy: UserResponse;
    modifiedUtc: string;
    modifiedBy: UserResponse;
    comments: CommentResource[];
    isEditable: boolean;
    isWithdrawn:boolean;
    dedicatedUser: DedicatedUser;
}
