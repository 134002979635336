import { PerformanceKpi } from 'app/acceptance/shared/performancekpi-model';
import { CommentResource } from 'app/shared/models/comment.resource';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { StatusPhase } from 'app/shared/models/status-phase';

export class Acceptance {
     /**
      * Positionsnummer
      */
     id: string;
     contractPositionConfigurationId: string;
     /**
      * Funktion
      */
     tradeConfigurationId: string;
     /**
      * Prozentualle Angabe.
      */
     tolerance_limit: number;
     /**
      * Arbeitsvorbereitung durchgeführt (ja/nein).
      */
     work_preperation: boolean;
     /**
      * Entspricht der Leistungsdokumentation (bereits im Formualr berechnete Werte)
      */
     performance_kpi?: PerformanceKpi;
     /**
      * Leistungsdurchführungs Verantwortlicher
      */
     work_owner: string;
     /**
      * Leistung vollständig
      */
     work_completed: boolean;
     /**
      * Leistungskontroll Verantwortlicher
      */
     work_control_owner: string;
     /**
      * Leistungs Hauptverantwortlicher
      */
     av_documents: boolean;
     /**
          * AV-Dokumente
          */

     work_performance_documents: boolean;
     /**
          * Leistungserbringungsdokumente
          */
     work_main_owner: string;
     /**
      * Leistungkontrolldokumente
      */
     work_control_docuemnts: boolean;
     zuordnungsnachweis: { value: number, weighting: number };
     leistungsvollstaendigkeit: { value: number, weighting: number };
     leistungsqualitaet: { value: number, weighting: number };
     leistungstermintreue: { value: number, weighting: number };
     vertragliche_Vereinbarung: { value: number, weighting: number };
     acceptanceResponsible: string;
     acceptance_Status: string;
     comments: CommentResource[];
     status_Phase1: StatusPhase;
     status_Phase2: StatusPhase;
     status_Phase3: StatusPhase;
     contractEntityNumber: string;
     amount: number;
     interval: number;
     year: number;
     createdUtc: string;
     modifiedUtc: string;
     createdBy: UserResponse;
     modifiedBy: UserResponse;
     isEditable: boolean;
     isWithdrawn: boolean;
     dedicatedUser: UserResponse;
}
