import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Karte } from '../../shared/components/card/card';
import { EInterval } from '../../shared/models/interval';
import { Subscription, forkJoin } from 'rxjs';
import { SpotCheckService } from '../shared/spot-check.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RandomCardCreator } from '../random-card-creator';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { BaseFilterData } from 'app/shared/components/filter/filter';
import { SpotCheckFormComponent } from '../spot-check-form/spot-check-form.component';

@Component({
  selector: 'epo-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit, OnDestroy {
  public filterSettings = new FilterSettings(false, true, false, false, false);
  public stichprobenKarten: Karte[];
  public selectedIntervalType: EInterval;
  private _currentFilter: BaseFilterData;
  private filterSubscription: Subscription;

  constructor(
    private _randomSampleService: SpotCheckService,
    private _changeDetector: ChangeDetectorRef,
    private _filterService: FilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.filterSubscription = this._filterService.getFilter().subscribe((filter) => {
      this.filterChanged(filter as BaseFilterData);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public addStichprobe(stichprobenartId: string) {
    const modalRef = this.modalService.open(SpotCheckFormComponent, { size: 'lg' });
    const filterAdjustedByType = this._currentFilter;
    filterAdjustedByType.selectedCheckType.id = stichprobenartId;
    modalRef.componentInstance.filterData = filterAdjustedByType;

    modalRef.result.then(() => {
      this.loadData(true);
    },
      () => { });
  }

  public editTargets() {
    this.router.navigate(['../spot-check-targets'], { relativeTo: this.activatedRoute });
  }

  private filterChanged(filter: BaseFilterData) {
    this._currentFilter = filter;
    this.selectedIntervalType = this._currentFilter.selectedIntervalType;
    this.loadData(true);
  }

  private loadData(forceReload: boolean, manualRunChangeDetection?: boolean) {
    if (forceReload) {
      this._randomSampleService.cleanCache();
    }

    forkJoin([
      this._randomSampleService.getTypes(),
      this._randomSampleService.getTargetsBy(
        this._currentFilter.selectedTrade.id, this._currentFilter.selectedContractPosition.id),
      this._randomSampleService.getSpotChecks({
        selectedDateRange: this._currentFilter.selectedDateRange,
        contractPositionId: this._currentFilter.selectedContractPosition.id,
        tradeId: this._currentFilter.selectedTrade.id,
        userId: this._currentFilter.selectedUser.id
      })
    ]).subscribe(x => {
      const stichprobenarten = x[0];
      const targets = x[1];
      const stichproben = x[2];

      const cardCreator = new RandomCardCreator(stichprobenarten, targets, stichproben.items, this.selectedIntervalType);
      this.stichprobenKarten = cardCreator.createCards();
      if (manualRunChangeDetection) {
        this._changeDetector.detectChanges();
      }
    });
  }
}
