import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { Deficit } from 'app/shared/masterData/deficit/deficit.resource';
import { DeficitGroup } from 'app/shared/masterData/deficit/deficit-group.resource';
import { Language } from 'app/shared/masterData/language/language.resource';
import { ContractPosition } from 'app/shared/masterData/contract-position/contract-position.resource';
import { CheckType } from 'app/shared/masterData/check-type/check-type.resource';
import { DeficitVariant } from 'app/shared/masterData/deficit-variant';

export class DeficitHistoryHelper {

    constructor(private _deficits: Deficit[],
        private _deficitGroups: DeficitGroup[],
        private _contractPositions: ContractPosition[],
        private _checkTypes: CheckType[],
        private _languages: Language[]) {
    }


    private checkProperties: PropertyDisplayMapping[] = [
        { propertyName: 'title', displayName: 'shared.name'},
        { propertyName: 'description', displayName: 'shared.description', type: 'code'},
        { propertyName: 'isActive', displayName: 'global-admin.deficits.active', type: 'boolean'},
        { propertyName: 'deficitGroupId', displayName: 'global-admin.deficits.deficit-group', displayValueTransformation: (id) => this.resolveDeficitGroup(id)},
    ]

    public generateHistoryEntries(): HistoryEntryModel[] {
        const historyResources = [new Deficit(), ...this._deficits];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: Deficit, newValues: Deficit): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this.checkProperties) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                    changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if (changedProperty.newValue != null || changedProperty.oldValue != null) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }
        this.handeTranslations(newValues, oldValues, historyEntry);
        this.handeMappings(newValues, oldValues, historyEntry);
        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }

    private handeTranslations(newValues: Deficit, oldValues: Deficit, historyEntry: HistoryEntryModel) {
        let changedTranslations = new ChangedProperty();
        changedTranslations.propertyName = 'shared.translations';
        for (const newTranslation of newValues.translations) {
            const oldTranslation = oldValues.translations && oldValues.translations.find(old => old.languageId == newTranslation.languageId);
            if (oldTranslation) {
                if (newTranslation.title != oldTranslation.title) {
                    changedTranslations.subProperties.push({
                        propertySubName: this.resolveLanguage(newTranslation.languageId),
                        propertyName: 'shared.name',
                        newValue: newTranslation.title,
                        oldValue: oldTranslation.title
                    });
                }
                if (newTranslation.description != oldTranslation.description) {
                    changedTranslations.subProperties.push({
                        propertySubName: this.resolveLanguage(newTranslation.languageId),
                        propertyName: 'shared.description',
                        newValue: newTranslation.description,
                        oldValue: oldTranslation.description,
                        viewType: 'code'
                    });
                }
            } else {
                changedTranslations.subProperties.push({
                    propertySubName: this.resolveLanguage(newTranslation.languageId),
                    propertyName: 'shared.name',
                    newValue: newTranslation.title
                });
                changedTranslations.subProperties.push({
                    propertySubName: this.resolveLanguage(newTranslation.languageId),
                    propertyName: 'shared.description',
                    newValue: newTranslation.description,
                    viewType: 'code'
                });
            }
        }
        const removedTranslations = oldValues.translations.filter(oldTrans => !newValues.translations.find(newTrans => newTrans.languageId == oldTrans.languageId));
        for (const trans of removedTranslations) {
            changedTranslations.subProperties.push({
                propertySubName: this.resolveLanguage(trans.languageId),
                propertyName: 'shared.name',
                oldValue: trans.title
            });
            changedTranslations.subProperties.push({
                propertySubName: this.resolveLanguage(trans.languageId),
                propertyName: 'shared.description',
                oldValue: trans.description,
                viewType: 'code'
            });
        }
        if (changedTranslations.subProperties.length > 0) {
            historyEntry.changedProperties.push(changedTranslations);
        }
    }

    private handeMappings(newValues: Deficit, oldValues: Deficit, historyEntry: HistoryEntryModel) {
        let changedMappins = new ChangedProperty();
        changedMappins.propertyName = 'shared.mappings';
        this.handleChangedMappings(newValues, oldValues, changedMappins);
        this.handleRemovedMappings(oldValues, newValues, changedMappins, historyEntry);
    }

    private handleRemovedMappings(oldValues: Deficit, newValues: Deficit, changedMappins: ChangedProperty, historyEntry: HistoryEntryModel) {
        if (!oldValues.mappings) {
            return;
        }
        const removedMappings = oldValues.mappings.filter(oldMap => !newValues.mappings.find(newMap => newMap.id == oldMap.id));
        for (const map of removedMappings) {
            changedMappins.subProperties.push({
                propertyName: this.resolveMappingName(map.deficitVariant),
                oldValue: `${this.resolveCheckType(map.checkTypeId)} / ${this.resolveContractPos(map.contractPositionId)}`
            });
        }
        if (changedMappins.subProperties.length > 0) {
            historyEntry.changedProperties.push(changedMappins);
        }
    }

    private handleChangedMappings(newValues: Deficit, oldValues: Deficit, changedMappins: ChangedProperty) {
        if (!newValues.mappings) {
            return;
        }
        for (const newMapping of newValues.mappings) {
            const oldMapping = oldValues.mappings && oldValues.mappings.find(old => old.id == newMapping.id);
            if (oldMapping && oldMapping) {
                if (newMapping.checkTypeId != oldMapping.checkTypeId || newMapping.contractPositionId != oldMapping.contractPositionId) {
                    changedMappins.subProperties.push({
                        propertyName: this.resolveMappingName(newMapping.deficitVariant),
                        newValue: `${this.resolveCheckType(newMapping.checkTypeId)} / ${this.resolveContractPos(newMapping.contractPositionId)}`,
                        oldValue: `${this.resolveCheckType(oldMapping.checkTypeId)} / ${this.resolveContractPos(oldMapping.contractPositionId)}`
                    });
                }
            }
            else {
                changedMappins.subProperties.push({
                    propertyName: this.resolveMappingName(newMapping.deficitVariant),
                    newValue: `${this.resolveCheckType(newMapping.checkTypeId)} / ${this.resolveContractPos(newMapping.contractPositionId)}`
                });
            }
        }
    }

    private resolveLanguage(id: string): string {
        const lang = this._languages.find(p => p.id == id);
        return lang && lang.shortName || id;
    }

    private resolveDeficitGroup(id: string): string {
        const group = this._deficitGroups.find(p => p.id == id);
        return group && group.name || id;
    }

    private resolveContractPos(id: string): string {
        const pos = this._contractPositions.find(p => p.id == id);
        return pos && pos.name || id;
    }

    private resolveCheckType(id: string): string {
        const type = this._checkTypes.find(type => type.id == id);
        return type && type.name || id;
    }

    private resolveMappingName(deficitVariant: DeficitVariant) {
        if (deficitVariant === DeficitVariant.SpotCheck) {
            return 'shared.spot-check-mapping';
        }
        return 'shared.control-mapping';
    }

    private discardEmptyString(input: string): string {
        if (!this.isString(input)) return input;
        if (input.trim()) return input;
        return null;
    }

    private isString(value) {
        return typeof value === 'string';
    }
}

