<div class="more-width-container">
  <h3 class="pb-2">{{ 'global-admin.users.title-configure' | translate }}</h3>
  <table class="table table-hover">
    <thead class="thead">
      <th style="width: 25%;">{{ 'global-admin.users.mail' | translate }}</th>
      <th style="width: 20%;">{{ 'global-admin.users.name' | translate }}</th>
      <th style="width: 20%;">{{ 'global-admin.users.firstname' | translate }}</th>
      <th style="width: 25%;">{{ 'global-admin.users.role' | translate }}</th>
      <th style="width: 10%;"></th>
    </thead>
    <th>
      <epo-column-filter [(selection)]="loadFilter.email" (selectionChange)="onFilterChange()"
        [getValuesFunction]="golbalAutoCompleteService.getMatchingUserEmails">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.lastname" (selectionChange)="onFilterChange()"
        [getValuesFunction]="golbalAutoCompleteService.getMatchingUserLastNames">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.firstname" (selectionChange)="onFilterChange()"
        [getValuesFunction]="golbalAutoCompleteService.getMatchingUserFirstNames">
      </epo-column-filter>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>{{loadFilter.contractRole |
          contractRoleToString:'shared.all' | translate }}</button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let role of roleSelection" class="dropdown-item" (click)="filterRoleSelected(role)"
            ngbDropdownItem>{{role | contractRoleToString:'shared.all' | translate }}</a>
        </div>
      </div>
    </th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="5">
          {{ 'shared.loading' | translate }}...
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let user of users">
        <td>{{user.loginEmail}}</td>
        <td>{{user.lastname | emptyToDash}}</td>
        <td>{{user.firstname | emptyToDash}}</td>
        <td>
          <div ngbDropdown class="d-block">
            <button *ngIf="user.contractRole === undefined" class="btn btn-outline-primary dropdown-toggle"
              id="roleDropDown" ngbDropdownToggle [disabled]="isSaving">{{ 'global-admin.users.dialog.select-role' |
              translate }}</button>
            <button *ngIf="user.contractRole !== undefined" class="btn btn-outline-primary dropdown-toggle"
              id="deficitGroupDropdown" ngbDropdownToggle [disabled]="isSaving">{{user.contractRole |
              contractRoleToString:'shared.roles.no-assignment' | translate}}</button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="roleDropDown">
              <a *ngFor="let role of roleSelection" class="dropdown-item" (click)="setUserRole(user, role)"
                ngbDropdownItem>{{role | contractRoleToString:'shared.roles.no-assignment' | translate}}</a>
            </div>
          </div>
        </td>
        <td>
          <div *ngIf="isSaving" class="loader"></div>
        </td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>