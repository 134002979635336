import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeficitTagsService } from 'app/shared/masterData/deficit-tags/deficit-tags.service';
import { DeficitTag } from 'app/shared/masterData/deficit/deficit-tag.resource';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { ModalDeficitTagsEditComponent } from './modal-deficit-tags-edit/modal-deficit-tags-edit.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';

@Component({
  selector: 'epo-deficit-tags',
  templateUrl: './deficit-tags.component.html',
  styleUrls: ['./deficit-tags.component.scss']
})

export class DeficitTagsComponent extends BaseFilterPagingComponent<DeficitTag>{

  constructor(
    public modalService: NgbModal,
    public deficitTagsService: DeficitTagsService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _languageService: LanguageService
  ) {
    super();
  }

  loadData() {
    this.isLoading = true;
    const langName = localStorage.getItem('lang') || 'en';
    this._languageService.languagesObservable.pipe(take(1)).subscribe(l => {
      const lang = l.find(l => l.shortName === langName.toUpperCase());
      if (lang) {
        this.languageId = lang.id;
        this.loadFilter.languageId = lang.id;
      } else {
        this.languageId = null;
        this.loadFilter.languageId = null;
      }
      const deficitTags$ = this.deficitTagsService.getAllDeficitTags(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
      const languages$ = this._languageService.languagesObservable.pipe(take(1));
      forkJoin([deficitTags$, languages$]).subscribe(([tags, languages]) => {
        this.items = tags.items;
        this.pagingGlobalCount = tags.count;
        this.languages = languages;
        this.isLoading = false;
      }, err => {
        console.log(err);
      });
    });
  }

  public openCreateDeficitTagModal() {
    const deficitTag = new DeficitTag();
    deficitTag.isActive = true;
    const modalRef = this.modalService.open(ModalDeficitTagsEditComponent, { centered: true });
    modalRef.componentInstance.deficitTag = deficitTag;
    modalRef.componentInstance.inNewMode = true;
    modalRef.result.then((result) => {
      this.deficitTagsService.createDeficitTag(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openEditModal(deficitTag: DeficitTag) {
    const modalRef = this.modalService.open(ModalDeficitTagsEditComponent, { centered: true });
    modalRef.componentInstance.deficitTag = Object.assign({}, deficitTag);
    modalRef.componentInstance.inEditMode = true;
    modalRef.result.then((result) => {
      this.deficitTagsService.updateDeficitTag(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openDeleteModal(deficitTag: DeficitTag) {
    const modalRef = this.modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = deficitTag;
    modalRef.result.then((tag) => {
      this.deficitTagsService.deleteDeficitTagById(tag.id).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    });
  }

}
