<div class="card">
  <div class="card-header" [style.background-color]="card.backgroundColor">
    <div class="row">
      <div class="col-sm-9">
        <h5 class="card-title">{{card.name}}</h5>
      </div>
      <div class="col-sm-3 header-right">
        <a target="_blank" (click)="editTargets()">
          <div class="header-button" placement="left" ngbTooltip="{{ 'shared.buttons.edit' | translate }}">
            <i class="fa fa-edit fa-lg"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="card-block text-center">
    <div class="card-text">
      <div class="d-inline">
        <a class="btn btn-outline-secondary btn-sm add" (click)="add(card.id)" placement="left"
          ngbTooltip="{{ 'shared.card-add' | translate:topicParam }}">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a>
      </div>
      <span class="figures" data-toggle="tooltip" data-placement="right"
        title="{{ 'shared.card-is-should' | translate }}">{{card.istWert}}/{{card.sollWert}}</span>
      <div class="d-block context">
        <span>{{ 'shared.planing.per-left' | translate }}&nbsp;</span>
        <span class="zeitraum">{{ convertIntervalToDisplayValue(card.interval) | translate }}</span>
      </div>
    </div>
  </div>
  <div class="progress">
    <div class="progress-bar" role="progressbar" [style.background-color]="card.backgroundColor"
      [style.width]="getProgress(card.sollWert, card.istWert) + '%'" aria-valuenow="75" aria-valuemin="0"
      aria-valuemax="100" title="{{ 'shared.card-is-should' | translate }}"></div>
  </div>
</div>