<div class="modal-header">
  <h4 *ngIf="inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.deficits.dialog.title-new" | translate }}
  </h4>
  <h4 *ngIf="!inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.deficits.dialog.title-edit" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #deficitForm>
    <div class="borderline">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
          <a ngbNavLink>{{ "shared.lang-default" | translate }}</a>
          <ng-template ngbNavContent>
            <div>
              <div class="form-group">
                <label for="name">{{
                  "global-admin.deficits.dialog.name" | translate
                  }}</label>
                <input id="name" name="name" class="form-control" type="text" [(ngModel)]="deficit.titleDefault" />
              </div>
              <div class="form-group">
                <label for="description">{{
                  "global-admin.deficits.dialog.description" | translate
                  }}</label>
                <textarea [ngxSummernote]="descriptionConfig" [(ngModel)]="deficit.descriptionDefault"
                  name="texteditor"></textarea>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem *ngFor="let trans of translations">
          <a ngbNavLink>{{ trans.language.longName }}</a>
          <ng-template ngbNavContent>
            <div>
              <div class="form-group">
                <label for="name">{{
                  "global-admin.deficits.dialog.name" | translate
                  }}</label>
                <input id="name" name="name" class="form-control" type="text" [(ngModel)]="trans.title" />
              </div>
              <div class="form-group">
                <label for="description">{{
                  "global-admin.deficits.dialog.description" | translate
                  }}</label>
                <textarea [ngxSummernote]="descriptionConfig" [(ngModel)]="trans.description"
                  name="texteditor"></textarea>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
    <div class="form-group">
      <label for="name">{{ "global-admin.deficits.dialog.deficit-group" | translate }}</label>
      <div ngbDropdown class="d-block dropdown">
        <button *ngIf="deficit.deficitGroupId !== undefined" class="btn btn-outline-primary dropdown-toggle drop-button"
          id="deficitGroupDropdown" ngbDropdownToggle>
          {{ getDeficitGroupName(deficit.deficitGroupId | resolveDeficitGroup | async) }}
        </button>
        <button *ngIf="deficit.deficitGroupId === undefined" class="btn btn-outline-primary dropdown-toggle drop-button"
          id="deficitGroupDropdown" ngbDropdownToggle>
          {{ "global-admin.deficits.dialog.select-group" | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="deficitGroupDropdown">
          <a *ngFor="let deficitGroup of deficitGroups" class="dropdown-item"
            (click)="setDeficitGroupId(deficitGroup.id)" ngbDropdownItem>{{ getDeficitGroupName(deficitGroup) }}</a>
        </div>
      </div>
    </div>
    <!--Mapping start-->
    <div class="form-group">
      <div class="active-select">
        <label class="checkbox-container">{{ "global-admin.deficits.dialog.control" | translate }}
          <div class="active-check">
            <input type="checkbox" [(ngModel)]="deficit.isControlDeficit" name="isControlBox" />
            <span class="checkmark"></span>
          </div>
        </label>
      </div>
    </div>
    <div class="form-group">
      <div class="active-select">
        <label class="checkbox-container">{{ "global-admin.deficits.dialog.spot-check" | translate }}
          <div class="active-check">
            <input type="checkbox" [(ngModel)]="deficit.isSpotCheckDeficit" name="isSpotCheckBox" />
            <span class="checkmark"></span>
          </div>
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="name">{{ "global-admin.deficits.dialog.types-positions" | translate }}</label>
      <table class="table-bordered mapping-table">
        <th class="contractposition-column">
          {{ "global-admin.deficits.dialog.position" | translate }}
        </th>
        <th class="checktype-column">
          {{ "global-admin.deficits.dialog.type" | translate }}
        </th>
        <th class="add-button-column"></th>
        <tr *ngFor="let mapping of deficit.commonMappings">
          <td class="contractposition-column">
            {{ getContractPositionName(mapping.contractPosition) }}
          </td>
          <td class="checktype-column">
            {{ getCheckTypeName(mapping.checkType) }}
          </td>
          <td class="add-button-column">
            <button (click)="removeMapping(mapping)" class="btn btn-outline-light">
              <i class="fa fa-minus fa-2x red d-block" aria-hidden="true"
                title="{{ 'global-admin.deficits.dialog.remove' | translate }}"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td class="contractposition-column">
            <div ngbDropdown class="d-block dropdown">
              <button *ngIf="newMapping.contractPositionId" class="btn btn-outline-primary dropdown-toggle drop-button"
                id="contractPositionDropDown" ngbDropdownToggle>
                {{ getContractPositionName(newMapping.contractPosition) }}
              </button>
              <button *ngIf="!newMapping.contractPositionId" class="btn btn-outline-primary dropdown-toggle drop-button"
                id="contractPositionDropDown" ngbDropdownToggle>
                {{ "global-admin.deficits.dialog.select-position" | translate }}
              </button>
              <div ngbDropdownMenu class="dropdown-menu">
                <a *ngFor="let contractPosition of contractPositions" class="dropdown-item"
                  (click)="mapContractPosition(contractPosition)" ngbDropdownItem>{{
                  getContractPositionName(contractPosition) }}</a>
              </div>
            </div>
          </td>
          <td class="checktype-column">
            <div ngbDropdown class="d-block dropdown">
              <button *ngIf="newMapping.checkTypeId" class="btn btn-outline-primary dropdown-toggle drop-button"
                id="сontrolCheckTypeDropdown" ngbDropdownToggle>
                {{ getCheckTypeName(newMapping.checkType) }}
              </button>
              <button *ngIf="!newMapping.checkTypeId" class="btn btn-outline-primary dropdown-toggle drop-button"
                id="сontrolCheckTypeDropdown" ngbDropdownToggle>
                {{ "global-admin.deficits.dialog.select-type" | translate }}
              </button>
              <div ngbDropdownMenu class="dropdown-menu">
                <a *ngFor="let checkType of checkTypes" class="dropdown-item" (click)="mapCheckType(checkType)"
                  ngbDropdownItem>{{ getCheckTypeName(checkType) }}</a>
              </div>
            </div>
          </td>
          <td class="add-button-column">
            <button (click)="addMapping()" [disabled]="!canAddMapping" class="btn btn-outline-light">
              <i class="fa fa-plus fa-2x green d-block" aria-hidden="true"
                title="{{ 'global-admin.deficits.dialog.add' | translate }}"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <!--mapping end-->
    <!--tags-->
    <div class="form-group">
      <label for="name">{{ "global-admin.deficits.tags" | translate }}</label>
      <div ngbDropdown class="d-block dropdown" autoClose="outside">
        <button *ngIf="selectedTags.length > 0" class="btn btn-outline-primary dropdown-toggle drop-button"
          id="deficitTagsDropdown" ngbDropdownToggle>
          <span *ngFor="let tag of selectedTags" class="tag">{{ tag.name }}</span>
        </button>
        <button *ngIf="selectedTags.length === 0" class="btn btn-outline-primary dropdown-toggle drop-button"
          id="deficitTagsDropdown" ngbDropdownToggle>
          {{ "global-admin.deficits.dialog.no-audits" | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="deficitTagsDropdown">
          <div *ngFor="let tag of tags" ngbDropdownItem>
            <label class="checkbox-container tag-item">
              <input type="checkbox" [(ngModel)]="tag.isSelected" [name]="tag.id" />
              <span class="checkmark"></span>
              <span class="tag-name">{{ tag.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!--tags end-->
    <div class="form-group">
      <div class="active-select">
        <label class="checkbox-container">{{ "global-admin.deficits.dialog.active" | translate }}
          <div class="active-check">
            <input type="checkbox" [(ngModel)]="deficit.isActive" name="isActiveBox" />
            <span class="checkmark"></span>
          </div>
        </label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">
    <i class="icon" class="fas fa-ban"></i>
    {{ "shared.dialog.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-dark" (click)="save()" [disabled]="!isValid()">
    <i class="icon" class="fas fa-save"></i>
    {{ "shared.dialog.save" | translate }}
  </button>
</div>