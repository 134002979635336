import { SpotCheckResponse } from './spot-check-response';
import { RandomSampleViewModel } from './random-sample';
import { BaseSampleCreator } from 'app/shared/base-sample-creator';
import { RandomSampleStatusCalculator } from './random-sample-status-calculator';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TranslateService } from '@ngx-translate/core';


export class RandomSampleCreator extends BaseSampleCreator {
    constructor(private randomSampleResponses: SpotCheckResponse[],
        realEstates: TradeConfigurationResponse[],
        types: CheckTypeConfiguration[],
        contractPositions: ContractPositionConfiguration[],
        private translate: TranslateService) {
        super(realEstates, types, contractPositions);
    }

    public createRandomSamples(): RandomSampleViewModel[] {
        const randomSamples = [];
        const calculator = new RandomSampleStatusCalculator();
        for (const randomSampleResponse in this.randomSampleResponses) {
            if (this.randomSampleResponses.hasOwnProperty(randomSampleResponse)) {
                const response = this.randomSampleResponses[randomSampleResponse];
                const newRandomSample = new RandomSampleViewModel();
                this.mapDeficit(newRandomSample, response, calculator);
                randomSamples.push(newRandomSample);
            }
        }

        return randomSamples;
    }

    public updateDeficitViewModel(vm: RandomSampleViewModel, deficit: SpotCheckResponse) {
        this.mapDeficit(vm, deficit, new RandomSampleStatusCalculator())
    }

    private mapDeficit(newRandomSample: RandomSampleViewModel, response: SpotCheckResponse, calculator: RandomSampleStatusCalculator) {
        newRandomSample.id = response.id;
        newRandomSample.createdDateTimeUtc = this.tryParseDate(response.createdUtc);
        newRandomSample.contractPositionId = response.contractPositionConfigurationId;
        newRandomSample.contractPositionName = this.getContractPositionName(response.contractPositionConfigurationId, this.translate.instant('shared.unknown-contract-position'));
        newRandomSample.realEstateId = response.tradeConfigurationId;
        newRandomSample.realEstateName = this.getRealEstateName(response.tradeConfigurationId, this.translate.instant('shared.unknown-trade'));
        newRandomSample.typeId = response.checkTypeConfigurationId;
        newRandomSample.typeName = this.getStichprobenName(response.checkTypeConfigurationId, this.translate.instant('shared.unknown-type'));
        newRandomSample.ticketNumber = response.ticketNumber;
        newRandomSample.appendix = response.appendix;
        newRandomSample.bewertungDienstleistersteuerer = calculator.getBewertungDurchDls(response.rating, response.statusPhaseOne);
        newRandomSample.reaktionDienstleister = calculator.getReaktionDurchDl(response.statusPhaseTwo);
        newRandomSample.ergebnis =
            calculator.getAggregatedStatus(response.rating, response.statusPhaseOne,
                response.statusPhaseTwo, response.statusPhaseThree);
        newRandomSample.deficitValues = response.deficitValues;
        newRandomSample.contractEntityNumber = response.contractEntityNumber;
        newRandomSample.deficitFixed = response.deficitFixed;
        newRandomSample.rating = response.rating;
        newRandomSample.statusPhaseThree = response.statusPhaseThree;
        newRandomSample.isWithdrawn = response.isWithdrawn;
    }
}
