import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';

export class LoadFilterHelper {
    public static addBaseFilterValues(query: string, filter: NameLoadFilter): string {
        if (filter.languageId) {
            query = `${query}&languageId=${filter.languageId}`;
        }
        if (filter['contractId'] !== undefined) {
            query = `${query}&contractid=${filter['contractId']}`;
        }
        if (filter.name) {
            query = `${query}&name=${filter.name}`;
        }
        if (filter['isActive'] !== undefined) {
            query = `${query}&isActive=${filter['isActive']}`;
        }
        if (filter['configurationActive'] !== undefined) {
            query = `${query}&configurationActive=${filter['configurationActive']}`;
        }
        return query;
    }

    public static addFilterParameter(url: string, parameter: string, parameterName: string) {
        if (!parameter || (parameter === '-1')) {
            return url;
        }
        const operand = url.lastIndexOf('?') > -1 ? '&' : '?';
        return `${url}${operand}${parameterName}=${parameter}`;
    }
}