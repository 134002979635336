import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './language.service';
import { of } from 'rxjs';

@Pipe({
    name: 'resolveLanguage'
})
export class LanguageNamePipe implements PipeTransform {

    constructor(private languageService: LanguageService) { }

    transform(value: any, args?: any): any {
        if (!value) {
            return of({
                id: null,
                shortName: 'EN',
                longName: 'English (default)'
            });
        }
        return this.languageService.getLanguage(value);
    }
}
