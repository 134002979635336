
export class StatisticFigures {
  public isKontrollen = false;

  constructor(isKontrollen: boolean,
    private noClaimsCount: number,
    private claimsCount: number,
    private reclamationsCount: number,
    private claimReturnsCount: number,
    private complaintReturnsCount: number,
    private questionableCount: number,
    private inProgressCount: number,
    private notResolvedCount: number,
    private noReactionCount: number) {
    this.isKontrollen = isKontrollen;
  }

  keineBeanstandungenCount(): number {
    return this.noClaimsCount;
  }
  beanstandungenCount(): number {
    return this.claimsCount;
  }
  reklamationenCount(): number {
    return this.reclamationsCount;
  }
  zuruecknahmenCount(): number {
    return this.claimReturnsCount;
  }
  einspruchZuruecknahmenCount(): number {
    return this.complaintReturnsCount;
  }
  strittigCount(): number {
    return this.questionableCount;
  }
  inBearbeitungCount(): number {
    return this.inProgressCount;
  }
  nichtBehobenCount(): number {
    return this.notResolvedCount;
  }
  noDlReactionCount(): number {
    return this.noReactionCount;
  }

  totalCount(): number {
    return this.keineBeanstandungenCount() +
      this.beanstandungenCount() +
      this.reklamationenCount() +
      this.zuruecknahmenCount() +
      this.einspruchZuruecknahmenCount() +
      this.strittigCount() +
      this.inBearbeitungCount() +
      this.noDlReactionCount();
  }
}
