import { Translation } from '../language/translation';
import { BaseExistingResource } from 'app/shared/models/base-existing.resource';

export class Contract extends BaseExistingResource {
    urlPath: string;
    name: string;
    id: string;
    ordererEmail: string;
    contractorEmail: string;
    contractLanguageId: string;
    contractLanguageCode: string;
    isActive?: boolean;
    translations?: Translation[] = [];
}