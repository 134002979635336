import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToasterMessage } from './toaster-message';


@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private subject = new Subject<ToasterMessage>();

    getSubject(): Subject<ToasterMessage> {
        return this.subject;
    }

    show(message: ToasterMessage) {
        this.subject.next(message);
    }
}