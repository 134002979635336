export namespace ErrorMessages {

    export const ERR0000 = 'ERR0000'

    export const ERN0000 = 'ERN0000'
    export const ERN0001 = 'ERN0001'
    export const ERN0002 = 'ERN0002'
    export const ERN0003 = 'ERN0003'

    export const ENA0000 = 'ENA0000'

    export const EAE0000 = 'EAE0000'

    export const EIU0000 = 'EIU0000'
    export const EIU0001 = 'EIU0001'
    export const EIU0002 = 'EIU0002'
    export const EIU0003 = 'EIU0003'
    export const EIU0004 = 'EIU0004'
    export const EIU0005 = 'EIU0005'
    export const EIU0006 = 'EIU0006'
    export const EIU0007 = 'EIU0007'
    export const EIU0008 = 'EIU0008'
    export const EIU0009 = 'EIU0009'
}