import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, forkJoin } from 'rxjs';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take } from 'rxjs/operators';
import { AbnahmenFormularComponent } from '../acceptance-form/acceptance-form.component';

@Component({
    selector: 'epo-spot-check-edit-entry',
    template: '<div class="loader" style="margin: auto;"></div>',
})
export class AcceptanceEditEntryComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        private router: Router,
        private contractService: ContractService
    ) { }

    public ngOnInit() {
        const contract$ = this.contractService.selectedContract.pipe(take(1));
        const params$ = this.activatedRoute.queryParams.pipe(take(1));
        forkJoin(contract$, params$).subscribe(([contract, params]) => {
            setTimeout(() => {
                // timeout: workaround for xpressionChangedAfterItHasBeenCheckedError
                if (params['id']) {
                    const modalRef = this.modalService.open(AbnahmenFormularComponent, {
                        size: 'lg',
                    });
                    modalRef.componentInstance.acceptanceId = params['id'];
                    modalRef.result.then(
                        () => {
                            this.redirect(contract.urlPath);
                        },
                        () => {
                            this.redirect(contract.urlPath);
                        }
                    );
                } else {
                    const modalRef = this.modalService.open(AbnahmenFormularComponent, {
                        size: 'lg',
                    });
                    modalRef.result.then(
                        () => {
                            this.redirect(contract.urlPath);
                        },
                        () => {
                            this.redirect(contract.urlPath);
                        }
                    );
                }
            }, 1);
        });
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private redirect(contractUrl: string) {
        this.router.navigate([`/contract/${contractUrl}/acceptance/overview`]);
    }
}
