import { DeficitMapping } from './deficitMapping';
import { DeficitTranslation } from './deficit-translation.resource';
import { BaseExistingResource } from 'app/shared/models/base-existing.resource';
import { DeficitTag } from './deficit-tag.resource';

export class Deficit extends BaseExistingResource {
  id: string;
  mappings: DeficitMapping[] = [];
  translations: DeficitTranslation[] = [];
  deficitGroupId: string;
  deficitGroupName: string;
  title: string;
  titleDefault: string;
  description: string;
  descriptionDefault: string;
  isControlDeficit: boolean;
  isSpotCheckDeficit: boolean;
  isActive: boolean = true;
  entityNumber: number;
  tags: DeficitTag[] = [];
}
