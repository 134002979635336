import { EInterval } from 'app/shared/models/interval';
import { UserResponse } from '../allgemein/users/user-response';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';

export class ResolveIdHelper {

    static getIntervalName(interval: EInterval, intervalTypes, unknownText: string) {
        const foundIntervalName = intervalTypes.find(c => c.value === interval);
        if (foundIntervalName) {
            return foundIntervalName.displayName;
        } return unknownText;
    }

    static getCheckTypeName(checkTypeId, _controlTypes: CheckTypeConfiguration[], unknownText: string): string {
        const foundType = _controlTypes.find(checkType => checkType.id === checkTypeId);
        if (foundType && foundType.checkType.name) {
            return foundType.checkType.name;
        } return unknownText;
    }

    static getUserName(responsibleId: string, users: UserResponse[], unknownText: string) {
        const foundUser = users.find(user => user.id === responsibleId);
        if (foundUser) {
            return foundUser.lastname;
        } return unknownText;
    }

    static getTradeName(tradeId, trades: TradeConfiguration[], unknownText: string): string {
        const foundTrade = trades.find(realEstate => realEstate.id === tradeId);
        if (foundTrade && foundTrade.trade.name) {
            return foundTrade.trade.name;
        }
        return unknownText;
    }

    static getContractPositionName(contractPositionId, contractPositions: ContractPositionConfiguration[], unknownText: string): string {
        const foundPosition = contractPositions.find(position => position.id === contractPositionId);
        if (foundPosition && foundPosition.contractPosition.name) {
            return foundPosition.contractPosition.name;
        }
        return unknownText;
    }

}
