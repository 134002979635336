<div class="more-width-container mt-5 container">
  <div class="row stats-header">
    <div class="col">
      <h3 class="top-right-button pointer" (click)="showCreateModal()" *ngIf="isAdmin">
        <i class="fa fa-plus"></i>
        {{ "global-admin.contracts.new" | translate }}
      </h3>
    </div>
  </div>
  <div class="pt-5">
    <h3 class="pb-2">{{ "global-admin.contracts.title" | translate }}</h3>
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>{{ "global-admin.contracts.name" | translate }}</th>
          <th>{{ "global-admin.contracts.url" | translate }}</th>
          <th>{{ "global-admin.contracts.language" | translate }}</th>
          <th class="no-mobile">
            {{ "global-admin.contracts.orderer-email" | translate }}
          </th>
          <th class="no-mobile">
            {{ "global-admin.contracts.contractor-email" | translate }}
          </th>
          <th></th>
          <th colspan="2"></th>
        </tr>
      </thead>
      <th></th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()" [getValuesFunction]="
            globalAutocompleteService.getMatchingContractNames
          ">
        </epo-column-filter>
      </th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.url" (selectionChange)="onFilterChange()" [getValuesFunction]="
            globalAutocompleteService.getMatchingContractUrls
          ">
        </epo-column-filter>
      </th>
      <th></th>
      <th></th>
      <tbody *ngIf="isLoading" class="loading-placeholder">
        <tr>
          <td colspan="8">
            <app-loading-spinner></app-loading-spinner>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr *ngFor="let contract of contracts">
          <td class="table-item-name">
            <div (click)="showHistory(contract)" class="ml-1 fa-border history-button"
              title="{{ 'shared.history' | translate }}">
              <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
            </div>
          </td>
          <td class="table-item-name">
            {{ contract.name }}
          </td>
          <td class="table-item-name">
            <a routerLink="/contract/{{ contract.urlPath }}">{{
              contract.urlPath
              }}</a>
          </td>
          <td class="table-item-name">
            {{
            (contract.contractLanguageId | resolveLanguage | async)?.longName
            }}
          </td>
          <td class="table-item-name" class="no-mobile">
            {{ contract.ordererEmail }}
          </td>
          <td class="table-item-name" class="no-mobile">
            {{ contract.contractorEmail }}
          </td>
          <td class="no-mobile">
            <button class="btn btn-outline-primary float-right no-mobile" (click)="showEditModal(contract)"
              *ngIf="isAdmin">
              <i class="fas fa-edit"></i>
              {{ "shared.buttons.edit" | translate }}
            </button>
          </td>
          <td class="no-mobile">
            <button class="btn btn-outline-danger float-right no-mobile" (click)="openDeleteModal(contract)"
              *ngIf="isAdmin">
              <i class="fa fa-trash" aria-hidden="true"></i>
              {{ "shared.buttons.delete" | translate }}
            </button>
          </td>
          <td class="no-desktop">
            <button type="button" class="btn btn-outline-primary float-lg-right" (click)="showEditModal(contract)">
              {{ "shared.buttons.edit" | translate }}
            </button>
            <button type="button" class="btn btn-outline-danger float-lg-right" (click)="openDeleteModal(contract)">
              {{ "shared.buttons.delete" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>