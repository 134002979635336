export enum StatisticType {
    /**
     * Keine Beanstandung.
     */
    NoClaim,
    /**
     * Beanstandung.
     */
    Claim,
    /**
     * Reklamation.
     */
    Reclamation,
    /**
     * Reklamationen Zurücknahmen.
     */
    ReclamationCancelled,
    /**
     * Einspruch Zurücknahmen.
     */
    ClaimCancelled,
    /**
     * Strittig.
     */
    Conflict,
    /**
     * In Bearbeitung.
     */
    InProgress,
    /**
     * Nicht Behoben.
     */
    NotResolved,
    /**
     * Reaktion durch DL fehlt.
     */
    NoReaction,
}
