import { Component, OnInit } from '@angular/core';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { UserProfileService } from '../user-profile.service';
import { User } from '../user';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'epo-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public isUserGlobalAdmin: boolean = false;
  public isUserContractAdmin: boolean = false;

  public baseDataAllgemein = [
    { linkName: 'deficites', displayName: 'nav.base-data.deficits', icon: 'fa-tasks' },
    { linkName: 'deficit-global-proposals', displayName: 'Shortcoming Proposals', icon: 'fa fa-user-edit fa-2x' },
    { linkName: 'deficit-groups', displayName: 'nav.base-data.deficit-groups', icon: 'fa-object-group' },
    { linkName: 'trades', displayName: 'nav.base-data.trades', icon: 'fa-building' },
    { linkName: 'check-types', displayName: 'nav.base-data.check-types', icon: 'fa-adjust' },
    { linkName: 'contract-positions', displayName: 'nav.base-data.contract-positions', icon: 'fa-signature' },
    { linkName: 'deficittags', displayName: 'nav.base-data.deficittags', icon: 'fas fa-tags' },
    { linkName: 'users', displayName: 'nav.base-data.users', icon: 'fa-users' },
    { linkName: 'languages', displayName: 'nav.base-data.languages', icon: 'fa-language' },
    { linkName: 'contracts', displayName: 'nav.base-data.contracts', icon: 'fa-file-signature' }
  ];
  public contractConfig = [
    { linkName: 'deficite-configurations', displayName: 'nav.contract-config.deficite-configurations' },
    { linkName: 'trade-configurations', displayName: 'nav.contract-config.trade-configurations' },
    { linkName: 'contract-position-configurations', displayName: 'nav.contract-config.contract-position-configurations' },
    { linkName: 'check-type-configurations', displayName: 'nav.contract-config.check-type-configurations' },
    { linkName: 'contract-user-configurations', displayName: 'nav.contract-config.contract-user-configurations' },
  ];

  constructor(public contractService: ContractService,
    private userProfileService: UserProfileService) { }

  ngOnInit() {
    this.userProfileService.currentUser.subscribe(user => {
      this.isUserGlobalAdmin = (user as User).role === GlobalUserRole.Admin || (user as User).role === GlobalUserRole.GlobalAdmin;
    });
    const user$ = this.userProfileService.currentUser.pipe(take(1));
    const contract$ = this.contractService.selectedContract.pipe(take(1));
    forkJoin(user$, contract$).subscribe(([user, contract]) => {
      this.isUserContractAdmin = (user as User).contractUsers.findIndex(cu =>
        contract && cu.contractRole === ContractUserRole.Admin && cu.contractId === contract.id) > -1;
    });
  }

  toggleNavbar() {
    document.getElementById('navbarToggler').classList.toggle('collapse');
  }

  closeNavbar() {
    document.getElementById('navbarToggler').classList.add('collapse');
  }

  public deselectContract() {
    this.contractService.deselectContract();
  }
}
