<div class="container more-width-container mt-5">
    <div class="row stats-header">
      <!-- <div class="col">
        <h3 class="top-right-button pointer" (click)="openCreateLanguageModal()">
          <i class="fa fa-plus"></i> {{ 'global-admin.languages.new' | translate }}</h3>
      </div> -->
    </div>
  
    <div class="container pt-5">
      <h3 class="pb-2">{{ 'global-admin.languages.title' | translate }}</h3>
      <table class="table table-hover">
        <thead class="thead">
          <th>{{ 'global-admin.languages.name' | translate }}</th>
          <th>{{ 'global-admin.languages.name-short' | translate }}</th>
          <th></th>
          <th></th>
        </thead>
        <tbody *ngIf="isLoading" class="loading-placeholder">
          <tr>
            <td colspan="3">
              <app-loading-spinner></app-loading-spinner>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr *ngFor="let language of languages">
            <td class="contract-position-name">{{language.longName}}</td>
            <td class="contract-position-name">{{language.shortName}}</td>
            <td class="edit" class="no-mobile"><button type="button" class="btn btn-outline-primary float-lg-right"
                (click)="openEditLanguageModal(language)">
                <i class="fas fa-edit"></i>
                {{ 'shared.buttons.edit' | translate }}</button></td>
            <td class="delete" class="no-mobile"><button type="button" class="btn btn-outline-danger float-lg-right"
                (click)="openDeleteModal(language)">
                <i class="fa fa-trash" aria-hidden="true"></i>
                {{ 'shared.buttons.delete' | translate }}</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>