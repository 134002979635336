import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';

import { ControlOverviewComponent } from './overview/overview.component';
import { ControlPlanningComponent } from './control-planning/control-planning.component';

import { ControlService } from './shared/control.service';
import { FormsModule } from '@angular/forms';
import { KontrollenFormularComponent } from 'app/control/control-form/control-form.component';
import { ControlEditEntryComponent } from './control-edit-entry/control-edit-entry.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
    ControlOverviewComponent,
    ControlPlanningComponent,
    KontrollenFormularComponent,
    ControlEditEntryComponent
  ],
  providers: [
  ControlService
  ],
  entryComponents: [KontrollenFormularComponent],
})
export class ControlModule { }
