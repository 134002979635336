<div class="more-width-container mt-5">
  <div class="header-cntainer">
    <h3 class="pb-2">
      {{ "shared.deficit-group-proposals.title" | translate }}
    </h3>
    <!-- <div class="item-counter">
      20
    </div> -->
  </div>
  <table class="table table-hover">
    <thead class="thead">
      <th></th>
      <th>
        {{ "shared.deficit-group-proposals.content-group" | translate }}
        <span (click)="sort('DeficitGroup')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('DeficitGroup', 'Asc') && !isActiveSorting('DeficitGroup', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('DeficitGroup', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('DeficitGroup', 'Desc')"></i>
        </span>
      </th>
      <th  >
        {{ "shared.deficit-group-proposals.description" | translate }}
      </th>
      <th>{{ "shared.deficit-group-proposals.suggested-description" | translate }}</th>
      <th>{{ "shared.deficit-group-proposals.comment" | translate }}</th>
      <th>{{ "shared.deficit-group-proposals.requested-by" | translate }}</th>
      <th>{{ "shared.deficit-group-proposals.requested-on" | translate }}
        <span (click)="sort('CreatedOn')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('CreatedOn', 'Asc') && !isActiveSorting('CreatedOn', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('CreatedOn', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('CreatedOn', 'Desc')"></i>
        </span>
      </th>
      <th>{{ "shared.deficit-group-proposals.status" | translate }}</th>
      <th>{{ "shared.deficit-group-proposals.id" | translate }}
        <span (click)="sort('Number')">
          <i class="fas fa-sort"
            *ngIf="!isActiveSorting('Number', 'Asc') && !isActiveSorting('Number', 'Desc')"></i>
          <i class="fas fa-sort-up" *ngIf="isActiveSorting('Number', 'Asc')"></i>
          <i class="fas fa-sort-down" *ngIf="isActiveSorting('Number', 'Desc')"></i>
        </span>
      </th>
      <th *ngIf="isUserLocalOrGlobalAdmin">{{ "shared.deficit-group-proposals.actions" | translate }}</th>
      <th *ngIf="isUserLocalOrGlobalAdmin">{{ "shared.deficit-group-proposals.promoted-status" | translate }}</th>
    </thead>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.deficitGroup" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingDeficitGroupNames" [isActive]="true"
         [configurationActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(searchText)]="loadFilter.description" (searchTextChange)="onFilterChange()"
      [autocomplete]="false">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(searchText)]="loadFilter.suggestedDescription" (searchTextChange)="onFilterChange()"
      [autocomplete]="false">
      </epo-column-filter>
    </th>
    <th>
      <epo-column-filter [(searchText)]="loadFilter.comment" (searchTextChange)="onFilterChange()"
      [autocomplete]="false">
      </epo-column-filter>
    </th>
    <th>
    </th>
    <th>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
          {{ "shared.deficit-group-proposals." + loadFilter.isStatus |translate}}
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let status of proposalStatusSelection" class="dropdown-item" (click)="isStatusSelected(status)"
            ngbDropdownItem>{{ "shared.deficit-group-proposals." + status | translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.entityNumber" (selectionChange)="onFilterChange()"
      [getValuesFunction]="globalAutocompleteService.getMatchingDeficitNumbers">
      </epo-column-filter>
    </th>
    <th *ngIf="isUserLocalOrGlobalAdmin"></th>
    <th *ngIf="isUserLocalOrGlobalAdmin"></th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="9">
          Loading....
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let item of items">
        <td class="table-item-name">
          <div class="ml-1 history-button fa-border"
            title="Show History"
            (click)="showHistory(item.id)">
            <i class="fa fa-history fa-lg" aria-hidden="true"></i>
          </div>
        </td>
        <td>
          <div [innerHTML]="item.contentGroup"></div>
        </td>
        <td style="width: 20%">
          <div class="description-container" [innerHTML]="item.previousDescription"></div>
        </td>
        <td style="width: 20%">
          <div class="description-container" [innerHTML]="item.descriptionProposal"></div>
        </td>
        <td>
          <div class="description-container" [innerHTML]="item.comment"></div>
        </td>
        <td>
          <div [innerHTML]="item.createdBy.firstname + ' ' + item.createdBy.lastname"></div>
        </td>
        <td>
          <div [innerHTML]="item.createdUtc"></div>
        </td>
        <td>
          <div *ngIf="item.status == 0">
            <i class="fa fa-2x fa-hourglass-half"></i>
          </div>
          <div   *ngIf="item.status == 1 || item.status == 3">
            <i class="fa fa-2x fa-check green"></i>
          </div>
          <div   *ngIf="item.status == 2">
            <i class="fa fa-2x fa-times red"></i>
          </div>
        </td>
        <td> 
          <div   [innerText]="item.entityNumber"></div>
        </td>
        <td *ngIf="isUserLocalOrGlobalAdmin" class="no-mobile">
          <div *ngIf="isLoading && isUserLocalOrGlobalAdmin" class="loader"></div>

          <button *ngIf="item.status == 0" type="button" 
            class="btn btn-outline-primary"
            (click)="approveItem(item.id)">
            <div class="flex-gap">
              <div><i class="fa fa-edit"></i></div>
              <div>{{ "shared.deficit-group-proposals.approve" | translate }}</div>
            </div>
          </button>

          <button *ngIf="item.status == 0" type="button" 
            class="btn btn-outline-dark"
            (click)="rejectItem(item.id)"
            style="margin-bottom: 5px">
            <div class="flex-gap">
              <div><i class="fa fa-ban"></i></div>
              <div>{{ "shared.deficit-group-proposals.reject" | translate }}</div>
            </div>
          </button>

          <button *ngIf="item.status == 1 || item.status == 3" type="button" 
            class="iconButton" 
            class="btn btn-dark"
            (click)="promoteItem(item.id)"
            [disabled]="item.status == 3">
            <div class="flex-gap">
              <div><i class="fa fa-share-square"></i></div>
              <div>{{ "shared.deficit-group-proposals.promote" | translate }}</div>
            </div>
          </button>
        </td>
        <td *ngIf="isUserLocalOrGlobalAdmin">
          <div   *ngIf="item.promotedProposalStatus == 0">
            <i class="fa fa-2x fa-hourglass-half"></i>
          </div>
          <div   *ngIf="item.promotedProposalStatus == 1">
            <i class="fa fa-2x fa-check green"></i>
          </div>
          <div   *ngIf="item.promotedProposalStatus == 2">
            <i class="fa fa-2x fa-times red"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>