import { DeficitTag } from "app/shared/masterData/deficit/deficit-tag.resource";

export class DeficitTagViewModel {
    constructor(tag: DeficitTag, isSelected: boolean) {
        this.id = tag.id;
        this.name = tag.name;
        this.nameDefault = tag.nameDefault;
        this.isSelected = isSelected;
        this.tag = tag;
    }

    id?: string;
    name: string;
    nameDefault: string;
    isSelected: boolean;
    tag: DeficitTag;
}