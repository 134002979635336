<div class="view" *ngIf="contractService.selectedContract | async as contract">
  <div class="row dashview">
      <div class="col-md-6 dashview">
        <div class="dash random" routerLink="/contract/{{contract.urlPath}}/spot-check/overview">
          <div class="text">
            <div class="iconheader">
            <i  class="logo-container" class="fa fa-random fa-2x" aria-hidden="true"></i> 
            <h4>{{ 'dashboard.spotchecks' | translate }}</h4>
            </div>
            <p>{{ 'dashboard.spotchecks-description' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 dashview">
        <div class="dash control" routerLink="/contract/{{contract.urlPath}}/control/overview">
            <div class="text" >
              <div class="iconheader">
                <i class="logo-container" class="fa fa-bullseye fa-2x" aria-hidden="true"></i>
                <h4 > {{ 'dashboard.controls' | translate }}</h4>
              </div>
              <p>{{ 'dashboard.controls-description' | translate }}</p>
              </div>
        </div>
      </div>
    </div>
    <div class="row dashview">
      <div class="col-md-12 dashview" routerLink="/contract/{{contract.urlPath}}/acceptance/overview">
        <div class="dash acceptance">
            <div class="text">
              <div  class="iconheader">
                <i class="logo-container" class="fa fa-tasks fa-2x" aria-hidden="true"></i>
                <h4> {{ 'dashboard.acceptances' | translate }}</h4>
              </div>
              <p>{{ 'dashboard.acceptances-description' | translate }}</p>
            </div>
        </div>
      </div>
    </div>
</div>