import { Component, OnInit } from '@angular/core';
import { Deficit } from 'app/shared/masterData/deficit/deficit.resource';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeficitEditComponent } from './modal-deficite-edit/modal-deficit-edit.component';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { DeficitService } from 'app/shared/masterData/deficit/deficit.service';
import { environment } from 'environments/environment';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { DeficitLoadFilter } from './deficit-load-filter';
import { DeficitHistoryHelper } from './history/deficit-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { forkJoin } from 'rxjs';
import { Language } from 'app/shared/masterData/language/language.resource';
import { DeficitGroup } from 'app/shared/masterData/deficit/deficit-group.resource';
import { take } from 'rxjs/operators';
import { CheckTypeService } from 'app/shared/masterData/check-type/check-type.service';
import { ContractPositionService } from 'app/shared/masterData/contract-position/contract-position.service';
import { CheckType } from 'app/shared/masterData/check-type/check-type.resource';
import { ContractPosition } from 'app/shared/masterData/contract-position/contract-position.resource';
import { OrderBy, OrderDirection } from '../shared/order-by';
import { DeficitModel } from 'app/shared/masterData/deficit/deficit.model';
import { DeficitMapping } from 'app/shared/masterData/deficit/deficitMapping';
import { DeficitVariant } from 'app/shared/masterData/deficit-variant';
import { DeficitTag } from 'app/shared/masterData/deficit/deficit-tag.resource';
import { DeficitTagsService } from 'app/shared/masterData/deficit-tags/deficit-tags.service';
import { NameLoadFilter } from '../shared/name-load-filter';

@Component({
  selector: 'epo-deficites',
  templateUrl: './deficites.component.html',
  styleUrls: ['./deficites.component.scss']
})
export class DeficitesComponent implements OnInit {
  public isLoading = true;
  deficits: DeficitModel[];
  private _deficitGroups: DeficitGroup[];
  private _checkTypes: CheckType[];
  private _contractPositions: ContractPosition[];
  private _languages: Language[];
  private _tags: DeficitTag[];
  public pagingGlobalCount = 0;
  public pageSize: number;
  public currentPage = 1;

  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData();
  }
  get page() {
    return this.currentPage;
  }

  public loadFilter: DeficitLoadFilter = new DeficitLoadFilter();
  public boolSelection = [undefined, true, false];
  public languageId: string;
  public sorting: { column: 'DeficitGroup' | 'Name' | 'Number', direction: OrderDirection } = { column: 'DeficitGroup', direction: OrderDirection.Asc };

  constructor(
    private _deficitService: DeficitService,
    private _modalService: NgbModal,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _languageService: LanguageService,
    private _checkTypeService: CheckTypeService,
    private _contractPositionService: ContractPositionService
  ) {
    this.pageSize = environment.pageSize;
  }

  ngOnInit(): void {
    this.loadData();
  }

  onFilterChange() {
    this.currentPage = 1;
    this.loadData();
  }

  isControlSelected(value) {
    this.loadFilter.isControl = value;
    this.onFilterChange();
  }

  isSpotCheckSelected(value) {
    this.loadFilter.isSpotCheck = value;
    this.onFilterChange();
  }

  isActiveSelected(value) {
    this.loadFilter.isActive = value;
    this.onFilterChange();
  }

  isActiveSorting(column: 'DeficitGroup' | 'Name' | 'Number', direction: 'Asc' | 'Desc') {
    return this.sorting.column == column && this.sorting.direction == OrderDirection[direction];
  }

  sort(column: 'DeficitGroup' | 'Name' | 'Number') {
    if (column === this.sorting.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  private loadData() {
    this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    const langName = localStorage.getItem('lang') || 'en';
    this._languageService.languagesObservable.pipe(take(1)).subscribe(l => {
      const lang = l.find(l => l.shortName === langName.toUpperCase());
      if (lang) {
        this.loadFilter.languageId = lang.id;
        this.languageId = this.loadFilter.languageId;
      } else {
        this.loadFilter.languageId = null;
        this.languageId = null;
      }
      this.isLoading = true;
      const deficits$ = this._deficitService.getDeficits(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
      const groups$ = this._deficitService.deficitGroupsObservable.pipe(take(1));
      const checkTypes$ = this._checkTypeService.checkTypesObservable.pipe(take(1));
      const contractPositions$ = this._contractPositionService.contractPositionsObservable.pipe(take(1));
      const langs$ = this._languageService.languagesObservable.pipe(take(1));
      forkJoin([deficits$, groups$, checkTypes$, contractPositions$, langs$]).subscribe(([deficits, groups, checkTypes, contractPositions, langs]) => {
        this.deficits = deficits.items.map(d => new DeficitModel(d, this.languageId));
        this.pagingGlobalCount = deficits.count;
        this._deficitGroups = groups;
        this._checkTypes = checkTypes;
        this._contractPositions = contractPositions;
        this._languages = langs;
        this.isLoading = false;
      }, err => {
        console.log(err);
      });
    });
  }

  public openCreateDeficitModal() {
    const deficit = new Deficit();
    const modalRef = this._modalService.open(ModalDeficitEditComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.deficit = new DeficitModel(deficit, this.languageId);
    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result: DeficitModel) => {
      let newDeficit: Deficit = Object.assign(new Deficit(), result);

      if (result.isControlDeficit) {
        newDeficit.mappings = [...newDeficit.mappings, ...result.commonMappings.map(m =>
          new DeficitMapping(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition, DeficitVariant.Control))];
      }
      if (result.isSpotCheckDeficit) {
        newDeficit.mappings = [...newDeficit.mappings, ...result.commonMappings.map(m =>
          new DeficitMapping(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition, DeficitVariant.SpotCheck))];
      }
      this._deficitService.createDeficit(newDeficit).subscribe(() => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openEditDeficitModal(deficit: DeficitModel) {
    const modalRef = this._modalService.open(ModalDeficitEditComponent, { centered: true, backdrop: 'static', size: 'lg' });
    const def = Object.assign({}, deficit);
    modalRef.componentInstance.deficit = def;
    modalRef.componentInstance.inEditMode = true;
    modalRef.result.then((result) => {
      let updatedDeficit: Deficit = Object.assign(new Deficit(), result);

      if (result.isControlDeficit) {
        updatedDeficit.mappings = [...updatedDeficit.mappings, ...result.commonMappings.map(m =>
          new DeficitMapping(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition, DeficitVariant.Control))];
      }
      if (result.isSpotCheckDeficit) {
        updatedDeficit.mappings = [...updatedDeficit.mappings, ...result.commonMappings.map(m =>
          new DeficitMapping(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition, DeficitVariant.SpotCheck))];
      }
      this._deficitService.updateDeficit(updatedDeficit).subscribe(() => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openDeleteModal(deficit: DeficitModel) {
    const modalRef = this._modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = deficit;
    modalRef.result.then((deficit) => {
      this._deficitService.deleteDeficit(deficit.id).subscribe(() => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    });
  }

  public showHistory(deficit: Deficit) {
    this._deficitService.getDeficitHistory(deficit.id).subscribe(history => {
      let changeHistory = new DeficitHistoryHelper(history, this._deficitGroups, this._contractPositions, this._checkTypes, this._languages).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: deficit.title };
    })
  }
}
