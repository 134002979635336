// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    //apiUrl: 'https://as-ccp-backend-test01-22-staging.azurewebsites.net',
    apiUrl: 'https://as-ccp-backend-test01-22.azurewebsites.net',
    archibusUrl: 'https://cafm.internal.epo.org/archibus/epo-bldgops-cost-report.axvw',
    authConfig: {
      tenant: 'epocloud.onmicrosoft.com',
      clientId: '4eddf965-5278-4db5-9c0d-c1230d8c2b7d',
      anonymousEndpoints: ['./assets', './assets/i18n']
    },
    pageSize: 50
  };
  
