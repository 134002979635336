import { Component, OnInit, OnDestroy } from '@angular/core';
import { AcceptanceService } from './acceptance.service';
import { Acceptance } from 'app/acceptance/shared/Acceptance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbnahmenFormularComponent } from './acceptance-form/acceptance-form.component';
import { forkJoin, Subscription } from 'rxjs';
import { AcceptanceCreator } from './shared/acceptance-creator';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { allId, BaseFilterData } from 'app/shared/components/filter/filter';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { AcceptanceHistoryHelper } from './shared/acceptance-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'epo-acceptance',
  templateUrl: './acceptance.component.html',
  styleUrls: ['./acceptance.component.scss']
})
export class AcceptanceComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public filterSettings = new FilterSettings(true, true, true, false, true);
  public abnahmen: Acceptance[];
  public showTradeColumn = false;
  public showContractPositionColumn = false;
  private _currentFilter: BaseFilterData;

  private contractPositionConfigurations: ContractPositionConfiguration[];
  private tradeConfigurations: TradeConfigurationResponse[];

  private filterSubscription: Subscription;

  public pagingGlobalCount = 0;
  public pageSize: number = 65535;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData(true);
  }
  get page() {
    return this.currentPage;
  }

  constructor(private acceptanceService: AcceptanceService,
    private contractPositionService: ContractPositionConfigurationService,
    private _filterService: FilterService,
    private modalService: NgbModal,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.filterSubscription = this._filterService.getFilter().subscribe((filter) => {
      this.filterChanged(filter as BaseFilterData);
    });
    this.pageSize = environment.pageSize;
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public filterChanged(filter: BaseFilterData) {
    this._currentFilter = filter;
    this.currentPage = 1;
    this.showTradeColumn = this._currentFilter.selectedTrade.id === allId;
    this.showContractPositionColumn = filter.selectedContractPosition.id === allId;
    this.loadData(true);
  }

  public openForm() {
    const modalRef = this.modalService.open(AbnahmenFormularComponent, { size: 'lg' });
    modalRef.componentInstance.filterData = this._currentFilter;

    modalRef.result.then((result) => {
      this.loadData(false);
    },
      () => {

      });
  }

  public editAbnahme(abnahme: Acceptance) {
    const modalRef = this.modalService.open(AbnahmenFormularComponent, { size: 'lg' });
    modalRef.componentInstance.acceptanceId = abnahme.id;

    modalRef.result.then((result) => {
      this.loadData(false);
    },
      () => {
      });
  }

  public version(acceptance: Acceptance) {
    this.acceptanceService.getAcceptanceHistory(acceptance.id).subscribe(history => {
      let changeHistory = new AcceptanceHistoryHelper(history, this.tradeConfigurations,
        this.contractPositionConfigurations).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: acceptance.contractEntityNumber };
    })
  }

  public resolveContractPosition(contractPosConfigId: string) {
    const cp = this.contractPositionConfigurations.find(cp => cp.id == contractPosConfigId);
    if (cp)
      return cp.contractPosition.name;
      return this._translate.instant('shared.unknown-contract-position');
  }

  public resolveTrade(tradeConfigId: string) {
    const trade = this.tradeConfigurations.find(trade => trade.id == tradeConfigId);
    if (trade)
      return trade.trade.name;
    return this._translate.instant('shared.unknown-trade');
  }

  private loadData(forceReload: boolean) {
    this.isLoading = true;
    if (forceReload) {
      this.acceptanceService.cleanCache();
    }
    forkJoin(
      this.acceptanceService.getAbnahmen(
        this._currentFilter.selectedDateRange,
        this._currentFilter.selectedContractPosition.id,
        this._currentFilter.selectedTrade.id,
        this._currentFilter.selectedUser.id,
        this.pageSize * (this.currentPage - 1),
        this.pageSize),
      this.acceptanceService.getTradeConfigurations(),
      this.contractPositionService.getContractPositionConfigurationsForCurrentContract()
    ).subscribe((responses) => {
      this.pagingGlobalCount = responses[0].count
      const abnahmen = responses[0];
      this.tradeConfigurations = responses[1];
      this.contractPositionConfigurations = responses[2].items;
      const creator = new AcceptanceCreator(abnahmen.items, this.tradeConfigurations);
      this.abnahmen = creator.create();
      this.isLoading = false;
    });
  }
}
