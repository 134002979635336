import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { take, mergeMap } from 'rxjs/operators';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { Observable } from 'rxjs';
import { SpotCheckData } from '../spot-check-form/spot-check-data';

@Injectable({
  providedIn: 'root'
})
export class StichprobenFormularService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public createSpotCheck(stichprobenData: SpotCheckData): Observable<SpotCheckData> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks`);
        return this._httpClient.post<SpotCheckData>(url,
          {
            rating: stichprobenData.rating,
            checkTypeConfigurationId: stichprobenData.checkTypeConfigurationId,
            contractPositionConfigurationId: stichprobenData.contractPositionConfigurationId,
            tradeConfigurationId: stichprobenData.tradeConfigurationId,
            appendix: stichprobenData.appendix,
            ticketNumber: stichprobenData.ticketNumber,
            ticketDescription: stichprobenData.ticketDescription,
            tenderPosition: stichprobenData.tenderPosition,
            attachementLink: stichprobenData.attachementLink,
            plant: stichprobenData.plant,
            roomNumber: stichprobenData.roomNumber,
            statusPhaseOne: stichprobenData.statusPhaseOne,
            statusPhaseTwo: stichprobenData.statusPhaseTwo,
            statusPhaseThree: stichprobenData.statusPhaseThree,
            deficitValues: stichprobenData.deficitValues,
            controlId: stichprobenData.controlId,
            dedicatedUser: stichprobenData.dedicatedUser
          });
      }));
  }

  public createSpotCheckComment(comment: string, spotCheckId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/${spotCheckId}/comments`);
        return this._httpClient.post(url, { comment });
      }));
  }

  public updateSpotCheck(stichprobeFormdata) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/${stichprobeFormdata.id}`);
        return this._httpClient.put(url, stichprobeFormdata);
      }));
  }
  
  public withdrawSpotCheck(stichprobeFormdata) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/${stichprobeFormdata.id}/toggleWithdraw`);
        return this._httpClient.put(url, stichprobeFormdata);
      }));
  }

  public createspotCheckAttachments(files: File[], spotcheckId: string) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/${spotcheckId}/attachments`);
        return this._httpClient.post(url, formData, {
          reportProgress: true,
          observe: 'events',
        });
      }));
  }

  public deleteSpotCheckAttachment(id: string, spotcheckId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/spotchecks/${spotcheckId}/attachments/${id}`);
        return this._httpClient.delete(url);
      }));
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}



