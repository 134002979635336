import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from '../../shared/components/modal-delete/modal-delete.component';
import { RandomSampleTargetsService } from './random-sample-targets.service';
import { ModalSamplePlanningEditComponent } from './modal-sample-target-edit/modal-sample-target-edit.component';
import { SpotCheckService } from 'app/random-sample/shared/spot-check.service';
import { forkJoin } from 'rxjs';
import { RandomSampleTargetViewModel } from './random-sample-target-view-model';
import { ResolveIdHelper } from 'app/admin/shared/resolveIdHelper';
import { EInterval } from 'app/shared/models/interval';
import { RandomSampleTarget } from 'app/shared/models/random-sample-target';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfigurationService } from 'app/shared/masterData/trade/configuration/trade-configuration.service';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { TargetLoadFilter } from 'app/admin/allgemein/shared/target-load-filter';
import { OrderBy, OrderDirection } from 'app/admin/allgemein/shared/order-by';
import { CheckTargetHistoryHelper } from '../../shared/helper/check-target-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';

@Component({
  selector: 'epo-random-sample-targets',
  templateUrl: './random-sample-targets.component.html',
  styleUrls: ['./random-sample-targets.component.scss']
})
export class RandomSampleTargetsComponent extends BaseFilterPagingComponent<RandomSampleTarget> implements OnInit {

  public hasTargets = false;
  loadFilter = new TargetLoadFilter();
  public sorting: { column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval', direction: OrderDirection };

  constructor(private _randomSampleTargetService: RandomSampleTargetsService,
    private tradeConfigervice: TradeConfigurationService,
    private randomSampleService: SpotCheckService,
    private modalService: NgbModal,
    private sampleTargetsService: RandomSampleTargetsService,
    private contractPositionService: ContractPositionConfigurationService,
    private translate: TranslateService,
    public globalAutocompleteService: GolbalAutoCompleteService) {
    super();
  }

  public randomSampleTargets: RandomSampleTargetViewModel[];

  public intervalTypes = [{ value: EInterval.monthly, displayName: 'shared.monthly' },
  { value: EInterval.quarterly, displayName: 'shared.quarterly' },
  { value: EInterval.yearly, displayName: 'shared.yearly' }];

  private contractPositionConfigs: ContractPositionConfiguration[];
  private checkTypeConfigs: CheckTypeConfiguration[];
  private tradeConfigs: TradeConfiguration[];

  ngOnInit() {
    this.loadData();
  }

  isActiveSorting(column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval', direction: 'Asc' | 'Desc') {
    return this.sorting?.column == column && this.sorting.direction == OrderDirection[direction];
  }

  sort(column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval') {
    if (column === this.sorting?.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  public loadData() {
    this.isLoading = true;
    if (this.sorting) {
      this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    }
    forkJoin([
      this.randomSampleService.getTypes(),
      this.tradeConfigervice.getTradeConfigurationsForCurrentContract(),
      this._randomSampleTargetService.getRandomSampleTargets(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter),
      this.contractPositionService.getContractPositionConfigurationsForCurrentContract()
    ]).subscribe((response) => {
      this.checkTypeConfigs = response[0];
      this.tradeConfigs = response[1].items;
      const targets = response[2];
      this.contractPositionConfigs = response[3].items;

      this.hasTargets = targets.items.length > 0;

      this.items = targets.items;
      this.pagingGlobalCount = targets.count;
      this.randomSampleTargets = [];

      this.items.forEach((target) => {
        const viewModel = new RandomSampleTargetViewModel();
        viewModel.tradeName = ResolveIdHelper.getTradeName(target.tradeConfigurationId, this.tradeConfigs, this.translate.instant('shared.unknown-trade'));
        viewModel.spotCheckName = ResolveIdHelper.getCheckTypeName(target.checkTypeConfigurationId, this.checkTypeConfigs, this.translate.instant('shared.unknown-type'));
        viewModel.contractPositionName = ResolveIdHelper.getContractPositionName(target.contractPositionConfigurationId, this.contractPositionConfigs, this.translate.instant('shared.unknown-contract-position'));
        viewModel.amount = target.amount;
        viewModel.interval = target.interval;
        viewModel.intervalName = ResolveIdHelper.getIntervalName(target.interval, this.intervalTypes, this.translate.instant('shared.unknown-interval'));
        viewModel.id = target.id;
        viewModel.contractPositionConfigurationId = target.contractPositionConfigurationId;
        viewModel.checkTypeConfigurationId = target.checkTypeConfigurationId;
        viewModel.tradeConfigurationId = target.tradeConfigurationId;
        viewModel.createdBy = target.createdBy;
        viewModel.createdUtc = target.createdUtc;
        viewModel.modifiedBy = target.modifiedBy;
        viewModel.modifiedUtc = target.modifiedUtc;

        this.randomSampleTargets.push(viewModel);
      });
      this.isLoading = false;
    });
  }

  public openCreateTargetModal() {
    const target = new RandomSampleTarget();
    const modalRef = this.modalService.open(ModalSamplePlanningEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs.filter(x => x.isActive === true && x.trade.isActive === true);
    modalRef.componentInstance.contractPositionConfigs = this.contractPositionConfigs.filter(x => x.isActive === true && x.contractPosition.isActive === true);
    modalRef.componentInstance.checkTypeConfigs = this.checkTypeConfigs.filter(x => x.isActive === true && x.checkType.isActive === true);
    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this.sampleTargetsService.createTarget(result).subscribe(() => {
        this.loadData();
      },
        () => { });
    },
      () => { });
  }

  public openEditTargetModal(target: RandomSampleTarget) {
    const modalRef = this.modalService.open(ModalSamplePlanningEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.inNewMode = false;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs;
    modalRef.componentInstance.contractPositionConfigs = this.contractPositionConfigs;
    modalRef.componentInstance.checkTypeConfigs = this.checkTypeConfigs;

    modalRef.result.then((result) => {
      this.sampleTargetsService.updateTarget(result).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public openDeleteModal(target: RandomSampleTarget) {
    const modalRef = this.modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = target;
    modalRef.result.then((tar) => {
      this.sampleTargetsService.deleteTarget(tar.id).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public showHistory(checkTarget: RandomSampleTarget) {
    this.sampleTargetsService.getTargetHistory(checkTarget.id).subscribe(history => {
      const changeHistory = new CheckTargetHistoryHelper(history).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
    });
  }
}









