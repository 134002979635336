import { AcceptancePOSTRequest } from 'app/acceptance/shared/acceptance-post-request';
import { WorkOwner } from 'app/acceptance/shared/work-owner';
import { WorkControlOwner } from 'app/acceptance/shared/work-control-owner';
import { WorkMainOwner } from 'app/acceptance/shared/work-main-owner';
import { DateRange } from 'app/shared/date-range';
import { StatusPhase } from 'app/shared/models/status-phase';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class AcceptanceData {

    selectedAcceptanceYear: string;
    selectedIntervalType: string;
    selectedIntervalAmount: number;

    selectedContractPos: string;
    selectedEstate: string;
    tolerance: number;

    workPreparation: boolean;
    selectedResponsible: WorkOwner;
    performance: boolean;
    selectedControlResponsible: WorkControlOwner;
    selectedMainResponsible: WorkMainOwner;
    documentsAv: boolean;
    documentsPerformance: boolean;
    documentsControl: boolean;
    evidenceAllocation: number;
    performanceCompleteness: number;

    performanceQuality: number;
    onSchedule: number;

    samples: string[];
    controls: string[];
    contractAgreement: number;

    status_phase1: StatusPhase;
    status_phase2: StatusPhase;
    status_phase3: StatusPhase;

    comment: string;
    
    dedicatedUser: UserResponse;
    
    constructor() { }

    public createFromFormGroup(formGroup: any) {
        let usr = new UserResponse();
        usr.id = formGroup.state.user;
        this.selectedContractPos = formGroup.agreement.selectedContractPos;
        this.selectedEstate = formGroup.agreement.selectedEstate;
        this.tolerance = formGroup.agreement.tolerance;
        this.workPreparation = this.convertValueToBoolean(formGroup.preparation.workPreparation);
        this.selectedResponsible = formGroup.preparation.selectedResponsible;
        this.performance = this.convertValueToBoolean(formGroup.preparation.performance);
        this.selectedControlResponsible = parseInt(formGroup.preparation.selectedControlResponsible);
        this.selectedMainResponsible = parseInt(formGroup.preparation.selectedMainResponsible);
        this.documentsPerformance = this.convertValueToBoolean(formGroup.preparation.documentsPerformance);
        this.documentsControl = this.convertValueToBoolean(formGroup.preparation.documentsControl);
        this.documentsAv = this.convertValueToBoolean(formGroup.preparation.documentsAv);
        this.status_phase1 = formGroup.state.phase.status_Phase1;
        this.status_phase2 = formGroup.state.phase.status_Phase2;
        this.status_phase3 = formGroup.state.phase.status_Phase3;
        this.evidenceAllocation = formGroup.preparation.evidenceAllocation;
        this.performanceCompleteness = formGroup.preparation.performanceCompleteness;
        this.performanceQuality = formGroup.rating.performanceQuality;
        this.onSchedule = formGroup.rating.onSchedule;
        this.contractAgreement = formGroup.tasks.contractAgreement;
        this.comment = formGroup.state.comment;
        this.dedicatedUser = usr;
        return this;
    }

    public toPostRequest(dateRange: DateRange): AcceptancePOSTRequest {
        const req: AcceptancePOSTRequest = {
            contractPositionConfigurationId: this.selectedContractPos,
            tradeConfigurationId: this.selectedEstate,
            toleranceLimit: 0,
            workPreperation: this.workPreparation,
            workOwner: this.selectedResponsible,
            workCompleted: this.performance,
            workControlOwner: this.selectedControlResponsible,
            workMainOwner: this.selectedMainResponsible,
            workPerformanceDocuments: this.documentsPerformance,
            workControlDocuments: this.documentsControl,
            avDocuments: this.documentsAv,
            startAcceptanceDateTimeUtc: dateRange.from,
            endAcceptanceDateTimeUtc: dateRange.to,
            statusPhaseOne: this.status_phase1,
            statusPhaseTwo: this.status_phase2,
            statusPhaseThree: this.status_phase3,
            zuordnungsnachweis: this.evidenceAllocation,
            leistungsvollstaendigkeit: this.performanceCompleteness,
            leistungsqualitaet: this.performanceQuality,
            leistungstermintreue: this.onSchedule,
            vertraglicheVereinbarung: this.contractAgreement,
            dedicatedUser: this.dedicatedUser
        };
        return req;
    }

    private convertValueToBoolean(value: any): boolean {
        if (value == null) {
            return false;
        }
        return value;
    }
}







