import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { StatusPhase } from 'app/shared/models/status-phase';
import { AcceptanceResource } from './acceptance-resource';
import { WorkControlOwner } from './work-control-owner';
import { WorkMainOwner } from './work-main-owner';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class AcceptanceHistoryHelper {

    constructor(private acceptances: AcceptanceResource[],
        private tradeConfigurations: TradeConfigurationResponse[], private contractPositionConfigurations: ContractPositionConfiguration[]) {
    }

    private checkProperties: PropertyDisplayMapping[] = [
        { propertyName: 'workPreperation', displayName: 'acceptances.form.work-preparation', type: 'boolean' },
        { propertyName: 'statusPhaseOne', displayName: 'acceptances.state-contractor', displayValueTransformation: this.resolveStatusPhase },
        { propertyName: 'statusPhaseTwo', displayName: 'acceptances.reaction-orderer', displayValueTransformation: this.resolveStatusPhase },
        { propertyName: 'statusPhaseThree', displayName: 'acceptances.improvement-contractor', displayValueTransformation: this.resolveStatusPhase },
        { propertyName: 'workControlOwner', displayName: 'acceptances.performance-control-responsible', displayValueTransformation: this.resolveWorkControlOwner },
        { propertyName: 'workMainOwner', displayName: 'acceptances.performance-main-responsible', displayValueTransformation: this.resolveWorkMainOwner },
        { propertyName: 'contractPositionConfigurationId', displayName: 'acceptances.contract-position', displayValueTransformation: (id) => this.resolveContractPos(id) },
        { propertyName: 'tradeConfigurationId', displayName: 'acceptances.trade', displayValueTransformation: (id) => this.resolveTrade(id) },

        { propertyName: 'startAcceptanceDateTimeUtc', displayName: 'acceptances.from', displayValueTransformation: this.transformDate },
        { propertyName: 'endAcceptanceDateTimeUtc', displayName: 'acceptances.until', displayValueTransformation: this.transformDate },

        { propertyName: 'workPreparation', displayName: 'acceptances.form.work-preparation', type: 'boolean' },
        { propertyName: 'workCompleted', displayName: 'acceptances.form.performance-complete', type: 'boolean' },

        { propertyName: 'workPerformanceDocuments', displayName: 'acceptances.form.documentation-complete' },
        { propertyName: 'workControlDocuments', displayName: 'acceptances.documents-exist' },

        { propertyName: 'leistungsqualitaet', displayName: 'acceptances.performance-quality' },
        { propertyName: 'leistungstermintreue', displayName: 'acceptances.schedule-performance' },
        { propertyName: 'isWithdrawn', displayName: 'acceptances.form.withdrawn-acceptance', type: 'boolean' },
        { propertyName: 'dedicatedUser', displayName: 'controls.form.dedicated-user', displayValueTransformation: this.resolveDedicatedUserName }
    ]

    public generateHistoryEntries(): HistoryEntryModel[] {
        const historyResources = [new AcceptanceResource(), ...this.acceptances];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: AcceptanceResource, newValues: AcceptanceResource): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this.checkProperties) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    if (prop.propertyName === 'dedicatedUser') {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    } else {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    }
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if (changedProperty.newValue != null || changedProperty.oldValue != null) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }
        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }

    private resolveDedicatedUserName(usr: UserResponse): string {
        if (usr) {
            if (usr.firstname && usr.lastname) {
                return `${usr.firstname} ${usr.lastname}`;
            } else {
                return `${usr.loginEmail}`;
            }
        } else {
            return "";
        }
    }

    private resolveStatusPhase(phase: StatusPhase) {
        switch (phase) {
            case StatusPhase.Accepted:
                return 'status.acceptance-granted';
            case StatusPhase.Conflict:
                return 'status.prompted-for-improvement';
            case StatusPhase.Claim:
                return 'status.acceptance-denied';
            case StatusPhase.ConflictCancelled:
                return 'status.objection-cancelled';
            case StatusPhase.Done:
                return 'status.performed';
            case StatusPhase.ClaimCancelled:
                return 'status.claim-reclamation-cancelled';
            case StatusPhase.InProgress:
                return 'status.in-progress';
            case StatusPhase.None:
            case undefined:
                return null;
            default:
                return 'status.unknown';
        }
    }

    private resolveContractPos(id: string): string {
        const pos = this.contractPositionConfigurations.find(p => p.id == id);
        return pos && pos.contractPosition.name || id;
    }

    private resolveTrade(id: string): string {
        const trade = this.tradeConfigurations.find(trade => trade.id == id);
        return trade && trade.trade.name || id;
    }

    private discardEmptyString(input: string): string {
        if (!this.isString(input)) return input;
        if (input.trim()) return input;
        return null;
    }

    private resolveWorkMainOwner(workOwner: WorkMainOwner) {
        if (workOwner)
            switch (workOwner) {
                case WorkMainOwner.Objektleiter: return 'shared.object-manager';
                default: return 'unknown';
            }
    }

    private transformDate(dateString: string) {
        if (dateString) {
            const date = new Date(dateString + 'Z');
            return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
        }
    }

    private resolveWorkControlOwner(workOwner: WorkControlOwner) {
        if (workOwner)
            switch (workOwner) {
                case WorkControlOwner.Teamleiter: return 'shared.team-manager';
                case WorkControlOwner.Objektleiter: return 'shared.object-manager';
                default: return 'unknown';
            }
    }

    private isString(value) {
        return typeof value === 'string';
    }
}

