import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StatisticsComponent } from '../core/statistics/statistics.component';
import { CardComponent } from './components/card/card.component';
import { DisplayStatusComponent } from 'app/shared/components/display-status/display-status.component';
import { TruncatePipe } from '../shared/truncate.pipe';
import { DashboardComponent } from 'app/core/dashboard/dashboard.component';
import { DefiziteComponent } from 'app/shared/components/defizite/defizite.component';
import { RouterModule } from '@angular/router';
import { DateRangeService } from './components/filter/date-range.service';
import { FilterComponent } from './components/filter/filter.component';
import { DeficitGroupNamePipe } from './masterData/deficit/deficit-group.pipe';
import { CheckTypePipe } from './masterData/check-type/check-type.pipe';
import { DeficitPipe } from './masterData/deficit/deficit.pipe';
import { ContractPositionPipe } from './masterData/contract-position/contract-position.pipe';
import { BoolSymbolPipe } from './pipes/boolSymbol.pipe';
import { EpmtyToDashPipe } from './pipes/empty-to-dash-pipe';
import { GlobalRoleToStringPipe } from './pipes/global-role-to-string.pipe';
import { SampleCommentsComponent } from './components/sample-comments/sample-comments.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ContractRoleToStringPipe } from './pipes/contract-role-to-string.pipe';
import { DeficitFixedToStringPipe } from './pipes/deficit-fixed-to-string.pipe';
import { CreatedModifiedComponent } from './components/created-modified/created-modified.component';
import { MomentModule } from 'ngx-moment';
import { PagingComponent } from './components/paging/paging.component';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoolToStringPipe } from './pipes/bool-to-string.pipe';
import { EntityHistoryComponent } from './components/entity-history/entity-history.component';
import { ChangedPropertyComponent } from './components/entity-history/changed-property/changed-property.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { HideIfMissingRoleDirective } from './helper/hide-if-missing-role.directive';
import { TableLoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LanguageNamePipe } from './masterData/language/language.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ModalIsSyncingComponent } from './components/modal-is-syncing/modal-is-syncing.component';
import { UpdateNotificationComponent } from './components/update-notification/update-notification.component';
import { DeficitProposalRequestComponent } from './components/deficit-proposal-request/deficit-proposal-request.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DeficitGroupProposalService } from './services/deficit-group-proposal.service';
import { DeficitGroupProposalsComponent } from './components/deficit-proposals/deficit-group-proposals/deficit-group-proposals.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    MomentModule,
    FormsModule,
    TranslateModule.forChild({}),
    NgxSummernoteModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CardComponent,
    TruncatePipe,
    StatisticsComponent,
    DisplayStatusComponent,
    DashboardComponent,
    DefiziteComponent,
    FilterComponent,
    DeficitGroupNamePipe,
    CheckTypePipe,
    DeficitPipe,
    ContractPositionPipe,
    BoolSymbolPipe,
    EpmtyToDashPipe,
    GlobalRoleToStringPipe,
    SampleCommentsComponent,
    MultiSelectComponent,
    ContractRoleToStringPipe,
    DeficitFixedToStringPipe,
    CreatedModifiedComponent,
    PagingComponent,
    ColumnFilterComponent,
    BoolToStringPipe,
    EntityHistoryComponent,
    ChangedPropertyComponent,
    ToasterComponent,
    HideIfMissingRoleDirective,
    TableLoadingSpinnerComponent,
    LanguageNamePipe,
    ModalIsSyncingComponent,
    UpdateNotificationComponent,
    DeficitProposalRequestComponent,
    DeficitGroupProposalsComponent
  ],
  providers: [
    DateRangeService, DeficitGroupProposalService
  ],
  exports: [
    CardComponent,
    TruncatePipe,
    CommonModule,
    StatisticsComponent,
    DisplayStatusComponent,
    DefiziteComponent,
    FilterComponent,
    DeficitGroupNamePipe,
    CheckTypePipe,
    DeficitPipe,
    ContractPositionPipe,
    BoolSymbolPipe,
    EpmtyToDashPipe,
    GlobalRoleToStringPipe,
    SampleCommentsComponent,
    MultiSelectComponent,
    ContractRoleToStringPipe,
    DeficitFixedToStringPipe,
    CreatedModifiedComponent,
    PagingComponent,
    ColumnFilterComponent,
    BoolToStringPipe,
    EntityHistoryComponent,
    ToasterComponent,
    HideIfMissingRoleDirective,
    TableLoadingSpinnerComponent,
    LanguageNamePipe,
    TranslateModule,
    UpdateNotificationComponent,
    DeficitProposalRequestComponent
  ],
  entryComponents: [
    EntityHistoryComponent,
    ModalIsSyncingComponent
  ]
})
export class SharedModule { }
