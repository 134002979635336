import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AcceptanceData } from './acceptance-data';
import { AbnahmenFormularService } from 'app/acceptance/shared/abnahmen-formular.service';
import { Acceptance } from 'app/acceptance/shared/Acceptance';
import { SpotCheckService } from 'app/random-sample/shared/spot-check.service';
import { RealEstateResponsibilityResponse } from 'app/random-sample/shared/real-estate-responsibility-response';
import { EInterval } from 'app/shared/models/interval';
import { ControlService } from 'app/control/shared/control.service';
import { DateRange } from 'app/shared/date-range';
import { WorkControlOwner } from 'app/acceptance/shared/work-control-owner';
import { WorkMainOwner } from 'app/acceptance/shared/work-main-owner';
import { DateRangeService } from 'app/shared/components/filter/date-range.service';
import { allId, BaseFilterData } from 'app/shared/components/filter/filter';
import { StatisticFigures } from 'app/core/statistics/statistic-figures';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { AcceptanceStatusTransitionModel } from './acceptance-status-transition.model';
import { UserRoleService } from 'app/shared/services/user-role.service';
import { AcceptanceCreator } from '../shared/acceptance-creator';
import { StatusPhase } from 'app/shared/models/status-phase';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'app/core/user-profile.service';
import { User } from 'app/core/user';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { DedicatedUser } from 'app/shared/masterData/user/dedicated-user';
import { Subscription } from 'rxjs';
import { transformContractUserToDedicatedUser, transformNullToDedicatedUser } from 'app/shared/helper/user-transformer';
import { ContractUserService } from 'app/shared/masterData/user/contract-user.service';
import { ContractUserResource } from 'app/core/contract-user.resource';

@Component({
  selector: 'epo-acceptance-form',
  templateUrl: './acceptance-form.component.html',
  styleUrls: ['./acceptance-form.component.scss']
})
export class AbnahmenFormularComponent implements OnInit,OnDestroy {

  acceptance: Acceptance;
  @Input() acceptanceId: string;
  @Input() contractPositionId: string;

  intervalTypeList = ['shared.monthly', 'shared.quarterly'];
  quarterList = [1, 2, 3, 4];
  monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  responsiblesControl = [
    { value: WorkControlOwner.Teamleiter, displayName: 'shared.team-manager' },
    { value: WorkControlOwner.Objektleiter, displayName: 'shared.object-manager' }
  ];
  mainResponsibles = [{ value: WorkMainOwner.Objektleiter, displayName: 'shared.object-manager' }];

  dateRange: DateRange;
  acceptanceForm: FormGroup;
  contractPositions: ContractPositionConfiguration[];
  responsibilities: RealEstateResponsibilityResponse[];
  acceptanceData: AcceptanceData;
  showQuartersSelection = true;
  showMonthsSelection = false;
  filterData: BaseFilterData;
  submitted = false;
  isSaving = false;
  sampleFigures: StatisticFigures = new StatisticFigures(false, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  controlFigures: StatisticFigures = new StatisticFigures(true, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  public acceptanceYearList = this.getYearsFromToNow(2016);
  public createdUtc: string;
  public createdBy: UserResponse;
  public modifiedUtc: string;
  public modifiedBy: UserResponse;
  tradeConfigurations: TradeConfigurationResponse[];
  private _titleNew: string;
  private _titleEdit: string;
  public get title() {
    if (this.acceptance && this.acceptance.contractEntityNumber) {
      return this._titleEdit;
    }
    return this._titleNew;
  }
  public acceptanceStatusTransitionModel: AcceptanceStatusTransitionModel;
  
  public withdrawnAcceptance:boolean = false;
  public isUserGlobalAdmin:boolean;
  
  isLoading: boolean = true;

  public dedicatedUsers: DedicatedUser[] = [];
  private _assignedUser: DedicatedUser;
  public assignedUserGuid: string;
  public get assignedUser(): DedicatedUser {
    return this._assignedUser;
  }
  public set assignedUser(value: DedicatedUser) {
    this._assignedUser = value;
  }
  private _contractUsersSub: Subscription;
  
  
  constructor(
    public activeModal: NgbActiveModal,
    private _userProfileService: UserProfileService,
    private _randomSampleService: SpotCheckService,
    private _controlSampleService: ControlService,
    private _abnahmenFormularService: AbnahmenFormularService,
    private _dateRangeService: DateRangeService,
    private _contractPositionService: ContractPositionConfigurationService,
    private _userRoleService: UserRoleService,
    private _toaster: ToasterService,
    private _translate: TranslateService,
    private _contractUserService: ContractUserService
    ) {
    this._translate.get('acceptances.form.title-new').subscribe(t => {
      this._titleNew = t;
    });
  }

  private isAcceptanceWithdrawn(): boolean{
    return this.acceptance ? this.acceptance.isWithdrawn : false;
  }

  ngOnInit() {
    this.populateContractUsersSelectOptions();
    this._userProfileService.currentUser.subscribe(user => {
      this.isUserGlobalAdmin = (user as User).role === GlobalUserRole.GlobalAdmin;
    });
    this.getContractPositionData();
    this.getTradeConfigs();
    const basisFormular = this.createFormGroup();
    if (this.acceptanceId) {
      this._abnahmenFormularService.getAcceptanceById(this.acceptanceId).subscribe(acceptance => {
        this.acceptance = new AcceptanceCreator([acceptance], null).create()[0];
        if (this.acceptance.isWithdrawn) {
          this.withdrawnAcceptance = this.acceptance.isWithdrawn;
          this._translate.get('acceptances.form.title-withdrawn', { number: this.acceptance.contractEntityNumber }).subscribe(t => {
            this._titleEdit = t;
          });
        } else {
          this._translate.get('acceptances.form.title-edit', { number: this.acceptance.contractEntityNumber }).subscribe(t => {
            this._titleEdit = t;
          });
        }
        this._userRoleService.ContractRole.subscribe(role => {
          this.acceptanceStatusTransitionModel = new AcceptanceStatusTransitionModel(this.acceptance, role);
          if (this.acceptanceStatusTransitionModel.transitionModels) {
            basisFormular.get('state.phase').setValidators(Validators.required);
          }
        })
        this.acceptanceForm = this.handleEditMode(basisFormular);
        this.changeIntervalDivider();
        if (this.isEstateAndContractPositionValid()) {
          this.getSamplesAndControls();
        }
        this.isLoading = false;
      })
    } else {
      this._userRoleService.ContractRole.subscribe(role => {
        this.acceptanceStatusTransitionModel = new AcceptanceStatusTransitionModel(new Acceptance(), role);
        if (this.acceptanceStatusTransitionModel.transitionModels) {
          basisFormular.get('state.phase').setValidators(Validators.required);
        }
      });
      this.acceptanceForm = this.handleNewMode(basisFormular);
      this.changeIntervalDivider();
      if (this.isEstateAndContractPositionValid()) {
        this.getSamplesAndControls();
      }
      this.isLoading = false;
    }
  }

  get form() { return this.acceptanceForm ? this.acceptanceForm.controls : null; }

  public assignDedicatedUser(event:any) {
    try {
      this.form.user?.setValue(this.dedicatedUsers.find(item => item.id === event.target.value));
    } catch (error) {
      console.log("could not find user in list of contract users");
      console.log(error);
    }
  }

  public printNameOfContractUser(user:ContractUserResource):string{
    if (user.firstname && user.lastname) {
        return `${user.firstname} ${user.lastname}`;
    } else {
        return user.loginEmail;
    }
  }

  private populateContractUsersSelectOptions():void {
    this._contractUsersSub = this._contractUserService.getFilteredContractUsersForCurrentContract().subscribe(response => {
      response.items.map(item => {
        this.dedicatedUsers.push(transformContractUserToDedicatedUser(item));
      });
    }, err => {
      console.log(err);
    },()=>{
      this.dedicatedUsers.push(transformNullToDedicatedUser());
    })
  }
  public changeIntervalDivider() {

    if (this.acceptanceForm.get('acceptanceRange.selectedIntervalType').value === this.intervalTypeList[0]) {
      this.showQuartersSelection = false;
      this.showMonthsSelection = true;
    } else {
      this.showMonthsSelection = false;
      this.showQuartersSelection = true;
    }
  }

  public withdrawAcceptance():void {
    this.isSaving = true;
    this.saveWithdrawnAcceptance(new AcceptanceData().createFromFormGroup(this.acceptanceForm.getRawValue()));
  }

  public onSubmit() {
    if(this.isAcceptanceWithdrawn()){
      this._toaster.show({
        heading: 'errors.heading',
        description: 'acceptances.form.withdrawn-acceptance-error',
        type: 'error',
      });
      return;
    }
    this.submitted = true;
    this.isSaving = true;
    if (this.acceptanceForm.invalid) {
      this.isSaving = false;
      return;
    }
    try {
      let values = this.checkDedicatedUserActivity();
      this.saveAbnahmenData(values);
    } catch (error) {
      this.isSaving = false;
      console.log(error);
      this._toaster.show({
        heading: 'errors.heading',
        description: 'acceptances.form.dedicated-user-acceptance-error',
        type: 'error',
      });
    }
  }

  private checkDedicatedUserActivity(): AcceptanceData {
    let dedicatedUserObj;
    let values = new AcceptanceData().createFromFormGroup(this.acceptanceForm.getRawValue());
    console.log(values.dedicatedUser);
    this.dedicatedUsers.forEach( item => {
      if(item.id === values.dedicatedUser.id)
        dedicatedUserObj = item;
    });
    if (dedicatedUserObj && dedicatedUserObj.id != "00000000-0000-0000-0000-000000000000") {
      if (!dedicatedUserObj.isActive){
        throw ("user is Inactive");
      }
    } else {
      values.dedicatedUser = null;
    }
    return values;
  }

  public handleContractPositionChange() {
    if (this.isEstateAndContractPositionValid()) {
      this.getSamplesAndControls();
    }
  }

  public handleEstateChange() {
    if (this.isEstateAndContractPositionValid()) {
      this.getSamplesAndControls();
    }
  }

  public handleTimeIntervalChange() {
    if (this.isEstateAndContractPositionValid()) {
      this.getSamplesAndControls();
    }
  }

  private isEstateAndContractPositionValid(): boolean {
    if (this.form.agreement['controls'].selectedContractPos && this.form.agreement['controls'].selectedContractPos.value
      && this.form.agreement['controls'].selectedEstate && this.form.agreement['controls'].selectedEstate.value) {
      return true;
    }
    return false;
  }

  public saveWithdrawnAcceptance(acceptanceFormData: AcceptanceData) {
    this._abnahmenFormularService.withdrawAcceptance(acceptanceFormData.toPostRequest(this.dateRange), this.acceptance.id)
    .subscribe(() => {
      this.isSaving = false;
      this.activeModal.close();
    }, (err) => {
      this.isSaving = false;
      this.activeModal.close();
      console.log(err);
    });

  }

  private saveAbnahmenData(abnahmenFormData: AcceptanceData) {
    this.isSaving = true;
    if (this.acceptance === undefined) {
      this._abnahmenFormularService.createAcceptance(abnahmenFormData.toPostRequest(this.dateRange))
        .subscribe((createdAcceptance) => {
          this.acceptance = new Acceptance();
          this.acceptance.id = createdAcceptance.id;
          this.handleCommentAndClose(abnahmenFormData.comment, createdAcceptance.id);
        }, (err) => {
          console.log(err);
          this.isSaving = false;
        });
    } else {
      this._abnahmenFormularService.updateAcceptance(abnahmenFormData.toPostRequest(this.dateRange), this.acceptance.id)
          .subscribe(() => {
            this.handleCommentAndClose(abnahmenFormData.comment, this.acceptance.id);
          }, (err) => {
            this.isSaving = false;
            console.log(err);
          });
    }
  }

  private handleCommentAndClose(comment: string, acceptanceId: string) {
    if (comment) {
      this.isSaving = true;
      this._abnahmenFormularService.createAcceptanceComment(comment, acceptanceId).subscribe(
        () => this.activeModal.close(),
        err => {
          this.isSaving = false;
          console.log(err);
        }
      );
    } else {
      this.isSaving = false;
      this.activeModal.close();
    }
  }

  private handleEditMode(basisFormular: FormGroup): FormGroup {
    if (!this.acceptance.isEditable || !this.acceptanceStatusTransitionModel.transitionModels) {
      this.disableAllInputs(basisFormular);
    } else if (this.acceptance.status_Phase1 <= StatusPhase.InProgress || this.acceptance.status_Phase2 === StatusPhase.Conflict && this.acceptance.status_Phase3 <= StatusPhase.InProgress) {
      this.disableDefaultInputs(basisFormular);
    } else {
      this.disableAllInputs(basisFormular);
    }
    basisFormular.get('agreement.selectedContractPos').setValue(this.acceptance.contractPositionConfigurationId);
    basisFormular.get('agreement.selectedEstate').setValue(this.acceptance.tradeConfigurationId);

    basisFormular.get('acceptanceRange.selectedAcceptanceYear').setValue(this.acceptance.year);
    basisFormular.get('acceptanceRange.selectedIntervalType').setValue(this.intervalTypeList[this.acceptance.interval]);
    if (this.acceptance.interval === 0) {
      basisFormular.get('acceptanceRange.selectedIntervalAmount').setValue(this.monthsList[this.acceptance.amount]);
    } else {
      basisFormular.get('acceptanceRange.selectedIntervalAmount').setValue(this.quarterList[this.acceptance.amount]);
    }

    basisFormular.get('preparation.workPreparation').setValue(this.acceptance.work_preperation);
    basisFormular.get('preparation.performance').setValue(this.acceptance.work_completed);
    basisFormular.get('preparation.selectedControlResponsible').setValue(WorkControlOwner[this.acceptance.work_control_owner]);
    basisFormular.get('preparation.selectedMainResponsible').setValue(WorkMainOwner[this.acceptance.work_main_owner]);
    basisFormular.get('preparation.documentsControl').setValue(this.acceptance.work_control_docuemnts);
    basisFormular.get('preparation.documentsAv').setValue(this.acceptance.av_documents);
    basisFormular.get('preparation.documentsPerformance').setValue(this.acceptance.work_performance_documents);

    basisFormular.get('rating.performanceQuality').setValue(this.acceptance.leistungsqualitaet.value);
    basisFormular.get('rating.onSchedule').setValue(this.acceptance.leistungstermintreue.value);
    if (this.acceptance.dedicatedUser) {
      basisFormular.get('state.user').setValue(this.acceptance.dedicatedUser.id);
    }
    

    this.createdBy = this.acceptance.createdBy;
    this.modifiedBy = this.acceptance.modifiedBy;
    this.createdUtc = this.acceptance.createdUtc;
    this.modifiedUtc = this.acceptance.modifiedUtc;

    return basisFormular;
  }

  private disableAllInputs(basisFormular: FormGroup) {
    this.disableDefaultInputs(basisFormular);
    basisFormular.get('preparation').disable();
    basisFormular.get('rating').disable();
    basisFormular.get('tasks').disable();
  }

  private disableDefaultInputs(basisFormular: FormGroup) {
    basisFormular.get('acceptanceRange').disable();
    basisFormular.get('agreement').disable();
  }

  private handleNewMode(basisFormular: FormGroup): FormGroup {
    const selectedContractPosFromFilter = this.contractPositionId || this.getSelectedContractPosFromFilter();
    const selectedEstateFromFilter = this.getSelectedEstateFromFilter();
    const selectedAcceptanceYearFromFilter = this.getSelectedAcceptanceYear();
    const selectedIntervalTypeFromFilter = this.getSelectedIntervalType();
    const selectedIntervalAmountFromFilter = this.getSelectedIntervalAmount();

    basisFormular.get('agreement.selectedContractPos').setValue(selectedContractPosFromFilter);
    if (selectedContractPosFromFilter !== undefined) {
      basisFormular.get('agreement.selectedContractPos').disable();
    }
    basisFormular.get('agreement.selectedEstate').setValue(selectedEstateFromFilter);
    if (selectedEstateFromFilter !== undefined) {
      basisFormular.get('agreement.selectedEstate').disable();
    }
    basisFormular.get('acceptanceRange.selectedAcceptanceYear').setValue(selectedAcceptanceYearFromFilter);
    basisFormular.get('acceptanceRange.selectedIntervalType').setValue(selectedIntervalTypeFromFilter);
    basisFormular.get('acceptanceRange.selectedIntervalAmount').setValue(selectedIntervalAmountFromFilter);
    return basisFormular;
  }

  private createFormGroup() {
    return new FormGroup({
      acceptanceRange: new FormGroup({
        selectedAcceptanceYear: new FormControl(Validators.required),
        selectedIntervalType: new FormControl(Validators.required),
        selectedIntervalAmount: new FormControl(Validators.required)
      }),
      agreement: new FormGroup({
        selectedContractPos: new FormControl('', Validators.required),
        selectedEstate: new FormControl('', Validators.required),
      }),
      preparation: new FormGroup({
        workPreparation: new FormControl(),
        performance: new FormControl(),
        selectedControlResponsible: new FormControl('', Validators.required),
        selectedMainResponsible: new FormControl('', Validators.required),
        documentsAv: new FormControl(),
        documentsPerformance: new FormControl(),
        documentsControl: new FormControl(),
      }),
      rating: new FormGroup({
        performanceQuality: new FormControl(100, [Validators.required, Validators.pattern('^[0-9]{1,3}$'), Validators.min(0), Validators.max(120)]),
        onSchedule: new FormControl(100, [Validators.required, Validators.pattern('^[0-9]{1,3}$'), Validators.min(0), Validators.max(120)])
      }),
      tasks: new FormGroup({
        samples: new FormControl(),
        controls: new FormControl(),
      }),
      state: new FormGroup({
        phase: new FormControl(''),
        user: new FormControl(),
        comment: new FormControl()
      }),
      requestType: new FormControl(),
      text: new FormControl()
    }, Validators.required);
  }


  private getSelectedEstateFromFilter() {
    if (this.filterData && this.filterData.selectedTrade.id !== allId) {
      return this.filterData.selectedTrade.id;
    }
  }

  private getSelectedContractPosFromFilter() {
    if (this.filterData && this.filterData.selectedContractPosition.id !== allId) {
      return this.filterData.selectedContractPosition.id;
    }
  }

  private getSelectedAcceptanceYear() {
    if (this.filterData && this.filterData.selectedDateRange.from) {
      return this.filterData.selectedDateRange.from.getFullYear();
    }
  }

  private getSelectedIntervalType() {
    if (this.filterData && this.filterData.selectedIntervalType !== EInterval.yearly) {
      return this.intervalTypeList[this.filterData.selectedIntervalType];
    }
  }

  private getSelectedIntervalAmount() {
    if (this.filterData && this.filterData.selectedIntervalType === EInterval.monthly) {
      return this.monthsList[this.filterData.selectedDateRange.from.getMonth()];
    } else if (this.filterData && this.filterData.selectedIntervalType && this.filterData.selectedIntervalType === EInterval.quarterly) {
      switch (this.filterData.selectedDateRange.from.getMonth()) {
        case 0:
        case 1:
        case 2:
          return this.quarterList[0];
        case 3:
        case 4:
        case 5:
          return this.quarterList[1];
        case 6:
        case 7:
        case 8:
          return this.quarterList[2];
        case 9:
        case 10:
        case 11:
          return this.quarterList[3];
      }
    }
  }

  private getContractPositionData() {
    this._contractPositionService.getContractPositionConfigurationsForCurrentContract()
      .subscribe((contractPositions) => {
        this.contractPositions = contractPositions.items.filter(x => (x.isActive === true && x.contractPosition.isActive) || this.acceptanceId);
      });
  }

  private getTradeConfigs() {
    this._randomSampleService.getTradeConfigurations()
      .subscribe((gewerke) => {
        this.tradeConfigurations = gewerke.items.filter(x => (x.isActive === true && x.trade.isActive) || this.acceptanceId);
      });
  }

  private getYearsFromToNow(fromYear: number): Array<number> {
    const currentYear = new Date().getFullYear(), years = [];
    fromYear = fromYear;
    while (fromYear <= currentYear) {
      years.push(fromYear++);
    }
    return years;
  }

  private getSamplesAndControls() {
    this.setDateRange();
    this.getSpotCheckStatistics();
    this.getControlStatistics();
  }

  private setDateRange() {
    const year = this.form.acceptanceRange['controls'].selectedAcceptanceYear.value;
    if (this.form.acceptanceRange['controls'].selectedIntervalType.value === 'shared.monthly') {
      const month: number = this.form.acceptanceRange['controls'].selectedIntervalAmount.value - 1;
      this.dateRange = this._dateRangeService.getDateRangeByMonthAndYear(month, year);
    } else if (this.form.acceptanceRange['controls'].selectedIntervalType.value === 'shared.quarterly') {
      const quarter: number = this.form.acceptanceRange['controls'].selectedIntervalAmount.value - 0;
      this.dateRange = this._dateRangeService.getDateRangeByQuarterAndYear(quarter, year);
    }
  }
  private getSpotCheckStatistics() {
    return this._randomSampleService.getStatistics({
      selectedDateRange: this.dateRange,
      contractPositionId: this.form.agreement['controls'].selectedContractPos.value,
      typeId: null,
      tradeId: this.form.agreement['controls'].selectedEstate.value,
      userId: null
    }
    ).subscribe((res) => {
      this.sampleFigures = new StatisticFigures(
        false,
        res.noClaimCount,
        res.claimCount,
        res.reclamationCount,
        res.claimCancelledCount,
        res.reclamationCancelledCount,
        res.conflictCount,
        res.inProgressCount,
        0,
        res.noReactionCount
      );
    });
  }

  private getControlStatistics() {
    return this._controlSampleService.getStatistics({
      selectedDateRange: this.dateRange,
      contractPositionId: this.form.agreement['controls'].selectedContractPos.value,
      typeId: null,
      tradeId: this.form.agreement['controls'].selectedEstate.value,
      userId: null
    }).subscribe((res) => {
      this.controlFigures = new StatisticFigures(
        true,
        res.noClaimCount,
        res.claimCount,
        res.reclamationCount,
        0,
        0,
        0,
        0,
        res.notFixedCount,
        0
      );
    });
  }

  ngOnDestroy(): void {
    this._contractUsersSub.unsubscribe();
  }
}
