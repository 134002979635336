import { DeficitMappingsSortingMapper } from 'app/shared/helper/deficit-mappins-sorting-helper';

export class DeficitViewModel {
  constructor(id: string, deficitGroupId: string, deficitGroupName: string, title: string, description: string, globalDescription: string,
    isSelectd: boolean, isActive: boolean, deficitConfigurationId: string, mappings: { checkType: string, contractPosition: string }[],
    isSpotCheckDeficit: boolean, isControlDeficit: boolean, entityNumber: number) {
    this.id = id;
    this.deficitGroupId = deficitGroupId;
    this.deficitGroupName = deficitGroupName;
    this.title = title;
    this.description = description;
    this.editedDescription = description;
    this._descriptionCopy = description;
    this.globalDescription = globalDescription;
    this.isSelected = isSelectd;
    this.isActive = isActive;
    this.deficitConfigurationId = deficitConfigurationId;
    this.mappings = DeficitMappingsSortingMapper.sortMaps(mappings.filter((entry, index, selfArray) =>
      index === selfArray.findIndex((p) => (
        p.checkType === entry.checkType && p.contractPosition === entry.contractPosition
      ))
    ));
    this.isControlDeficit = isControlDeficit;
    this.isSpotCheckDeficit = isSpotCheckDeficit;
    this.entityNumber = entityNumber;
  }

  id: string;
  deficitGroupId: string;
  deficitGroupName: string;
  title: string;
  description: string;
  globalDescription: string;
  editedDescription?: string;
  isSelected?: boolean;
  isActive?: boolean;
  deficitConfigurationId?: string;
  mappings: { checkType: string, contractPosition: string }[] = [];
  isControlDeficit: boolean;
  isSpotCheckDeficit: boolean;
  entityNumber: number;

  private _descriptionCopy;
  private _isEditing = false;

  get isEditing() {
    return this._isEditing;
  }

  set isEditing(value: boolean) {
    if (value === false) {
      this._descriptionCopy = this.editedDescription;
    }
    this._isEditing = value;
  }

  cancelEdit() {
    this.editedDescription = this._descriptionCopy;
    this.isEditing = false;
  }

  resetDescription() {
    this.editedDescription = null;
  }
}
