import { Component, OnInit } from '@angular/core';
import { DeficitWithValue } from 'app/shared/deficit-with-value.view-model';
import { DeficitGroupProposalService } from 'app/shared/services/deficit-group-proposal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeficitConfigurationService } from 'app/shared/masterData/deficit/configuration/deficit-configuration.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epo-deficit-proposal-request',
  templateUrl: './deficit-proposal-request.component.html',
  styleUrls: ['./deficit-proposal-request.component.scss']
})
export class DeficitProposalRequestComponent implements OnInit {
  public descriptionConfig = {
    placeholder: '',
    tabsize: 2,
    height: 150,
    toolbar: [
      ['misc', ['undo', 'redo', 'codeview']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['insert', ['picture', 'link', 'hr']]
    ]
  }

  public title:string;
  // public group:DeficitGroupViewModel;
  public deficit: DeficitWithValue;
  public comment: string = "";
  public sourceDescription: string;
  public deficitProposalForm: FormGroup;
  
  constructor(
    public activeModal: NgbActiveModal,
    private _deficitProposalService: DeficitGroupProposalService,
    private _deficitConfigurationService: DeficitConfigurationService,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.deficitProposalForm = this.formBuilder.group({
      descriptionProposal: this.deficit.description.toString(),
      comment: ''
    });
  }

  onSubmit(): void {
    let temp: {descriptionProposal, comment} = this.deficitProposalForm.getRawValue();
    let request = {
      deficitConfiguration: null,
      descriptionProposal: temp.descriptionProposal,
      previousDescription: this.deficit.description,
      comment: temp.comment,
      status: 0
    }
    this._deficitConfigurationService.getById(this.deficit.id).subscribe((res)=> {
      request.deficitConfiguration = res;
      this._deficitProposalService.createDeficitGroupProposal(request).subscribe(res => {
        this.closeForm();
      });
    });
  }

  public closeForm():void{
    this.activeModal.close();
  }
}
