import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SpotCheckResponse } from './spot-check-response';
import { TargetResponse } from './target-response';
import { Observable } from 'rxjs';
import { shareReplay, map, take, mergeMap } from 'rxjs/operators';
import { BaseSampleService } from 'app/shared/base-sample-service';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { PagedResultResponse } from './paged-result.response';
import { allId } from 'app/shared/components/filter/filter';
import { CheckLoadFilter } from 'app/shared/models/check-load-filter';
import { SpotCheckStatistics } from '../spot-check-form/spot-check-statistics';

@Injectable({
  providedIn: 'root'
})
export class SpotCheckService extends BaseSampleService {
  private cachedTrades$: Observable<PagedResultResponse<TradeConfigurationResponse>>;
  private cachedTargets$: Observable<PagedResultResponse<TargetResponse>>;
  private cachedTypes$: Observable<Array<CheckTypeConfiguration>>;
  private cachedContractPositions$: Observable<PagedResultResponse<ContractPositionConfiguration>>;
  private cachedDeficitGroups$: Observable<Array<DeficitGroupConfiguration>>;

  private spotCheck: Observable<SpotCheckResponse>;

  protected _typeStore: Array<CheckTypeConfiguration> = [];

  protected _deficitGroupStore: Array<DeficitGroupConfiguration> = [];

  constructor(private _http: HttpClient, private contractService: ContractService) {
    super();
    this.initializeTypeStore();
    this.initializeDeficitGroupStore();
  }

  public getDeficitStore() {
    return this._deficitGroupStore;
  }
  public getTypeStore() {
    return this._typeStore;
  }

  public getSpotChecks(filter: CheckLoadFilter): Observable<PagedResultResponse<SpotCheckResponse>> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/spotchecks?skip=${filter.skipNr > 0 ? filter.skipNr : 0}`;
        if (filter.takeNr) {
          url = `${url}&take=${filter.takeNr}`;
        }
        url = this.addFilterToUrl(url, filter);
        if (filter.statsType !== null && filter.statsType !== undefined) {
          url = `${url}&statisticType=${filter.statsType}`;
        }
        return this._http.get<PagedResultResponse<SpotCheckResponse>>(url);
      }))
  }

  public getSpotCheckById(spotCheckId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = `${environment.apiUrl}/api/${contract.id}/spotchecks/${spotCheckId}`;
        this.spotCheck = this._http.get<SpotCheckResponse>(url)
          .pipe(shareReplay());
        return this.spotCheck;
      }));
  }

  public getSpotCheckHistory(spotCheckId: string): Observable<SpotCheckResponse[]> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        return this._http.get<SpotCheckResponse[]>(`${environment.apiUrl}/api/${contract.id}/spotchecks/${spotCheckId}/history`);
      }));
  }

  public getTradeConfigurations() {
    if (this.cachedTrades$) {
      return this.cachedTrades$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        this.cachedTrades$ = this._http.get<PagedResultResponse<TradeConfigurationResponse>>(url)
          .pipe(shareReplay());
        return this.cachedTrades$;
      }));
  }

  public getTargets() {
    if (this.cachedTargets$) {
      return this.cachedTargets$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = `${environment.apiUrl}/api/${contract.id}/spotchecks/targets`;
        this.cachedTargets$ = this._http.get<PagedResultResponse<TargetResponse>>(url);
        return this.cachedTargets$;
      }));
  }

  public getTargetsBy(filterByTradeId: string, filterByContractPositionId: string) {
    return this.getTargets()
      .pipe(
        map(targets => targets.items.filter(target => (filterByTradeId === allId || target.tradeConfigurationId === filterByTradeId)
          && (filterByContractPositionId === allId || target.contractPositionConfigurationId === filterByContractPositionId)))
      );
  }

  public getTypes() {
    if (this.cachedTypes$) {
      return this.cachedTypes$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/spotchecks/types`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        this.cachedTypes$ = this._http.get<Array<CheckTypeConfiguration>>(url);
        this.cachedTypes$.subscribe((v) => {
          if (v) {
            this._typeStore = v;
          }
        });
        return this.cachedTypes$;
      }));
  }

  public getDeficitGroups(active: boolean = true) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/deficitgroupconfigurations/spotchecks?isactive=${active}`;
        if (contract.contractLanguageId) {
          url = `${url}&languageId=${contract.contractLanguageId}`;
        }
        this.cachedDeficitGroups$ = this._http.get<Array<DeficitGroupConfiguration>>(url);
        this.cachedDeficitGroups$.subscribe((v) => {
          if (v) {
            this._deficitGroupStore = v;
          }
        });
        return this.cachedDeficitGroups$;
      }));
  }

  public getDeficitsForSpotCheck(spotChckId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/deficitgroupconfigurations/spotchecks/${spotChckId}`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        return this._http.get<Array<DeficitGroupConfiguration>>(url);
      }));
  }

  public getContractPositions() {
    if (this.cachedContractPositions$) {
      return this.cachedContractPositions$;
    }
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations`;
        if (contract.contractLanguageId) {
          url = `${url}?languageId=${contract.contractLanguageId}`;
        }
        this.cachedContractPositions$ = this._http.get<PagedResultResponse<ContractPositionConfiguration>>(url)
          .pipe(shareReplay());
        return this.cachedContractPositions$;
      }));
  }


  public getStatistics(filter: CheckLoadFilter) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${environment.apiUrl}/api/${contract.id}/spotchecks/statistics`;
        url = this.addFilterToUrl(url, filter);
        return this._http.get<SpotCheckStatistics>(url);
      }));
  }

  public cleanCache() {
    this.cachedTypes$ = undefined;
    this.cachedTrades$ = undefined;
  }

  protected initializeTypeStore() {
    this.getTypes().subscribe(_ => { });
  }

  protected initializeDeficitGroupStore() {
    this.getDeficitGroups().subscribe(_ => { });
  }
}
