import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { ContractService } from './contract.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ContractUrlGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.contractService.getContractByUrl(route.paramMap.get('contractUrlComponent')).pipe(
      take(1),
      map(contract => {
        if (contract) {
          this.contractService.selectContractByUrl(route.paramMap.get('contractUrlComponent'));
          return true;
        }
        else this.router.navigate(['selectContract']);
        return false;
      })
    );
  }

  constructor(private contractService: ContractService, private router: Router) {
  }

}