import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, mergeMap } from 'rxjs/operators';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { TargetLoadFilter } from '../../admin/allgemein/shared/target-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';
import { Observable } from 'rxjs';
import { AcceptanceTarget } from './acceptance-target';

@Injectable({
  providedIn: 'root'
})
export class AcceptanceTargetsService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public getAcceptanceTargets(skipNr?: number, takeNr?: number, filter?: TargetLoadFilter) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        let url = `${this.createEpoUrl(`api/${contract.id}/acceptances/targets`)}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
          url = `${url}&take=${takeNr}`;
        }
        if (filter) {
          url = LoadFilterHelper.addFilterParameter(url, contract.contractLanguageId, 'languageId');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractId, 'contractId');
          url = LoadFilterHelper.addFilterParameter(url, filter.contractPosition, 'contractPosition');
          url = LoadFilterHelper.addFilterParameter(url, filter.trade, 'trade');
          url = LoadFilterHelper.addFilterParameter(url, filter.orderBy, 'orderBy');
        }
        return this._httpClient.get<PagedResultResponse<AcceptanceTarget>>(url);
      }));
  }

  public createTarget(target: AcceptanceTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/targets`);
        return this._httpClient.post(url, target);
      }));
  }

  public updateTarget(target: AcceptanceTarget) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/targets/${target.id}`);
        return this._httpClient.put(url, target);
      }));
  }

  public deleteTarget(id: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/targets/${id}`);
        return this._httpClient.delete(url);
      }));
  }

  public getHistory(targetId: string): Observable<AcceptanceTarget[]> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/targets/${targetId}/history`);
        return this._httpClient.get<AcceptanceTarget[]>(url);
      }));
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}






