import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Karte } from '../../shared/components/card/card';
import { forkJoin, Subscription } from 'rxjs';
import { EInterval } from '../../shared/models/interval';
import { ControlService } from '../shared/control.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KontrollenFormularComponent } from 'app/control/control-form/control-form.component';
import { ControlCardCreator } from '../control-card-creator';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { BaseFilterData } from 'app/shared/components/filter/filter';

@Component({
  selector: 'epo-control-planning',
  templateUrl: './control-planning.component.html',
  styleUrls: ['./control-planning.component.scss']
})
export class ControlPlanningComponent implements OnInit, OnDestroy {
  public filterSettings = new FilterSettings(false, true, false, false, false);
  public karten: Karte[];
  public selectedIntervalType: EInterval;
  private _currentFilter: BaseFilterData;
  private filterSubscription: Subscription;

  constructor(
    private _controlSampleService: ControlService,
    private _changeDetector: ChangeDetectorRef,
    private _filterService: FilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.filterSubscription = this._filterService.getFilter().subscribe((filter) => {
      this.filterChanged(filter as BaseFilterData);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public filterChanged(filter: BaseFilterData) {
    this._currentFilter = filter;
    this.selectedIntervalType = this._currentFilter.selectedIntervalType;
    this.loadData(false);
  }

  public addKontrolle(kontrollartId: string) {
    const modalRef = this.modalService.open(KontrollenFormularComponent, { size: 'lg' });
    const filterAdjustedByType = this._currentFilter;
    filterAdjustedByType.selectedCheckType.id = kontrollartId;
    modalRef.componentInstance.filterData = filterAdjustedByType;
    modalRef.result.then(() => {
      this.loadData(true);
    },
      () => { });
  }

  public editTargets() {
    this.router.navigate(['../control-targets'], { relativeTo: this.activatedRoute });
  }

  private loadData(forceReload: boolean, manualRunChangeDetection?: boolean) {
    if (forceReload) {
      this._controlSampleService.cleanCache();
    }

    forkJoin([
      this._controlSampleService.getTypes(),
      this._controlSampleService.getTargetsBy(this._currentFilter.selectedTrade.id,
        this._currentFilter.selectedContractPosition.id),
      this._controlSampleService.getKontrollen({
        selectedDateRange: this._currentFilter.selectedDateRange,
        contractPositionId: this._currentFilter.selectedContractPosition.id,
        tradeId: this._currentFilter.selectedTrade.id,
        userId: this._currentFilter.selectedUser.id
      }),
    ]).subscribe(x => {
      const kontrollArten = x[0].filter(x => x.isActive === true);
      const targets = x[1];
      const kontrollen = x[2];

      const cardCreator = new ControlCardCreator(kontrollArten, targets, kontrollen.items, this.selectedIntervalType);
      this.karten = cardCreator.createCards();
      if (manualRunChangeDetection) {
        this._changeDetector.detectChanges();
      }
    });
  }
}
