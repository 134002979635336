import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HeaderColumn } from 'app/shared/models/header-column';
import { EpoHelper } from 'app/shared/epo-helper';
import { RandomSampleViewModel } from '../shared/random-sample';
import { Subscription } from 'rxjs';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { allId, BaseFilterData } from 'app/shared/components/filter/filter';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { Rating } from 'app/shared/models/rating';
import { StatusPhase } from 'app/shared/models/status-phase';

@Component({
  selector: 'epo-stichproben-table',
  templateUrl: './stichproben-table.component.html',
  styleUrls: ['./stichproben-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StichprobenTableComponent implements OnInit, OnDestroy {
  public showGewerk = false;
  public showAllStichprobenarten = false;
  public showContractPositionColumn = false;

  @Input() headerColumns: HeaderColumn[];
  @Input() stichproben: RandomSampleViewModel[];
  @Input() isLoading: boolean;

  @Output() onStichprobeClicked: EventEmitter<string> = new EventEmitter();
  @Output() onVersionClicked: EventEmitter<string> = new EventEmitter();
  closeResult: string;
  private filterSubscription: Subscription;

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.filterSubscription = this.filterService.getFilter().subscribe((filter) => {
      this.handleFilter(filter as BaseFilterData);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  public groupByRandomGroup(currentHeaderColumns: HeaderColumn[]) {
    return EpoHelper.groupBy(currentHeaderColumns, c => c.inhaltsgruppe);
  }


  /**
   * Finde anhand von ergebnisCluster den entsprechenden Cluster in Stichprobe
   * @param stichprobe Entspricht Reihe.
   * @param column Entspricht der Spalte.
   */
  public getCssByLCluster(randomSample: RandomSampleViewModel, column: HeaderColumn) {
    if (!randomSample && !randomSample.deficitValues) {
      return;
    }
    // Finde das erste Element anhand der Stichprobenart und ContractPosition.
    const selectedDeficit = randomSample.deficitValues.find((lc) => {
      return column.deficitId == lc.deficitId;
    });

    if (!selectedDeficit) {
      return;
    }

    if (!selectedDeficit.defizitExists) {
      return 'fa-times';
    }

    if (selectedDeficit.defizitExists) {
      return 'fa-check';
    }
  }

  private handleFilter(value: BaseFilterData) {
    // Prüfen ob 'Alle' bei Gewerk ausgewählt worden ist (entsprechend wir Spalte angezeigt);
    this.showGewerk = value.selectedTrade.id === allId;
    // Prüfen ob 'Alle' bei Stichprobenart ausgewählt worden ist (entsprechend werden Spalten ausgeblendet);
    this.showAllStichprobenarten = value.selectedCheckType.id === allId;
    this.showContractPositionColumn = value.selectedContractPosition.id === allId;
  }

  public getCssForDefizit(spotCheck: RandomSampleViewModel) {
    if (spotCheck.rating === Rating.NoClaim || spotCheck.statusPhaseThree === StatusPhase.ClaimCancelled) {
      return 'fa-minus';
    }
    if (spotCheck.deficitFixed === DeficitFixed.Ja) {
      return 'fa-thumbs-up';
    }
    if (spotCheck.deficitFixed === DeficitFixed.Nein) {
      return 'fa-thumbs-down';
    }
    if (spotCheck.deficitFixed === DeficitFixed.ZurKlaerung) {
      return 'fa-question';
    }
  }
}
