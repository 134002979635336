import { DeficitMappingsSortingMapper } from 'app/shared/helper/deficit-mappins-sorting-helper';
import { DeficitVariant } from '../deficit-variant';
import { DeficitTag } from './deficit-tag.resource';
import { DeficitTranslation } from './deficit-translation.resource';
import { Deficit } from './deficit.resource';
import { DeficitMappingBase } from './deficitMapping';

export class DeficitModel {
    constructor(deficit: Deficit, languageId: string) {
        this.id = deficit.id;
        this.translations = deficit.translations;
        this.deficitGroupId = deficit.deficitGroupId;
        this.deficitGroupName = deficit.deficitGroupName;
        this.title = deficit.title;
        this.titleDefault = deficit.titleDefault;
        this.description = deficit.description;
        this.descriptionDefault = deficit.descriptionDefault;
        this.isControlDeficit = deficit.isControlDeficit;
        this.isSpotCheckDeficit = deficit.isSpotCheckDeficit;
        this.isActive = deficit.isActive;
        this.entityNumber = deficit.entityNumber;
        this.tags = deficit.tags?.filter(d => d.isActive);

        if (this.isControlDeficit) {
            this.commonMappings = deficit.mappings.filter(m => m.deficitVariant === DeficitVariant.Control).map(m =>
                new DeficitMappingBase(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition));
        } else {
            this.commonMappings = deficit.mappings.filter(m => m.deficitVariant === DeficitVariant.SpotCheck).map(m =>
                new DeficitMappingBase(m.id, m.deficitId, m.checkTypeId, m.checkType, m.contractPositionId, m.contractPosition));
        }
        this.commonMappings = DeficitMappingsSortingMapper.sortMappings(this.commonMappings, languageId);
    }

    id: string;
    commonMappings: DeficitMappingBase[] = [];
    translations: DeficitTranslation[] = [];
    tags: DeficitTag[] = [];
    deficitGroupId: string;
    deficitGroupName: string;
    title: string;
    titleDefault: string;
    description: string;
    descriptionDefault: string;
    isControlDeficit: boolean;
    isSpotCheckDeficit: boolean;
    isActive: boolean = true;
    entityNumber: number;
}