import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Contract } from 'app/shared/masterData/contract/contract.resource';
import { Language } from 'app/shared/masterData/language/language.resource';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';
import { LanguageService } from 'app/shared/masterData/language/language.service';

@Component({
  templateUrl: './modal-edit-contract.component.html',
  styleUrls: ['./modal-edit-contract.component.scss']
})
export class ModalEditContractComponent {
  public inNewMode: boolean;
  public contract: Contract;
  private isActive: boolean = false;
  selectionLanguages: Language[] = [{
    id: null,
    shortName: 'EN',
    longName: 'English'
  }];
  languages: Language[] = [];
  translations: TranslationViewModel[];

  constructor(public activeModal: NgbActiveModal, private languageService: LanguageService) { }

  ngOnInit() {
    const entityTranslations = this.contract !== undefined ? this.contract.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.selectionLanguages = [...this.selectionLanguages, ...langs];
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = entityTranslations.find(t => t.language.id === lang.id);
        if (translation) {
          return new TranslationViewModel(translation, lang);
        } else {
          return new TranslationViewModel({
            language: lang,
            languageId: lang.id,
            name: ''
          }, lang);
        }
      });
    });
  }

  formatContractName(contractName: string) {
    if (contractName !== undefined && contractName !== null && this.isActive === false) {
      return this.contract.urlPath = contractName.split(' ').join('');
    } else {
      return this.contract.urlPath;
    }
  }

  isFieldActive() {
    this.isActive = true;
    return null;
  }

  setLanguageId(id: string) {
    this.contract.contractLanguageId = id;
  }

  canSave() {
    return this.contract && this.contract.name && this.contract.urlPath && this.translationsValid();
  }

  private translationsValid() {
    return this.translations && !this.translations.find(t => !t.isValid);
  }

  save() {
    this.contract.translations = this.translations.filter(t => t.name).map(t => t.toResource());
    this.activeModal.close(this.contract);
  }
}
