<section class="filter">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="pull-right">
          <div class="filter-section">
            <div class="heading">{{ 'filter.period' | translate }}</div>
            <div class="btn-group" role="group" aria-label="timeframe">
              <button type="button" class="btn btn-sm btn-light"
                [class.active]="currentTimeframe == timeframeEnumForTemplate.month" (click)="selectByMonth()">{{
                'filter.month' | translate }}</button>
              <button type="button" class="btn btn-sm btn-light"
                [class.active]="currentTimeframe == timeframeEnumForTemplate.quarter" (click)="selectByQuarter()">{{
                'filter.quarter' | translate }}</button>
              <button type="button" class="btn btn-sm btn-light"
                [class.active]="currentTimeframe == timeframeEnumForTemplate.year" (click)="selectByYear()">{{
                'filter.year' | translate }}</button>
            </div>

            <div ngbDropdown class="d-inline-block" *ngIf="currentTimeframe == timeframeEnumForTemplate.month">
              <button class="btn btn-sm btn-outline-light" id="ddMonths" ngbDropdownToggle>{{ selectedMonth?.value |
                translate}}</button>
              <div ngbDropdownMenu aria-labelledby="ddMonths">
                <button *ngFor="let month of months" (click)="changeMonth(month)" class="dropdown-item">{{ month.value |
                  translate}}</button>
              </div>
            </div>

            <div ngbDropdown class="d-inline-block" *ngIf="currentTimeframe == timeframeEnumForTemplate.quarter">
              <button class="btn btn-sm btn-outline-light" id="ddQuarters" ngbDropdownToggle>{{ selectedQuarter?.value |
                translate }}</button>
              <div ngbDropdownMenu aria-labelledby="ddQuarters">
                <button *ngFor="let quarter of quarters" (click)="changeQuarter(quarter)" class="dropdown-item">{{
                  quarter.value | translate }}</button>
              </div>
            </div>

            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-sm btn-outline-light" id="ddYears" ngbDropdownToggle>{{selectedYear}}</button>
              <div ngbDropdownMenu aria-labelledby="ddYears">
                <button *ngFor="let year of years" (click)="changeYear(year)" class="dropdown-item">{{year}}</button>
              </div>
            </div>
          </div>

          <div class="filter-section" *ngIf="settings.showContractPosition">
            <div class="heading">{{ 'filter.contract-position' | translate }}</div>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-sm btn-outline-light filter-toggle" id="ddContractPosition"
                ngbDropdownToggle>{{ellipsisString(filter.selectedContractPosition?.contractPosition.name)}}</button>
              <div ngbDropdownMenu aria-labelledby="ddContractPosition">
                <button *ngFor="let contractPosition of contractPositionConfigurations"
                  (click)="changeContractPosition(contractPosition)"
                  class="dropdown-item">{{contractPosition.contractPosition.name}}</button>
              </div>
            </div>
          </div>

          <div class="filter-section">
            <div class="heading">{{ 'filter.trade' | translate }}</div>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-sm btn-outline-light filter-toggle" id="ddTargets"
                ngbDropdownToggle>{{filter.selectedTrade?.trade.name}}</button>
              <div ngbDropdownMenu aria-labelledby="ddTargets">
                <button *ngFor="let trade of tradeConfigurations" (click)="changeTrade(trade)"
                  class="dropdown-item">{{trade.trade.name}}</button>
              </div>
            </div>
          </div>

          <div class="filter-section" *ngIf="settings.showArt">
            <div class="heading">{{ 'filter.type' | translate }}</div>
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button class="btn btn-sm btn-outline-light filter-toggle" id="ddStichprobenart"
                ngbDropdownToggle>{{filter.selectedCheckType?.checkType.name}}</button>
              <div ngbDropdownMenu aria-labelledby="ddStichprobenart">
                <button *ngFor="let checkType of checkTypeConfigurations"
                  (click)="changeStichprobenartSelection(checkType)"
                  class="dropdown-item">{{checkType.checkType.name}}</button>
              </div>
            </div>
          </div>

          <div class="filter-section" *ngIf="settings.showUser">
            <div class="heading">{{ 'filter.user' | translate }}</div>
            <div ngbDropdown placement="bottom-right" class="d-inline-block dropdown-left">
              <button class="btn btn-sm btn-outline-light filter-toggle" id="ddBenutzer"
                ngbDropdownToggle>{{filter.selectedUser?.name}}</button>
              <div ngbDropdownMenu aria-labelledby="ddBenutzer" class="dropdown-area">
                <button *ngFor="let user of users" (click)="changeUserSelection(user)"
                  class="dropdown-item">{{user.name}}
                  <span class="role" *ngIf="user.role != null">{{user.role | contractRoleToString | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>