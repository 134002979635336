import { Component, OnInit, OnDestroy } from '@angular/core';
import { CheckTypeConfigurationService } from 'app/shared/masterData/check-type/configuration/check-type-configuration.service';
import { CheckTypeService } from 'app/shared/masterData/check-type/check-type.service';
import { CheckTypeViewModel } from './check-type-view-model';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { CheckTypeLoadFilter } from 'app/admin/allgemein/check-types/check-type-load-filter';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { take } from 'rxjs/operators';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { ConfigHistoryHelper } from 'app/admin/shared/config-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epo-check-type-configuration',
  templateUrl: './check-type-configuration.component.html',
  styleUrls: ['./check-type-configuration.component.scss']
})
export class CheckTypeConfigurationsComponent implements OnInit, OnDestroy {

  public pagingGlobalCount = 0;
  public pageSize: number;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadCheckTypes();
  }
  get page() {
    return this.currentPage;
  }

  private subscription: Subscription;
  public isLoading = false;
  public isSaving = false;
  public checkTypes: CheckTypeViewModel[] = [];
  public checkTypeConfigs: CheckTypeConfiguration[];
  public loadFilter: CheckTypeLoadFilter = new CheckTypeLoadFilter();
  public boolSelection = [undefined, true, false];

  constructor(
    private _checkTypeService: CheckTypeService,
    private _checkTypeConfigService: CheckTypeConfigurationService,
    private contractService: ContractService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.pageSize = environment.pageSize;
    this.getData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onFilterChange() {
    this.currentPage = 1;
    this.loadData();
  }

  isControlFilterChanged(value) {
    this.loadFilter.isControl = value;
    this.onFilterChange();
  }

  isSpotCheckFilterChanged(value) {
    this.loadFilter.isSpotCheck = value;
    this.onFilterChange();
  }

  isSelectedFilterChanged(value) {
    this.loadFilter.isSelected = value;
    this.onFilterChange();
  }

  isActiveFilterChanged(value) {
    this.loadFilter.configurationActive = value;
    this.onFilterChange();
  }

  public getData() {
    const $configs = this._checkTypeConfigService.getCheckTypeConfigurationsForCurrentContract();

    this.subscription = $configs.subscribe(configs => {
      this.checkTypeConfigs = configs.items;
      this.loadData();
    });
  }

  private loadData() {
    this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
      this.loadFilter.contractId = contract.id;
      this.loadFilter.languageId = contract.contractLanguageId;
      this.loadCheckTypes();
    });
  }

  private loadCheckTypes() {
    this.isLoading = true;
    const $checkTypes = this._checkTypeService.getCheckTypes(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    $checkTypes.subscribe(checkTypes => {
      this.pagingGlobalCount = checkTypes.count;
      this.checkTypes = checkTypes.items.map(d => {
        const config = this.checkTypeConfigs.find(g => g.checkTypeId === d.id);
        return {
          id: d.id,
          name: d.name,
          isSelected: (config && config.isActive) ? true : false,
          checkTypeConfigurationId: config ? config.id : null,
          isSpotCheck: d.isSpotCheck,
          isControl: d.isControl,
          isActive: config ? config.isActive : d.isActive
        };
      });
      this.isLoading = false;
    });
  }

  saveConfiguration(checkType: CheckTypeViewModel) {
    if (checkType.isSelected) {
      this.isSaving = true;
      this._checkTypeConfigService.createCheckTypeConfiguration(checkType.id).subscribe((r) => {
        const cPos = this.checkTypes?.find(c => c.id === r.checkTypeId);
        if (cPos) {
          cPos.checkTypeConfigurationId = r.id;
        }
        this._checkTypeConfigService.getCheckTypeConfigurationsForCurrentContract().subscribe((res) => {
          this.checkTypeConfigs = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err)
        this.isSaving = false;
      })
    } else {
      this.isSaving = true;
      this._checkTypeConfigService.deleteCheckTypeConfiguration(checkType.checkTypeConfigurationId).subscribe(() => {
        this._checkTypeConfigService.getCheckTypeConfigurationsForCurrentContract().subscribe((res) => {
          this.checkTypeConfigs = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err)
        this.isSaving = false;
      })
    }
  }

  setIsActive(checkType: CheckTypeViewModel) {
    if (!checkType.isSelected) {
      return;
    }
    this.isSaving = true;
    this._checkTypeConfigService.setCheckTypeConfigurationActive(checkType.checkTypeConfigurationId, checkType.isActive).subscribe(() => {
      this.isSaving = false;
    }, err => {
      console.log(err)
      this.isSaving = false;
    })
  }

  public showHistory(position: CheckTypeViewModel) {
    this._checkTypeConfigService.getCheckTypeConfigurationHistory(position.checkTypeConfigurationId).subscribe(history => {
      const config: PropertyDisplayMapping[] = [
        { propertyName: 'isActive', displayName: 'shared.active', type: 'boolean' }
      ]
      const changeHistory = new ConfigHistoryHelper(history, config).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: position?.name };
    });
  }
}
