import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { UserRoleService } from '../services/user-role.service';
import { ContractUserRole } from '../masterData/user/contract-user-role';
import { Subscription } from 'rxjs';


@Directive({
    selector: '[hideIfMissingRole]'
  })
  export class HideIfMissingRoleDirective implements OnInit, OnDestroy{

    private hasRoleSubscription: Subscription;
    private renderingContent: boolean;

    ngOnInit(): void {
        this.hasRoleSubscription = this.userRoleService.hasRequiredRole(this.requiredRoles).subscribe(
            hasRole =>{
                if(hasRole){
                    if(!this.renderingContent){
                        this.viewContainer.createEmbeddedView(this.templateRef);
                        this.renderingContent = true;
                    }
                } else {
                    this.renderingContent = false;
                    this.viewContainer.clear();
                }
            }
        )
    }

    ngOnDestroy(): void {
        this.hasRoleSubscription.unsubscribe();
    }

    private requiredRoles : ContractUserRole[];
    @Input() set hideIfMissingRole( requiredRoles: ContractUserRole[]){
        this.requiredRoles = requiredRoles;
    }


    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef, private userRoleService: UserRoleService) {}
  
    }