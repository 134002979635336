import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ControlTarget } from 'app/shared/models/control-target';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';

@Component({
  selector: 'epo-modal-control-target-edit',
  templateUrl: './modal-control-target-edit.component.html',
  styleUrls: ['./modal-control-target-edit.component.scss'],
})
export class ModalControlTargetsEditComponent {
  public target: ControlTarget;
  public inNewMode: boolean;
  public inEditMode: boolean;
  public tradeConfigs: TradeConfiguration[];
  public checkTypeConfigs: CheckTypeConfiguration[];
  public contractPositionConfigs: ContractPositionConfiguration[];
  public intervalTypes;
  constructor(public activeModal: NgbActiveModal) {}

 
  formIsFilled() {
    return (
      this.target.amount !== undefined &&
      this.target.interval !== undefined &&
      this.target.contractPositionConfigurationId !== undefined &&
      this.target.checkTypeConfigurationId !== undefined &&
      this.target.tradeConfigurationId !== undefined
    );
  }
}
