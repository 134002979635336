import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay, take, mergeMap } from 'rxjs/operators';
import { BaseSampleService } from 'app/shared/base-sample-service';
import { environment } from 'environments/environment';
import { AcceptanceResource } from './shared/acceptance-resource';
import { TargetResponse } from 'app/random-sample/shared/target-response';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';


@Injectable()
export class AcceptanceService extends BaseSampleService {
    constructor(private _http: HttpClient, private contractService: ContractService) { super(); }

    public getAbnahmen(dateRange: { from: Date, to: Date }, contractPositionConfigurationId?: string,
        tradeConfigurationId?: string, userId?: string, skipNr?: number, takeNr?: number) {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                let url = `${environment.apiUrl}/api/${contract.id}/acceptances?skip=${skipNr > 0 ? skipNr : 0}`;
                if (takeNr) {
                    url = `${url}&take=${takeNr}`;
                }
                if (dateRange) {
                    if (dateRange.from) {
                        url = `${url}&from=${dateRange.from.toISOString()}`;
                    }
                    if (dateRange.to) {
                        url = `${url}&until=${dateRange.to.toISOString()}`;
                    }
                }
                if (contractPositionConfigurationId && contractPositionConfigurationId !== '-1') {
                    url = `${url}&contractPositionConfigurationId=${contractPositionConfigurationId}`;
                }
                if (tradeConfigurationId && tradeConfigurationId !== '-1') {
                    url = `${url}&tradeConfigurationId=${tradeConfigurationId}`;
                }
                if (userId && userId !== '-1') {
                    url = `${url}&userId=${userId}`;
                }
                return this._http.get<PagedResultResponse<AcceptanceResource>>(url);
            }));
    }

    public getAcceptanceHistory(id: string) {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/acceptances/${id}/history`;
                return this._http.get<AcceptanceResource[]>(url);
            }));
    }

    public getTradeConfigurations() {
        if (this.cachedTradeConfigurations$) {
            return this.cachedTradeConfigurations$;
        }
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                let url = `${environment.apiUrl}/api/${contract.id}/tradeconfigurations`;
                if (contract.contractLanguageId) {
                    url = `${url}?languageId=${contract.contractLanguageId}`;
                }
                this.cachedTradeConfigurations$ = this._http.get<PagedResultResponse<TradeConfigurationResponse>>(url)
                    .pipe(shareReplay(),
                        map(res => res.items));
                return this.cachedTradeConfigurations$;
            }));
    }

    public getTargets(filterByTradeId?: string): Observable<TargetResponse[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/acceptances/targets`;
                return this._http.get<PagedResultResponse<TargetResponse>>(url)
                    .pipe(
                        map(targets =>
                            targets.items.filter(target => {
                                return this.isTradeMatching(target.tradeConfigurationId, filterByTradeId);
                            })
                        ));
            }));
    }

    public getById(id: string) {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/acceptances/${id}`;
                return this._http.get<AcceptanceResource>(url);
            })
        );
    }

    public cleanCache() {
        this.cachedTradeConfigurations$ = undefined;
    }

    private isInRange(startDateTime: string, endDateTime: string, filterByDateRange: { from: Date; to: Date; }): any {
        if (!filterByDateRange) {
            return true;
        }
        const parsedStartDateTime = new Date(startDateTime + 'Z');
        const parsedEndDateTime = new Date(endDateTime + 'Z');
        return parsedStartDateTime >= filterByDateRange.from && parsedEndDateTime <= filterByDateRange.to;
    }


    private cachedTradeConfigurations$: Observable<Array<TradeConfigurationResponse>>;
}
