import Dexie from 'dexie';
import { ConnectionData } from './connection-data';
import { ControlFullDto } from './control-full-dto';
import { SpotCheckFullDto } from './spotcheck-full-dto';

export class LocalCacheDb extends Dexie {
    controls: Dexie.Table<ConnectionData<ControlFullDto>, string>;
    spotChecks: Dexie.Table<ConnectionData<SpotCheckFullDto>, string>;
    controlComments: Dexie.Table<ConnectionData<{comment: string, parentId: string}>, string>;
    spotCheckComments: Dexie.Table<ConnectionData<{comment: string, parentId: string}>, string>;
    constructor() {
      super('ccpCacheDb');
      this.version(1).stores({
        controls: 'id',
        spotChecks: 'id',
        controlComments: 'id',
        spotCheckComments: 'id'
      });
    }
  }
