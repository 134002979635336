import { Injectable } from '@angular/core';
import { AcceptancePOSTRequest } from './acceptance-post-request';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { take, mergeMap } from 'rxjs/operators';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { Observable } from 'rxjs';
import { AcceptanceResource } from './acceptance-resource';

@Injectable({
  providedIn: 'root'
})
export class AbnahmenFormularService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public createAcceptance(abnahmeFormData: AcceptancePOSTRequest) : Observable<AcceptanceResource> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances`);
        return this._httpClient.post<AcceptanceResource>(url, abnahmeFormData);
      }));
  }

  public updateAcceptance(abnahmeFormData: AcceptancePOSTRequest, id: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/${id}`);
        return this._httpClient.put(url, abnahmeFormData);
      }));
  }
  
  public withdrawAcceptance(abnahmeFormData: AcceptancePOSTRequest, id: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/${id}/toggleWithdraw`);
        return this._httpClient.put(url, abnahmeFormData);
      }));
  }

  public getAcceptanceById(id: string) : Observable<AcceptanceResource>{
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/${id}`);
        return this._httpClient.get<AcceptanceResource>(url);
      }));
  }

  public createAcceptanceComment(comment: string, acceptanceId: string){
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) =>{
        const url = this.createEpoUrl(`api/${contract.id}/acceptances/${acceptanceId}/comments`);
        return this._httpClient.post(url, {comment});
      })
    )
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}
