import { Component, OnDestroy } from '@angular/core';
import { DeficitService } from 'app/shared/masterData/deficit/deficit.service';
import { DeficitViewModel } from './deficit-view-model';
import { DeficitConfigurationService } from 'app/shared/masterData/deficit/configuration/deficit-configuration.service';
import { DeficitConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-configuration.resource';
import { forkJoin, Subscription } from 'rxjs';
import { Deficit } from 'app/shared/masterData/deficit/deficit.resource';
import { DeficitLoadFilter } from 'app/admin/allgemein/deficites/deficit-load-filter';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { take } from 'rxjs/operators';
import { OrderBy, OrderDirection } from 'app/admin/allgemein/shared/order-by';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { CheckTypeConfigurationService } from 'app/shared/masterData/check-type/configuration/check-type-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { ConfigHistoryHelper } from 'app/admin/shared/config-history.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';

@Component({
  selector: 'epo-deficit-configuration',
  templateUrl: './deficit-configuration.component.html',
  styleUrls: ['./deficit-configuration.component.scss']
})
export class DeficiteConfigurationsComponent extends BaseFilterPagingComponent<DeficitConfiguration> implements OnDestroy {

  public sorting: { column: 'DeficitGroup' | 'Name' | 'Number', direction: OrderDirection } = { column: 'DeficitGroup', direction: OrderDirection.Asc };

  public descriptionConfig = {
    placeholder: '',
    tabsize: 2,
    height: 150,
    toolbar: [
      ['misc', ['undo', 'redo', 'codeview']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['insert', ['picture', 'link', 'hr']]
    ]
  }
  public isEditing = false;

  private subscription: Subscription;
  public isSaving = false;
  public deficits: DeficitViewModel[] = [];
  public loadFilter: DeficitLoadFilter = new DeficitLoadFilter();
  public boolSelection = [undefined, true, false];
  private _contractPositions: ContractPositionConfiguration[] = [];
  private _checkTypes: CheckTypeConfiguration[] = [];

  constructor(
    private _deficitService: DeficitService,
    private _deficitConfigurationService: DeficitConfigurationService,
    private _contractPositionConfigService: ContractPositionConfigurationService,
    private _checkTypeConfigService: CheckTypeConfigurationService,
    private contractService: ContractService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _modalService: NgbModal
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isControlFilterChanged(value) {
    this.loadFilter.isControl = value;
    this.onFilterChange();
  }

  isSpotCheckFilterChanged(value) {
    this.loadFilter.isSpotCheck = value;
    this.onFilterChange();
  }

  isSelectedFilterChanged(value) {
    this.loadFilter.isSelected = value;
    this.onFilterChange();
  }

  sort(column: 'DeficitGroup' | 'Name' | 'Number') {
    if (column === this.sorting.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  isActiveSorting(column: 'DeficitGroup' | 'Name' | 'Number', direction: 'Asc' | 'Desc') {
    return this.sorting.column == column && this.sorting.direction == OrderDirection[direction];
  }

  isActiveConfigSelected(value) {
    this.loadFilter.configurationActive = value;
    this.onFilterChange();
  }

  public loadData() {
    const $configs = this._deficitConfigurationService.getDeficitConfigurationsForCurrentContract();
    const $contractPositions = this._contractPositionConfigService.getContractPositionConfigurationsForCurrentContract(true);
    const $checkTypes = this._checkTypeConfigService.getCheckTypeConfigurationsForCurrentContract(true);
    this.subscription = forkJoin([$configs, $contractPositions, $checkTypes]).subscribe(([configs, contractPostions, checkTpes]) => {
      this.items = [].concat.apply([], (configs.items.map(g => g.deficitConfigurations)));
      this._contractPositions = contractPostions.items;
      this._checkTypes = checkTpes.items;
      this.loadDeficits();
    });
  }

  private loadDeficits() {
    this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
      this.loadFilter.contractId = contract.id;
      this.loadFilter.languageId = contract.contractLanguageId;
      this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
      this.load();
    });
  }

  private load() {
    this.isLoading = true;
    const $deficits = this._deficitService.getDeficits(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    $deficits.subscribe(deficits => {
      this.pagingGlobalCount = deficits.count;
      this.deficits = deficits.items.map(d => {
        const config = this.items.find(g => g.deficit.id === d.id);
        return new DeficitViewModel(
          d.id,
          d.deficitGroupId,
          d.deficitGroupName,
          d.title,
          config ? config.deficit.description : d.description,
          d.description,
          (config && config.isActive) ? true : false,
          config ? config.isActive : d.isActive,
          config ? config.id : null,
          d.mappings.filter(m => this._contractPositions.find(cp => cp.contractPositionId === m.contractPositionId)
            && this._checkTypes.find(cp => cp.checkTypeId === m.checkTypeId))
            .map(ct => { return { checkType: ct.checkType.name, contractPosition: ct.contractPosition.name } }),
          d.isSpotCheckDeficit,
          d.isControlDeficit,
          d.entityNumber
        );
      }).filter(d => d.mappings.length > 0 || d.isSelected);
      this.isLoading = false;
    });
  }

  saveConfiguration(deficit: DeficitViewModel) {
    if (!deficit.isSelected && !!deficit.isEditing) {
      deficit.isSelected = true;
    }
    const description = (deficit.editedDescription === deficit.description) ? null : deficit.editedDescription;
    if (deficit.isSelected) {
      this.isSaving = true;
      if (deficit.deficitConfigurationId) {
        this._deficitConfigurationService.updateDeficitConfiguration(deficit.deficitConfigurationId, deficit.id, description).subscribe((res) => {
          deficit.deficitConfigurationId = res.id;
          deficit.description = res.deficit.description;
          deficit.editedDescription = res.deficit.description;
          this.isSaving = false;
          deficit.isEditing = false;
        }, err => {
          console.log(err);
          this.isSaving = false;
          deficit.isEditing = false;
        });
      } else {
        this.createConfig(deficit, description);
      }

    } else {
      this.deleteConfig(deficit);
    }
  }

  private createConfig(deficit: DeficitViewModel, description: string) {
    this._deficitConfigurationService.createDeficitConfiguration(deficit.id, description).subscribe((createdId) => {
      deficit.deficitConfigurationId = createdId;
      this._deficitConfigurationService.getDeficitConfigurationsForCurrentContract().subscribe(res => {
        this.items = [].concat.apply([], (res.items.map(g => g.deficitConfigurations)));
        if (deficit.isEditing) {
          deficit.editedDescription = deficit.description;
          deficit.isEditing = false;
        }
        this.isSaving = false;
      });
    }, err => {
      console.log(err);
      deficit.isSelected = false;
      this.isSaving = false;
    });
  }

  private deleteConfig(deficit: DeficitViewModel) {
    this.isSaving = true;
    this._deficitConfigurationService.deleteDeficitConfiguration(deficit.deficitConfigurationId).subscribe(() => {
      deficit.deficitConfigurationId = null;
      this._deficitConfigurationService.getDeficitConfigurationsForCurrentContract().subscribe(res => {
        this.items = [].concat.apply([], (res.items.map(g => g.deficitConfigurations)));
        this.isSaving = false;
      });
    }, err => {
      console.log(err);
      deficit.isSelected = true;
      this.isSaving = false;
    });
  }

  setIsActiveConfig(deficit: DeficitViewModel) {
    if (deficit.isSelected) {
      if (!deficit.deficitConfigurationId) {
        this.createConfig(deficit, null);
        return;
      }
      else {
        this.isSaving = true;
        this._deficitConfigurationService.setDeficitonfigurationActive(deficit.deficitConfigurationId, deficit.isSelected).subscribe(() => {
          this.isSaving = false;
        }, err => {
          console.log(err);
          this.isSaving = false;
        });
        return;
      }
    } else {
      this.deleteConfig(deficit);
    }
  }


  public showHistory(deficit: DeficitViewModel) {
    this._deficitConfigurationService.getDeficitConfigurationHistory(deficit.deficitConfigurationId).subscribe(history => {
      const config: PropertyDisplayMapping[] = [
        { propertyName: 'isActive', displayName: 'shared.active', type: 'boolean' },
        { propertyName: 'description', displayName: 'global-admin.deficits.description', type: 'code' },
      ]
      const changeHistory = new ConfigHistoryHelper(history, config).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: deficit?.title };
    });
  }
}
