<div class="modal-header">
  <h4 *ngIf="inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.contracts.dialog.title-new" | translate }}
  </h4>
  <h4 *ngIf="!inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.contracts.dialog.title-edit" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>{{ "shared.lang-default" | translate }}</a>
      <ng-template ngbNavContent>
        <div>
          <label class="label">{{"global-admin.contracts.dialog.name" | translate}}</label>
          <input class="form-control form-control-lg" type="text" [(ngModel)]="contract.name" required
          (keyup)="formatContractName(contract.name)"/>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem *ngFor="let trans of translations">
      <a ngbNavLink>{{ trans.language.longName }}</a>
      <ng-template ngbNavContent>
        <div>
          <label class="label">{{"global-admin.contracts.dialog.name" | translate}}</label>
          <input class="form-control form-control-lg" type="text" [(ngModel)]="trans.name" />
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
  <div class="mt-3">
    <label class="label">{{"global-admin.contracts.dialog.url" | translate}}</label>
    <input class="form-control form-control-lg" #url="ngModel" id="url" name="url" type="text"
      [(ngModel)]="contract.urlPath" required (click)="isFieldActive()" pattern="^[a-zA-Z0-9]+" />
  </div>
  <div *ngIf="contract.urlPath && url.invalid" class="card text-white bg-danger mt-2">
    {{ "global-admin.contracts.dialog.invalid-url" | translate }}
  </div>
  <div class="mt-3">
    <label class="label">{{
      "global-admin.contracts.dialog.orderer-email" | translate
      }}</label>
    <input class="form-control form-control-lg" #ordererEmail="ngModel" id="ordererEmail" name="ordererEmail"
      type="text" [(ngModel)]="contract.ordererEmail" />
  </div>
  <div class="mt-3">
    <label class="label">{{
      "global-admin.contracts.dialog.contractor-email" | translate
      }}</label>
    <input class="form-control form-control-lg" #contractorEmail="ngModel" id="contractorEmail" name="contractorEmail"
      type="text" [(ngModel)]="contract.contractorEmail" />
  </div>
  <div class="mt-3">
    <label class="label" for="name">{{
      "global-admin.contracts.dialog.language" | translate
      }}</label>
    <div ngbDropdown class="d-block dropdown">
      <button *ngIf="contract.contractLanguageId" class="btn btn-outline-primary dropdown-toggle drop-button"
        id="languageDropDown" ngbDropdownToggle>
        {{ (contract.contractLanguageId | resolveLanguage | async)?.longName }}
      </button>
      <button *ngIf="!contract.contractLanguageId" class="btn btn-outline-primary dropdown-toggle drop-button"
        id="languageDropDown" ngbDropdownToggle>
        English
      </button>
      <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="languageDropDown">
        <a *ngFor="let language of selectionLanguages" class="dropdown-item" (click)="setLanguageId(language.id)"
          ngbDropdownItem>{{ language.longName }}</a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">
    <i class="icon" class="fas fa-ban"></i>{{ "shared.dialog.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-dark" [disabled]="!canSave() || url.invalid" (click)="save()">
    <i class="icon" class="fas fa-save"></i>{{ "shared.dialog.save" | translate }}
  </button>
</div>