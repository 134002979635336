import { Injectable } from '@angular/core';
import { User } from './user';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from "@azure/msal-browser";
import { UserService } from 'app/admin/allgemein/users/user.service';
import { ReplaySubject, Observable } from 'rxjs';
import { UserResponse } from 'app/admin/allgemein/users/user-response';


@Injectable({ providedIn: 'root' })
export class UserProfileService {

  private _cachedUser: User;
  private _currentUserSubject = new ReplaySubject<User | 'Unauthorized'>(1);
  constructor(private msalService: MsalService, private userService: UserService) {  }

  public async login() {
    let account: AccountInfo;
    if (!this.msalService.instance.getActiveAccount()) {
      account = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(account);
    }
    if (this.msalService.instance.getActiveAccount()) {
      this.setPrimaryAtrributesOfCachedUser();
      this.userService.updateUserOnLogin().subscribe((user: UserResponse) => {
        this.setExtendedAtrributesOfCachedUser(user);
      }, err => {
        if (err.status == 401) {
          this._currentUserSubject.next('Unauthorized');
        }
      });
    }
  }

  private setExtendedAtrributesOfCachedUser(user: UserResponse): void {
    this._cachedUser.id = user.id;
    this._cachedUser.role = user.role;
    this._cachedUser.contractUsers = user.contractUsers;
    this._currentUserSubject.next(this._cachedUser);
  }

  private setPrimaryAtrributesOfCachedUser(): void {
    this._cachedUser = {
      id: null,
      email: this.msalService.instance.getActiveAccount().idTokenClaims.email as string,
      objectId: this.msalService.instance.getActiveAccount().idTokenClaims.oid,
      firstname: this.msalService.instance.getActiveAccount().idTokenClaims.given_name as string,
      lastname: this.msalService.instance.getActiveAccount().idTokenClaims.family_name as string
    };
  }

  public get currentUser(): Observable<User | 'Unauthorized'> {
    return this._currentUserSubject.asObservable();
  }

  logout(popup: boolean = null) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  public get cachedUser() {
    return this._cachedUser;
  }
}
