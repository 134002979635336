import { Component, OnInit } from '@angular/core';
import { Language } from 'app/shared/masterData/language/language.resource';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { ModalLanguageEditComponent } from './modal-language-edit/modal-language-edit.component';

@Component({
  selector: 'epo-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public isLoading = false;
  languages: Language[];

  constructor(private languageService: LanguageService, private toasterService: ToasterService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loadData();
  }

  onFilterChange() {
    this.loadData();
  }

  private loadData() {
    this.isLoading = true;
    this.languageService.getLanguages().subscribe(res => {
      this.languages = res.items;
      this.isLoading = false;
    }, err => {
      console.log(err);
    });
  }

  public openCreateLanguageModal() {
    const language = new Language();
    const modalRef = this.modalService.open(ModalLanguageEditComponent, { centered: true });
    modalRef.componentInstance.language = language;
    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this.languageService.createLanguage(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openEditLanguageModal(language: Language) {
    const modalRef = this.modalService.open(ModalLanguageEditComponent, { centered: true });
    modalRef.componentInstance.language = Object.assign({}, language);
    modalRef.componentInstance.inNewMode = false;

    modalRef.result.then((result) => {
      this.languageService.updateLanguage(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openDeleteModal(language: Language) {
    const modalRef = this.modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = language;

    modalRef.result.then((language) => {
      this.languageService.deleteLanguage(language.id).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }
}
