import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserResponse } from './user-response';
import { UserService } from './user.service';
import { CreateUserResource } from 'app/shared/masterData/user/create-user-resource';
import { ModalUserEditComponent } from './modal-user-edit/modal-user-edit.component';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { UserLoadFilter } from './user-load-filter';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';

@Component({
  selector: 'epo-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseFilterPagingComponent<UserResponse> {
  public hasResponsibilities = false;
  public loadFilter: UserLoadFilter;
  public roleSelection: GlobalUserRole[] = [undefined, GlobalUserRole.Admin, GlobalUserRole.User, GlobalUserRole.GlobalAdmin];

  constructor(private _userService: UserService, private modalService: NgbModal, public golbalAutoCompleteService: GolbalAutoCompleteService) {
    super();
  }

  roleSelected(role) {
    this.loadFilter.role = role;
    this.onFilterChange();
  }

  loadData() {
    this.isLoading = true;
    this._userService.getUsers(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter).subscribe((users) => {
      this.items = users.items;
      this.pagingGlobalCount = users.count;
      this.isLoading = false;
    }, err => {
      console.log(err);
    });
  }
}

