import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'epo-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit, OnChanges {
  maxPagingElements: number = 7;

  amountPages: number;
  pages: any[] = [];

  constructor() { }

  @Input()
  globalCount: number;

  @Input()
  pageSize: number;

  @Input()
  currentPage: number;

  @Output()
  currentPageChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this._init();
  }

  ngOnChanges() {
    this._init();
  }

  _init() {
    this.amountPages = Math.ceil(this.globalCount / this.pageSize);

    this._updatePages();
  }

  _updatePages() {
    this.pages = [];
    let rollerSet: Boolean = false;
    for (var _i = 1; _i <= this.amountPages; _i++) {
      if (this.maxPagingElements < this.amountPages) {
        if (this.currentPage <= 3 || this.currentPage > this.amountPages - 3) {
          if (_i <= 4 || _i > this.amountPages - 4) {
            this.pages.push(_i);
          } else {
            if (!rollerSet) {
              this.pages.push('...');
              rollerSet = true;
            }
          }
        } else {
          if ((_i <= this.currentPage + 1 && _i >= this.currentPage - 1) || _i > this.amountPages - 1 || _i == 1) {
            this.pages.push(_i);
          } else {
            if (_i == this.currentPage + 2 || _i == 2) {
              this.pages.push('...');
            }
          }
        }
      } else {
        this.pages.push(_i);
      }
    }

    this.currentPageChange.emit(this.currentPage);
  }

  next() {
    this.currentPage++;
    this._updatePages();
  }

  prev() {
    this.currentPage--;
    this._updatePages();
  }

  setPage(page) {
    this.currentPage = page;
    this._updatePages();
  }
}
