import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';

import { MonitoringComponent } from './overview/monitoring.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { StichprobenTableComponent } from './stichproben-table/stichproben-table.component';
import { SpotCheckFormComponent } from './spot-check-form/spot-check-form.component';

// Services.
import { RandomSampleStatusCalculator } from 'app/random-sample/shared/random-sample-status-calculator';
import { FormsModule } from '@angular/forms';
import { SpotCheckEditEntryComponent } from './spot-check-edit-entry/spot-check-edit-entry.component';
import { RandomSampleTargetsComponent } from 'app/random-sample/random-sample-targets/random-sample-targets.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
    MonitoringComponent,
    SchedulingComponent,
    StichprobenTableComponent,
    SpotCheckFormComponent,
    SpotCheckEditEntryComponent,
    RandomSampleTargetsComponent
  ],
  providers: [
    RandomSampleStatusCalculator
  ],
  entryComponents: [
    SpotCheckFormComponent, 
  ],
})
export class SpotCheckModule { }
