import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { Language } from 'app/shared/masterData/language/language.resource';
import { Contract } from 'app/shared/masterData/contract/contract.resource';

export class ContractHistoryHelper {

    constructor(private _deficits: Contract[],
        private _languages: Language[]) {
    }

    private checkProperties: PropertyDisplayMapping[] = [
        { propertyName: 'name', displayName: 'shared.name' },
        { propertyName: 'urlPath', displayName: 'global-admin.contracts.url' },
        { propertyName: 'ordererEmail', displayName: 'global-admin.contracts.contractor-email' },
        { propertyName: 'contractorEmail', displayName: 'global-admin.contracts.orderer-email' },
        { propertyName: 'contractLanguageId', displayName: 'global-admin.contracts.dialog.language', displayValueTransformation: (id) => this.resolveLanguage(id)  },
    ]

    public generateHistoryEntries(): HistoryEntryModel[] {
        const historyResources = [new Contract(), ...this._deficits];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: Contract, newValues: Contract): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this.checkProperties) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                    changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if (changedProperty.newValue != null || changedProperty.oldValue != null) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }
        this.handeTranslations(newValues, oldValues, historyEntry);
       
        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }

    private handeTranslations(newValues: Contract, oldValues: Contract, historyEntry: HistoryEntryModel) {
        let changedTranslations = new ChangedProperty();
        changedTranslations.propertyName = 'shared.translations';
        for (const newTranslation of newValues.translations) {
            const oldTranslation = oldValues.translations && oldValues.translations.find(old => old.languageId == newTranslation.languageId);
            if (oldTranslation) {
                if (newTranslation.name != oldTranslation.name) {
                    changedTranslations.subProperties.push({
                        propertySubName: this.resolveLanguage(newTranslation.languageId),
                        propertyName: 'shared.name',
                        newValue: newTranslation.name,
                        oldValue: oldTranslation.name
                    });
                }
            } else {
                changedTranslations.subProperties.push({
                    propertySubName: this.resolveLanguage(newTranslation.languageId),
                    propertyName: 'shared.name',
                    newValue: newTranslation.name
                });
            }
        }
        const removedTranslations = oldValues.translations.filter(oldTrans => !newValues.translations.find(newTrans => newTrans.languageId == oldTrans.languageId));
        for (const trans of removedTranslations) {
            changedTranslations.subProperties.push({
                propertySubName: this.resolveLanguage(trans.languageId),
                propertyName: 'shared.name',
                oldValue: trans.name
            });
        }
        if (changedTranslations.subProperties.length > 0) {
            historyEntry.changedProperties.push(changedTranslations);
        }
    }

    private resolveLanguage(id: string): string {
        const lang = this._languages.find(p => p.id == id);
        return lang && lang.shortName || id;
    }

    private discardEmptyString(input: string): string {
        if (!this.isString(input)) return input;
        if (input.trim()) return input;
        return null;
    }

    private isString(value) {
        return typeof value === 'string';
    }
}

