import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContractService } from '../../contract/contract.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { CheckTypeConfiguration } from './check-type-configuration.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';

@Injectable({
    providedIn: 'root'
})

export class CheckTypeConfigurationService {

    constructor(private _http: HttpClient, private contractService: ContractService) {
    }

    getCheckTypeConfigurationsForCurrentContract(active?: boolean): Observable<PagedResultResponse<CheckTypeConfiguration>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                let url = `${environment.apiUrl}/api/${contract.id}/checktypeconfigurations`;
                if (contract.contractLanguageId) {
                    url = `${url}?languageId=${contract.contractLanguageId}`;
                }
                if (active != undefined) {
                    url = `${url}${url.indexOf('?') > -1 ? '&' : '?'}configurationActive=${active}`;
                }
                return this._http.get<PagedResultResponse<CheckTypeConfiguration>>(url)
            })
        );
    }

    createCheckTypeConfiguration(checkTypeId: string): Observable<CheckTypeConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/checktypeconfigurations`;
                return this._http.post<CheckTypeConfiguration>(url, { checkTypeId: checkTypeId, isActive: true });
            })
        );
    }

    setCheckTypeConfigurationActive(checkTypeConfigId: string, isActive: boolean): Observable<CheckTypeConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/checktypeconfigurations/${checkTypeConfigId}/active`;
                return this._http.patch<CheckTypeConfiguration>(url, { isActive });
            })
        );
    }

    deleteCheckTypeConfiguration(checkTypeConfigId: string): Observable<any> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/checktypeconfigurations/${checkTypeConfigId}`;
                return this._http.delete(url);
            })
        );
    }

    public getCheckTypeConfigurationHistory(id: string): Observable<CheckTypeConfiguration[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                return this._http.get<CheckTypeConfiguration[]>(`${environment.apiUrl}/api/${contract.id}/checktypeconfigurations/${id}/history`);
            }));
    }
}