import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: MsalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('openid-configuration') > -1 
    || request.url.indexOf('blob.core.windows.net') > -1
    || request.url.indexOf("/assets/i18n/en.json") > -1) {
      return next.handle(request);
    }
    let account: AccountInfo;
    if (!!this.authService.instance.getActiveAccount()) {
      this.authService.getLogger().verbose("Interceptor - active account selected");
      account = this.authService.instance.getActiveAccount();
    } else {
      this.authService.getLogger().verbose("Interceptor - no active account, fallback to first account");
      account = this.authService.instance.getAllAccounts()[0];
    }

    return this.authService.acquireTokenSilent({ account } as SilentRequest)
      .pipe(
        catchError((err) =>{
          console.error(err);
          return of(err);
        }),
        switchMap((result: AuthenticationResult) => {
          this.authService.getLogger().verbose("Interceptor - setting authorization headers");
          const headers = request.headers
            .set("Authorization", `Bearer ${result.idToken}`);
          const requestClone = request.clone({ headers });
          return next.handle(requestClone);
        })
      );
  }
}

export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
  deps: [MsalService]
};
