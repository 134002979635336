import { UserResponse } from "app/admin/allgemein/users/user-response";

export class DeficitGlobalProposalData{
    comment: string;
    createdBy: UserResponse;
    createdUtc: string;
    modifiedBy: UserResponse;
    modifiedUtc: string;
    descriptionProposal: string;
    id: string;
    previousDescription: string;
    status: DeficitGlobalProposalStatus;
    entityNumber?: number;
    Language: string;
}

export enum DeficitGlobalProposalStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2
}