<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<app-loading-spinner class="loading-spinner" *ngIf="isLoading"></app-loading-spinner>
<div class="modal-body" [ngClass]="{ transparent: isLoading == true }">
  <fieldset>
    <form novalidate (ngSubmit)="onSubmit()" #f="ngForm">
      <fieldset [disabled]="withdrawnSpotCheck">
        <div class="form-group row"  *ngIf="formData.ticketNumber && currentStatusPhase > 1">
          <label for="ticketNumber" class="col-sm-2 col-form-label">{{
            "spot-checks.form.archibus-link" | translate
            }}</label>
          <div class="col-xl-10 link">
            <a class="link" href="{{ ticketUrl }}" target="_blank">{{ "spot-checks.form.archibus-link" | translate }}
              {{ formData.ticketNumber }}</a>
          </div>
        </div>
  
        <div class="form-group row" *ngIf="spotCheckId">
          <label class="col-sm-2 col-form-label">{{
            "spot-checks.form.spot-check-link" | translate
            }}</label>
          <div class="col-xl-10 link">
            <a class="btn btn-secondary custom" (click)="copyLinkToClipboard()"
              ngbTooltip="{{ 'shared.link-copied' | translate }}" triggers="click:blur" placement="right">
              <i class="fas fa-clipboard"></i>
              {{ "shared.copy-link" | translate }}
            </a>
          </div>
        </div>
  
        <div class="form-group row" *ngIf="controlUrl">
          <label class="col-sm-2 col-form-label">{{
            "spot-checks.form.related-control" | translate
            }}</label>
          <div class="col-xl-10 link">
            <a href="{{ controlUrl }}">{{ "spot-checks.form.to-control" | translate }}
            </a>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "shared.contract-position" | translate
            }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="formData.contractPositionConfigurationId" #inputContract="ngModel" class="custom-select"
              name="dropDownContract" id="selectContractPosition" [disabled]="contractPositionDisabled"
              (change)="updateDefizite()" required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!formData.contractPositionConfigurationId"></option>
              <!---->
              <option *ngFor="let contractPosition of contractPositionConfigs" [ngValue]="contractPosition.id">
                {{ contractPosition.contractPosition.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputContract.invalid" class="alert alert-danger" role="alert">
              {{ "spot-checks-form.select-contract-position" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.type" | translate
            }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="formData.checkTypeConfigurationId" #inputSample="ngModel" class="custom-select"
              name="dropdownSample" id="selectStichprobenart" [disabled]="sampleTypeDisabled" (change)="updateDefizite()"
              required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!formData.checkTypeConfigurationId"></option>
              <!---->
              <option *ngFor="let checkType of checkTypeConfigs" [ngValue]="checkType.id">
                {{ checkType.checkType.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputSample.invalid" class="alert alert-danger" role="alert"></div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.trade" | translate
            }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="formData.tradeConfigurationId" #inputGewerk="ngModel" class="custom-select"
              name="dropdownGewerk" id="selectGewerke" [disabled]="tradeDisabled" required>
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!formData.tradeConfigurationId"></option>
              <!---->
              <option *ngFor="let tradeConfig of tradeConfigs" [ngValue]="tradeConfig.id">
                {{ tradeConfig.trade.name }}
              </option>
            </select>
            <div *ngIf="f.submitted && inputGewerk.invalid" class="alert alert-danger" role="alert">
              {{ "spot-checks.form.select-trade" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.ticket-nr" | translate
            }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="formData.ticketNumber" type="text" class="form-control" id="inputAnforderungen"
              [disabled]="requirementsDisabled" name="inAnforderungen" ngModel />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label"></label>
          <div class="col-xl-10">
            <textarea [(ngModel)]="formData.ticketDescription" name="inputDescription" class="form-control"
              id="inputDescription" [disabled]="descriptionDisabled" rows="3"></textarea>
            <p class="subtext">
              {{ "spot-checks.form.import-ticket" | translate }}
            </p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.tender-position" | translate
            }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="formData.tenderPosition" type="text" class="form-control" id="lvPos"
              [disabled]="lvPositionDisabled" name="lvPos" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.plant" | translate
            }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="formData.plant" type="text" class="form-control" id="InputAnlage"
              [disabled]="anlageDisabled" name="inputAnlage" />
            <p class="subtext"></p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.attachment-link" | translate
            }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="formData.attachementLink" type="text" class="form-control" name="textAnlageStandard"
              id="anlageStandard" [disabled]="anlageDefaultDisabled" />
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.room" | translate
            }}</label>
          <div class="col-xl-10">
            <input [(ngModel)]="formData.roomNumber" type="text" class="form-control" id="inputRaum"
              [disabled]="raumDisabled" name="Raum" />
            <p class="subtext">
              {{ "spot-checks.form.import-ticket" | translate }}
            </p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.deficits" | translate
            }}</label>
          <div class="col-xl-10">
            <epo-defizite (selectedDeficits)="onSelectedDefiziteChanged($event)" [disableDefizite]="defiziteDisabled"
              [deficitGroups]="filteredDeficitGroups" [hasDefizite]="deficiteExistInSelectedCombination">
            </epo-defizite>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label"></label>
          <div class="col-xl-10">
            <div *ngIf="f.submitted && noDefiziteSelected" class="alert alert-danger" role="alert">
              {{ "spot-checks.form.select-deficit" | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.deficit-note" | translate
            }}</label>
          <div class="col-xl-10">
            <textarea [(ngModel)]="formData.appendix" name="inputComment" class="form-control" id="textAreaAnmerkung"
              [disabled]="commentDisabled" rows="3"></textarea>
            <p class="subtext">
              {{ "spot-checks.form.deficit-subtext" | translate }}
            </p>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.state" | translate
            }}</label>
          <div class="col-xl-10">
            <select *ngIf="
                statusTransitionModel && statusTransitionModel.transitionModels
              " [(ngModel)]="selectedNewState" class="custom-select" name="selectedStatus" id="selectStatus"
              [disabled]="sampleStateDisabled">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!selectedNewState"></option>
              <!---->
              <option *ngFor="let status of statusTransitionModel.transitionModels" [ngValue]="status.value">
                {{ status.displayName | translate }}
              </option>
            </select>
            <select [disabled]="true" *ngIf="
                !statusTransitionModel || !statusTransitionModel.transitionModels
              " [(ngModel)]="currentState" class="custom-select" name="lastStatus">
              <option [ngValue]="currentState">
                {{ currentState | translate }}
              </option>
            </select>
          </div>
        </div>
        
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{ "spot-checks.form.dedicated-user" | translate}}</label>
          <div class="col-xl-10">
            <select #dedicatedUser [(ngModel)]="assignedUserGuid" class="custom-select" name="dedicated-user">
              <option *ngFor="let dedicatedUser of dedicatedUsers" [value]="dedicatedUser.id" [disabled]="!dedicatedUser.isActive">{{printNameOfContractUser(dedicatedUser)}}</option>
            </select>
          </div>
        </div>
  
        <fieldset class="form-group" [disabled]="sampleRatingDisabled">
          <div class="row">
            <legend class="col-form-label col-xl-2 pt-0">
              {{ "spot-checks.form.rating" | translate }}
            </legend>
            <div class="col-xl-10">
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="formData.rating" #inputRating="ngModel"
                  [required]="formData.statusPhaseOne != 1" [value]="rating.NoClaim" type="radio" name="rating"
                  id="radioNoClaim" [attr.disabled]="hasExistingDeficit" />
                <div class="radio-label">
                  <h6>
                    {{ "spot-checks.form.no-claim" | translate }}
                  </h6>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="formData.rating" #inputRating="ngModel"
                  [required]="formData.statusPhaseOne != 1" [value]="rating.Claim" type="radio" name="rating"
                  id="radioClaim" [attr.disabled]="hasNoDeficits" />
                <div class="radio-label">
                  <h6>
                    {{ "spot-checks.form.claim" | translate }}
                  </h6>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="formData.rating" [required]="formData.statusPhaseOne != 1"
                  #inputRating="ngModel" [value]="rating.Reclamation" type="radio" name="rating" id="radioReklamation"
                  value="reklamation" />
                <div class="radio-label">
                  <h6>
                    {{ "spot-checks.form.reclamation" | translate }}
                  </h6>
                </div>
              </div>
              <div *ngIf="f.submitted && inputRating.invalid" class="alert alert-danger" role="alert">
                {{ "spot-checks.form.please-rate" | translate }}
              </div>
              <div *ngIf="
                  f.submitted &&
                  formData.rating == 0 &&
                  formData.statusPhaseOne == 2
                " class="alert alert-danger" role="alert">
                {{ "spot-checks.form.please-rate" | translate }}
              </div>
            </div>
          </div>
        </fieldset>
  
        <div class="form-group row" *ngIf="formData?.comments?.length > 0">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.comments" | translate
            }}</label>
          <div class="col-xl-10">
            <epo-sample-comments [comments]="formData.comments"></epo-sample-comments>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.comment" | translate
            }}</label>
          <div class="col-xl-10">
            <textarea class="form-control" name="comment" rows="5" type="text" [(ngModel)]="comment">
            </textarea>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "files.attachments" | translate
            }}</label>
          <div class="col-xl-10 file-link">
            <p *ngFor="let attachment of formData.attachments">
              <a href="{{ attachment.url }}" target="_blank" download="{{ attachment.fileName }}">
                {{ attachment.fileName }} </a><i class="fa fa-times-circle" (click)="removeAttachment(attachment)"></i>
            </p>
            <p *ngFor="let file of filesToUpload">
              <span class="">{{ file.name }} ({{ (file.size / 1000000).toFixed(2) }} Mb)</span>
              <i class="fa fa-times-circle" (click)="removeFile(file)"></i>
            </p>
            <div *ngIf="fileError" class="alert alert-danger" role="alert">
              {{ "files.upload-error" | translate }}
            </div>
            <div class="progress form-group" *ngIf="progress > 0">
              <ngb-progressbar [type]="fileError ? 'danger' : 'primary'" [value]="progress" class="file-progress">
                <span>{{ progress }} %</span>
              </ngb-progressbar>
            </div>
            <div>
              <input type="file" accept=".jpg" id="spotCheckFileInput" #spotCheckFileInput
                (change)="selectFile($event.target.files)" multiple [hidden]="true" />
              <button class="btn btn-outline-dark" type="button" (click)="openFileSelect($event)">
                <i class="fas fa-paperclip"></i>
                {{ "files.upload-files" | translate }}
              </button>
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label">{{
            "spot-checks.form.deficit-fixed" | translate
            }}</label>
          <div class="col-xl-10">
            <select [(ngModel)]="formData.deficitFixed" #inputDeficite="ngModel" class="custom-select"
              name="dropdownDeficite" id="selectDeficite" [disabled]="fixedDisabled || withdrawnSpotCheck">
              <option *ngFor="let state of deficitFixedStatus" [ngValue]="state.value">
                {{ getDeficitStateName(state.displayName) }}
              </option>
            </select>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-xl-2 col-form-label"></label>
          <div class="col-xl-10">
            <div *ngIf="
                f.submitted &&
                (inputContract.invalid ||
                  noDefiziteSelected ||
                  inputGewerk.invalid ||
                  inputSample.invalid ||
                  inputRating.invalid)
              " class="alert alert-danger" role="alert">
              {{ "spot-checks.form.required-fields" | translate }}
            </div>
          </div>
        </div>
      </fieldset>

      <epo-created-modified [createdBy]="formData.createdBy" [modifiedBy]="formData.modifiedBy"
        [createdUtc]="formData.createdUtc" [modifiedUtc]="formData.modifiedUtc"></epo-created-modified>

      <div class="modal-footer">
        <button *ngIf="isUserGlobalAdmin && spotCheckId" type="button" class="btn btn-outline-dark" (click)="withdrawSpotCheck()" [disabled]="isSaving">
          <i *ngIf="!withdrawnSpotCheck" class="fas fa-trash"></i>
          <span *ngIf="!withdrawnSpotCheck">{{ "shared.dialog.withdraw" | translate }}</span>
          <i *ngIf="withdrawnSpotCheck" class="fas fa-trash-restore"></i>
          <span *ngIf="withdrawnSpotCheck">{{ "shared.dialog.restore" | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">
          <i class="icon" class="fas fa-ban"></i>
          {{ "shared.dialog.cancel" | translate }}
        </button>
        <button class="btn btn-dark" type="submit" [disabled]="isSaving">
          <i class="icon" class="fas fa-save"></i>
          {{ "shared.dialog.save" | translate }}
        </button>
      </div>
    </form>
  </fieldset>
</div>