<div *ngIf="!hasDefizite" class="form-group row">
  <div class="col-xl-10">
    {{ 'deficits-selection.no-deficits' | translate }}
  </div>
</div>

<div *ngIf="hasDefizite">
  <div class=" defizite-container" *ngFor="let group of deficitGroups">
    <div class="card bg-light mb-3 w-100">
      <div class="card-header text-center header">{{group.name}}</div>
      <div class="card-body p-0">
        <table class="table w-100 tablefix">
          <thead>
            <tr>
              <th width=50%>
              </th>
              <th>
                <div class="rotation-wrapper-inner">
                  <span class="rotated rotatedTitle1">{{ 'deficits-selection.no-deficit' | translate }}</span>
                </div>
              </th>
              <th>
                <div class="rotation-wrapper-inner">
                  <span class="rotated rotatedTitle2">{{ 'deficits-selection.deficit-exists' | translate }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tr class="deficit-row" *ngFor="let deficit of group.deficits">
            <td class="text-left" width="50%">
              <div class="tag-container">
                <span class="tag" *ngFor="let tag of deficit.tags">{{ tag.name }}</span>
              </div>
              <div class="title-container">
                <div class="ml-1 fa-border history-button" (click)="openEditShortcomingDialog(deficit)">
                  <i class="fa fa-edit icon-in-border icon-edit"></i>
                </div>
                <strong style="margin-left: 5px;">{{deficit.title}}</strong>
              </div>
              <div class="description-container" [innerHTML]="deficit.description">
              </div>
            </td>
            <td>
              <label class="checkbox-container">
                <input type="checkbox" [checked]="deficit.defizitExists === false" name="checkedNegative"
                  [disabled]="disableDefizite" (change)="onCheckNegative(deficit)">
                <span class="checkmark"></span>
              </label>
            </td>
            <td>
              <label class="checkbox-container">
                <input type="checkbox" [checked]="deficit.defizitExists" name="checkedPositive"
                  [disabled]="disableDefizite" (change)="onCheckPositive(deficit)">
                <span class="checkmark negative"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>