import { UserResponse } from "app/admin/allgemein/users/user-response";
import { ContractUserResource } from "app/core/contract-user.resource";

export class DedicatedUser {
    firstname:string = "";
    lastname:string = "";
    loginEmail:string = "";
    id:string = "";
    isActive:boolean = true;
    
    public toUserResponse():UserResponse {
        let tempUser = new UserResponse();
        tempUser.firstname = this.firstname;
        tempUser.lastname = this.lastname;
        tempUser.loginEmail = this.loginEmail;
        tempUser.id = this.id;
        return tempUser;
    }

    public fromContractUserResource(usr: ContractUserResource):void {
        this.firstname = usr.firstname;
        this.lastname = usr.lastname;
        this.loginEmail = usr.loginEmail;
        this.id = usr.userId;
        this.isActive = usr.isActive;
    }

    public fromUserResponse(usr: UserResponse):void {
        this.firstname = usr.firstname;
        this.lastname = usr.lastname;
        this.loginEmail = usr.loginEmail;
        this.id = usr.id;
    }
}