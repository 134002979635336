import { Component, OnInit, Input } from '@angular/core';
import { BaseFilterData, descriptionAll, SimpleUser, allId } from './filter';
import { FilterSettings } from './filter-settings';
import { DateRangeService } from './date-range.service';
import { UserProfileService } from 'app/core/user-profile.service';
import { DateRange } from '../../date-range';
import { EInterval } from '../../models/interval';
import { forkJoin } from 'rxjs';
import { FilterService } from './filter.service';
import { UserResponse } from 'app/admin/allgemein/users/user-response';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { CheckTypeConfigurationService } from 'app/shared/masterData/check-type/configuration/check-type-configuration.service';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfigurationService } from 'app/shared/masterData/trade/configuration/trade-configuration.service';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { TranslateService } from '@ngx-translate/core';
import { ContractUserService } from 'app/shared/masterData/user/contract-user.service';
import { ContractUserResource } from 'app/core/contract-user.resource';

enum TimeframeValues {
  month,
  quarter,
  year
}

@Component({
  selector: 'epo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  public filter: BaseFilterData;

  @Input() set filterSettings(value: FilterSettings) {
    if (!value) {
      throw new Error('Missing Filter Settings!');
    }
    this.settings = value;
    this._translate.get(descriptionAll).subscribe(tr => {
      if (value.showAlleGewerke) {
        this.tradeConfigurations = [
          { id: allId, tradeId: allId, trade: { name: tr, id: allId } }
        ];
      } else {
        this.tradeConfigurations = [];
      }
      this.checkTypeConfigurations = this.settings.showContractPositionAll ? [
        { id: allId, checkTypeId: allId, checkType: { name: tr, id: allId, isSpotCheck: true, isControl: true } }
      ] : [];

      this.contractPositionConfigurations = [
        { id: allId, contractPositionId: allId, contractPosition: { name: tr, id: allId } }
      ];

      this.users = [
        { id: allId, name: tr }
      ]
    });
  }

  public tradeConfigurations: TradeConfiguration[] = [];
  public contractPositionConfigurations: ContractPositionConfiguration[] = [];
  public checkTypeConfigurations: CheckTypeConfiguration[] = [];
  public users: SimpleUser[] = [];
  /**
   * Monate in JS sind zero-based! Do not touch ;)
   */
  public months = [
    { id: 0, value: 'filter.months.january' },
    { id: 1, value: 'filter.months.february' },
    { id: 2, value: 'filter.months.march' },
    { id: 3, value: 'filter.months.april' },
    { id: 4, value: 'filter.months.may' },
    { id: 5, value: 'filter.months.june' },
    { id: 6, value: 'filter.months.july' },
    { id: 7, value: 'filter.months.august' },
    { id: 8, value: 'filter.months.september' },
    { id: 9, value: 'filter.months.october' },
    { id: 10, value: 'filter.months.november' },
    { id: 11, value: 'filter.months.december' }
  ];

  public quarters = [
    { id: 1, value: 'filter.quarters.1' },
    { id: 2, value: 'filter.quarters.2' },
    { id: 3, value: 'filter.quarters.3' },
    { id: 4, value: 'filter.quarters.4' }
  ];
  public years = this.getYearsFromToNow(2016);

  public timeframeEnumForTemplate = TimeframeValues;
  public currentTimeframe: TimeframeValues = TimeframeValues.month;
  public selectedMonth: { id: number, value: string };
  public selectedQuarter: { id: number, value: string };
  public selectedYear: number;
  public showArt = false;
  public settings: FilterSettings;

  constructor(
    private _dateFilterService: DateRangeService,
    protected _userProfileService: UserProfileService,
    private _filterService: FilterService,
    private _userService: ContractUserService,
    private _contractPositionConfigurationsService: ContractPositionConfigurationService,
    private _checkTypeConfigurationsService: CheckTypeConfigurationService,
    private _tradeConfigurationService: TradeConfigurationService,
    private _translate: TranslateService
  ) {
    this.filter = new BaseFilterData();
  }

  ngOnInit(): void {
    this.initFilter();
  }

  public changeContractPosition(contractPosition: ContractPositionConfiguration) {
    this.filter.selectedContractPosition = contractPosition;
    this.sendUpdate();
  }

  public changeTrade(trade: TradeConfiguration) {
    this.filter.selectedTrade = trade;
    this.sendUpdate();
  }

  public changeStichprobenartSelection(art: CheckTypeConfiguration) {
    this.filter.selectedCheckType = art;
    this.sendUpdate();
  }

  private initFilter(): void {
    this.initDate();
    forkJoin([
      this._checkTypeConfigurationsService.getCheckTypeConfigurationsForCurrentContract(true),
      this._tradeConfigurationService.getTradeConfigurationsForCurrentContract(true),
      this._contractPositionConfigurationsService.getContractPositionConfigurationsForCurrentContract(true),
      this._userService.getCreatorsForCurrentContract()
    ]).subscribe((responses) => {
      let types = [];
      if (this.settings.filterCheckTypes) {
        if (this.settings.filterCheckTypes === 'Spotcheck') {
          types = responses[0].items.filter(c => c.checkType.isSpotCheck);
        } else {
          types = responses[0].items.filter(c => c.checkType.isControl);
        }
      } else {
        types = responses[0].items;
      }
      const tradeConfigurations = responses[1];
      const contractPositions = responses[2];
      const users = responses[3].filter(u => u.firstname || u.lastname);

      this.initUser(users);
      this.initCheckTypes(types);
      this.initContractPositions(contractPositions.items);
      this.initTrades(tradeConfigurations.items);

      this.sendUpdate();
    }, err => {
      console.log(err)
    });
  }

  private initUser(users: ContractUserResource[]): any {
    this.users.push(...users.map((u) => new SimpleUser(u)));
    this.filter.selectedUser = this.users[0];
  }

  private initCheckTypes(types: CheckTypeConfiguration[]) {
    this.checkTypeConfigurations.push(...types);
    if (Array.isArray(this.checkTypeConfigurations)) {
      this.filter.selectedCheckType = this.checkTypeConfigurations[0];
    }
  }

  private initContractPositions(contractPositions: ContractPositionConfiguration[]) {
    this.contractPositionConfigurations.push(...contractPositions)
    if (Array.isArray(this.contractPositionConfigurations)) {
      this.filter.selectedContractPosition = this.contractPositionConfigurations[0];
    }
  }

  private initTrades(trades: TradeConfiguration[]) {
    this.tradeConfigurations.push(...trades);
    if (Array.isArray(this.tradeConfigurations)) {
      this.filter.selectedTrade = this.tradeConfigurations[0];
    }
  }

  protected sendUpdate(): void {
    this._filterService.update(this.filter);
  }

  public changeUserSelection(user: SimpleUser) {
    this.filter.selectedUser = user;
    this.sendUpdate();
  }

  public changeMonth(month: { id: number, value: string }) {
    this.selectedMonth = month;
    this.processDateChange(true);
  }

  public changeQuarter(quarter: { id: number, value: string }) {
    this.selectedQuarter = quarter;
    this.processDateChange(true);
  }

  public changeYear(year: number) {
    this.selectedYear = year;
    this.processDateChange(true);
  }

  public selectByMonth() {
    this.currentTimeframe = TimeframeValues.month;
    this.processDateChange(true);
  }

  public selectByQuarter() {
    this.currentTimeframe = TimeframeValues.quarter;
    this.processDateChange(true);
  }

  public selectByYear() {
    this.currentTimeframe = TimeframeValues.year;
    this.processDateChange(true);
  }


  public ellipsisString(value: string) {
    if (value && value.length > 30) {
      const firstTwentyCharacters = value.substr(0, 30);
      return `${firstTwentyCharacters}...`;
    }

    return value;
  }

  private processDateChange(emitChanges: boolean) {
    let dateRange: DateRange;

    if (this.currentTimeframe === TimeframeValues.month) {
      this.filter.selectedIntervalType = EInterval.monthly;
      dateRange = this._dateFilterService.getDateRangeByMonthAndYear(this.selectedMonth.id, this.selectedYear);
    } else if (this.currentTimeframe === TimeframeValues.quarter) {
      this.filter.selectedIntervalType = EInterval.quarterly;
      dateRange = this._dateFilterService.getDateRangeByQuarterAndYear(this.selectedQuarter.id, this.selectedYear);
    } else if (this.currentTimeframe === TimeframeValues.year) {
      this.filter.selectedIntervalType = EInterval.yearly;
      dateRange = this._dateFilterService.getDateRangeByYear(this.selectedYear);
    }


    if (!this.filter.selectedDateRange ||
      this.filter.selectedDateRange.from.getTime() !== dateRange.from.getTime() ||
      this.filter.selectedDateRange.to.getTime() !== dateRange.to.getTime()) {
      // DateRange hat sich geändert, daher emit von Event okay.
      this.filter.selectedDateRange = dateRange;
      if (emitChanges) {
        this.sendUpdate();
      }
    }
  }
  protected initDate() {
    const currentDate = new Date();
    this.selectedMonth = this.months[currentDate.getMonth()];
    const indexOfDefaultQuarter = this.getIndexOfQuartersByMonth(currentDate.getMonth());
    this.selectedQuarter = this.quarters[indexOfDefaultQuarter];
    this.selectedYear = this.years[this.years.indexOf(currentDate.getFullYear())];
    this.processDateChange(false);
  }


  /**
   * Holt anhand des aktuellen Datums den entsprechenden Index (zero-based) der lokal hinterlegten Quarters.
   */
  private getIndexOfQuartersByMonth(zeroBasedMonth: number): number {
    // Da nicht mit zero-based Monat verglichen werden soll +1.
    const currentMonth = zeroBasedMonth + 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      return 0;
    }
    if (currentMonth >= 4 && currentMonth <= 6) {
      return 1;
    }
    if (currentMonth >= 7 && currentMonth <= 9) {
      return 2;
    }
    if (currentMonth >= 10 && currentMonth <= 12) {
      return 3;
    }

    // Irgendetwas wirklich schlimmes ist passiert. Wer es hier her geschafft hat - Glückwunsch! ;)
    throw new Error('Can\'t parse default quarter id by current month. ');
  }

  private getYearsFromToNow(fromYear: number): Array<number> {
    const currentYear = new Date().getFullYear(), years = [];
    fromYear = fromYear;

    while (fromYear <= currentYear) {
      years.push(fromYear++);
    }

    return years;
  }
}
