import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
//import { truncate } from 'fs';

@Injectable({
    providedIn: 'root'
})
export class GolbalAutoCompleteService {

    constructor(private _httpClient: HttpClient) { }

    public getMatchingContractPositionNames = (nameQuery: string, languageId?: string, isActive?: boolean) => {
        let url = this.createEpoUrl(`api/autocomplete/contractposition?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        if (isActive != null) {
            url = `${url}&isActive=${isActive}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingCheckTypeNames = (nameQuery: string, languageId?: string, isActive?: boolean) => {
        let url = this.createEpoUrl(`api/autocomplete/checktype?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        if (isActive != null) {
            url = `${url}&isActive=${isActive}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingContractNames = (nameQuery: string, languageId?: string) => {
        let url = this.createEpoUrl(`api/autocomplete/contract?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingContractUrls = (nameQuery: string) => {
        const url = this.createEpoUrl(`api/autocomplete/contracturl?name=${nameQuery}`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingUserFirstNames = (nameQuery: string) => {
        const url = this.createEpoUrl(`api/autocomplete/userfirstname?name=${nameQuery}`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingUserLastNames = (nameQuery: string) => {
        const url = this.createEpoUrl(`api/autocomplete/userlastname?name=${nameQuery}`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingUserEmails = (nameQuery: string) => {
        const url = this.createEpoUrl(`api/autocomplete/useremail?name=${nameQuery}`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingTradeNames = (nameQuery: string, languageId?: string, isActive?: boolean) => {
        let url = this.createEpoUrl(`api/autocomplete/trade?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        if (isActive != null) {
            url = `${url}&isActive=${isActive}`;
        }
        return this._httpClient.get<string[]>(url);
    }


    public getMatchingDeficitGroupNames = (nameQuery: string, languageId?: string, isActive?: boolean, contractId?: string, configurationActive?: boolean) => {
        let url = this.createEpoUrl(`api/autocomplete/deficitgroups?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        if (isActive != null) {
            url = `${url}&isActive=${isActive}`;
        }
        if (configurationActive != null) {
            url = `${url}&configurationActive=${configurationActive}`;
        }
        if (contractId != null) {
            url = `${url}&contractId=${contractId}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingDeficitNames = (nameQuery: string, languageId?: string) => {
        let url = this.createEpoUrl(`api/autocomplete/deficits?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingDeficitNamesForConfiguration = (nameQuery: string, languageId?: string) => {
        let url = this.createEpoUrl(`api/autocomplete/deficits?name=${nameQuery}&isactive=true`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingDeficitNumbers = (nameQuery: string) => {
        let url = this.createEpoUrl(`api/autocomplete/deficitNumbers?name=${nameQuery}`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingDeficitNumbersForConfiguration = (nameQuery: string) => {
        let url = this.createEpoUrl(`api/autocomplete/deficitNumbers?name=${nameQuery}&isactive=true`);
        return this._httpClient.get<string[]>(url);
    }

    public getMatchingDeficitTagNames = (nameQuery: string, languageId?: string, isActive?: boolean) => {
        let url = this.createEpoUrl(`api/autocomplete/deficittags?name=${nameQuery}`);
        if (languageId) {
            url = `${url}&languageId=${languageId}`;
        }
        if (isActive != null) {
            url = `${url}&isActive=${isActive}`;
        }
        return this._httpClient.get<string[]>(url);
    }

    private createEpoUrl = (relativePath: string) =>
        `${environment.apiUrl}/${relativePath}`;
}
