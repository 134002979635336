import { GradeDescriptionLookup } from 'app/shared/models/grade-description-lookup';
import { DisplayStatus } from 'app/shared/components/display-status/display-status';
import { Rating } from 'app/shared/models/rating';
import { StatusPhase } from 'app/shared/models/status-phase';
import { SpotCheckResponse } from './spot-check-response';
import { Injectable } from '@angular/core';

@Injectable()
export class RandomSampleStatusCalculator {

    getBewertungDurchDls(rating: Rating, phase1: StatusPhase): DisplayStatus {
        if (phase1 === StatusPhase.Done) {
            if (rating === Rating.NoClaim) { return new DisplayStatus('fa-thumbs-up green', GradeDescriptionLookup.noClaim); }
            if (rating === Rating.Claim) { return new DisplayStatus('fa-thumbs-down yellow', GradeDescriptionLookup.claim); }
            if (rating === Rating.Reclamation) {
                return new DisplayStatus('fa-thumbs-down red', GradeDescriptionLookup.reklamation);
            }
        } else if (phase1 === StatusPhase.InProgress) {
            return new DisplayStatus('fa-circle-notch', 'status.in-progress-orderer');
        }
    }

    getReaktionDurchDl(phase2: StatusPhase): DisplayStatus {
        if (phase2 === StatusPhase.InProgress) {
            return DisplayStatus.createWaitingIcon('status.in-progress-contractor')
        } if (phase2 === StatusPhase.Accepted) {
            return new DisplayStatus('fa-check-circle', 'status.accepted-contractor');
        } else if (phase2 === StatusPhase.Conflict) {
            return new DisplayStatus('fa-bolt', 'status.conflict-contractor');
        }
    }

    getFinalStatusForSpotCheck(spotCheck: SpotCheckResponse): string {

        if (spotCheck.statusPhaseThree === StatusPhase.ClaimCancelled) {
            if (spotCheck.rating === Rating.Claim) {
                return 'status.claim-cancelled-orderer';
            }
            if (spotCheck.rating === Rating.Reclamation) {
                return 'status.reclamation-cancelled-orderer';
            }
        }
        if (spotCheck.statusPhaseThree === StatusPhase.ConflictCancelled) {
            return 'status.objection-cancelled-contractor';
        }
        if (spotCheck.statusPhaseThree === StatusPhase.Conflict) {
            return 'status.conflict-orderer-contractor';
        }
        if (spotCheck.statusPhaseThree === StatusPhase.None && spotCheck.statusPhaseTwo === StatusPhase.Accepted) {
            return 'status.accepted-contractor';
        }
        if (spotCheck.rating === Rating.NoClaim) {
            if (spotCheck.statusPhaseOne === StatusPhase.Done) {
                return 'status.done-orderer';
            }
        }
        return 'status.in-progress';
    }

    getAggregatedStatus(rating: Rating, phase1: StatusPhase, phase2: StatusPhase, phase3: StatusPhase): DisplayStatus {
        // Bewertung ausstehend.
        if (phase1 === StatusPhase.InProgress) {
            return { cssClass: 'fa-hourglass', description: 'status.in-progress-orderer' };
        }
        // Bewertung -> Keine Beanstandung.
        if (rating === Rating.NoClaim) {
            return { cssClass: 'fa-thumbs-up green', description: 'status.no-claim' };
        }

        if (rating === Rating.Claim) {
            const aggregatedStatus = this.getAggregatedStatusForClaim(phase2, phase3);
            if (aggregatedStatus !== null) {
                return aggregatedStatus;
            }
        }

        else if (rating === Rating.Reclamation) {
            const aggregatedStatus = this.getAggregatedStatusForReklamation(phase2, phase3);
            if (aggregatedStatus !== null) {
                return aggregatedStatus;
            }
        }

        // Da keiner der genannten Fälle zutrifft, unbekannten Status zurückgeben.
        return DisplayStatus.createUnknownStatus();
    }

    private getAggregatedStatusForClaim(status_Phase2: StatusPhase,
        status_Phase3: StatusPhase): DisplayStatus | null {
        if (status_Phase2 === StatusPhase.None || status_Phase2 === StatusPhase.InProgress) {
            return { cssClass: 'fa-hourglass', description: 'status.in-progress-contractor' };
        }
        if (status_Phase2 === StatusPhase.Accepted) {
            return { cssClass: 'fa-thumbs-down yellow', description: 'status.claim' };
        }
        if (status_Phase3 === StatusPhase.None || status_Phase3 === StatusPhase.InProgress) {
            return { cssClass: 'fa-hourglass', description: 'status.in-progress-orderer' };
        }
        if (status_Phase3 === StatusPhase.ClaimCancelled) {
            return { cssClass: 'fa-thumbs-up green', description: 'status.no-claim' };
        }
        if (status_Phase3 === StatusPhase.ConflictCancelled) {
            return DisplayStatus.createMediumNotOkIcon('status.claim');
        }
        if (status_Phase3 === StatusPhase.Conflict) {
            return DisplayStatus.createMediumConflictIcon('status.conflict-orderer-contractor');
        }
        return null;
    }

    private getAggregatedStatusForReklamation(status_Phase2: StatusPhase,
        status_Phase3: StatusPhase): DisplayStatus | null {
        if (status_Phase2 === StatusPhase.None) {
            return { cssClass: 'fa-hourglass', description: 'status.in-progress-contractor' };
        }
        if (status_Phase2 === StatusPhase.Accepted) {
            return { cssClass: 'fa-thumbs-down red', description: 'status.reclamation' };
        }
        if (status_Phase3 === StatusPhase.None || status_Phase3 === StatusPhase.InProgress) {
            return { cssClass: 'fa-hourglass', description: 'status.in-progress-orderer' };
        }
        if (status_Phase3 === StatusPhase.ClaimCancelled) {
            return { cssClass: 'fa-thumbs-up green', description: 'status.no-claim' };
        }
        if (status_Phase3 === StatusPhase.ConflictCancelled) {
            return DisplayStatus.createBadNotOkIcon('status.reclamation');
        }
        if (status_Phase3 === StatusPhase.Conflict) {
            return DisplayStatus.createBadConflictIcon('status.conflict-orderer-contractor');
        }
        return null;
    }
}

