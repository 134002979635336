<div class="container pt-5">
  <h3 class="pb-2">{{ 'global-admin.types.title-configure' | translate }}</h3>
  <table class="table table-hover">
    <thead class="thead">
      <th></th>
      <th>{{ 'global-admin.types.name' | translate }}</th>
      <th>{{ 'global-admin.types.spot-checks' | translate }}</th>
      <th>{{ 'global-admin.types.controls' | translate }}</th>
      <th>{{ 'shared.selection' | translate }}</th>
    </thead>
    <th></th>
    <th>
      <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()"
        [getValuesFunction]="globalAutocompleteService.getMatchingCheckTypeNames" [isActive]="true">
      </epo-column-filter>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>{{loadFilter.isSpotCheck |
          boolToString:'shared.all' | translate }}</button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isSpotCheck of boolSelection" class="dropdown-item"
            (click)="isSpotCheckFilterChanged(isSpotCheck)" ngbDropdownItem>{{isSpotCheck | boolToString:'shared.all' |
            translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>{{loadFilter.isControl |
          boolToString:'shared.all' | translate }}</button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isControl of boolSelection" class="dropdown-item" (click)="isControlFilterChanged(isControl)"
            ngbDropdownItem>{{isControl | boolToString:'shared.all' | translate }}</a>
        </div>
      </div>
    </th>
    <th>
      <div ngbDropdown class="d-block">
        <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>{{loadFilter.isSelected |
          boolToString:'shared.all' | translate }}</button>
        <div ngbDropdownMenu class="dropdown-menu">
          <a *ngFor="let isSelected of boolSelection" class="dropdown-item"
            (click)="isSelectedFilterChanged(isSelected)" ngbDropdownItem>{{isSelected | boolToString:'shared.all' |
            translate }}</a>
        </div>
      </div>
    </th>
    <tbody *ngIf="isLoading" class="loading-placeholder">
      <tr>
        <td colspan="4">
          {{ 'shared.loading' | translate }}...
          <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let checkType of checkTypes">
        <td style="width: 3%;" class="table-item-name">
          <div (click)="showHistory(checkType)" class="ml-1 fa-border history-button"
            title="{{ 'shared.history' | translate }}" *ngIf="checkType.checkTypeConfigurationId">
            <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
          </div>
        </td>
        <td class="table-item-name">{{checkType.name}}</td>
        <td>
          <i class="{{checkType.isSpotCheck | toBoolSymbol:2}}"></i>
        </td>
        <td>
          <i class="{{checkType.isControl | toBoolSymbol:2}}"></i>
        </td>
        <td class="edit">
          <label *ngIf="!isSaving" class="checkbox-container">
            <input type="checkbox" [(ngModel)]="checkType.isSelected" (change)="saveConfiguration(checkType)">
            <span class="checkmark"></span>
          </label>
          <div *ngIf="isSaving" class="loader"></div>
        </td>
      </tr>
    </tbody>
  </table>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>