import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Control } from '../control-form/control-data';

@Injectable({
  providedIn: 'root'
})
export class ControlFormService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }

  public createKontrolle(control): Observable<Control> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls`);
        return this._httpClient.post<Control>(url, control);
      }));
  }

  public createControlComment(comment: string, controlId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/${controlId}/comments`);
        return this._httpClient.post(url, { comment });
      }));
  }

  public createControlAttachments(files: File[], controlId: string) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/${controlId}/attachments`);
        return this._httpClient.post(url, formData, {
          reportProgress: true,
          observe: 'events',
        });
      }));
  }

  public deleteControlAttachment(id: string, controlId: string) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/${controlId}/attachments/${id}`);
        return this._httpClient.delete(url);
      }));
  }

  public updateKontrolle(control) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/${control.id}`);
        return this._httpClient.put(url, control);
      }));
  }

  public withdrawControl(control: any) {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/controls/${control.id}/toggleWithdraw`);
        return this._httpClient.put(url, control);
      }));
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}
