import { StatusPhase } from 'app/shared/models/status-phase';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { SpotCheckResponse } from '../shared/spot-check-response';
import { Rating } from 'app/shared/models/rating';

export class SpotCheckStatusTransitionModel {
    private _transitionModels;
    private _canEdit = false;

    constructor(private spotCheck: SpotCheckResponse, private contractRole: ContractUserRole) {
        this._transitionModels = this.generateTransitionModels();
    }

    public get transitionModels() {
        return this._transitionModels;
    }

    private generateTransitionModels() {
        if (!this.spotCheck.statusPhaseOne || this.spotCheck.statusPhaseOne === StatusPhase.InProgress) {
            if (this.isOrderer) {
                this._canEdit = true;
                return [
                    { displayName: 'status.in-progress-orderer', value: { statusPhaseOne: StatusPhase.InProgress, statusPhaseTwo: StatusPhase.None, statusPhaseThree: StatusPhase.None } },
                    { displayName: 'status.done-orderer', value: { statusPhaseOne: StatusPhase.Done, statusPhaseTwo: StatusPhase.None, statusPhaseThree: StatusPhase.None } }
                ];
            }
            return;
        }
        if (this.spotCheck.statusPhaseTwo === StatusPhase.InProgress || this.spotCheck.statusPhaseTwo === StatusPhase.None) {
            if (this.isOrderer) {
                this._canEdit = true;
            }
            if (this.isContractor && (this.spotCheck.rating === Rating.Claim || this.spotCheck.rating === Rating.Reclamation)) {
                return [
                    { displayName: 'status.in-progress-contractor', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: StatusPhase.InProgress, statusPhaseThree: StatusPhase.None } },
                    { displayName: 'status.conflict-contractor', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: StatusPhase.Conflict, statusPhaseThree: StatusPhase.None } },
                    { displayName: 'status.accepted-contractor', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: StatusPhase.Accepted, statusPhaseThree: StatusPhase.None } },
                ];
            }
            return;
        }
        if (this.spotCheck.statusPhaseTwo === StatusPhase.Conflict && (this.spotCheck.statusPhaseThree === StatusPhase.InProgress
            || this.spotCheck.statusPhaseThree === StatusPhase.None
            || (this.spotCheck.statusPhaseThree === StatusPhase.Conflict && this.isManagementUser))) {
            let tmp = [
                { displayName: 'status.in-progress-orderer-contractor', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: this.spotCheck.statusPhaseTwo, statusPhaseThree: StatusPhase.InProgress } },
                { displayName: 'status.conflict-contractor-orderer', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: this.spotCheck.statusPhaseTwo, statusPhaseThree: StatusPhase.Conflict } },
            ];
            if (this.isOrderer) {
                this._canEdit = true;
                tmp.push({
                    displayName: `${this.spotCheck.rating === Rating.Claim ? 'status.claim-cancelled-orderer' : 'status.reclamation-cancelled-orderer'}`,
                    value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: this.spotCheck.statusPhaseTwo, statusPhaseThree: StatusPhase.ClaimCancelled }
                });
            }
            if (this.isContractor) {
                tmp.push(
                    { displayName: 'status.objection-cancelled-contractor', value: { statusPhaseOne: this.spotCheck.statusPhaseOne, statusPhaseTwo: this.spotCheck.statusPhaseTwo, statusPhaseThree: StatusPhase.ConflictCancelled } }
                );
            }
            return tmp;
        }
    }

    private get isManagementUser(): boolean {
        switch (this.contractRole) {
            case ContractUserRole.Admin:
            case ContractUserRole.ContractorManager:
            case ContractUserRole.OrdererManager:
                return true;
            default:
                return false;
        }
    }

    private get isContractor(): boolean {
        switch (this.contractRole) {
            case ContractUserRole.Admin:
            case ContractUserRole.ContractorEmployee:
            case ContractUserRole.ContractorManager:
                return true;
            default:
                return false;
        }
    }

    private get isOrderer(): boolean {
        switch (this.contractRole) {
            case ContractUserRole.Admin:
            case ContractUserRole.OrdererEmployee:
            case ContractUserRole.OrdererManager:
                return true;
            default:
                return false;
        }
    }

    public get canEdit(): boolean {
        return this._canEdit;
    }
}