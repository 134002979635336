import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { ControlTargetsService } from './control-targets.service';
import { ControlTarget } from 'app/shared/models/control-target';
import { ModalControlTargetsEditComponent } from './modal-control-target-edit/modal-control-target-edit.component';
import { EInterval } from 'app/shared/models/interval';
import { ControlService } from 'app/control/shared/control.service';
import { forkJoin } from 'rxjs';
import { ControlTargetViewModel } from './control-target-view-model';
import { ResolveIdHelper } from 'app/admin/shared/resolveIdHelper';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { TradeConfigurationService } from 'app/shared/masterData/trade/configuration/trade-configuration.service';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { ControlTargetResponse } from 'app/shared/models/control-targets-response';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { TargetLoadFilter } from 'app/admin/allgemein/shared/target-load-filter';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { OrderBy, OrderDirection } from 'app/admin/allgemein/shared/order-by';
import { CheckTargetHistoryHelper } from 'app/shared/helper/check-target-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';

@Component({
  selector: 'epo-control-targets',
  templateUrl: './control-targets.component.html',
  styleUrls: ['./control-targets.component.scss']
})
export class ControlTargetsComponent extends BaseFilterPagingComponent<ControlTargetResponse> implements OnInit {

  public hasTargets = false;
  loadFilter = new TargetLoadFilter();
  public sorting: { column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval', direction: OrderDirection };

  constructor(private _tradeConfigService: TradeConfigurationService,
    private _controlSampleService: ControlService,
    private modalService: NgbModal,
    private controlTargetService: ControlTargetsService,
    private contractPositionService: ContractPositionConfigurationService,
    private translate: TranslateService,
    public globalAutocompleteService: GolbalAutoCompleteService) {
    super();
  }

  public target: ControlTargetResponse;

  controlTargets: ControlTargetViewModel[];
  
  private contractPositionConfigs: ContractPositionConfiguration[];
  private checkTypeConfigs: CheckTypeConfiguration[];
  private tradeConfigs: TradeConfiguration[];

  public intervalTypes = [{ value: EInterval.monthly, displayName: 'shared.monthly' },
  { value: EInterval.quarterly, displayName: 'shared.quarterly' },
  { value: EInterval.yearly, displayName: 'shared.yearly' }];

  ngOnInit() {
    this.loadData();
  }

  isActiveSorting(column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval', direction: 'Asc' | 'Desc') {
    return this.sorting?.column == column && this.sorting.direction == OrderDirection[direction];
  }

  sort(column: 'Function' | 'Service' | 'CheckType' | 'Amount' | 'Interval') {
    if (column === this.sorting?.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  public loadData() {
    this.isLoading = true;
    if (this.sorting) {
      this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    }
    forkJoin([
      this._controlSampleService.getTypes(),
      this._tradeConfigService.getTradeConfigurationsForCurrentContract(),
      this.controlTargetService.getControlTargets(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter),
      this.contractPositionService.getContractPositionConfigurationsForCurrentContract()
    ]).subscribe((response) => {
      this.checkTypeConfigs = response[0];
      this.tradeConfigs = response[1].items;
      const targets = response[2];
      this.contractPositionConfigs = response[3].items;

      this.hasTargets = targets.items.length > 0;
      this.pagingGlobalCount = targets.count;

      this.items = targets.items;
      this.controlTargets = [];

      targets.items.forEach((target) => {
        const viewModel = new ControlTargetViewModel();
        viewModel.tradeName = ResolveIdHelper.getTradeName(target.tradeConfigurationId, this.tradeConfigs, this.translate.instant('shared.unknown-trade'));
        viewModel.checkTypeName = ResolveIdHelper.getCheckTypeName(target.checkTypeConfigurationId, this.checkTypeConfigs, this.translate.instant('shared.unknown-type'));
        viewModel.amount = target.amount;
        viewModel.interval = target.interval;
        viewModel.intervalName = ResolveIdHelper.getIntervalName(target.interval, this.intervalTypes, this.translate.instant('shared.unknown-interval'));
        viewModel.contractPositionName = ResolveIdHelper.getContractPositionName(target.contractPositionConfigurationId, this.contractPositionConfigs, this.translate.instant('shared.unknown-contract-position'))
        viewModel.id = target.id;
        viewModel.contractPositionConfigurationId = target.contractPositionConfigurationId;
        viewModel.checkTypeConfigurationId = target.checkTypeConfigurationId;
        viewModel.tradeConfigurationId = target.tradeConfigurationId;
        viewModel.createdUtc = target.createdUtc;
        viewModel.modifiedUtc = target.modifiedUtc;
        viewModel.createdBy = target.createdBy;
        viewModel.modifiedBy = target.modifiedBy;

        this.controlTargets.push(viewModel);
      });
      this.isLoading = false;
    });
  }

  public openCreateTargetModal() {
    const target = new ControlTarget();
    const modalRef = this.modalService.open(ModalControlTargetsEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs.filter(x => x.isActive === true && x.trade.isActive === true);
    modalRef.componentInstance.contractPositionConfigs = this.contractPositionConfigs.filter(x => x.isActive === true && x.contractPosition.isActive === true);
    modalRef.componentInstance.checkTypeConfigs = this.checkTypeConfigs.filter(x => x.isActive === true && x.checkType.isActive === true);

    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this.controlTargetService.createTarget(result).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public openEditTargetModal(target: ControlTarget) {
    const modalRef = this.modalService.open(ModalControlTargetsEditComponent, { centered: true });
    modalRef.componentInstance.target = target;
    modalRef.componentInstance.intervalTypes = this.intervalTypes;
    modalRef.componentInstance.inNewMode = false;
    modalRef.componentInstance.tradeConfigs = this.tradeConfigs;
    modalRef.componentInstance.contractPositionConfigs = this.contractPositionConfigs;
    modalRef.componentInstance.checkTypeConfigs = this.checkTypeConfigs;

    modalRef.result.then((result) => {
      this.controlTargetService.updateTarget(result).subscribe(() => {
        this.loadData();

      }, () => { });
    }, () => { });
  }

  public openDeleteModal(target: ControlTarget) {
    const modalRef = this.modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = target;
    modalRef.result.then((tar) => {
      this.controlTargetService.deleteTarget(tar.id).subscribe(() => {
        this.loadData();
      }, () => { });
    }, () => { });
  }

  public showHistory(checkTarget: ControlTarget) {
    this.controlTargetService.getTargetHistory(checkTarget.id).subscribe(history => {
      const changeHistory = new CheckTargetHistoryHelper(history).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
    });
  }
}









