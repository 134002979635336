import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, Subject } from 'rxjs';
import { ToasterService } from '../toaster/toaster.service';

@Component({
    selector: 'app-update-notification',
    template: ''
})
export class UpdateNotificationComponent {
    updateAvailable$: Observable<boolean>;
    closed$ = new Subject<void>();

    constructor(updates: SwUpdate, toaster: ToasterService) {
        updates.available.subscribe(res => {
            toaster.show({ heading: 'cache.new-version-heading', description: 'cache.new-version-body', type: 'info', autoDismiss: false });
        });
    }
}