/**
 * Helper-Klasse für Array-Operationen.
 */
export class Group<T> {
  key: string;
  members: T[] = [];
  constructor(key: string) {
    this.key = key;
  }
}
export class EpoHelper {
  // public static sortBy<T>(list: T[], func: (x: T) => string): T[] {
  public static sortBy<T>(list: T[], propertyName: any): T[] {

    return list.sort(function (a, b) {
      const genreA = a[propertyName].toUpperCase();
      const genreB = b[propertyName].toUpperCase();

      let comparison = 0;
      if (genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
      return comparison;
    });
  }

  public static groupBy<T>(list: T[], func: (x: T) => string): Group<T>[] {
    const res: Group<T>[] = [];
    let group: Group<T> = null;
    list.forEach((o) => {
      const groupName = func(o);
      if (group === null) {
        group = new Group<T>(groupName);
      }
      if (groupName !== group.key) {
        res.push(group);
        group = new Group<T>(groupName);
      }
      group.members.push(o);
    });
    if (group != null) {
      res.push(group);
    }
    return res;
  }
}
