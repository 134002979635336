import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ContractService } from '../contract/contract.service';
import { ContractUserResource } from 'app/core/contract-user.resource';
import { PostContractUserResource } from 'app/core/post-contract-user.resource';
import { ContractUserRole } from './contract-user-role';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';

@Injectable({
    providedIn: 'root'
})

export class ContractUserService {

    constructor(private _http: HttpClient, private contractService: ContractService) {
    }

    getContractUsersForCurrentContract(): Observable<PagedResultResponse<ContractUserResource>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers`;
                return this._http.get<PagedResultResponse<ContractUserResource>>(url)
            })
        );
    }

    getFilteredContractUsersForCurrentContract(): Observable<PagedResultResponse<ContractUserResource>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers?isActive=true`;
                return this._http.get<PagedResultResponse<ContractUserResource>>(url)
            })
        );
    }

    getCreatorsForCurrentContract(): Observable<ContractUserResource[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers/creators`;
                return this._http.get<ContractUserResource[]>(url)
            })
        );
    }

    createContractUser(contractUser: PostContractUserResource): Observable<ContractUserResource> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers`;
                return this._http.post<ContractUserResource>(url, contractUser);
            }));
    }

    updateContractUser(id: string, contractRole: ContractUserRole): Observable<ContractUserResource> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers/${id}`;
                return this._http.put<ContractUserResource>(url, { contractRole: contractRole });
            }));
    }

    deleteContractUser(contractUserId: string): Observable<any> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractusers/${contractUserId}`;
                return this._http.delete(url);
            }));
    }
}