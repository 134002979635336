import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Control } from './control-data';
import { ControlService } from 'app/control/shared/control.service';
import { ControlPhase } from 'app/shared/models/controlPhase';
import { Rating } from 'app/shared/models/rating';
import { ControlResponse } from 'app/control/shared/control-response';
import { forkJoin, Observable, throwError, of, Subscription } from 'rxjs';
import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { allId, BaseFilterData } from 'app/shared/components/filter/filter';
import { DeficitWithValue } from 'app/shared/deficit-with-value.view-model';
import { DeficitGroupViewModel } from 'app/shared/deficit-group-view-model';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { DeficitConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-configuration.resource';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, map, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { UserRoleService } from 'app/shared/services/user-role.service';
import { RequiredRoles } from 'app/shared/helper/required-roles';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { DeficitMapping } from 'app/shared/masterData/deficit/deficitMapping';
import { DeficitVariant } from 'app/shared/masterData/deficit-variant';
import { TranslateService } from '@ngx-translate/core';
import { FileAttachment } from 'app/shared/models/file-attachment.resource';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpEvent,
} from '@angular/common/http';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { ControlFormService } from '../shared/control-form.service';
import { OnlineOfflineService } from 'app/local-cache/services/online-offline.service';
import { OfflineSyncService } from 'app/local-cache/services/offline-sync.service';
import { StatusPhase } from 'app/shared/models/status-phase';
import { UserProfileService } from 'app/core/user-profile.service';
import { User } from 'app/core/user';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { DedicatedUser } from 'app/shared/masterData/user/dedicated-user';
import { transformContractUserToDedicatedUser, transformNullToDedicatedUser, transformUserResponseToDedicatedUser } from 'app/shared/helper/user-transformer';
import { ContractUserService } from 'app/shared/masterData/user/contract-user.service';
import { ContractUserResource } from 'app/core/contract-user.resource';

@Component({
  selector: 'epo-control-form',
  templateUrl: './control-form.component.html',
  styleUrls: ['./control-form.component.scss'],
})
export class KontrollenFormularComponent implements OnInit,OnDestroy {
  @Input() controlId: string;

  @Input() ticketDescription: string;
  @Input() plant: string;
  @Input() ticketNumber: string;
  @Input() roomNumber: string;
  @Input() tenderPosition: string;
  @Input() attachementLink: string;
  @Input() checkTypeName: string;
  @Input() contractPositionName: string;
  @Input() tradeName: string;
  @Input() deviceUsageKind: string;

  @ViewChild('controlFileInput', { static: false })
  fileInput: ElementRef;

  private _tradeConfigs: TradeConfigurationResponse[] = [];
  get tradeConfigs() {
    return this._tradeConfigs.filter(
      (x) =>
        (x.isActive && x.trade.isActive) ||
        (this.controlSample && this.controlSample.tradeConfigurationId === x.id)
    );
  }
  private _checkTypeConfigs: CheckTypeConfiguration[] = [];
  get checkTypeConfigs() {
    return this._checkTypeConfigs.filter(
      (x) =>
        (x.isActive && x.checkType.isActive) ||
        (this.controlSample &&
          this.controlSample.checkTypeConfigurationId === x.id)
    );
  }
  private _contractPositionConfigs: ContractPositionConfiguration[] = [];
  get contractPositionConfigs() {
    return this._contractPositionConfigs.filter(
      (x) =>
        (x.isActive && x.contractPosition.isActive) ||
        (this.controlSample &&
          this.controlSample.contractPositionConfigurationId === x.id)
    );
  }
  public deficitGroups: DeficitGroupConfiguration[];
  private filterData: BaseFilterData;
  public controlFormData = new Control();
  public createSpotCheckLink: string;

  public contractDisabled = false;
  public controlTypeDisabled = false;
  public gewerkDisabled = false;
  public requirementIdDisabled = false;
  public descriptionDisabled = false;
  public lvPositionDisabled = false;
  public deviceShortNameDisabled = false;
  public usageTypeDisabled = false;
  public anlageDefaultDisabled = false;
  public raumDisabled = false;
  public commentDisabled = false;
  public controlStateDisabled = false;
  public controlRatingDisabled = false;
  public ratingDisabled = false;
  public defiziteDisabled = false;
  private _fixedDisabled = false;
  public disableForm = false;
  public isLoading = true;
  public isSaving = false;

  public controlWithdrawn:boolean = false;

  public progress: number;
  public fileError: boolean = false;

  filesToUpload: File[] = [];
  attachmentsToRemove: FileAttachment[] = [];

  requiredRolesForSpotCheckCreation = RequiredRoles.createSpotCheck;

  public deficitFixedStatus = [
    { value: DeficitFixed.Ja, displayName: 'spot-checks.form.state-yes' },
    { value: DeficitFixed.Nein, displayName: 'spot-checks.form.state-no' },
    {
      value: DeficitFixed.ZurKlaerung,
      displayName: 'spot-checks.form.state-clarify',
    },
  ];

  public controlStatus: ControlPhase;

  public noDefiziteSelected: boolean;
  public deficiteExistInSelectedCombination = false;

  public rating = Rating;
  public controlPhase = ControlPhase;

  public isNewControlSample = false;
  public get ticketUrl() {
    return `${environment.archibusUrl}?woactivity_log_hwohactivity_log.wr_id=${this.controlFormData.ticketNumber}`;
  }

  public filteredDeficitGroups: DeficitGroupViewModel[];
  public statusPhase1Value: ControlPhase;
  public sampleStatusPhase1 = [
    {
      value: ControlPhase.InProgress,
      dispayName: 'status.in-progress',
      disabled: false,
    },
    {
      value: ControlPhase.DoneTl,
      dispayName: 'status.done-tm',
      disabled: false,
    },
  ];

  private selectedDeficits: DeficitValueResponse[];
  public controlSample: ControlResponse;
  private _contractId: string;

  public isUserGlobalAdmin: boolean;

  public dedicatedUsers: DedicatedUser[] = [];
  private _assignedUser: DedicatedUser;
  public assignedUserGuid: string;
  @ViewChild('dedicatedUser') select: ElementRef;
  public get assignedUser(): DedicatedUser {
    return this._assignedUser;
  }
  public set assignedUser(value: DedicatedUser) {
    this._assignedUser = value;
  }
  private _contractUsersSub: Subscription;

  constructor(
    private _controlSampleService: ControlService,
    private _userProfileService: UserProfileService,
    public activeModal: NgbActiveModal,
    public controlFormService: ControlFormService,
    private _contractService: ContractService,
    private _userRoleService: UserRoleService,
    private _translate: TranslateService,
    private _toaster: ToasterService,
    private _onlineOfflineService: OnlineOfflineService,
    private _offlineSyncService: OfflineSyncService,
    private _contractUserService: ContractUserService
  ) {
    this._translate.get('controls.form.title-new').subscribe((t) => {
      this._titleNew = t;
    });
    this._contractService.selectedContract
      .pipe(take(1))
      .subscribe((contract) => {
        this._contractId = contract.id;
      });
  }

  ngOnInit() {
    this.populateContractUsersSelectOptions();
    this._userProfileService.currentUser.subscribe(user => {
      this.isUserGlobalAdmin = (user as User).role === GlobalUserRole.GlobalAdmin;
    });
    this.getBaseData();
    this.handleUserRoles();
    this.getBaseData();
  }

  private _titleNew: string;
  private _titleEdit: string;

  public get title() {
    if (this.controlSample && this.controlSample.contractEntityNumber) {
      return this._titleEdit;
    }
    return this._titleNew;
  }

  public get hasExistingDeficit() {
    return (
      (this.selectedDeficits &&
        this.selectedDeficits.some((deficit) => deficit.defizitExists)) ||
      null
    );
  }

  public get hasNoDeficits() {
    return (
      !this.selectedDeficits ||
      !this.selectedDeficits.some((deficit) => deficit.defizitExists) ||
      null
    );
  }

  public get fixedDisabled(): boolean {
    return (
      this._fixedDisabled || this.controlFormData.rating === Rating.NoClaim
    );
  }
  
  private handleUserRoles() {
    if (!this.controlId) {
      this._userRoleService.redirectIfUserIsMissingRoles(
        RequiredRoles.createControl,
        () => this.activeModal.dismiss()
      );
    } else {
      this._userRoleService
        .hasRequiredRole(RequiredRoles.createControl)
        .subscribe((canEdit) => {
          if (!canEdit) {
            this.disableAllInputFields();
          }
        });
    }
    this._userRoleService
      .hasRequiredRole([ContractUserRole.ContractorManager])
      .subscribe((hasRole) => {
        if (hasRole) {
          this.sampleStatusPhase1.push({
            value: ControlPhase.DoneOl,
            dispayName: 'status.done-om',
            disabled: false,
          });
          this.sampleStatusPhase1.push({
            value: ControlPhase.DoneKVP,
            dispayName: 'status.done-cip',
            disabled: false,
          });
        } else {
          this.sampleStatusPhase1.push({
            value: ControlPhase.DoneOl,
            dispayName: 'status.done-om',
            disabled: true,
          });
          this.sampleStatusPhase1.push({
            value: ControlPhase.DoneKVP,
            dispayName: 'status.done-cip',
            disabled: true,
          });
        }
      });
  }

  public getBaseData() {
    this.isLoading = true;
    const deficitGroups$ = this.controlId
      ? forkJoin([
        this._controlSampleService.getDeficitsForControl(this.controlId),
        this._controlSampleService.getDeficitGroups(),
      ]).pipe(
        map((res) => {
          res[0].forEach((group1) => {
            const group2 = res[1].find((g) => g.name === group1.name);
            if (!group2) {
              res[1] = [...res[1], group1];
            } else {
              group1.deficitConfigurations.forEach((dc1) => {
                const dc2 = group2.deficitConfigurations.find(
                  (d) => d.id === dc1.id
                );
                if (!dc2) {
                  group2.deficitConfigurations = [
                    ...group1.deficitConfigurations,
                    ...group2.deficitConfigurations,
                  ].filter((v, i, a) => v.id === a[i].id);
                }
              });
            }
          });
          return res[1];
        })
      )
      : this._controlSampleService.getDeficitGroups();

    forkJoin([
      this._controlSampleService.getContractPositions(),
      this._controlSampleService.getTradeConfigurations(),
      this._controlSampleService.getTypes(),
      deficitGroups$,
    ]).subscribe(
      (response) => {
        this._contractPositionConfigs = response[0].items;
        this._tradeConfigs = response[1].items;
        this._checkTypeConfigs = response[2];
        this.deficitGroups = response[3];
        this.deficitGroups.forEach((group) => {
          group.deficitConfigurations = this.sortAndFilterDeficits(
            group.deficitConfigurations
          );
        });
        this.handleForm();
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  private sortAndFilterDeficits(
    configs: DeficitConfiguration[]
  ): DeficitConfiguration[] {
    configs = configs.filter(
      (v, i, a) =>
        v.id === a[i].id && (this.controlId != null || v.isActive === true)
    );
    return configs.sort((n1, n2) => {
      if (n1.deficit.title > n2.deficit.title) {
        return 1;
      }
      if (n1.deficit.title < n2.deficit.title) {
        return -1;
      }
      return 0;
    });
  }

  setDefaultState() {
    this.statusPhase1Value = ControlPhase.InProgress;
  }

  public handleForm() {
    if (this.controlId) {
      this.loadControlSampleById(this.controlId);
    } else {
      this.initNewForm();
    }
  }

  initNewForm() {
    this.selectedDeficits = [];
    this.isNewControlSample = true;

    this.setDefaultState();
    this.assignGewerkFromFilter();
    this.assignContractPositionFromFilter();
    this.assignStichprobenartFromFilter();
    this.assignDefaultSample();

    this.controlFormData.deficitFixed = DeficitFixed.Nein;

    this.controlFormData.ticketDescription = this.ticketDescription;
    this.controlFormData.plant = this.plant;
    this.controlFormData.ticketNumber = this.ticketNumber;
    this.controlFormData.roomNumber = this.roomNumber;
    this.controlFormData.tenderPosition = this.tenderPosition;
    this.controlFormData.deviceShortName = this.attachementLink;
    this.controlFormData.deviceUsageKind = this.deviceUsageKind;

    if (this.tradeName) {
      const tradeConfig = this._tradeConfigs.find((t) =>
        t.trade.name.toUpperCase().includes(this.tradeName.toUpperCase())
      );
      if (tradeConfig) {
        this.controlFormData.tradeConfigurationId = tradeConfig.id;
      }
    }
    if (this.contractPositionName) {
      const contractPositionConfig = this._contractPositionConfigs.find((t) =>
        t.contractPosition.name
          .toUpperCase()
          .includes(this.contractPositionName.toUpperCase())
      );
      if (contractPositionConfig) {
        this.controlFormData.contractPositionConfigurationId =
          contractPositionConfig.id;
      }
    }
    if (this.checkTypeName) {
      const checkTypeConfig = this._checkTypeConfigs.find((t) =>
        t.checkType.name
          .toUpperCase()
          .includes(this.checkTypeName.toUpperCase())
      );
      if (checkTypeConfig) {
        this.controlFormData.checkTypeConfigurationId = checkTypeConfig.id;
      }
    }
    this.updateDefizite();
  }

  private assignDefaultSample() {
    this.controlFormData.statusPhaseOne = ControlPhase.InProgress;
  }

  public loadControlSampleById(controlSampleId: string) {
    this._controlSampleService.getControlSampleById(controlSampleId).subscribe(
      (controlSample) => {
        this.controlSample = controlSample;
        if (this.controlSample.isWithdrawn) {
            this._translate
            .get('controls.form.title-withdrawn', {
              number: this.controlSample.contractEntityNumber,
            })
            .subscribe((t) => {
              this._titleEdit = t;
            });
        } else {
            this._translate
            .get('controls.form.title-edit', {
              number: this.controlSample.contractEntityNumber,
            })
            .subscribe((t) => {
              this._titleEdit = t;
            });
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.assignDataFromControlSampleToFormData();
        this.setFormFields();
        this.updateDefizite();
        this.buildSpotCheckLink();
      }
    );
  }

  public updateDefizite() {
    if (
      !this.controlFormData.contractPositionConfigurationId ||
      !this.controlFormData.checkTypeConfigurationId
    ) {
      return;
    }
    let filteredDeficitGroups = this.deficitGroups.map((deficitGroup) => {
      const filteredDeficits = deficitGroup.deficitConfigurations.filter(
        (deficit) => {
          return this.isDeficitMatchingCheckTypeAndContractPosition(
            deficit.deficit.mappings,
            this.controlFormData.checkTypeConfigurationId,
            this.controlFormData.contractPositionConfigurationId
          ) || this.selectedDeficits.find(sd => sd.deficitId === deficit.id);
        }
      );
      const filteredDeficitsWithValue = this.assignValueToDeficit(
        this.controlSample,
        filteredDeficits
      );
      const newGroup: DeficitGroupViewModel = {
        name: deficitGroup.name,
        deficits: filteredDeficitsWithValue,
      };
      return newGroup;
    });

    filteredDeficitGroups = this.filterEmptyGroups(filteredDeficitGroups);
    if (filteredDeficitGroups && filteredDeficitGroups.length > 0) {
      this.deficiteExistInSelectedCombination = true;
    }
    this.filteredDeficitGroups = filteredDeficitGroups;
  }

  private isDeficitMatchingCheckTypeAndContractPosition(
    deficitMappings: DeficitMapping[],
    checkTypeConfigId: string,
    contractPositionConfigId: string
  ): boolean {
    const checkType = this._checkTypeConfigs.find(
      (c) => c.id == checkTypeConfigId
    );
    const contractPosition = this._contractPositionConfigs.find(
      (c) => c.id == contractPositionConfigId
    );
    return (
      checkType &&
      contractPosition &&
      deficitMappings.some(
        (map) =>
          map.checkTypeId === checkType.checkTypeId &&
          map.contractPositionId === contractPosition.contractPositionId &&
          map.deficitVariant === DeficitVariant.Control
      )
    );
  }

  private assignValueToDeficit(
    controlSample: ControlResponse,
    filteredDeficits: DeficitConfiguration[]
  ): DeficitWithValue[] {
    return filteredDeficits.map((defizit) => {
      const defizitWithValue = new DeficitWithValue();
      defizitWithValue.id = defizit.id;
      defizitWithValue.title = defizit.deficit.title;
      defizitWithValue.description = defizit.deficit.description;
      defizitWithValue.tags = defizit.deficit.tags;

      if (!controlSample || !controlSample.deficitValues) {
        defizitWithValue.defizitExists = null;
        return defizitWithValue;
      }
      const value = controlSample.deficitValues.find(
        (deficitValue) => deficitValue.deficitId === defizit.id
      );
      if (value) {
        defizitWithValue.defizitExists = value.defizitExists;
      } else {
        defizitWithValue.defizitExists = null;
      }

      return defizitWithValue;
    });
  }

  private filterEmptyGroups(filteredDeficitGroups: DeficitGroupViewModel[]) {
    return filteredDeficitGroups.filter((group) => {
      return group.deficits && group.deficits.length > 0;
    });
  }

  public onSelectedDefiziteChanged(selectedDefizite: DeficitValueResponse[]) {
    this.selectedDeficits = selectedDefizite;
    if (this.hasAtLeastOneSelectedDefizit(this.selectedDeficits)) {
      this.noDefiziteSelected = false;
      if (
        this.controlFormData.rating === Rating.NoClaim &&
        this.selectedDeficits.some((deficit) => deficit.defizitExists)
      ) {
        this.controlFormData.rating = Rating.None;
      }
    }
    if (
      this.controlFormData.rating === Rating.Claim &&
      !this.selectedDeficits.some((deficit) => deficit.defizitExists)
    ) {
      this.controlFormData.rating = Rating.None;
    }
  }

  public onChangeStatus() {
    const value = this.statusPhase1Value;
    if (value == ControlPhase.None) {
      this.controlFormData.statusPhaseOne = ControlPhase.None;
    }
    if (value == ControlPhase.InProgress) {
      this.controlFormData.statusPhaseOne = ControlPhase.InProgress;
    }
    if (value == ControlPhase.DoneOl) {
      this.controlFormData.statusPhaseOne = ControlPhase.DoneOl;
    }
    if (value == ControlPhase.DoneTl) {
      this.controlFormData.statusPhaseOne = ControlPhase.DoneTl;
    }
    if (value == ControlPhase.DoneKVP) {
      this.controlFormData.statusPhaseOne = ControlPhase.DoneKVP;
    }
  }

  public getStichprobenName(stichprobenartId): string {
    const foundStichprobenart = this._checkTypeConfigs.find(
      (checkType) => checkType.id === stichprobenartId
    );
    if (foundStichprobenart && foundStichprobenart.checkType.name) {
      return foundStichprobenart.checkType.name;
    }
    return 'Unbekannte Stichprobenart';
  }

  private assignDataFromControlSampleToFormData() {
    this.controlFormData.contractPositionConfigurationId = this.controlSample.contractPositionConfigurationId;
    this.controlFormData.checkTypeConfigurationId = this.controlSample.checkTypeConfigurationId;
    this.controlFormData.tradeConfigurationId = this.controlSample.tradeConfigurationId;
    this.controlFormData.ticketNumber = this.controlSample.ticketNumber;
    this.controlFormData.roomNumber = this.controlSample.roomNumber;
    this.controlFormData.ticketDescription = this.controlSample.ticketDescription;
    this.controlFormData.id = this.controlSample.id;
    this.controlFormData.plant = this.controlSample.plant;
    this.controlFormData.appendix = this.controlSample.appendix;
    this.controlFormData.rating = this.controlSample.rating;
    this.controlFormData.tenderPosition = this.controlSample.tenderPosition;
    this.controlFormData.statusPhaseOne = this.controlSample.statusPhaseOne;
    this.controlFormData.deficitFixed = this.controlSample.deficitFixed;
    this.controlFormData.deviceShortName = this.controlSample.deviceShortName;
    this.controlFormData.deviceUsageKind = this.controlSample.deviceUsageKind;
    this.controlFormData.comments = this.controlSample.comments;
    this.selectedDeficits = this.controlSample.deficitValues;
    this.controlFormData.createdUtc = this.controlSample.createdUtc;
    this.controlFormData.modifiedUtc = this.controlSample.modifiedUtc;
    this.controlFormData.createdBy = this.controlSample.createdBy;
    this.controlFormData.modifiedBy = this.controlSample.modifiedBy;
    this.statusPhase1Value = this.controlSample.statusPhaseOne;
    this.controlFormData.attachments = this.controlSample.attachments;
    this.controlFormData.isWithdrawn = this.controlSample.isWithdrawn;
    //new field DedicatedUser
    if (this.controlSample.dedicatedUser) {
      try {
        this.assignedUser = transformUserResponseToDedicatedUser(this.controlSample.dedicatedUser);
        this.assignedUserGuid = this.controlSample.dedicatedUser.id;
        this.setSelectedValueInSelectElementRef();  
      } catch (error) {
        console.log(error.message);
        console.log("dedicated user not found or inactve, setting to default null user");
        this.assignedUser = transformNullToDedicatedUser();
        this.assignedUserGuid = "00000000-0000-0000-0000-000000000000";
        this.setSelectedValueInSelectElementRef();
      }
    }
    //for use in template
    this.controlWithdrawn = this.controlSample.isWithdrawn;
  }
  
  private isControlWithdrawn(): boolean{
    return this.controlSample ? this.controlSample.isWithdrawn : this.controlWithdrawn;
  }

  private setSelectedValueInSelectElementRef(){
    this.select.nativeElement.options[this.dedicatedUsers.findIndex(item => item.id == this.assignedUser.id)].selected = true;
  }

  private assignDedicatedUser() {
    try {
      this.assignedUser = this.dedicatedUsers.find(item => item.id === this.assignedUserGuid);
    } catch (error) {
      console.log("could not find user in list of contract users");
      console.log(error);
    }
  }
  private setAssignedUserOnPostFormData():void {
    this.assignDedicatedUser();
    if (this.assignedUser && this.assignedUser.id != "00000000-0000-0000-0000-000000000000") {
      if (this.assignedUser.isActive) {
        this.controlFormData.dedicatedUser = this.assignedUser.toUserResponse();
      } else {
        throw "Dedicated User is Inactive"; 
      }
    } else {
      this.controlFormData.dedicatedUser = null;
    }
  }

  private populateContractUsersSelectOptions():void {
    this._contractUsersSub = this._contractUserService.getFilteredContractUsersForCurrentContract().subscribe(response => {
      response.items.map(item => {
        this.dedicatedUsers.push(transformContractUserToDedicatedUser(item));
      });
    }, err => {
      console.log(err);
    },
    ()=>{
      this.dedicatedUsers.push(transformNullToDedicatedUser());
    })
  }
  public printNameOfContractUser(user:ContractUserResource):string{
    if (user.firstname && user.lastname) {
        return `${user.firstname} ${user.lastname}`;
    } else {
        return user.loginEmail;
    }
  }


  private setFormFields(): void {
    if (this.isControlWithdrawn()) {
      // TODO: the below solution might suit us well for the disablement of the fiedls
      // this.disableForm = true;
      this.disableDefaultInputFields();
      this.disableAllInputFields();
      return;
    }
    if (this.controlFormData.statusPhaseOne >= ControlPhase.InProgress) {
      this.disableDefaultInputFields();
    }
    if (this.controlFormData.statusPhaseOne > ControlPhase.InProgress) {
      this.defiziteDisabled = true;
    }
    if (!this.controlSample.isEditable) {
      this.controlStateDisabled = true;
    }
  }

  private disableDefaultInputFields() {
    this.gewerkDisabled = true;
    this.controlTypeDisabled = true;
    this.contractDisabled = true;
  }

  private disableAllInputFields() {
    this.contractDisabled = true;
    this.controlTypeDisabled = true;
    this.gewerkDisabled = true;
    this.requirementIdDisabled = true;
    this.descriptionDisabled = true;
    this.lvPositionDisabled = true;
    this.deviceShortNameDisabled = true;
    this.usageTypeDisabled = true;
    this.anlageDefaultDisabled = true;
    this.raumDisabled = true;
    this.commentDisabled = true;
    this.controlStateDisabled = true;
    this.controlRatingDisabled = true;
    this._fixedDisabled = true;
    this.ratingDisabled = true;
    this.defiziteDisabled = true;
  }

  private hasAtLeastOneSelectedDefizit(defiziteResults) {
    if (!Array.isArray(defiziteResults)) {
      return false;
    }
    const index = defiziteResults.findIndex((result) => {
      return (
        (result && result.defizitExists === true) ||
        (result && result.defizitExists === false)
      );
    });
    return index > -1;
  }

  public withdrawControl() {
    this.isSaving = true;
    this.saveWithdrawnControl(this.controlFormData);
    this.isSaving = false;
  }

  

  public onSubmit() {
    if (!this.isControlWithdrawn()) {
      this.isSaving = true;
      if (
        this.controlFormData.isFormDataValid(this.statusPhase1Value) &&
        (this.controlFormData.statusPhaseOne === ControlPhase.InProgress ||
          this.isValidDeficite())
      ) {
        try {
          this.controlFormData.deficitValues = this.selectedDeficits;
          this.setAssignedUserOnPostFormData();
          this.saveKontrollData(this.controlFormData);
        } catch (error) {
          this.noDefiziteSelected = true;
          this.isSaving = false;  
          this._toaster.show({
            heading: 'errors.heading',
            description: 'controls.form.dedicated-user-control-error',
            type: 'error',
          });
        }
      } else {
        this.noDefiziteSelected = true;
        this.isSaving = false;
      }  
    } else {
      this._toaster.show({
        heading: 'errors.heading',
        description: 'controls.form.withdrawn-control-error',
        type: 'error',
      });
    }
    
  }

  private isValidDeficite(): boolean {
    if (
      this.deficiteExistInSelectedCombination &&
      this.hasAtLeastOneSelectedDefizit(this.selectedDeficits)
    ) {
      return true;
    }

    return !this.deficiteExistInSelectedCombination;
  }

  public saveWithdrawnControl(controlFormData: Control){
    if (this._onlineOfflineService.isOnline) {
      this.controlFormService
            .withdrawControl(controlFormData).subscribe(
              () => {
                this.isSaving = false;
                this.activeModal.close();
              },
              (err) => {
                this.isSaving = false;
                this.activeModal.close();
                console.log(err);
              }
      );
    } else {
      this._offlineSyncService.saveControlToCache(
        controlFormData,
        this.controlFormData.comment,
        this.filesToUpload,
        this.getAttachmentsToDelete(),
        this._contractId
      );
      this.isSaving = false;
      this.activeModal.close();
    }
  }

  private saveKontrollData(kontrolleFormData: Control) {
    this.isSaving = true;
    if (this.controlSample && this.controlSample.id) {
      if (!this.controlSample.isEditable && this.controlFormData.comment) {
        if (this._onlineOfflineService.isOnline) {
          this.createComment(this.controlSample.id).subscribe(() => { });
        } else {
          this._offlineSyncService.saveControlCommentToCache(
            this.controlFormData.comment,
            this.controlSample.id,
            this._contractId
          );
          this.isSaving = false;
          this.activeModal.close();
        }
      } else {
        if (this._onlineOfflineService.isOnline) {
          this.controlFormService.updateKontrolle(kontrolleFormData).subscribe(
            () => {
              if (this.controlFormData.comment) {
                this.saveDependentObjects([
                  this.createComment(this.controlSample.id),
                  ...this.updateAttachments(),
                ]);
              } else {
                this.saveDependentObjects(this.updateAttachments());
              }
            },
            (err) => {
              this.isSaving = false;
              console.log(err);
            }
          );
        } else {
          this._offlineSyncService.saveControlToCache(
            kontrolleFormData,
            this.controlFormData.comment,
            this.filesToUpload,
            this.getAttachmentsToDelete(),
            this._contractId
          );
          this.isSaving = false;
          this.activeModal.close();
        }
      }
    } else {
      if (this._onlineOfflineService.isOnline) {
        this.controlFormService.createKontrolle(kontrolleFormData).subscribe(
          (control) => {
            this.controlSample = new ControlResponse();
            this.controlSample.id = control.id;
            if (this.controlFormData.comment) {
              this.saveDependentObjects([
                this.createComment(control.id),
                ...this.updateAttachments(),
              ]);
            } else {
              this.saveDependentObjects(this.updateAttachments());
            }
          },
          (err) => {
            this.isSaving = false;
            console.log(err);
          }
        );
      } else {
        this._offlineSyncService.saveControlToCache(
          kontrolleFormData,
          this.controlFormData.comment,
          this.filesToUpload,
          this.getAttachmentsToDelete(),
          this._contractId
        );
        this.isSaving = false;
        this.activeModal.close();
      }
    }
  }

  saveDependentObjects(callbacks$: (Observable<object> | Observable<void>)[]) {
    this.isSaving = true;
    forkJoin(callbacks$).subscribe(
      () => {
        this.activeModal.close();
      },
      (err) => {
        this.isSaving = false;
        console.log(err);
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  private createComment(controlId: string) {
    return this.controlFormService.createControlComment(
      this.controlFormData.comment,
      controlId
    );
  }

  private updateAttachments(): (Observable<object> | Observable<void>)[] {
    this.isSaving = true;
    this.fileError = false;
    const attachmentsToDelete = this.getAttachmentsToDelete();
    const deleteAttachments$ = attachmentsToDelete.map((a) =>
      this.controlFormService.deleteControlAttachment(a, this.controlSample.id)
    );
    if (this.filesToUpload.length === 0) {
      if (attachmentsToDelete.length === 0) {
        return [of({})];
      }
      return deleteAttachments$;
    }
    const createAttachments$ = this.controlFormService
      .createControlAttachments(this.filesToUpload, this.controlSample.id)
      .pipe(
        map((event) => this.reportFileProgress(event)),
        catchError((err) => this.catchFileError(err))
      );

    return [createAttachments$, ...deleteAttachments$];
  }

  private getAttachmentsToDelete() {
    return this.controlSample && this.controlSample.attachments
      ? this.controlSample.attachments
        .filter(
          (a) =>
            this.controlFormData.attachments.findIndex(
              (f) => f.id === a.id
            ) === -1
        )
        .map((a) => a.id)
      : [];
  }

  reportFileProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      this.progress = Math.round((event.loaded / event.total) * 100);
    }
  }

  catchFileError(error: HttpErrorResponse) {
    this.fileError = true;
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    this._toaster.show({
      heading: 'files.upload-failed',
      description: 'files.upload-failed-detail',
      type: 'error',
    });
    return throwError(errorMessage);
  }

  private assignGewerkFromFilter() {
    if (
      this.filterData &&
      this.filterData.selectedTrade &&
      this.filterData.selectedTrade.id !== allId
    ) {
      this.controlFormData.tradeConfigurationId = this.filterData.selectedTrade.id;
      this.gewerkDisabled = true;
    }
  }

  private assignContractPositionFromFilter() {
    if (
      this.filterData &&
      this.filterData.selectedContractPosition &&
      this.filterData.selectedContractPosition.id !== allId
    ) {
      this.controlFormData.contractPositionConfigurationId = this.filterData.selectedContractPosition.id;
      this.contractDisabled = true;
    }
  }

  private assignStichprobenartFromFilter() {
    if (
      this.filterData &&
      this.filterData.selectedCheckType &&
      this.filterData.selectedCheckType.id !== allId
    ) {
      this.controlFormData.checkTypeConfigurationId = this.filterData.selectedCheckType.id;
      this.controlTypeDisabled = true;
    }
  }

  buildSpotCheckLink() {
    this._contractService.selectedContract
      .pipe(take(1))
      .subscribe((contract) => {
        let url = `${window.location.origin}/contract/${contract.urlPath}/spot-check/edit-form?controlId=${this.controlId}`;
        if (this.controlFormData.tradeConfigurationId) {
          url = `${url}&tradeId=${this.controlFormData.tradeConfigurationId}`;
        }
        if (this.controlFormData.checkTypeConfigurationId) {
          url = `${url}&checkTypeId=${this.controlFormData.checkTypeConfigurationId}`;
        }
        if (this.controlFormData.contractPositionConfigurationId) {
          url = `${url}&contractPositionId=${this.controlFormData.contractPositionConfigurationId}`;
        }
        if (this.controlFormData.ticketNumber) {
          url = `${url}&ticketNumber=${this.controlFormData.ticketNumber}`;
        }
        this.createSpotCheckLink = url;
      });
  }

  removeAttachment(attachment: FileAttachment) {
    this.controlFormData.attachments = this.controlFormData.attachments.filter(
      (a) => a.id !== attachment.id
    );
  }

  removeFile(file: File) {
    this.filesToUpload = this.filesToUpload.filter((f) => f.name !== file.name);
  }

  selectFile(files: FileList) {
    this.filesToUpload = [...this.filesToUpload, ...Array.from(files)];
  }

  openFileSelect(event$: MouseEvent) {
    this.fileInput.nativeElement.click();
  }

  getControlStateName(state: string) {
    if (this.controlFormData.rating === Rating.NoClaim) {
      return '-';
    }
    return this._translate.instant(state);
  }

  ngOnDestroy(): void {
    this._contractUsersSub.unsubscribe();
  }
}
