<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'cache.modal.title' | translate }}</h4>
</div>
<div class="modal-body">
  <div>
    {{ 'cache.modal.body' | translate }}
  </div>
  <div>
    <app-loading-spinner [showText]="false"></app-loading-spinner>
  </div>
</div>