import { Deficit } from './deficit.resource';
import { DeficitVariant } from '../deficit-variant';
import { PostDeficitTag } from './post-deficit-tag.resource';

export class DeficitPostResource {
  id: string;
  checkTypeIds: string[];
  contractPositionIds: string[];
  deficitGroupId: string;
  titleDefault: string;
  descriptionDefault: string;
  isActive: boolean;
  mappings: { checkTypeId: string, contractPositionId: string, deficitVariant: DeficitVariant }[];
  translations: { title: string, description: string, languageId: string }[];
  tags: PostDeficitTag[];

  constructor(deficit: Deficit) {
    this.id = deficit.id;
    this.deficitGroupId = deficit.deficitGroupId;
    this.titleDefault = deficit.titleDefault;
    this.descriptionDefault = deficit.descriptionDefault;
    this.mappings = deficit.mappings.map(map => {
      return {
        checkTypeId: map.checkTypeId,
        contractPositionId: map.contractPositionId,
        deficitVariant: map.deficitVariant
      }
    }),
      this.translations = deficit.translations.map(t => {
        return {
          title: t.title,
          description: t.description,
          languageId: t.languageId
        }
      }),
      this.isActive = deficit.isActive;
      this.tags = deficit.tags.map(t => {
        return {deficitId: deficit.id, deficitTagId: t.id}
      });
  }
}