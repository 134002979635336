import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DeficitGroup } from 'app/shared/masterData/deficit/deficit-group.resource';
import { HttpClient } from '@angular/common/http';
import { Deficit } from 'app/shared/masterData/deficit/deficit.resource';
import { ReplaySubject, Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DeficitPostResource } from './deficit-post.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';
import { DeficitLoadFilter } from 'app/admin/allgemein/deficites/deficit-load-filter';
import { DeficitTranslation } from './deficit-translation.resource';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';
import { DeficitTag } from './deficit-tag.resource';

@Injectable({
    providedIn: 'root'
})
export class DeficitService {

    private deficitUrl = `${environment.apiUrl}/api/deficits`;
    private deficitGroupUrl = `${environment.apiUrl}/api/deficitgroups`;

    private deficitMap: Map<string, Deficit> = new Map<string, Deficit>();
    private deficitGroupMap: Map<string, DeficitGroup> = new Map<string, DeficitGroup>();
    private deficits: Deficit[] = [];
    private deficitGroups: DeficitGroup[] = [];

    private newValues = new ReplaySubject(1);

    constructor(private _http: HttpClient) {
        this.buildCache()
    }

    private buildCache() {

        const groups$ = this._http.get<PagedResultResponse<DeficitGroup>>(this.deficitGroupUrl).pipe(take(1));
        const deficits$ = this._http.get<PagedResultResponse<Deficit>>(this.deficitUrl).pipe(take(1));
        forkJoin(groups$, deficits$)
            .subscribe(([groups, deficits]) => {
                groups.items.map(deficitGroup => {
                    this.deficitGroupMap.set(deficitGroup.id, deficitGroup);
                });
                deficits.items.map(deficit => {
                    this.deficitMap.set(deficit.id, deficit);
                })
                this.deficitGroups = groups.items;
                this.deficits = deficits.items;
                this.newValues.next(true);
            });
    }

    public get deficitsObservable(): Observable<Deficit[]> {
        return this.newValues.pipe(
            map(() => this.deficits)
        )
    }

    public get deficitGroupsObservable(): Observable<DeficitGroup[]> {
        return this.newValues.pipe(
            map(() => this.deficitGroups)
        )
    }

    public getDeficitGroups(skipNr?: number, takeNr?: number, filter?: NameLoadFilter): Observable<PagedResultResponse<DeficitGroup>> {
        var url = `${this.deficitGroupUrl}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            url = LoadFilterHelper.addBaseFilterValues(url, filter);
        }
        return this._http.get<PagedResultResponse<DeficitGroup>>(url);
    }

    public getDeficits(skipNr?: number, takeNr?: number, filter?: DeficitLoadFilter): Observable<PagedResultResponse<Deficit>> {
        var url = `${this.deficitUrl}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter.name) {
                url = `${url}&name=${filter.name}`;
            }
            if (filter.deficitGroup) {
                url = `${url}&deficitGroup=${filter.deficitGroup}`;
            }
            if (filter.checkType) {
                url = `${url}&checkType=${filter.checkType}`;
            }
            if (filter.contractPosition) {
                url = `${url}&contractPosition=${filter.contractPosition}`;
            }
            if (filter.isSpotCheck !== undefined) {
                url = `${url}&isSpotCheck=${filter.isSpotCheck}`;
            }
            if (filter.isControl !== undefined) {
                url = `${url}&isControl=${filter.isControl}`;
            }
            if (filter['contractId'] !== undefined) {
                url = `${url}&contractid=${filter['contractId']}`;
            }
            if (filter['isSelected'] !== undefined) {
                url = `${url}&isselected=${filter['isSelected']}`;
            }
            if (filter.description) {
                url = `${url}&description=${filter.description}`;
            }
            if (filter.isActive !== undefined) {
                url = `${url}&isactive=${filter.isActive}`;
            }
            if (filter.languageId) {
                url = `${url}&languageId=${filter.languageId}`;
            }
            if (filter.entityNumber) {
                url = `${url}&entityNumber=${filter.entityNumber}`;
            }
            if (!!filter.orderBy) {
                url = `${url}&orderBy=${filter.orderBy}`;
            }
            if (filter.configurationActive !== undefined) {
                url = `${url}&configurationActive=${filter.configurationActive}`;
            }
            if (filter.tag) {
                url = `${url}&tag=${filter.tag}`;
            }
        }
        return this._http.get<PagedResultResponse<Deficit>>(url);
    }

    public getDeficitGroup(id: string): Observable<DeficitGroup> {
        return this.newValues.pipe(
            map(() => this.deficitGroupMap.get(id))
        )
    }

    public getDeficit(id: string): Observable<Deficit> {
        return this.newValues.pipe(
            map(() => this.deficitMap.get(id))
        )
    }

    public getDeficitHistory(id: string): Observable<Deficit[]> {
        return this._http.get<Deficit[]>(`${environment.apiUrl}/api/deficits/${id}/history`);
    }


    public getDeficitGroupHistory(id: string): Observable<DeficitGroup[]> {
        return this._http.get<DeficitGroup[]>(`${environment.apiUrl}/api/deficitgroups/${id}/history`);
    }

    public getDeficitTranslations(id: string): Observable<DeficitTranslation[]> {
        return this._http.get<DeficitTranslation[]>(`${this.deficitUrl}/${id}/translations`);
    }

    public createDeficit(deficit: Deficit) {
        return this._http.post<Deficit>(this.deficitUrl, new DeficitPostResource(deficit));
    }

    public updateDeficit(deficit: Deficit) {
        return this._http.put<Deficit>(`${this.deficitUrl}/${deficit.id}`, new DeficitPostResource(deficit));
    }

    public deleteDeficit(deficitId: string) {
        return this._http.delete(`${this.deficitUrl}/${deficitId}`);
    }

    public createDeficitGroup(deficitGroup: DeficitGroup) {
        return this._http.post<DeficitGroup>(this.deficitGroupUrl, deficitGroup);
    }

    public updateDeficitGroup(deficitGroup: DeficitGroup) {
        return this._http.put<Deficit>(`${this.deficitGroupUrl}/${deficitGroup.id}`, deficitGroup);
    }

    public deleteDeficitGroup(deficitGroupId: string) {
        return this._http.delete(`${this.deficitGroupUrl}/${deficitGroupId}`);
    }

}