import { ContractPosition } from '../contract-position/contract-position.resource';
import { CheckType } from '../check-type/check-type.resource';
import { DeficitVariant } from '../deficit-variant';

export class DeficitMappingBase {

    constructor(id: string = null, deficitId: string = null, checkTypeId: string = null,
        checkType: CheckType = null, contractPositionId: string = null, contractPosition: ContractPosition = null) {
        this.id = id
        this.deficitId = deficitId;
        this.contractPositionId = contractPositionId;
        this.checkTypeId = checkTypeId;
        this.checkType = checkType;
        this.contractPosition = contractPosition;
    }

    id?: string;
    deficitId: string;
    checkTypeId: string;
    checkType: CheckType;
    contractPositionId: string;
    contractPosition: ContractPosition;
}

export class DeficitMapping extends DeficitMappingBase {

    constructor(id: string = null, deficitId: string = null, checkTypeId: string = null,
        checkType: CheckType = null, contractPositionId: string = null, contractPosition: ContractPosition = null,
        deficitVariant: DeficitVariant = null) {
        super(id, deficitId, checkTypeId, checkType, contractPositionId, contractPosition)
        this.deficitVariant = deficitVariant;
    }

    id?: string;
    deficitId: string;
    checkTypeId: string;
    checkType: CheckType;
    contractPositionId: string;
    contractPosition: ContractPosition;
    deficitVariant: DeficitVariant;
}