<div *ngIf="sampleFigures">
  <div class="stats-header">
    <h3 class="brd bre">{{ header | translate }}</h3>
  </div>

  <div class="container statistics">
    <section class="row text-center justify-content-center mt-5">
      <div class="col-sm-6 col-md-3 placeholder">
        <div class="stats" (click)="statsClicked(statisticType.NoClaim)"
          [ngClass]="{'active': currentStatType == statisticType.NoClaim}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.keineBeanstandungenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-thumbs-up green d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.no-claims' | translate }}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder">
        <div class="stats" (click)="statsClicked(statisticType.Claim)"
          [ngClass]="{'active': currentStatType == statisticType.Claim}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.beanstandungenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-thumbs-down yellow d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.claims' | translate }}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder">
        <div class="stats" (click)="statsClicked(statisticType.Reclamation)"
          [ngClass]="{'active': currentStatType == statisticType.Reclamation}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.reklamationenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-thumbs-down red d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.reclamations' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && !sampleFigures.isKontrollen">
        <!-- Einspruch zurücknahmen (claim cancelled) entspricht Phase 3  -->
        <div class="stats" (click)="statsClicked(statisticType.ClaimCancelled)"
          [ngClass]="{'active': currentStatType == statisticType.ClaimCancelled}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.einspruchZuruecknahmenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-undo d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.claim-withdrawn' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3" *ngIf="sampleFigures && sampleFigures.isKontrollen">
        <!-- Strittig (Conflict) entspricht Phase 3  -->
        <div class="stats" (click)="statsClicked(statisticType.NotResolved)"
          [ngClass]="{'active': currentStatType == statisticType.NotResolved}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.nichtBehobenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-thumbs-down d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.not-fixed' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && sampleFigures.isKontrollen">
        <!-- In Bearbeitung  -->
        <div class="stats" (click)="statsClicked(statisticType.InProgress)"
          [ngClass]="{'active': currentStatType == statisticType.InProgress}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.inBearbeitungCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-hourglass d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.in-progress' | translate}}</span>
        </div>
      </div>
    </section>
    <section class="row text-center justify-content-center mt-5">
      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && !sampleFigures.isKontrollen">
        <!-- Reklamation zurücknahmen (conflict cancelled) entspricht Phase 3  -->
        <div class="stats" (click)="statsClicked(statisticType.ReclamationCancelled)"
          [ngClass]="{'active': currentStatType == statisticType.ReclamationCancelled}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.zuruecknahmenCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-undo d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.reclamation-withdrawn' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && !sampleFigures.isKontrollen">
        <!-- Strittig (Conflict) entspricht Phase 3  -->
        <div class="stats" (click)="statsClicked(statisticType.Conflict)"
          [ngClass]="{'active': currentStatType == statisticType.Conflict}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.strittigCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-bolt d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.conflict' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && !sampleFigures.isKontrollen">
        <!-- In Bearbeitung  -->
        <div class="stats" (click)="statsClicked(statisticType.InProgress)"
          [ngClass]="{'active': currentStatType == statisticType.InProgress}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.inBearbeitungCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-hourglass d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.in-progress' | translate}}</span>
        </div>
      </div>

      <div class="col-sm-6 col-md-3 placeholder" *ngIf="sampleFigures && !sampleFigures.isKontrollen">
        <!-- Keine Reaktion durch DL  -->
        <div class="stats" (click)="statsClicked(statisticType.NoReaction)"
          [ngClass]="{'active': currentStatType == statisticType.NoReaction}">
          <h3 *ngIf="sampleFigures">{{sampleFigures.noDlReactionCount()}}</h3>
          <div *ngIf="!sampleFigures" class="loading">
            <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa fa-hourglass d-block" aria-hidden="true"></i>
          <span>{{ 'statistics.no-reaction' | translate}}</span>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 mt-3" *ngIf="currentStatType || currentStatType == 0">
        <div class="alert alert-info" role="alert">
          {{ 'statistics.filter-active' | translate}}
        </div>
      </div>
    </section>
  </div>
</div>