<div class="modal-header">
  <h4 *ngIf="inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.languages.dialog.title-new" | translate }}
  </h4>
  <h4 *ngIf="!inNewMode" class="modal-title" id="modal-basic-title">
    {{ "global-admin.languages.dialog.title-edit" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mt-3">
    <label class="label">{{
      "global-admin.languages.dialog.name" | translate
      }}</label>
    <input #langName="ngModel" class="form-control form-control-lg" type="text" [(ngModel)]="language.longName"
      required />
  </div>
  <div class="mt-3">
    <label class="label">{{
      "global-admin.languages.dialog.name-short" | translate
      }}</label>
    <input #langShort="ngModel" class="form-control form-control-lg" type="text" [(ngModel)]="language.shortName"
      required readonly />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">
    <i class="icon" class="fas fa-ban"></i>
    {{ "shared.dialog.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-dark" [disabled]="langName.errors || langShort.errors"
    (click)="activeModal.close(language)">
    <i class="icon" class="fas fa-save"></i>
    {{ "shared.dialog.save" | translate }}
  </button>
</div>