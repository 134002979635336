import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../user-profile.service';
import { Router, NavigationEnd } from '@angular/router';
import { take, windowWhen } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ContractService } from 'app/shared/masterData/contract/contract.service';

@Component({
  selector: 'epo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public currentDisplayUserName = '-';
  header: string;
  subheader: string;

  get currentLang(): string {
    return this.translate.getDefaultLang().toUpperCase();
  }

  showLangPicker: boolean = true;

  constructor(private _userService: UserProfileService,
    private router: Router,
    private translate: TranslateService,
    private contarctService: ContractService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let route = this.router.routerState.root;
        while (route.firstChild)
          route = route.firstChild;
        route.data.pipe(
          take(1)
        ).subscribe(data => {
          this.header = data.header;
          this.subheader = data.subheader;
        })
      }
    });
    this.contarctService.selectedContract.subscribe(c => {
      this.showLangPicker = !c;
    })
  }

  ngOnInit() {
    const currentUser = this._userService.cachedUser;
    if (currentUser) {
      this.currentDisplayUserName = `${currentUser.firstname} ${currentUser.lastname}`;
    } else {
      this.currentDisplayUserName = 'Unknown user (Error)';
    }
  }

  logout() {
    this._userService.logout();
  }

  selectLang(lang: string) {
    if (this.translate.getDefaultLang() !== lang) {
      localStorage.setItem('lang', lang);
      window.location.reload();
    }
  }
}
