<div class="container more-width-container mt-5">
  <div class="row stats-header">
    <div class="col">
      <h3 class="top-right-button pointer" (click)="openCreateContractPositionModal()">
        <i class="fa fa-plus"></i>
        {{ "global-admin.contract-positions.new" | translate }}
      </h3>
    </div>
  </div>

  <div class="container pt-5">
    <h3 class="pb-2">
      {{ "global-admin.contract-positions.title" | translate }}
    </h3>
    <div>
      <table class="table table-hover">
        <thead class="thead">
          <th></th>
          <th>{{ "global-admin.contract-positions.name" | translate }}</th>
          <th>{{ "shared.active" | translate }}</th>
          <th></th>
          <th></th>
        </thead>
        <th></th>
        <th>
          <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()" [getValuesFunction]="
              globalAutocompleteService.getMatchingContractPositionNames
            " [languageId]="languageId">
          </epo-column-filter>
        </th>
        <th class="icon-container">
          <div ngbDropdown class="d-block">
            <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
              {{ loadFilter.isActive | boolToString: "shared.all" | translate }}
            </button>
            <div ngbDropdownMenu class="dropdown-menu">
              <a *ngFor="let isActive of boolSelection" class="dropdown-item" (click)="isActiveSelected(isActive)"
                ngbDropdownItem>{{ isActive | boolToString: "shared.all" | translate }}</a>
            </div>
          </div>
        </th>
        <tbody *ngIf="isLoading" class="loading-placeholder">
          <tr>
            <td colspan="3">
              <app-loading-spinner></app-loading-spinner>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr *ngFor="let contractPosition of items">
            <td class="table-item-name">
              <div (click)="showHistory(contractPosition)" class="ml-1 fa-border history-button"
                title="{{ 'shared.history' | translate }}">
                <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
              </div>
            </td>
            <td class="table-item-name">{{ contractPosition.name }}</td>
            <td>
              <i class="{{ contractPosition.isActive | toBoolSymbol: 2 }}"></i>
            </td>
            <td class="edit" class="no-mobile">
              <button type="button" class="btn btn-outline-primary float-lg-right"
                (click)="openEditContractPositionModal(contractPosition)">
                <i class="fas fa-edit"></i>
                {{ "shared.buttons.edit" | translate }}
              </button>
            </td>
            <td class="delete" class="no-mobile">
              <button type="button" class="btn btn-outline-danger float-lg-right"
                (click)="openDeleteModal(contractPosition)">
                <i class="fa fa-trash" aria-hidden="true"></i>
                {{ "shared.buttons.delete" | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>