import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { BaseExistingResource } from 'app/shared/models/base-existing.resource';

export class ConfigHistoryHelper<T extends BaseExistingResource> {

    constructor(private _valued: T[], private _config: PropertyDisplayMapping[]) {
    }

    public generateHistoryEntries(): T[] {
        const historyResources = [new BaseExistingResource(), ...this._valued];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: any, newValues: any): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this._config) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                    changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if (changedProperty.newValue != null || changedProperty.oldValue != null) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }

        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }

    private discardEmptyString(input: string): string {
        if (!this.isString(input)) return input;
        if (input.trim()) return input;
        return null;
    }

    private isString(value) {
        return typeof value === 'string';
    }
}

