<div class="more-width-container mt-5">
  <div class="row stats-header">
    <div class="col">
      <h3 class="top-right-button pointer" (click)="openCreateDeficitModal()">
        <i class="fa fa-plus"></i>
        {{ "global-admin.deficits.new-deficit" | translate }}
      </h3>
    </div>
  </div>

  <div class="pt-5 table-outter-wrapper">
    <div class="header-cntainer">
      <h3 class="pb-2">{{ "global-admin.deficits.title" | translate }}</h3>
      <div class="item-counter" *ngIf="!isLoading">
        {{ "shared.count" | translate }} {{ pagingGlobalCount }}
      </div>
    </div>
    <table class="table table-hover">
      <thead class="thead">
        <th></th>
        <th>
          {{ "global-admin.deficits.deficit-group" | translate }}
          <span (click)="sort('DeficitGroup')">
            <i class="fas fa-sort" *ngIf="
                !isActiveSorting('DeficitGroup', 'Asc') &&
                !isActiveSorting('DeficitGroup', 'Desc')
              "></i>
            <i class="fas fa-sort-up" *ngIf="isActiveSorting('DeficitGroup', 'Asc')"></i>
            <i class="fas fa-sort-down" *ngIf="isActiveSorting('DeficitGroup', 'Desc')"></i>
          </span>
        </th>
        <th>
          {{ "global-admin.deficits.name" | translate }}
          <span (click)="sort('Name')">
            <i class="fas fa-sort" *ngIf="
                !isActiveSorting('Name', 'Asc') &&
                !isActiveSorting('Name', 'Desc')
              "></i>
            <i class="fas fa-sort-up" *ngIf="isActiveSorting('Name', 'Asc')"></i>
            <i class="fas fa-sort-down" *ngIf="isActiveSorting('Name', 'Desc')"></i>
          </span>
        </th>
        <th>{{ "global-admin.deficits.description" | translate }}</th>
        <th class="no-mobile">
          {{ "global-admin.deficits.spot-checks" | translate }}
        </th>
        <th class="no-mobile">
          {{ "global-admin.deficits.controls" | translate }}
        </th>
        <th class="no-mobile">
          {{ "global-admin.deficits.contract-positions" | translate }}
        </th>
        <th class="no-mobile">
          {{ "global-admin.deficits.types" | translate }}
        </th>
        <th class="no-mobile">
          {{ "global-admin.deficits.tags" | translate }}
        </th>
        <th class="no-mobile">
          {{ "global-admin.deficits.active" | translate }}
        </th>
        <th>
          ID
          <span (click)="sort('Number')">
            <i class="fas fa-sort" *ngIf="
                !isActiveSorting('Number', 'Asc') &&
                !isActiveSorting('Number', 'Desc')
              "></i>
            <i class="fas fa-sort-up" *ngIf="isActiveSorting('Number', 'Asc')"></i>
            <i class="fas fa-sort-down" *ngIf="isActiveSorting('Number', 'Desc')"></i>
          </span>
        </th>
        <th></th>
        <th colspan="2"></th>
      </thead>
      <th></th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.deficitGroup" (selectionChange)="onFilterChange()"
          [getValuesFunction]="globalAutocompleteService.getMatchingDeficitGroupNames" [languageId]="languageId">
        </epo-column-filter>
      </th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.name" (selectionChange)="onFilterChange()"
          [getValuesFunction]="globalAutocompleteService.getMatchingDeficitNames" [languageId]="languageId">
        </epo-column-filter>
      </th>
      <th>
        <epo-column-filter [(searchText)]="loadFilter.description" (searchTextChange)="onFilterChange()"
          [autocomplete]="false">
        </epo-column-filter>
      </th>
      <th class="icon-container no-mobile">
        <div ngbDropdown class="d-block">
          <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
            {{
            loadFilter.isSpotCheck | boolToString: "shared.all" | translate
            }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            <a *ngFor="let isSpotCheck of boolSelection" class="dropdown-item"
              (click)="isSpotCheckSelected(isSpotCheck)" ngbDropdownItem>{{ isSpotCheck | boolToString: "shared.all" |
              translate }}</a>
          </div>
        </div>
      </th>
      <th class="icon-container no-mobile">
        <div ngbDropdown class="d-block">
          <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
            {{ loadFilter.isControl | boolToString: "shared.all" | translate }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            <a *ngFor="let isControl of boolSelection" class="dropdown-item" (click)="isControlSelected(isControl)"
              ngbDropdownItem>{{ isControl | boolToString: "shared.all" | translate }}</a>
          </div>
        </div>
      </th>
      <th class="no-mobile">
        <epo-column-filter [(selection)]="loadFilter.contractPosition" (selectionChange)="onFilterChange()"
          [getValuesFunction]="
            globalAutocompleteService.getMatchingContractPositionNames
          " [languageId]="languageId">
        </epo-column-filter>
      </th>
      <th class="no-mobile">
        <epo-column-filter [(selection)]="loadFilter.checkType" (selectionChange)="onFilterChange()"
          [getValuesFunction]="
            globalAutocompleteService.getMatchingCheckTypeNames
          " [languageId]="languageId">
        </epo-column-filter>
      </th>
      <th class="no-mobile">
        <epo-column-filter [(selection)]="loadFilter.tag" (selectionChange)="onFilterChange()"
          [getValuesFunction]="globalAutocompleteService.getMatchingDeficitTagNames" [languageId]="languageId" [isActive]="true" >
        </epo-column-filter>
      </th>
      <th class="icon-container no-mobile">
        <div ngbDropdown class="d-block">
          <button class="btn btn-outline-primary dropdown-toggle" ngbDropdownToggle>
            {{ loadFilter.isActive | boolToString: "shared.all" | translate }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            <a *ngFor="let isActive of boolSelection" class="dropdown-item" (click)="isActiveSelected(isActive)"
              ngbDropdownItem>{{ isActive | boolToString: "shared.all" | translate }}</a>
          </div>
        </div>
      </th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.entityNumber" (selectionChange)="onFilterChange()"
          [getValuesFunction]="globalAutocompleteService.getMatchingDeficitNumbers">
        </epo-column-filter>
      </th>
      <tbody *ngIf="isLoading" class="loading-placeholder">
        <tr>
          <td colspan="9">
            <app-loading-spinner></app-loading-spinner>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr *ngFor="let deficit of deficits">
          <td class="table-item-name">
            <div (click)="showHistory(deficit)" class="ml-1 fa-border history-button"
              title="{{ 'shared.history' | translate }}">
              <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
            </div>
          </td>
          <td style="width: 10%">
            {{ deficit.deficitGroupName }}
          </td>
          <td style="width: 10%">
            {{ deficit.title }}
          </td>
          <td style="width: 15%; max-width: 4rem; word-wrap: break-word">
            <div class="description-container" [innerHTML]="deficit.description"></div>
          </td>
          <td style="width: 5%; min-width: 110px" class="icon-container no-mobile">
            <i class="{{ deficit.isSpotCheckDeficit | toBoolSymbol: 2 }}"></i>
          </td>
          <td style="width: 5%" class="icon-container no-mobile">
            <i class="{{ deficit.isControlDeficit | toBoolSymbol: 2 }}"></i>
          </td>
          <td colspan="2" style="width: 20%">
            <div *ngFor="let map of deficit.commonMappings" class="mapping-row">
              <div class="mapping-column">
                <p>
                  {{ map.contractPosition.name }}
                </p>
              </div>
              <div class="mapping-column">
                <p>
                  {{ map.checkType.name }}
                </p>
              </div>
            </div>
          </td>
          <td style="width: 10%;">
            <span *ngFor="let tag of deficit.tags" class="tag">
              {{ tag.name }}<span class="separator">,</span>
            </span>
          </td>
          <td style="width: 5%" class="icon-container no-mobile">
            <i class="{{ deficit.isActive | toBoolSymbol: 2 }}"></i>
          </td>
          <td style="width: 5%">
            {{ deficit.entityNumber }}
          </td>
          <td class="no-mobile button-container edit" style="width: 5%">
            <button type="button" class="btn btn-outline-primary" (click)="openEditDeficitModal(deficit)">
              <div class="flex-gap">
                <div><i class="fas fa-edit"></i></div>
                <div class="btn-text">{{ "shared.buttons.edit" | translate }}</div>
              </div>
            </button>
            <button type="button" class="btn btn-outline-danger" (click)="openDeleteModal(deficit)">
              <div class="flex-gap">
                <div><i class="fa fa-trash" aria-hidden="true"></i></div>
                <div class="btn-text">{{ "shared.buttons.delete" | translate }}</div>
              </div>
            </button>
          </td>
          <td class="no-desktop">
            <button type="button" class="btn btn-outline-primary float-lg-right"
              (click)="openEditDeficitModal(deficit)">
              {{ "shared.buttons.edit" | translate }}
            </button>
            <button type="button" class="btn btn-outline-danger float-lg-right" (click)="openDeleteModal(deficit)">
              {{ "shared.buttons.delete" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>