import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractPositionService } from 'app/shared/masterData/contract-position/contract-position.service';
import { ContractPosition } from 'app/shared/masterData/contract-position/contract-position.resource';
import { ModalEditContractPositionComponent } from './modal-contract-position-edit/modal-contract-position-edit.component';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { ContractPositionHistoryHelper } from './history/cotract-position-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';

@Component({
  selector: 'epo-contract-positions',
  templateUrl: './contract-positions.component.html',
  styleUrls: ['./contract-positions.component.scss']
})
export class ContractPositionsComponent extends BaseFilterPagingComponent<ContractPosition> {

  constructor(public _modalService: NgbModal,
    public _contractPositionService: ContractPositionService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _languageService: LanguageService) {
    super();
  }

  loadData() {
    this.isLoading = true;
    const langName = localStorage.getItem('lang') || 'en';
    this._languageService.languagesObservable.pipe(take(1)).subscribe(l => {
      const lang = l.find(l => l.shortName === langName.toUpperCase());
      if (lang) {
        this.languageId = lang.id;
        this.loadFilter.languageId = lang.id;
      } else {
        this.languageId = null;
        this.loadFilter.languageId = null;
      }
      const positions$ = this._contractPositionService.getContractPositions(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
      const languages$ = this._languageService.languagesObservable.pipe(take(1));
      forkJoin([positions$, languages$]).subscribe(([positions, languages]) => {
        this.items = positions.items;
        this.pagingGlobalCount = positions.count;
        this.languages = languages;
        this.isLoading = false;
      }, err => {
        console.log(err);
      });
    });
  }

  public openCreateContractPositionModal() {

    const contractPosition = new ContractPosition();
    contractPosition.isActive = true;
    const modalRef = this._modalService.open(ModalEditContractPositionComponent, { centered: true });
    modalRef.componentInstance.contractPosition = contractPosition;
    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this._contractPositionService.createContractPosition(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openEditContractPositionModal(contractPosition: ContractPosition) {

    const modalRef = this._modalService.open(ModalEditContractPositionComponent, { centered: true });
    modalRef.componentInstance.contractPosition = Object.assign({}, contractPosition);
    modalRef.componentInstance.inEditMode = false;

    modalRef.result.then((result) => {
      this._contractPositionService.updateContractPosition(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openDeleteModal(contractPosition: ContractPosition) {
    const modalRef = this._modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = contractPosition;

    modalRef.result.then((position) => {
      this._contractPositionService.deleteContractPosition(position.id).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    });
  }

  public showHistory(contractPosition: ContractPosition) {
    this._contractPositionService.getContractPositionHistory(contractPosition.id).subscribe(history => {
      const changeHistory = new ContractPositionHistoryHelper(history, this.languages).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: contractPosition.name };
    });
  }
}