import { Component, OnInit } from '@angular/core';
import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';
import { environment } from 'environments/environment';
import { Language } from '../masterData/language/language.resource';

@Component({
    template: ''
  })

export abstract class BaseFilterPagingComponent<T> implements OnInit{

    public loadFilter: NameLoadFilter = new NameLoadFilter();
    items: T[];

    public languages: Language[];
    public languageId: string;

    public isLoading = false;
    public pagingGlobalCount = 0;
    public pageSize: number;
    public currentPage = 1;
    set page(val) {
        if (val == this.currentPage) return;
        this.currentPage = val;
        if (this.currentPage)
            this.loadData();
    }
    get page() {
        return this.currentPage;
    }

    public boolSelection = [undefined, true, false];

    constructor() {
        this.pageSize = environment.pageSize;
    }

    ngOnInit(): void {
        this.loadData();
    }

    onFilterChange() {
        this.currentPage = 1;
        this.loadData();
    }

    isActiveSelected(value) {
        this.loadFilter.isActive = value;
        this.onFilterChange();
    }

    abstract loadData();
}