<div class="container-wrapper">
  <div class="container">
    <div class="d-flex bd-highlight mb-3 no-mobile">
      <div class="ml-auto p-2 bd-highlight head">
        <h4>{{ header | translate }}</h4>
        <span class="pl-2">{{subheader | translate }}</span>
      </div>
      <div class="ml-auto p-2 bd-highlight username">
        <div ngbDropdown>
          <button class="btn btn-light" id="dropdownBasic1" ngbDropdownToggle>{{currentDisplayUserName}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" (click)="logout()">Logout</button>
          </div>
        </div>
        <div ngbDropdown *ngIf="showLangPicker">
          <button class="btn btn-light" id="langDropDown" ngbDropdownToggle>{{currentLang}}</button>
          <div ngbDropdownMenu aria-labelledby="langDropDown">
            <button class="dropdown-item" (click)="selectLang('de')">DE</button>
            <button class="dropdown-item" (click)="selectLang('en')">EN</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div ngbDropdown class="username no-desktop">
  <button class="btn btn-light" id="userDropDown" ngbDropdownToggle>{{currentDisplayUserName}}</button>
  <div ngbDropdownMenu aria-labelledby="userDropDown">
    <button class="dropdown-item" (click)="logout()">Logout</button>
  </div>
</div>