<epo-filter [filterSettings]="filterSettings"></epo-filter>


<div class="container mt-86">
  <div class="row">
    <main class="col-sm-12">
      <div class="stats-header">
        <h3 class="brd bre">{{ 'shared.my-planing-overview' | translate }}</h3>
      </div>

      <div class="card-deck">
        <div class="col-sm-12 col-md-6 col-xl-4" *ngFor="let spCard of stichprobenKarten">
          <epo-card [card]="spCard" [topic]="'shared.spot-check' | translate" (onAddClicked)="addStichprobe($event)"
            (onEditTargetsClicked)="editTargets()"></epo-card>
        </div>
      </div>
    </main>
  </div>
</div>
