import { HistoryEntryModel } from 'app/shared/components/entity-history/history-entry.model';
import { ChangedProperty } from 'app/shared/components/entity-history/changed-property/changed-property.model';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { RandomSampleTarget } from 'app/shared/models/random-sample-target';
import { EInterval } from 'app/shared/models/interval';
import { AcceptanceTarget } from 'app/acceptance/acceptance-targets/acceptance-target';

export class CheckTargetHistoryHelper {

    constructor(private _targets: RandomSampleTarget[] | AcceptanceTarget[]) {
    }

    private checkProperties: PropertyDisplayMapping[] = [
        { propertyName: 'interval', displayName: 'shared.interval', displayValueTransformation: (value) => this.translateInterval(value) },
        { propertyName: 'amount', displayName: 'shared.amount' }
    ]

    public generateHistoryEntries(): HistoryEntryModel[] {
        const historyResources = [new RandomSampleTarget(), ...this._targets];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: RandomSampleTarget | AcceptanceTarget, newValues: RandomSampleTarget | AcceptanceTarget): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this.checkProperties) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                    changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if (changedProperty.newValue != null || changedProperty.oldValue != null) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }

        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }

    private discardEmptyString(input: string): string {
        if (!(typeof input === 'string')) return input;
        if (input.trim()) return input;
        return null;
    }

    private translateInterval(value: EInterval) {
        switch (value) {
            case EInterval.monthly:
                return 'shared.monthly';
            case EInterval.quarterly:
                return 'shared.quarterly';
            case EInterval.yearly:
                return 'shared.yearly';
            default: '-';
        }
    }
}

