<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div class="modal-body" *ngIf="!isLoading">
  <form [formGroup]="acceptanceForm" (ngSubmit)="onSubmit()">
    <fieldset [disabled]="withdrawnAcceptance">
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.period' | translate }}</b>
      </div>
  
      <div formGroupName="acceptanceRange">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label label">{{ 'acceptances.form.year' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedAcceptanceYear">
              <option *ngFor="let year of acceptanceYearList" [value]="year">{{year}}</option>
            </select>
          </div>
        </div>
  
        <div class=" form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.interval-type' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedIntervalType" (change)="changeIntervalDivider()">
              <option *ngFor="let type of intervalTypeList" [value]="type">{{type | translate}}</option>
            </select>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            *ngIf="showQuartersSelection">{{ 'acceptances.form.quarter' | translate }}</label>
          <label class="col-sm-3 col-form-label"
            *ngIf="showMonthsSelection">{{ 'acceptances.form.month' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedIntervalAmount" *ngIf="showQuartersSelection"
              (change)="handleTimeIntervalChange()">
              <option *ngFor="let quarter of quarterList" [value]="quarter">{{quarter}}</option>
            </select>
            <select class="custom-select" formControlName="selectedIntervalAmount" *ngIf="showMonthsSelection"
              (change)="handleTimeIntervalChange()">
              <option *ngFor="let month of monthsList" [value]="month">{{month}}</option>
            </select>
          </div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.content' | translate }}</b>
      </div>
  
      <div formGroupName="agreement">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.contract-position' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedContractPos" (change)="handleContractPositionChange()">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!this.form.agreement['controls'].selectedContractPos.value"></option>
              <!---->
              <option *ngFor="let position of contractPositions" [value]="position.id">{{position.contractPosition.name}}
              </option>
            </select>
            <div *ngIf="submitted && form.agreement['controls'].selectedContractPos.invalid" class="alert alert-danger"
              role="alert">
              {{ 'acceptances.form.select-contract-position' | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.trade' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedEstate" (change)="handleEstateChange()">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!this.form.agreement['controls'].selectedEstate.value"></option>
              <!---->
              <option *ngFor="let tradeConfig of tradeConfigurations" [value]="tradeConfig.id">{{tradeConfig.trade.name}}
              </option>
            </select>
            <div *ngIf="submitted && form.agreement['controls'].selectedEstate.invalid" class="alert alert-danger"
              role="alert">
              {{ 'acceptances.form.select-trade' | translate }}
            </div>
          </div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.preparation' | translate }}</b>
      </div>
      <div formGroupName="preparation">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.work-preparation' | translate }}</label>
          <div class="col-sm-9">
            <div class="p-2 flex-fill align-self-center checkAreaPositive">
              <label class="checkbox-container">
                <input type="checkbox" name="checkedPositive" formControlName="workPreparation" id="inputPreparation">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.performance-complete' | translate }}</label>
          <div class="col-sm-9">
            <div class="p-2 flex-fill align-self-center checkAreaPositive">
              <label class="checkbox-container">
                <input type="checkbox" name="checkedPositive" formControlName="performance" id="inputPerformance">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label
            class="col-sm-3 col-form-label">{{ 'acceptances.form.performance-control-responsible' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedControlResponsible">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!this.form.preparation['controls'].selectedControlResponsible.value">
              </option>
              <!---->
              <option *ngFor="let responsibleControl of responsiblesControl" [value]="responsibleControl.value">
                {{responsibleControl.displayName | translate}}</option>
            </select>
            <div *ngIf="submitted && form.preparation['controls'].selectedControlResponsible.invalid"
              class="alert alert-danger" role="alert">
              {{ 'acceptances.form.select-performance-control-responsible' | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.performance-main-responsible' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="selectedMainResponsible" name="selectedMainResponsible">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!this.form.preparation['controls'].selectedMainResponsible.value">
              </option>
              <!---->
              <option *ngFor="let mainResponsible of mainResponsibles" [value]="mainResponsible.value">
                {{mainResponsible.displayName | translate}}</option>
            </select>
            <div *ngIf="submitted && form.preparation['controls'].selectedMainResponsible.invalid"
              class="alert alert-danger" role="alert">
              {{ 'acceptances.form.select-performance-main-responsible' | translate }}
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.documentation-complete' | translate }}</label>
          <div class="col-sm-9">
            <div class="p-2 flex-fill align-self-center checkAreaPositive">
              <label class="checkbox-container">
                <input type="checkbox" name="checkedPositive" formControlName="documentsPerformance"
                  id="inputDocPerformance">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.documents-exist' | translate }}</label>
          <div class="col-sm-9">
            <div class="p-2 flex-fill align-self-center checkAreaPositive">
              <label class="checkbox-container">
                <input type="checkbox" name="checkedPositive" formControlName="documentsControl"
                  id="inputDocumentsControl">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.quality-rating' | translate }}</b>
      </div>
      <div formGroupName="rating">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.performance-quality' | translate }}</label>
          <div class="col-sm-9">
            <input class="form-control" formControlName="performanceQuality" type="number" value=""
              id="inputPerfomanceQuality">
            <div *ngIf="submitted && form.rating['controls'].performanceQuality.invalid" class="alert alert-danger"
              role="alert">
              {{ 'acceptances.form.0-120' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.schedule-performance' | translate }}</label>
          <div class="col-sm-9">
            <input class="form-control" formControlName="onSchedule" type="number" value="" id="inputOnSchedule">
            <div *ngIf="submitted && form.rating['controls'].onSchedule.invalid" class="alert alert-danger" role="alert">
              {{ 'acceptances.form.0-120' | translate }}
            </div>
          </div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.performed-activities' | translate }}</b>
      </div>
      <div formGroupName="tasks">
        <div class="form-group row sample">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9 d-flex justify-content-between flex-row bd-highlight mb-3">
            <span>{{sampleFigures.keineBeanstandungenCount()}}</span>
            <span>{{sampleFigures.beanstandungenCount()}}</span>
            <span>{{sampleFigures.reklamationenCount()}}</span>
            <span>{{sampleFigures.einspruchZuruecknahmenCount()}}</span>
            <span>{{sampleFigures.zuruecknahmenCount()}}</span>
            <span>{{sampleFigures.strittigCount()}}</span>
          </div>
        </div>
  
        <div class="form-group row sample">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.spot-checks' | translate }}</label>
          <div class="col-sm-9 d-flex justify-content-between flex-row bd-highlight mb-3">
            <i class="fa fa-thumbs-up fa-2x green d-block" aria-hidden="true" title="{{ 'statistics.no-claims' | translate }}"></i>
            <i class="fa fa-thumbs-down fa-2x yellow d-block" aria-hidden="true" title="{{ 'statistics.claims' | translate }}"></i>
            <i class="fa fa-thumbs-down fa-2x red d-block" aria-hidden="true" title="{{ 'statistics.reclamations' | translate }}"></i>
            <i class="fa fa-undo fa-2x black d-block" aria-hidden="true" title="{{ 'statistics.withdrawn-claims' | translate }}"></i>
            <i class="fa fa-undo fa-2x black d-block" aria-hidden="true" title="{{ 'statistics.withdrawn-reclamations' | translate }}"></i>
            <i class="fa fa-bolt fa-2x black d-block" aria-hidden="true" title="{{ 'statistics.conflict' | translate }}"></i>
          </div>
        </div>
  
        <div class="form-group row sample">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9 d-flex justify-content-between flex-row bd-highlight mb-3">
            <span>{{controlFigures.keineBeanstandungenCount()}}</span>
            <span>{{controlFigures.beanstandungenCount()}}</span>
            <span>{{controlFigures.reklamationenCount()}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.controls' | translate }}</label>
          <div class="col-sm-9 d-flex justify-content-between flex-row bd-highlight mb-3">
            <i class="fa fa-thumbs-up fa-2x green d-block" aria-hidden="true" title="{{ 'statistics.no-claims' | translate }}"></i>
            <i class="fa fa-thumbs-down fa-2x yellow d-block" aria-hidden="true" title="{{ 'statistics.claims' | translate }}"></i>
            <i class="fa fa-thumbs-down fa-2x red d-block" aria-hidden="true" title="{{ 'statistics.reclamations' | translate }}"></i>
          </div>
        </div>
      </div>
  
      <div class="d-flex justify-content-center align-items-center acceptance-header">
        <b>{{ 'acceptances.form.state' | translate }}</b>
      </div>
  
      <div formGroupName="state">
        <div class="form-group row"
          *ngIf="acceptanceStatusTransitionModel && acceptanceStatusTransitionModel.transitionModels as transitionModels">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.decision' | translate }}</label>
          <div class="col-sm-9">
            <select class="custom-select" formControlName="phase">
              <!--for IE-->
              <option [ngValue]="null" hidden *ngIf="!this.form.state['controls'].phase.value"></option>
              <!---->
              <option *ngFor="let state of transitionModels" [ngValue]="state.value">{{state.displayName | translate}}</option>
            </select>
            <div *ngIf="submitted && form.state['controls'].phase.invalid" class="alert alert-danger" role="alert">
              {{ 'acceptances.form.select-state' | translate }}
            </div>
          </div>
        </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">{{ "acceptances.form.dedicated-user" | translate}}</label>
            <div class="col-sm-9">
              <select formControlName="user" (change)="assignDedicatedUser($event)" class="custom-select">
                <option *ngFor="let dedicatedUser of dedicatedUsers" [value]="dedicatedUser.id" [disabled]="!dedicatedUser.isActive">{{printNameOfContractUser(dedicatedUser)}}</option>
              </select>
            </div>
          </div>

        <div class="form-group row" *ngIf="acceptance?.comments?.length > 0">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.comments' | translate }}</label>
          <div class="col-sm-9">
            <epo-sample-comments [comments]="acceptance.comments"></epo-sample-comments>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ 'acceptances.form.comment' | translate }}</label>
          <div class="col-sm-9">
            <textarea class="form-control" rows="5" formControlName="comment" type="text" value=""
              id="inputComment"></textarea>
          </div>
        </div>
      </div>
      <div *ngIf="submitted && acceptanceForm.invalid" class="alert alert-danger" role="alert">
        {{ 'acceptances.form.required' | translate }}
      </div>
      <epo-created-modified [createdBy]="createdBy" [modifiedBy]="modifiedBy" [createdUtc]="createdUtc"
        [modifiedUtc]="modifiedUtc"></epo-created-modified>
    </fieldset>
  </form>
</div>

<div class="modal-footer">
  <button *ngIf="isUserGlobalAdmin && acceptanceId" type="button" class="btn btn-outline-dark" (click)="withdrawAcceptance()" [disabled]="isSaving">
    <i *ngIf="!withdrawnAcceptance" class="fas fa-trash"></i>
    <span *ngIf="!withdrawnAcceptance">{{ "shared.dialog.withdraw" | translate }}</span>
    <i *ngIf="withdrawnAcceptance" class="fas fa-trash-restore"></i>
    <span *ngIf="withdrawnAcceptance">{{ "shared.dialog.restore" | translate }}</span>
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
    <i  class="icon" class="fas fa-ban"></i>
    {{ 'shared.dialog.cancel' | translate }}
  </button>
  <button class="btn btn-dark" type="submit" (click)="onSubmit()" [disabled]="isSaving">
    <i class="icon" class="fas fa-save"></i>
    {{ 'shared.dialog.save' | translate }}
  </button>
</div>