import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';
import { DeficitTag } from 'app/shared/masterData/deficit/deficit-tag.resource';

import { Language } from 'app/shared/masterData/language/language.resource';
import { LanguageService } from 'app/shared/masterData/language/language.service';

@Component({
  selector: 'epo-modal-deficit-tags-edit',
  templateUrl: './modal-deficit-tags-edit.component.html',
  styleUrls: ['./modal-deficit-tags-edit.component.scss']
})
export class ModalDeficitTagsEditComponent{
  public deficitTag: DeficitTag;
  public inNewMode: boolean;
  public inEditMode: boolean;
  languages: Language[];
  translations: TranslationViewModel[];


  constructor(public activeModal: NgbActiveModal, private languageService: LanguageService) { }

  ngOnInit(): void {
    const entityTranslations = this.deficitTag !== undefined ? this.deficitTag.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = entityTranslations.find(t => t.language.id === lang.id);
        if (translation) {
          return new TranslationViewModel(translation, lang);
        } else {
          return new TranslationViewModel({
            language: lang,
            languageId: lang.id,
            name: ''
          }, lang);
        }
      });
    });
  }

  canSave(){
    return this.deficitTag && this.deficitTag.nameDefault && this.translationsValid();
  }

  translationsValid(){
    return this.translations && !this.translations.find(t => !t.isValid)
  }

  save(){
    this.deficitTag.translations = this.translations.filter(t => t.name).map(t => t.toResource());
    this.activeModal.close(this.deficitTag);
  }

}
