import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { Contract } from 'app/shared/masterData/contract/contract.resource';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditContractComponent } from './create-edit/modal-edit-contract.component';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { UserProfileService } from 'app/core/user-profile.service';
import { User } from 'app/core/user';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { environment } from 'environments/environment';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { ContractLoadFilter } from './contract-load-filter';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { ContractHistoryHelper } from './history/contract-history.helper';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { Language } from 'app/shared/masterData/language/language.resource';
import { filter, retry, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'epo-contracts-management',
  templateUrl: './contracts-management.component.html',
  styleUrls: ['./contracts-management.component.scss']
})
export class ContractsManagementComponent implements OnInit,OnDestroy {
  public isLoading = false;
  public pagingGlobalCount = 0;
  public pageSize: number;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData();
  }
  get page() {
    return this.currentPage;
  }

  private _languages: Language[];
  public contracts: Contract[];
  public isAdmin: boolean = false;
  public loadFilter: ContractLoadFilter = {}
  private readonly _destroying$ = new Subject<void>();

  constructor(public _contractService: ContractService,
    private _modalService: NgbModal,
    private _userProfileService: UserProfileService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _languageService: LanguageService,
    private msalBroadcastService: MsalBroadcastService) {
    this.pageSize = environment.pageSize;
  }

  ngOnInit() {
    this._userProfileService.currentUser.subscribe(usr => {
      this.isAdmin = usr as User && ((usr as User).role === GlobalUserRole.Admin || (usr as User).role === GlobalUserRole.GlobalAdmin);
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.loadData();
      })
  }

  onFilterChange() {
    this.currentPage = 1;
    this.loadData();
  }

  private loadData() {
    this.isLoading = true;
    const contracts$ = this._contractService.getContracts(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    const languages$ = this._languageService.languagesObservable.pipe(take(1));
    forkJoin([contracts$, languages$]).pipe(retry(3)).subscribe(([contracts, languages]) => {
      this.contracts = contracts.items;
      this.pagingGlobalCount = contracts.count;
      this._languages = languages;
      this.isLoading = false;
    }, err => {
      console.log(err);
    });
  }


  showEditModal(contract: Contract) {
    const modalRef = this._modalService.open(ModalEditContractComponent);
    modalRef.componentInstance.contract = Object.assign({}, contract);
    modalRef.componentInstance.inNewMode = false;
    modalRef.result.then((contract) => {
      this._contractService.updateContract(contract).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      });
    },
      () => { }
    );
  }

  showCreateModal() {
    const modalRef = this._modalService.open(ModalEditContractComponent);
    modalRef.componentInstance.contract = new Contract();
    modalRef.componentInstance.contract.isActive = true;
    modalRef.componentInstance.inNewMode = true;
    modalRef.result.then((contract) => {
      this._contractService.createContract(contract).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      });
    },
      () => { }
    );
  }

  public openDeleteModal(contract: Contract) {
    const modalRef = this._modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = contract;
    modalRef.result.then((contract) => {
      this._contractService.deleteContract(contract.id).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    });
  }

  public showHistory(contract: Contract) {
    this._contractService.getContractHistory(contract.id).subscribe(history => {
      const changeHistory = new ContractHistoryHelper(history, this._languages).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: contract.name };
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}