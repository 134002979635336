import { Language } from 'app/shared/masterData/language/language.resource';
import { Translation } from 'app/shared/masterData/language/translation';

export class TranslationViewModel {
    id?: string;
    language: Language;
    private _name: string = '';
    private _isEdited: boolean = false;

    get isValid() {
        if (!this._isEdited) {
            return true;
        }
        return !this.id || this._name;
    }

    get name() {
        return this._name;
    }
    set name(value: string) {
        if (value === this._name) {
            return;
        }
        this._name = value;
        this._isEdited = true;
    }

    constructor(resource: Translation, language: Language) {
        this.language = language;
        if (resource) {
            this._name = resource.name;
            this.id = resource.id;
        }
    }

    toResource(): Translation {
        return {
            id: this.id,
            name: this.name,
            languageId: this.language.id
        };
    }
}