import { Language } from 'app/shared/masterData/language/language.resource';
import { DeficitTranslation } from 'app/shared/masterData/deficit/deficit-translation.resource';

export class DeficitTranslationViewModel {
    id?: string;
    language: Language;
    private _title: string = '';
    private _description: string = '';
    private _isEdited: boolean = false;

    get isValid() {
        if (!this._isEdited) {
            return true;
        }
        if (this.id) {
            return this._title && this._description;
        }
        return !this._title === !this._description;
    }

    get title() {
        return this._title;
    }
    set title(value: string) {
        if (value === this._title) {
            return;
        }
        this._title = value;
        this._isEdited = true;
    }

    get description() {
        return this._description;
    }
    set description(value: string) {
        if (value === this._description) {
            return;
        }
        this._description = value;
        this._isEdited = true;
    }

    constructor(language: Language, resource?: DeficitTranslation) {
        this.language = language;
        if (resource) {
            this._title = resource.title;
            this._description = resource.description;
            this.id = resource.id;
        }
    }

    toResource(): DeficitTranslation {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            languageId: this.language.id
        };
    }
}