import { ChangedProperty } from "app/shared/components/entity-history/changed-property/changed-property.model";
import { HistoryEntryModel } from "app/shared/components/entity-history/history-entry.model";
import { PropertyDisplayMapping } from "app/shared/models/property-display-mapping";
import { DeficitGlobalProposalData, DeficitGlobalProposalStatus } from "./deficit-global-proposal-data";

export class DeficitGlobalProposalHistoryHelper {

    constructor(private spotChecks: DeficitGlobalProposalData[]) {

    }

    private checkProperties: PropertyDisplayMapping[] = [
        { propertyName: 'status', displayName: 'Status', displayValueTransformation: this.resolveStatusPhase }
    ]

    public generateHistoryEntries(): HistoryEntryModel[] {
        const historyResources = [new DeficitGlobalProposalData(), ...this.spotChecks];
        let history = [];
        for (let i = historyResources.length - 1; i > 0; --i) {
            const entry = this.createHistoryEntry(historyResources[i - 1], historyResources[i]);
            if (entry) {
                history.push(entry);
            }
        }
        return history;
    }

    private createHistoryEntry(oldValues: DeficitGlobalProposalData, newValues: DeficitGlobalProposalData): HistoryEntryModel {
        let historyEntry = new HistoryEntryModel();
        for (const prop of this.checkProperties) {
            if (newValues[prop.propertyName] != oldValues[prop.propertyName]) {
                let changedProperty = new ChangedProperty();
                changedProperty.propertyName = prop.displayName;
                changedProperty.viewType = prop.type;
                if (prop.displayValueTransformation != undefined) {
                    if (prop.propertyName === 'dedicatedUser' ) {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    } else {
                        changedProperty.newValue = this.discardEmptyString(prop.displayValueTransformation(newValues[prop.propertyName]));
                        changedProperty.oldValue = this.discardEmptyString(prop.displayValueTransformation(oldValues[prop.propertyName]));
                    }
                } else {
                    changedProperty.newValue = this.discardEmptyString(newValues[prop.propertyName]);
                    changedProperty.oldValue = this.discardEmptyString(oldValues[prop.propertyName]);
                }
                if ((changedProperty.newValue != null || changedProperty.oldValue != null) && changedProperty.newValue != changedProperty.oldValue) {
                    historyEntry.changedProperties.push(changedProperty);
                }
            }
        }
        if (historyEntry.changedProperties.length > 0) {
            historyEntry.updatedBy = `${newValues.modifiedBy.firstname} ${newValues.modifiedBy.lastname}`;
            historyEntry.updatedDate = newValues.modifiedUtc + 'Z';
            return historyEntry;
        }
        return null;
    }
    //TODO: Use translation
    private resolveStatusPhase(phase: DeficitGlobalProposalStatus) {
        switch (phase) {
            case DeficitGlobalProposalStatus.Pending:
                return 'Pending';
            case DeficitGlobalProposalStatus.Approved:
                return 'Approved';
            case DeficitGlobalProposalStatus.Rejected:
                return 'Rejected';
        }
    }

    private discardEmptyString(input: string): string {
        if (!this.isString(input)) return input;
        if (input.trim()) return input;
        return null;
    }

    private isString(value) {
        return typeof value === 'string';
    }
}