import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { ContractUserResource } from 'app/core/contract-user.resource';

export class UserResponse {
    id: string;
    firstname: string;
    lastname: string;
    loginEmail: string;
    isActive: boolean;
    role: GlobalUserRole;
    contractUsers?: ContractUserResource[];
}
