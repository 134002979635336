<div class="changed-property-container m-2">
  <span>{{ changedProperty.propertyName | translate }}</span>
  <span *ngIf="changedProperty.propertySubName">
    ({{ changedProperty.propertySubName | translate }})</span>
  <div class="properties p-2">
    <ng-container *ngIf="
        changedProperty.viewType === 'string' ||
        changedProperty.viewType === undefined
      ">
      <div *ngIf="changedProperty.oldValue != null" class="value-container oldValue" [ngClass]="
          changedProperty.newValue != null ? 'reduced-width' : 'full-width'
        ">
        <i class="fas fa-minus" style="margin-right: 8px; color: #bf1d2d"></i>
        {{ changedProperty.oldValue | translate }}
      </div>
      <div *ngIf="
          changedProperty.oldValue != null && changedProperty.newValue != null
        " class="value-container">
        <i class="fa fa-lg fa-arrow-right"></i>
      </div>
      <div *ngIf="changedProperty.newValue != null" class="value-container newValue" [ngClass]="
          changedProperty.oldValue != null ? 'reduced-width' : 'full-width'
        ">
        <i class="fas fa-plus" style="margin-right: 5px; color: #00a951"></i>
        {{ changedProperty.newValue | translate }}
      </div>
    </ng-container>

    <div style="font-size: 0.8em;" *ngIf="changedProperty.viewType === 'boolean'">
      <ng-container *ngIf="changedProperty.oldValue != null">
        <i class="{{changedProperty.oldValue | toBoolSymbol:2 :changedProperty.transformProperty}} fa-fw bool-icon-container"></i>
        <i class="fa fa-2x fa-arrow-right bool-icon-container"></i>
      </ng-container>
      <i class="{{changedProperty.newValue | toBoolSymbol:2 :changedProperty.transformProperty}} fa-fw bool-icon-container"></i>
    </div>

    <ng-container *ngIf="changedProperty.viewType === 'code'">
      <div *ngIf="changedProperty.oldValue != null" class="value-container oldValue" [ngClass]="
          changedProperty.newValue != null ? 'reduced-width' : 'full-width'
        ">
        <i class="fas fa-minus" style="margin-right: 8px; color: #bf1d2d"></i>
        <div [innerHTML]="changedProperty.oldValue"></div>
      </div>
      <div *ngIf="
          changedProperty.oldValue != null && changedProperty.newValue != null
        " class="value-container">
        <i class="fa fa-lg fa-arrow-right"></i>
      </div>
      <div *ngIf="changedProperty.newValue != null" class="value-container newValue" [ngClass]="
          changedProperty.oldValue != null ? 'reduced-width' : 'full-width'
        ">
        <i class="fas fa-plus" style="margin-right: 5px; color: #00a951"></i>
        <div [innerHTML]="changedProperty.newValue"></div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="
      changedProperty.subProperties && changedProperty.subProperties.length > 0
    " class="subproperty-container p-2">

    <changed-property *ngFor="let property of changedProperty.subProperties" [changedProperty]="property">
    </changed-property>
  </div>
</div>