import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boolToString'
})
export class BoolToStringPipe implements PipeTransform {

    transform(value: boolean, args?: any): any {
        if (value === true) {
            return 'shared.yes';
        } else if (value === false) {
            return 'shared.no';
        } else if (value === undefined) {
            return args;
        } else {
            return value;
        }

    }
}
