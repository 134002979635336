import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RandomSampleTarget } from 'app/shared/models/random-sample-target';
import { EInterval } from 'app/shared/models/interval';
import { TradeConfiguration } from 'app/shared/masterData/trade/configuration/trade-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { RandomSampleTargetViewModel } from '../random-sample-target-view-model';

@Component({
  selector: 'epo-modal-sample-target-edit',
  templateUrl: './modal-sample-target-edit.component.html',
  styleUrls: ['./modal-sample-target-edit.component.scss'],
})
export class ModalSamplePlanningEditComponent {
  public target: RandomSampleTarget;
  public inNewMode: boolean;
  public inEditMode: boolean;
  public tradeConfigs: TradeConfiguration[];
  public checkTypeConfigs: CheckTypeConfiguration[];
  public contractPositionConfigs: ContractPositionConfiguration[];
  public intervalTypes: { value: EInterval; displayName: string }[];
  translations: TranslationViewModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private languageService: LanguageService
  ) {}

  formIsFilled() {
    return (
      this.target.amount !== undefined &&
      this.target.interval !== undefined &&
      this.target.contractPositionConfigurationId !== undefined &&
      this.target.checkTypeConfigurationId !== undefined &&
      this.target.tradeConfigurationId !== undefined
    );
  }
}
