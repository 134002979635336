import { Deficit } from './deficit.resource';
import { Translation } from '../language/translation';
import { BaseExistingResource } from 'app/shared/models/base-existing.resource';

// Used in control sample deficite group
export class DeficitGroup extends BaseExistingResource {
    id: string;
    name: string;
    nameDefault?: string;
    isActive?: boolean;
    deficits: Deficit[];
    translations?: Translation[] = [];
}
