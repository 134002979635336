<epo-filter [filterSettings]="filterSettings"></epo-filter>

<div class="container more-width-container mt-5">
  <div class="row mt-10">
    <main class="col-sm-12">
      <div class="dashboard">
        <epo-statistics #stats [header]="statsHeader" [sampleFigures]="figures" (onStatsChanged)="statsChanged($event)">
        </epo-statistics>
      </div>

      <div class="mb-3">
        <div class="stats-header">
          <div class="row">
            <div class="col" *ngIf="!showAllStichprobenarten"></div>
            <div class="col">
              <h3 class="brd bre">{{ "spot-checks.overview" | translate }}</h3>
            </div>
            <div class="col" *ngIf="!showAllStichprobenarten">
              <h3 *hideIfMissingRole="requiredRolesForCreation" class="brd bre pull-right pointer"
                (click)="addStichprobe()">
                <i class="fa fa-plus"></i>{{ "spot-checks.new" | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

      <div class="col-sm-12 mt-4 mb-4 total-count" *ngIf="stichproben && stichproben.length > 0 && !isLoading">
        <p>
          {{ "spot-checks.count.left" | translate }}
          <span class="count">{{ pagingGlobalCount }}</span>
          {{ "spot-checks.count.right" | translate }}
        </p>
      </div>

      <epo-stichproben-table [stichproben]="stichproben" [headerColumns]="currentHeaderColumns" [isLoading]="isLoading"
        (onStichprobeClicked)="editStichprobeClicked($event)" (onVersionClicked)="versionStichprobeClicked($event)">
      </epo-stichproben-table>
      <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
    </main>
  </div>
</div>