import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/core/user';
import { UserProfileService } from 'app/core/user-profile.service';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { DeficitGlobalProposalService } from 'app/shared/services/deficit-global-proposal.service';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { Subscription } from 'rxjs';
import { OrderBy, OrderDirection } from '../shared/order-by';
import { DeficitGlobalProposalData, DeficitGlobalProposalStatus } from './deficit-global-proposal-data';
import { DeficitGlobalProposalHistoryHelper } from './deficit-global-proposal-history.helper';
import { DeficitGlobalProposalLoadFilter } from './deficit-global-proposal-load-filter.resource';

@Component({
  selector: 'epo-deficit-global-proposals',
  templateUrl: './deficit-global-proposals.component.html',
  styleUrls: ['./deficit-global-proposals.component.scss']
})
export class DeficitGlobalProposalsComponent extends BaseFilterPagingComponent<DeficitGlobalProposalData> implements OnDestroy {
  public isLoading: boolean = true;
  public sorting: { column: 'DeficitGroup' | 'CreatedOn' | 'Number', direction: OrderDirection } = { column: 'DeficitGroup', direction: OrderDirection.Asc };
  subscription: Subscription;
  public loadFilter: DeficitGlobalProposalLoadFilter = new DeficitGlobalProposalLoadFilter();
  public proposalStatusSelection = ['all', 'pending', 'approved', 'rejected'];

  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData();
  }
  get page() {
    return this.currentPage;
  }
  
  constructor(private _deficitProposalService: DeficitGlobalProposalService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private modalService: NgbModal,
    public _translateService:TranslateService) {
    super();
  }
  
  onFilterChange() {
    this.currentPage = 1;
    this.loadData();
  }

  isStatusSelected(value) {
    this.loadFilter.isStatus = value;
    this.onFilterChange();
  }


  loadData() {
    this.loadFilter.orderBy = OrderBy[`${this.sorting.column}${this.sorting.direction ? 'Desc' : 'Asc'}`];
    this.load();
  }

  private load() {
    this.isLoading = true;
    this.subscription = this._deficitProposalService.getDeficitGlobalProposal(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter)
    .subscribe( res => {
        this.items = res.items;
        this.items.map((prop: DeficitGlobalProposalData) => {
          prop.createdUtc = this.resolveDate(prop.createdUtc);
        });
        this.isLoading = false;
      }
    );
  }

  public sort(column: 'DeficitGroup' | 'CreatedOn' | 'Number') {
    if (column === this.sorting.column) {
      this.sorting.direction = +!this.sorting.direction;
    } else {
      this.sorting = { column: column, direction: OrderDirection.Asc };
    }
    this.onFilterChange();
  }

  private resolveDate(date:string):string{ 
    let dateTime:Date = new Date(date);
    return dateTime.toLocaleString();
  }

  public isActiveSorting(column: 'DeficitGroup' | 'CreatedOn' | 'Number', direction: 'Asc' | 'Desc') {
    return this.sorting.column == column && this.sorting.direction == OrderDirection[direction];
  }

  public approveItem(value: string): void {
    this._deficitProposalService.approveDeficitGlobalProposal(value)
      .subscribe(() => {
        this.items.map(proposal => {
          if (proposal.id === value) {
            proposal.status = DeficitGlobalProposalStatus.Approved;
          }
        }) 
      });
  }

  public rejectItem(value: string): void {
    this._deficitProposalService.rejectDeficitGlobalProposal(value)
    .subscribe(() => {
      this.items.map(proposal => {
        if (proposal.id === value) {
          proposal.status = DeficitGlobalProposalStatus.Rejected;
        }
      }) 
    });
  }

  public showHistory(value: string): void {
    this._deficitProposalService.getDeficitGlobalProposalHistory(value)
    .subscribe((history) => {
      // console.log(res);
      let changeHistory = new DeficitGlobalProposalHistoryHelper(history).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
