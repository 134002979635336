import { Injectable } from '@angular/core';
import { Trade } from './trade.resource';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { NameLoadFilter } from 'app/admin/allgemein/shared/name-load-filter';
import { ConfigLoadFilter } from 'app/admin/allgemein/shared/config-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';

@Injectable({
    providedIn: 'root'
})
export class TradeService {

    private url = `${environment.apiUrl}/api/trades`;

    constructor(private _http: HttpClient) {
    }

    public getTrades(skipNr?: number, takeNr?: number, filter?: NameLoadFilter | ConfigLoadFilter): Observable<PagedResultResponse<Trade>> {
        let url = `${this.url}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter['isSelected'] !== undefined) {
                url = `${url}&isselected=${filter['isSelected']}`;
            }
            url = LoadFilterHelper.addBaseFilterValues(url, filter);
        }
        return this._http.get<PagedResultResponse<Trade>>(url);
    }

    public getTradeHistory(id: string): Observable<Trade[]> {
        return this._http.get<Trade[]>(`${this.url}/${id}/history`);
    }

    public createTrade(trade: Trade) {
        return this._http.post<Trade>(this.url, trade)
    }

    public updateTrade(trade: Trade) {
        return this._http.put<Trade>(`${this.url}/${trade.id}`, trade);
    }

    public deleteTrade(tradeId: string) {
        return this._http.delete(`${this.url}/${tradeId}`);
    }
}