import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { AcceptanceComponent } from './acceptance.component';
import { AcceptanceService } from './acceptance.service';
import { AcceptanceStatusCalculator } from 'app/acceptance/acceptance-status-calculator';
import { AcceptancePlaningComponent } from './acceptance-planing/acceptance-planing.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AbnahmenFormularComponent } from 'app/acceptance/acceptance-form/acceptance-form.component';
import { AcceptanceEditEntryComponent } from './acceptance-edit-entry/acceptance-edit-entry.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule
  ],
  declarations: [
    AcceptanceComponent,
    AcceptancePlaningComponent,
    AbnahmenFormularComponent,
    AcceptanceEditEntryComponent
  ],
  providers: [
    AcceptanceService,
    AcceptanceStatusCalculator
  ],
  entryComponents: [AbnahmenFormularComponent],
})
export class AcceptanceModule { }
