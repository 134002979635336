import { Component, Input } from '@angular/core';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

@Component({
  selector: 'epo-created-modified',
  templateUrl: './created-modified.component.html',
  styleUrls: ['./created-modified.component.scss']
})
export class CreatedModifiedComponent {
  private _createdAt: string;
  @Input() set createdUtc(value: string) {
    if (value) {
      this._createdAt = value;
    }
  }
  get createdUtc(): string {

    return this._createdAt;
  }

  private _modifiedAt: string;
  @Input() set modifiedUtc(value: string) {
    if (value) {
      this._modifiedAt = value;
    }
  }
  get modifiedUtc(): string {

    return this._modifiedAt;
  }
  @Input() createdBy: UserResponse;
  @Input() modifiedBy: UserResponse;

  @Input() 

  get isModified() {
    return new Date(this.modifiedUtc).setSeconds(0, 0) !== new Date(this.createdUtc).setSeconds(0, 0);
  }
}
