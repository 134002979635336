<div *ngIf="(!stichproben || stichproben.length == 0) && !isLoading">
  <p class="no-results">{{ "spot-checks.no-spot-checks" | translate }}</p>
</div>

<div class="table-outter-wrapper mb-5" id="stichprobenTable"
  *ngIf="stichproben && stichproben.length > 0 && !isLoading">
  <table class="table table-hover">
    <thead>
      <tr class="rotated">
        <th></th>
        <th class="rotate-45">
          <div class="id-column">
            <span>Id</span>
          </div>
        </th>
        <th class="rotate-45 no-mobile">
          <div>
            <span>{{ "shared.created-at" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 bewertung">
          <div>
            <span>{{ "spot-checks.rating-orderer" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 reaktion">
          <div>
            <span>{{ "spot-checks.rating-contractor" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 ergebnis">
          <div>
            <span>{{ "spot-checks.result" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 reaktion-dark">
          <div>
            <span>{{ "spot-checks.deficit-fixed" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 no-mobile" *ngIf="showContractPositionColumn">
          <div>
            <span>{{ "spot-checks.contract-position" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 no-mobile" *ngIf="showGewerk">
          <div>
            <span>{{ "spot-checks.trade" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 art-header no-mobile" *ngIf="showAllStichprobenarten">
          <div>
            <span>{{ "spot-checks.type" | translate }}</span>
          </div>
        </th>
        <th class="rotate-45 no-mobile">
          <div>
            <span>{{ "spot-checks.ticket-nr" | translate }}</span>
          </div>
        </th>
        <ng-container *ngIf="!showAllStichprobenarten">
          <th class="rotate-45 no-mobile" *ngFor="let headerColumn of headerColumns">
            <div>
              <span>{{ headerColumn.title }}</span>
            </div>
          </th>
        </ng-container>
        <th class="rotate-45 rotate-3x no-mobile">
          <div class="appendix">
            <span>{{ "spot-checks.annotation" | translate }}</span>
          </div>
        </th>
      </tr>
      <tr *ngIf="!showAllStichprobenarten" class="no-mobile">
        <th>
          <!--Platzhalter Edit-->
        </th>
        <th>
          <!--Platzhalter Id-->
        </th>
        <th>
          <!--Platzhalter Erstellt am-->
        </th>
        <th class="bewertung">
          <!--Platzhalter Bewertung-->
        </th>
        <th class="reaktion">
          <!--Platzhalter Reaktion-->
        </th>
        <th class="ergebnis">
          <!--Platzhalter Ergebnis-->
        </th>
        <th class="reaktion-dark">
          <!--Platzhalter Defizit behoben-->
        </th>
        <th *ngIf="showContractPositionColumn">
          <!--Platzhalter Vertragsposition-->
        </th>
        <th *ngIf="showGewerk">
          <!--Platzhalter Gewerk-->
        </th>
        <th>
          <!--Platzhalter Anforderungsnummer-->
        </th>
        <ng-container class="no-mobile" *ngFor="let grouppedHeaderColumn of groupByRandomGroup(headerColumns)">
          <th [attr.colspan]="grouppedHeaderColumn.members.length" class="inhaltsgruppe">
            <div>
              <span>{{ grouppedHeaderColumn.key }}</span>
            </div>
          </th>
        </ng-container>
        <th>
          <!--Platzhalter Appendix-->
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let stichprobe of stichproben" [ngClass]="{'withdrawn-spotcheck': stichprobe.isWithdrawn}">
        <td class="button-container">
          <div class="ml-1 fa-border history-button" (click)="onStichprobeClicked.emit(stichprobe.id)"
            data-toggle="modal" data-target="#modalEditStichprobe" title="{{ 'shared.edit' | translate }}">
            <i class="fa fa-edit icon-in-border icon-edit"></i>
          </div>

          <div (click)="onVersionClicked.emit(stichprobe)" class="ml-1 fa-border history-button"
            title="{{ 'shared.history' | translate }}">
            <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
          </div>
        </td>
        <td>{{ stichprobe.contractEntityNumber }}</td>
        <td class="no-mobile">
          {{ stichprobe.createdDateTimeUtc | date: "dd.MM.yyyy" }}
        </td>
        <td class="bewertung">
          <epo-display-status [displayStatus]="stichprobe.bewertungDienstleistersteuerer" [isBig]="false">
          </epo-display-status>
        </td>
        <td class="reaktion">
          <epo-display-status [displayStatus]="stichprobe.reaktionDienstleister" [isBig]="false"></epo-display-status>
        </td>
        <td class="ergebnis">
          <epo-display-status [displayStatus]="stichprobe.ergebnis" [isBig]="true"></epo-display-status>
        </td>
        <td class="reaktion-dark">
          <i class="fa" [ngClass]="getCssForDefizit(stichprobe)"></i>
        </td>
        <td *ngIf="showContractPositionColumn" class="no-mobile">
          {{ stichprobe.contractPositionName }}
        </td>
        <td class="no-mobile" *ngIf="showGewerk">
          {{ stichprobe.realEstateName }}
        </td>
        <td class="no-mobile" *ngIf="showAllStichprobenarten">
          {{ stichprobe.typeName }}
        </td>
        <td class="no-mobile">{{ stichprobe.ticketNumber }}</td>
        <ng-container *ngIf="!showAllStichprobenarten" class="no-mobile">
          <td *ngFor="let headerColumn of headerColumns" class="no-mobile">
            <i class="fa" [ngClass]="getCssByLCluster(stichprobe, headerColumn)" aria-hidden="true"></i>
          </td>
        </ng-container>
        <td class="appendix no-mobile" [innerHtml]="stichprobe.appendix"></td>
      </tr>
    </tbody>
  </table>
</div>