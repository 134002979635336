import { DisplayStatus } from 'app/shared/components/display-status/display-status';
import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { Rating } from 'app/shared/models/rating';
import { StatusPhase } from 'app/shared/models/status-phase';

export class RandomSampleViewModel {
  id: string;
  createdDateTimeUtc: Date;
  createdBy: string;
  contractPositionId: string;
  contractPositionName: string;
  realEstateId: string;
  realEstateName: string;
  typeId: string;
  typeName: string;
  ticketNumber: string;
  appendix: string;
  rating: Rating;
  bewertungDienstleistersteuerer: DisplayStatus;
  reaktionDienstleister: DisplayStatus;
  ergebnis: DisplayStatus;
  deficitValues: DeficitValueResponse[];
  contractEntityNumber: string;
  deficitFixed: DeficitFixed;
  statusPhaseThree: StatusPhase;
  isWithdrawn:boolean;
}
