import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyToDash'
})
export class EpmtyToDashPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || value.length < 1) {
      return "-";
    } else {
      return value;
    }

  }
}
