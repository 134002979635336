<div class="more-width-container mt-5">
  <div class="row stats-header">
    <div class="col">
    </div>
  </div>
  <div class="pt-5 table-outter-wrapper">
    <h3 class="pb-2">{{ 'global-admin.users.title' | translate }}</h3>
    <table class="table table-hover">
      <thead class="thead">
        <th>{{ 'global-admin.users.mail' | translate }}</th>
        <th>{{ 'global-admin.users.name' | translate }}</th>
        <th>{{ 'global-admin.users.firstname' | translate }}</th>
      </thead>
      <th>
        <epo-column-filter [(selection)]="loadFilter.email" (selectionChange)="onFilterChange()"
          [getValuesFunction]="golbalAutoCompleteService.getMatchingUserEmails">
        </epo-column-filter>
      </th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.lastname" (selectionChange)="onFilterChange()"
          [getValuesFunction]="golbalAutoCompleteService.getMatchingUserLastNames">
        </epo-column-filter>
      </th>
      <th>
        <epo-column-filter [(selection)]="loadFilter.firstname" (selectionChange)="onFilterChange()"
          [getValuesFunction]="golbalAutoCompleteService.getMatchingUserFirstNames">
        </epo-column-filter>
      </th>
      <tbody *ngIf="isLoading" class="loading-placeholder">
        <tr>
          <td colspan="7">
            <app-loading-spinner></app-loading-spinner>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr *ngFor="let user of items">
          <td>{{user.loginEmail}}</td>
          <td>{{user.lastname | emptyToDash}}</td>
          <td>{{user.firstname | emptyToDash}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>