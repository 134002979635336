import { Injectable } from '@angular/core';
import { DateRange } from '../../date-range';

@Injectable()
export class DateRangeService {

  getDateRangeByMonthAndYear(month: number, year: number) {
    const from = new Date(year, month, 1);
    // Tag-Parameter wird auf 0 gesetzt um den letzten Tag des vorherigen Monats zubekommen. Daher auch bei Monat +1.
    const to = new Date(year, month + 1, 0, 23, 59, 59);
    return new DateRange(from, to);
  }

  getDateRangeByQuarterAndYear(quarter: number, year: number) {
    let fromMonth;
    let toMonth;
    if (quarter === 1) {
      fromMonth = 0;
      toMonth = 2;
    } else if (quarter === 2) {
      fromMonth = 3;
      toMonth = 5;
    } else if (quarter === 3) {
      fromMonth = 6;
      toMonth = 8;
    } else if (quarter === 4) {
      fromMonth = 9;
      toMonth = 11;
    }

    const from = new Date(year, fromMonth, 1);
    const to = new Date(year, toMonth + 1, 0, 23, 59, 59);
    return new DateRange(from, to);
  }

  getDateRangeByYear(year: number): DateRange {
    const from = new Date(year, 0);
    // Tag-Parameter wird auf 0 gesetzt um den letzten Tag des vorherigen Monats zubekommen. Daher auch bei Monat +1.
    const to = new Date(year, 12, 0, 23, 59, 59);
    return new DateRange(from, to);
  }
}
