import { StatusPhase } from 'app/shared/models/status-phase';
import { Acceptance } from '../shared/Acceptance';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';

export class AcceptanceStatusTransitionModel {
    private _transitionModels;

    constructor(
        private acceptance: Acceptance,
        private contractRole: ContractUserRole
    ) {
        this._transitionModels = this.generateTransitionModels();
    }

    public get transitionModels() {
        return this._transitionModels;
    }

    private generateTransitionModels() {
        if (
            !this.acceptance.status_Phase1 ||
            this.acceptance.status_Phase1 == StatusPhase.InProgress
        ) {
            return [
                {
                    displayName: 'status.in-progress',
                    value: {
                        status_Phase1: StatusPhase.InProgress,
                        status_Phase2: StatusPhase.None,
                        status_Phase3: StatusPhase.None,
                    },
                },
                {
                    displayName: 'status.ready-for-acceptance',
                    value: {
                        status_Phase1: StatusPhase.Done,
                        status_Phase2: StatusPhase.None,
                        status_Phase3: StatusPhase.None,
                    },
                },
            ];
        }
        if (
            this.isOrderer &&
            (this.acceptance.status_Phase2 == StatusPhase.InProgress ||
                this.acceptance.status_Phase2 == StatusPhase.None)
        ) {
            let tmp = [
                {
                    displayName: 'status.in-progress',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: StatusPhase.InProgress,
                        status_Phase3: this.acceptance.status_Phase3,
                    },
                },
                {
                    displayName: 'status.create-acceptance',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: StatusPhase.Accepted,
                        status_Phase3: this.acceptance.status_Phase3,
                    },
                },
                {
                    displayName: 'status.to-improve',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: StatusPhase.Conflict,
                        status_Phase3: StatusPhase.None,
                    },
                },
            ];
            if (this.acceptance.status_Phase3 != StatusPhase.None) {
                tmp.push({
                    displayName: 'Abnahme verweigern',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: StatusPhase.Claim,
                        status_Phase3: this.acceptance.status_Phase3,
                    },
                });
            }
            return tmp;
        }
        if (
            this.isContractor &&
            this.acceptance.status_Phase2 == StatusPhase.Conflict &&
            (this.acceptance.status_Phase3 == StatusPhase.InProgress ||
                this.acceptance.status_Phase3 == StatusPhase.None)
        ) {
            return [
                {
                    displayName: 'status.in-progress',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: this.acceptance.status_Phase2,
                        status_Phase3: StatusPhase.InProgress,
                    },
                },
                {
                    displayName: 'status.ready-for-acceptance',
                    value: {
                        status_Phase1: this.acceptance.status_Phase1,
                        status_Phase2: StatusPhase.None,
                        status_Phase3: StatusPhase.Done,
                    },
                },
            ];
        }
    }

    private get isContractor(): boolean {
        switch (this.contractRole) {
            case ContractUserRole.Admin:
            case ContractUserRole.ContractorEmployee:
            case ContractUserRole.ContractorManager:
                return true;
            default:
                return false;
        }
    }

    private get isOrderer(): boolean {
        switch (this.contractRole) {
            case ContractUserRole.Admin:
            case ContractUserRole.OrdererEmployee:
            case ContractUserRole.OrdererManager:
                return true;
            default:
                return false;
        }
    }
}
