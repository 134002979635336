import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StatisticType } from 'app/core/statistics/statistic-type.enum';
import { StatisticFigures } from 'app/core/statistics/statistic-figures';

@Component({
  selector: 'epo-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
  public statisticType = StatisticType;
  public currentStatType: StatisticType;

  @Input() header: string;
  @Input() sampleFigures: StatisticFigures;
  @Output() onStatsChanged: EventEmitter<StatisticType> = new EventEmitter();

  statsClicked(statType: StatisticType) {
    if (this.currentStatType === statType) {
      // Auswahl aufheben.
      this.resetSelection();
    } else {
      this.currentStatType = statType;
    }
    this.onStatsChanged.emit(this.currentStatType);
  }

  resetSelection() {
    this.currentStatType = null;
  }
}
