<epo-filter [filterSettings]="filterSettings"></epo-filter>

<div class="container more-width-container mt-86">
  <div class="row">
    <main class="col-sm-12">
      <div class="mb-3">
        <div class="stats-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <h3 class="brd bre">{{ "acceptances.overview" | translate }}</h3>
            </div>
            <div class="col">
              <h3 class="brd bre pull-right pointer" (click)="openForm()">
                <i class="fa fa-plus"></i>{{ "acceptances.new" | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

      <div class="col-sm-12 mt-4 mb-4 total-count" *ngIf="abnahmen && abnahmen.length > 0 && !isLoading">
        <p>
          {{ "acceptances.count.left" | translate }}
          <span class="count">{{ pagingGlobalCount }}</span>
          {{ "acceptances.count.right" | translate }}
        </p>
      </div>

      <div class="table-outter-wrapper" *ngIf="
          abnahmen && abnahmen.length > 0 && !isLoading;
          else abnahmenAreEmpty
        ">
        <table class="table table-header-rotated table-hover">
          <thead>
            <tr>
              <th></th>
              <th class="rotate-45">
                <div class="id-column">
                  <span>Id</span>
                </div>
              </th>
              <th class="rotate-45" *ngIf="showContractPositionColumn">
                <div>
                  <span>{{ "acceptances.contract-position" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45" *ngIf="showTradeColumn">
                <div>
                  <span>{{ "acceptances.trade" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{
                    "acceptances.performance-control-responsible" | translate
                    }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{
                    "acceptances.performance-main-responsible" | translate
                    }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{ "acceptances.preparations-done" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{ "acceptances.documents-exist" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 no-mobile">
                <div>
                  <span>{{
                    "acceptances.performance-quality" | translate
                    }}</span>
                </div>
              </th>
              <th class="rotate-45 w-100 no-mobile">
                <div>
                  <span>{{
                    "acceptances.schedule-performance" | translate
                    }}</span>
                </div>
              </th>

              <th class="rotate-45 bewertung">
                <div>
                  <span>{{ "acceptances.state-contractor" | translate }}</span>
                </div>
              </th>
              <th class="rotate-45 reaktion">
                <div>
                  <span>{{ "acceptances.state-orderer" | translate }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let abnahme of abnahmen" [ngClass]="{'withdrawn-acceptance': abnahme.isWithdrawn}">
              <td class="button-container">
                <div (click)="editAbnahme(abnahme)" title="{{ 'shared.edit' | translate }}"
                  class="ml-1 fa-border history-button">
                  <i class="fa fa-edit icon-in-border icon-edit"></i>
                </div>
                <div (click)="version(abnahme)" class="ml-1 fa-border history-button"
                  title="{{ 'shared.history' | translate }}">
                  <i class="fa fa-history fa-lg icon-in-border" aria-hidden="true"></i>
                </div>
              </td>
              <td>{{ abnahme.contractEntityNumber }}</td>
              <td *ngIf="showContractPositionColumn">
                {{
                resolveContractPosition(
                abnahme.contractPositionConfigurationId
                )
                }}
              </td>
              <td *ngIf="showTradeColumn">
                {{ resolveTrade(abnahme.tradeConfigurationId) }}
              </td>
              <td class="no-mobile">{{ abnahme.work_control_owner }}</td>
              <td class="no-mobile">{{ abnahme.work_main_owner }}</td>
              <td class="no-mobile">
                <span class="fa" [ngClass]="
                    abnahme.work_preperation === true ? 'fa-check' : 'fa-times'
                  "></span>
              </td>
              <td class="no-mobile">
                <span class="fa" [ngClass]="
                    abnahme.work_control_docuemnts === true
                      ? 'fa-check'
                      : 'fa-times'
                  "></span>
              </td>
              <td class="no-mobile">{{ abnahme.leistungsqualitaet.value }}</td>
              <td class="no-mobile">
                {{ abnahme.leistungstermintreue.value }}
              </td>
              <td class="bewertung">
                <epo-display-status [displayStatus]="abnahme.statusDienstleister" [isBig]="false"></epo-display-status>
              </td>
              <td class="reaktion">
                <epo-display-status [displayStatus]="abnahme.statusDienstleistersteuerer" [isBig]="false">
                </epo-display-status>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-template #abnahmenAreEmpty>
        <p class="no-results" *ngIf="!isLoading">
          {{ "acceptances.no-acceptances" | translate }}
        </p>
      </ng-template>
    </main>
  </div>
  <epo-paging [globalCount]="pagingGlobalCount" [pageSize]="pageSize" [(currentPage)]="page"></epo-paging>
</div>