import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DeficitIdValue } from './DefizitIdValue';
import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { DeficitGroupViewModel } from 'app/shared/deficit-group-view-model';
import { DeficitWithValue } from '../../deficit-with-value.view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeficitProposalRequestComponent } from '../deficit-proposal-request/deficit-proposal-request.component';


@Component({
  selector: 'epo-defizite',
  templateUrl: './defizite.component.html',
  styleUrls: ['./defizite.component.scss']
})
export class DefiziteComponent {

  @Input() disableDefizite: boolean;

  @Input() deficitGroups: DeficitGroupViewModel[];
  @Input() hasDefizite;

  @Output() selectedDeficits = new EventEmitter<DeficitValueResponse[]>();

  deficit: DeficitWithValue[];
  defizite: DeficitValueResponse;
  selectedDefiziteValues = [new DeficitIdValue('test', true)];

  /**
   *
   */
  constructor(private modalService: NgbModal) {
    
  }

  public onCheckPositive(deficit: DeficitWithValue) {
    if (deficit.defizitExists === true) {
      deficit.defizitExists = null;
    } else {
      deficit.defizitExists = true;
    }

    const flattenDeficits = this.parseSelectedDefizite(this.deficitGroups);
    this.selectedDeficits.emit(flattenDeficits);

  }

  public onCheckNegative(deficit: DeficitWithValue) {

    if (deficit.defizitExists === false) {
      deficit.defizitExists = null;
    } else {
      deficit.defizitExists = false;
    }
    const flattenDeficits = this.parseSelectedDefizite(this.deficitGroups);
    this.selectedDeficits.emit(flattenDeficits);
  }

  private parseSelectedDefizite(defizitGroups: DeficitGroupViewModel[]) {
    const defizite = defizitGroups.map((defizitGroup) => {
      const checkedDefizite = defizitGroup.deficits.filter((deficits) => {
        return (deficits.defizitExists === true || deficits.defizitExists === false);
      });
      if (Array.isArray(checkedDefizite) && checkedDefizite.length > 0) {
        const checkedDefizitResults = checkedDefizite.map((checkedDefizit) => {
          return { deficitId: checkedDefizit.id, defizitExists: checkedDefizit.defizitExists, checked: checkedDefizit.defizitExists };
        });
        return checkedDefizitResults;
      } else { return; }
    });

    const flattenDefizitResults = defizite
      .filter(defiizit => defiizit !== undefined)
      .reduce((acc, val) => acc.concat(val), []);

    return flattenDefizitResults;
  }

  public openEditShortcomingDialog(deficit: DeficitWithValue) {
    const modalRef = this.modalService.open(DeficitProposalRequestComponent, { size: 'lg' });
    modalRef.componentInstance.deficit = deficit;
    console.log(deficit);
  }
}
