import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, Subject } from 'rxjs';
import { LocalCacheDb } from '../models/local-db';
import { OnlineOfflineService } from './online-offline.service';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { ControlFullDto } from '../models/control-full-dto';
import { ConnectionData } from '../models/connection-data';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Control } from 'app/control/control-form/control-data';
import { v4 as uuidv4 } from 'uuid';
import { SpotCheckFullDto } from '../models/spotcheck-full-dto';
import { SpotCheckData } from 'app/random-sample/spot-check-form/spot-check-data';

@Injectable({ providedIn: 'root' })
export class OfflineSyncService {

    private _dataIsSynching = new Subject<boolean>();

    get dataIsSynching() {
        return this._dataIsSynching.asObservable();
    }

    private _cacheDb: LocalCacheDb = new LocalCacheDb();
    private _allControlsSaved = true;
    private _allControlCommentsSaved = true;
    private _allControlAttachmentsSaved = true;
    private _allSpotChecksSaved = true;
    private _allSpotCheckCommentsSaved = true;
    private _allSpotCheckAttachmentsSaved = true;

    private set allControlsSaved(value) {
        if (this._allControlsSaved === value) {
            return;
        }
        this._allControlsSaved = value;
        this.checkAllSaved();
    };
    private set allControlCommentsSaved(value) {
        if (this._allControlCommentsSaved === value) {
            return;
        }
        this._allControlCommentsSaved = value;
        this.checkAllSaved();
    };
    private set allControlAttachmentsSaved(value) {
        if (this._allControlAttachmentsSaved === value) {
            return;
        }
        this._allControlAttachmentsSaved = value;
        this.checkAllSaved();
    };
    private set allSpotChecksSaved(value) {
        if (this._allSpotChecksSaved === value) {
            return;
        }
        this._allSpotChecksSaved = value;
        this.checkAllSaved();
    };
    private set allSpotCheckCommentsSaved(value) {
        if (this._allSpotCheckCommentsSaved === value) {
            return;
        }
        this._allSpotCheckCommentsSaved = value;
        this.checkAllSaved();
    };
    private set allSpotCheckAttachmentsSaved(value) {
        if (this._allSpotCheckAttachmentsSaved === value) {
            return;
        }
        this._allSpotCheckAttachmentsSaved = value;
        this.checkAllSaved();
    };

    get allDataSaved(): boolean {
        return this._allControlsSaved && this._allControlCommentsSaved && this._allControlAttachmentsSaved
            && this._allSpotChecksSaved && this._allSpotCheckCommentsSaved && this._allSpotCheckAttachmentsSaved;
    }

    constructor(private _httpClient: HttpClient,
        private _onlineOfflineService: OnlineOfflineService,
        private _toaster: ToasterService) {
        this._onlineOfflineService.connectionChanged.subscribe(res => {
            if (res === true) {
                this.syncData();
            }
        })
    }

    public saveControlToCache(control: Control, comment: string, files: File[], deleteFiles: string[], contractId: string) {
        this._cacheDb.controls.add({
            id: uuidv4(),
            contractId: contractId,
            data: { control: control, comment: comment, uploadFiles: files, deleteFiles: deleteFiles }
        }).then(() => {
            this._toaster.show({ heading: 'cache.control-heading', description: 'cache.description' });
        });
    }

    public saveControlCommentToCache(comment: string, controlId: string, contractId: string) {
        this._cacheDb.controlComments.add({
            id: uuidv4(),
            contractId: contractId,
            data: { comment: comment, parentId: controlId }
        }).then(() => this._toaster.show({ heading: 'cache.control-heading', description: 'cache.description' }));
    }

    public saveSpotCheckToCache(spotcheck: SpotCheckData, comment: string, files: File[], deleteFiles: string[], contractId: string) {
        this._cacheDb.spotChecks.add({
            id: uuidv4(),
            contractId: contractId,
            data: { spotcheck: spotcheck, comment: comment, uploadFiles: files, deleteFiles: deleteFiles }
        }).then(() => this._toaster.show({ heading: 'cache.spotcheck-heading', description: 'cache.description' }));
    }

    public saveSpotCheckCommentToCache(comment: string, spotCheckId: string, contractId: string) {
        this._cacheDb.spotCheckComments.add({
            id: uuidv4(),
            contractId: contractId,
            data: { comment: comment, parentId: spotCheckId }
        }).then(() => this._toaster.show({ heading: 'cache.spotcheck-heading', description: 'cache.description' }));
    }

    private async syncData() {
        const allControls: ConnectionData<ControlFullDto>[] = await this._cacheDb.controls.toArray();
        const allControlComments: ConnectionData<{ comment: string, parentId: string }>[] = await this._cacheDb.controlComments.toArray();
        const allSpotchecks: ConnectionData<SpotCheckFullDto>[] = await this._cacheDb.spotChecks.toArray();
        const allSpotcheckComments: ConnectionData<{ comment: string, parentId: string }>[] = await this._cacheDb.spotCheckComments.toArray();
        if (allControls.length > 0 || allControlComments.length > 0 || allSpotchecks.length > 0 || allSpotcheckComments.length > 0) {
            this.resetSavedIndicators();
        }
        this.syncControlsWithBackend(allControls);
        this.syncComments(allControlComments, 'controls');
        this.syncSpotchecksWithBackend(allSpotchecks);
        this.syncComments(allSpotcheckComments, 'spotchecks');
    }

    private syncControlsWithBackend(controls: ConnectionData<ControlFullDto>[]) {
        if (controls.length === 0) {
            this.allControlsSaved = true;
            this.allControlAttachmentsSaved = true;
            return;
        }
        let savedCountrolsCount = 0;
        controls.forEach((item: ConnectionData<ControlFullDto>) => {
            this._cacheDb.controls.delete(item.id).then(() => {
                this.syncControlWithBackend(item.contractId, item.data.control).subscribe(res => {
                    this.syncControlAttachments(item.data.uploadFiles, res.id, item.contractId);
                    const comment$ = this.syncComment(item.data.comment, res.id, item.contractId, 'controls');
                    const fileDeletes$ = this.syncControlDeletedAttachments(item.data.deleteFiles, res.id, item.contractId);
                    const request$ = [comment$, ...fileDeletes$];
                    forkJoin(request$).subscribe(() => {
                        savedCountrolsCount++;
                        if (savedCountrolsCount === controls.length) {
                            this.allControlsSaved = true;
                        }
                    }, err => {
                        this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                        savedCountrolsCount++;
                        if (savedCountrolsCount === controls.length) {
                            this.allControlsSaved = true;
                        }
                    });
                }, err => {
                    this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                    savedCountrolsCount++;
                    if (savedCountrolsCount === controls.length) {
                        this.allControlsSaved = true;
                    }
                });
            })
        });
    }

    private syncControlWithBackend(contractId: string, control: Control): Observable<Control> {
        if (!control.id) {
            const url = this.createEpoUrl(`api/${contractId}/controls`);
            return this._httpClient.post<Control>(url, control);
        }
        const url = this.createEpoUrl(`api/${contractId}/controls/${control.id}`);
        return this._httpClient.put<Control>(url, control);
    }

    private syncControlAttachments(files: File[], controlId: string, contractId: string) {
        if (!files || files.length === 0) {
            this.allControlAttachmentsSaved = true;
            return;
        }
        let savedFilesCount = 0;
        const url = this.createEpoUrl(`api/${contractId}/controls/${controlId}/attachments/base64`);
        files.forEach(file => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                this._httpClient.post(url, { file: reader.result, fileName: file.name }).subscribe(() => {
                    savedFilesCount++;
                    if (savedFilesCount === files.length) {
                        this.allControlAttachmentsSaved = true;
                    }
                }, err => {
                    this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                    savedFilesCount++;
                    if (savedFilesCount === files.length) {
                        this.allControlAttachmentsSaved = true;
                    }
                });
            }
        });
    }

    private syncComment(comment: string, parentId: string, contractId: string, type: 'spotchecks' | 'controls') {
        if (!comment) {
            return of({});
        }
        const url = this.createEpoUrl(`api/${contractId}/${type}/${parentId}/comments`);
        return this._httpClient.post(url, { comment });
    }

    private syncControlDeletedAttachments(attachmentIds: string[], controlId: string, contractId: string) {
        if (!attachmentIds || attachmentIds.length === 0) {
            return [of({})];
        }
        let requests$ = [];
        attachmentIds.forEach(a => {
            const url = this.createEpoUrl(`api/${contractId}/controls/${controlId}/attachments/${a}`);
            requests$.push(this._httpClient.delete(url));
        });
        return requests$;
    }

    private syncComments(comments: ConnectionData<{ comment: string; parentId: string; }>[], type: 'spotchecks' | 'controls') {
        if (!comments || comments.length === 0) {
            if (type === 'spotchecks') {
                this.allSpotCheckCommentsSaved = true;
            } else {
                this.allControlCommentsSaved = true;
            }
            return;
        }
        let savedCmmentsCount = 0;
        comments.forEach(c => {
            this._cacheDb.controlComments.delete(c.id).then(() => {
                const comment = c.data.comment;
                this.syncComment(comment, c.data.parentId, c.contractId, type).subscribe(() => {
                    savedCmmentsCount++;
                    if (savedCmmentsCount === comments.length) {
                        if (type === 'spotchecks') {
                            this.allSpotCheckCommentsSaved = true;
                        } else {
                            this.allControlCommentsSaved = true;
                        }
                    }
                }, err => {
                    this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                    savedCmmentsCount++;
                    if (savedCmmentsCount === comments.length) {
                        if (type === 'spotchecks') {
                            this.allSpotCheckCommentsSaved = true;
                        } else {
                            this.allControlCommentsSaved = true;
                        }
                    }
                });
            });
        });
    }

    private syncSpotchecksWithBackend(spotChecks: ConnectionData<SpotCheckFullDto>[]) {
        if (spotChecks.length === 0) {
            this.allSpotChecksSaved = true;
            this.allSpotCheckAttachmentsSaved = true;
            return;
        }
        let savedSpotChecksCount = 0;
        spotChecks.forEach((item: ConnectionData<SpotCheckFullDto>) => {
            this._cacheDb.spotChecks.delete(item.id).then(() => {
                this.syncSpotCheckWithBackend(item.contractId, item.data.spotcheck).subscribe(res => {
                    this.syncSpotCheckAttachments(item.data.uploadFiles, res.id, item.contractId);
                    const comment$ = this.syncComment(item.data.comment, res.id, item.contractId, 'spotchecks');
                    const fileDeletes$ = this.syncSpotCheckDeletedAttachments(item.data.deleteFiles, res.id, item.contractId);
                    const request$ = [comment$, ...fileDeletes$];
                    forkJoin(request$).subscribe(() => {
                        savedSpotChecksCount++;
                        if (savedSpotChecksCount === spotChecks.length) {
                            this.allSpotChecksSaved = true;
                        }
                    }, err => {
                        this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                        savedSpotChecksCount++;
                        if (savedSpotChecksCount === spotChecks.length) {
                            this.allSpotChecksSaved = true;
                        }
                    });
                }, err => {
                    this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                    savedSpotChecksCount++;
                    if (savedSpotChecksCount === spotChecks.length) {
                        this.allSpotChecksSaved = true;
                    }
                });
            });
        });
    }

    private syncSpotCheckWithBackend(contractId: string, spotCheck: SpotCheckData): Observable<SpotCheckData> {
        if (!spotCheck.id) {
            const url = this.createEpoUrl(`api/${contractId}/spotchecks`);
            return this._httpClient.post<SpotCheckData>(url, spotCheck);
        }
        const url = this.createEpoUrl(`api/${contractId}/spotchecks/${spotCheck.id}`);
        return this._httpClient.put<SpotCheckData>(url, spotCheck);
    }

    private syncSpotCheckAttachments(files: File[], spotcheckId: string, contractId: string) {
        if (!files || files.length === 0) {
            this.allSpotCheckAttachmentsSaved = true;
            return;
        }
        let savedFilesCount = 0;
        const url = this.createEpoUrl(`api/${contractId}/spotchecks/${spotcheckId}/attachments/base64`);
        files.forEach(file => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                this._httpClient.post(url, { file: reader.result, fileName: file.name }).subscribe(() => {
                    savedFilesCount++;
                    if (savedFilesCount === files.length) {
                        this.allSpotCheckAttachmentsSaved = true;
                    }
                }, err => {
                    this._toaster.show({ heading: 'errors.heading', description: 'cache.error', technical: err.message || err, autoDismiss: false, type: 'error' });
                    savedFilesCount++;
                    if (savedFilesCount === files.length) {
                        this.allSpotCheckAttachmentsSaved = true;
                    }
                });
            }
        });
    }

    private syncSpotCheckDeletedAttachments(attachmentIds: string[], spotcheckId: string, contractId: string) {
        if (!attachmentIds || attachmentIds.length === 0) {
            return [of({})];
        }
        let requests$ = [];
        attachmentIds.forEach(a => {
            const url = this.createEpoUrl(`api/${contractId}/spotchecks/${spotcheckId}/attachments/${a}`);
            requests$.push(this._httpClient.delete(url));
        });
        return requests$;
    }

    private resetSavedIndicators() {
        this._allControlsSaved = false;
        this._allControlCommentsSaved = false;
        this._allSpotChecksSaved = false;
        this._allSpotCheckCommentsSaved = false;
        this._allControlAttachmentsSaved = false;
        this._allSpotCheckAttachmentsSaved = false;
        this._dataIsSynching.next(true);
        this._toaster.show({ heading: 'cache.sync-start-heading', description: 'cache.sync-start-description' });
    }

    private checkAllSaved() {
        if (this.allDataSaved === true) {
            this._toaster.show({ heading: 'cache.sync-end-heading', description: 'cache.sync-end-description', autoDismiss: false });
            this._dataIsSynching.next(false);
        }
    }

    private createEpoUrl(relativePath: string) {
        return `${environment.apiUrl}/${relativePath}`;
    }
}