import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeficitGlobalProposalData } from 'app/admin/allgemein/deficit-global-proposals/deficit-global-proposal-data';
import { DeficitGlobalProposalLoadFilter } from 'app/admin/allgemein/deficit-global-proposals/deficit-global-proposal-load-filter.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ContractService } from '../masterData/contract/contract.service';

@Injectable({
  providedIn: 'root'
})
export class DeficitGlobalProposalService {

  constructor(private _httpClient: HttpClient, private contractService: ContractService) { }
  
  public createDeficitGlobalProposal(body: any): Observable<any> {
    return this.contractService.selectedContract.pipe(
      take(1),
      mergeMap((contract) => {
        const url = this.createEpoUrl(`api/${contract.id}/deficitgroupproposals`);
        return this._httpClient.post<DeficitGlobalProposalData>(url, body);
      }));
  }

  public getDeficitGlobalProposal(skipNr?: number, takeNr?: number, filter?: DeficitGlobalProposalLoadFilter): Observable<PagedResultResponse<DeficitGlobalProposalData>> {
    let url = this.createEpoUrl(`api/deficitglobalproposals?skip=${skipNr > 0 ? skipNr : 0}`);
        if (takeNr) {
          url = `${url}&take=${takeNr}`;
        }
        if (filter) {
          if (filter.name) {
            url = `${url}&name=${filter.name}`;
          }
          if (filter.deficitGroup) {
            url = `${url}&deficitGroup=${filter.deficitGroup}`;
          }
          if (filter.description) {
            url = `${url}&description=${filter.description}`;
          }
          if (filter.suggestedDescription) {
            url = `${url}&suggesteddescription=${filter.suggestedDescription}`;
          }
          if (filter.comment) {
            url = `${url}&comment=${filter.comment}`;
          }
          if (filter.isActive !== undefined) {
            url = `${url}&isactive=${filter.isActive}`;
          }
          if (filter.isStatus != 'all') {
            url = `${url}&isstatus=${filter.isStatus}`;
          }
          if (filter.languageId) {
            url = `${url}&languageId=${filter.languageId}`;
          }
          if (filter.entityNumber) {
            url = `${url}&entityNumber=${filter.entityNumber}`;
          }
          if (!!filter.orderBy) {
            url = `${url}&orderBy=${filter.orderBy}`;
          }
        }
        console.log(url);
        return this._httpClient.get<PagedResultResponse<DeficitGlobalProposalData>>(url);
  }

  public approveDeficitGlobalProposal(deficitGroupProposalId) {
    const url = this.createEpoUrl(`api/deficitglobalproposals/${deficitGroupProposalId}/approve`);
    return this._httpClient.put(url, {});

  }

  public rejectDeficitGlobalProposal(deficitGroupProposalId) {
    const url = this.createEpoUrl(`api/deficitglobalproposals/${deficitGroupProposalId}/reject`);
    return this._httpClient.put(url, {});

  }

  public getDeficitGlobalProposalHistory(id:string): Observable<Array<DeficitGlobalProposalData>>{
    let url = this.createEpoUrl(`api/deficitglobalproposals/${id}/history`);
    return this._httpClient.get<Array<DeficitGlobalProposalData>>(url);
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}
