import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpotCheckData } from './spot-check-data';
import { StichprobenFormularService } from 'app/random-sample/shared/stichproben-formular.service';
import { SpotCheckService } from 'app/random-sample/shared/spot-check.service';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { SpotCheckResponse } from '../shared/spot-check-response';
import { StatusPhase } from 'app/shared/models/status-phase';
import { Rating } from 'app/shared/models/rating';
import { DeficitGroupViewModel } from 'app/shared/deficit-group-view-model';
import { DeficitValueResponse } from 'app/shared/models/deficit-value-response';
import { BaseFilterData, allId } from 'app/shared/components/filter/filter';
import { DeficitWithValue } from 'app/shared/deficit-with-value.view-model';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { DeficitConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-configuration.resource';
import { DeficitFixed } from 'app/shared/models/deficit-fixed';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take, map, catchError } from 'rxjs/operators';
import { UserRoleService } from 'app/shared/services/user-role.service';
import { SpotCheckStatusTransitionModel } from './spot-check-status-transition.model';
import { RequiredRoles } from 'app/shared/helper/required-roles';
import { environment } from 'environments/environment';
import { DeficitMapping } from 'app/shared/masterData/deficit/deficitMapping';
import { DeficitVariant } from 'app/shared/masterData/deficit-variant';
import { RandomSampleStatusCalculator } from '../shared/random-sample-status-calculator';
import { TranslateService } from '@ngx-translate/core';
import { OnlineOfflineService } from 'app/local-cache/services/online-offline.service';
import { OfflineSyncService } from 'app/local-cache/services/offline-sync.service';
import { FileAttachment } from 'app/shared/models/file-attachment.resource';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { UserProfileService } from 'app/core/user-profile.service';
import { User } from 'app/core/user';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { ContractUserService } from 'app/shared/masterData/user/contract-user.service';
import { ContractUserResource } from 'app/core/contract-user.resource';
import { 
  transformContractUserToDedicatedUser, 
  transformNullToDedicatedUser, 
  transformUserResponseToDedicatedUser 
} from 'app/shared/helper/user-transformer';
import { DedicatedUser } from 'app/shared/masterData/user/dedicated-user';

@Component({
  selector: 'epo-spot-check-form',
  templateUrl: './spot-check-form.component.html',
  styleUrls: ['./spot-check-form.component.scss'],
})
export class SpotCheckFormComponent implements OnInit,OnDestroy {
  @Input() spotCheckId: string;

  @Input() ticketDescription: string;
  @Input() plant: string;
  @Input() ticketNumber: string;
  @Input() roomNumber: string;
  @Input() tenderPosition: string;
  @Input() attachementLink: string;
  @Input() checkTypeName: string;
  @Input() contractPositionName: string;
  @Input() tradeName: string;
  @Input() checkTypeId: string;
  @Input() contractPositionId: string;
  @Input() tradeId: string;
  @Input() controlId: string;

  @ViewChild('spotCheckFileInput', { static: false })
  fileInput: ElementRef;

  public isLoading = true;
  public isSaving = false;
  public controlUrl: string;
  private _tradeConfigs: TradeConfigurationResponse[] = [];
  get tradeConfigs() {
    return this._tradeConfigs.filter(
      (x) =>
        (x.isActive && x.trade.isActive) ||
        (this.spotCheck && this.spotCheck.tradeConfigurationId === x.id)
    );
  }
  private _checkTypeConfigs: CheckTypeConfiguration[] = [];
  get checkTypeConfigs() {
    return this._checkTypeConfigs.filter(
      (x) =>
        (x.isActive && x.checkType.isActive) ||
        (this.spotCheck && this.spotCheck.checkTypeConfigurationId === x.id)
    );
  }
  private _contractPositionConfigs: ContractPositionConfiguration[] = [];
  get contractPositionConfigs() {
    return this._contractPositionConfigs.filter(
      (x) =>
        (x.isActive && x.contractPosition.isActive) ||
        (this.spotCheck &&
          this.spotCheck.contractPositionConfigurationId === x.id)
    );
  }
  public deficitGroups: DeficitGroupConfiguration[];

  public statusTransitionModel: SpotCheckStatusTransitionModel;

  public formData = new SpotCheckData();

  public set selectedNewState(values) {
    this.formData.statusPhaseOne = values.statusPhaseOne;
    this.formData.statusPhaseTwo = values.statusPhaseTwo;
    this.formData.statusPhaseThree = values.statusPhaseThree;
  }

  public tradeDisabled = false;
  public sampleTypeDisabled = false;
  public contractPositionDisabled = false;
  public descriptionDisabled = false;
  public lvPositionDisabled = false;
  public requirementsDisabled = false;
  public anlageDisabled = false;
  public raumDisabled = false;
  public commentDisabled = false;
  public sampleRatingDisabled = false;
  public sampleStateDisabled = false;
  public anlageDefaultDisabled = false;
  public defiziteDisabled = false;
  public progress: number;
  public fileError: boolean = false;

  public withdrawnSpotCheck:boolean = false;

  filesToUpload: File[] = [];
  attachmentsToRemove: FileAttachment[] = [];

  public deficitFixedStatus = [
    { value: DeficitFixed.Ja, displayName: 'spot-checks.form.state-yes' },
    { value: DeficitFixed.Nein, displayName: 'spot-checks.form.state-no' },
    {
      value: DeficitFixed.ZurKlaerung,
      displayName: 'spot-checks.form.state-clarify',
    },
  ];

  public noDefiziteSelected: boolean;
  public deficiteExistInSelectedCombination = false;

  public showEditTitle = false;
  public showNewTitle = true;
  public statusPhase = StatusPhase;
  public rating = Rating;
  public currentStatusPhase = 1;
  public currentState: string;

  public get ticketUrl() {
    return `${environment.archibusUrl}?woactivity_log_hwohactivity_log.wr_id=${this.formData.ticketNumber}`;
  }

  public filteredDeficitGroups: DeficitGroupViewModel[];
  private selectedDeficits: DeficitValueResponse[];
  private spotCheck: SpotCheckResponse;
  private filterData: BaseFilterData;

  public comment: string;
  private _contractId: string;
  public isUserGlobalAdmin: boolean;
  
  
  public dedicatedUsers: DedicatedUser[] = [];
  private _assignedUser: DedicatedUser;
  public assignedUserGuid: string;
  @ViewChild('dedicatedUser') select: ElementRef;
  public get assignedUser(): DedicatedUser {
    return this._assignedUser;
  }
  public set assignedUser(value: DedicatedUser) {
    this._assignedUser = value;
  }
  private _contractUsersSub: Subscription;
  
  constructor(
    public activeModal: NgbActiveModal,
    private _userProfileService: UserProfileService,
    private _stichprobenFormularService: StichprobenFormularService,
    private _randomSampleService: SpotCheckService,
    private _contractService: ContractService,
    private _userRoleService: UserRoleService,
    private _statusCalculator: RandomSampleStatusCalculator,
    private _translate: TranslateService,
    private _onlineOfflineService: OnlineOfflineService,
    private _toaster: ToasterService,
    private _offlineSyncService: OfflineSyncService,
    private _contractUserService: ContractUserService
  ) {
    this._translate.get('spot-checks.form.title-new').subscribe((t) => {
      this._titleNew = t;
    });
    this._contractService.selectedContract
      .pipe(take(1))
      .subscribe((contract) => {
        this._contractId = contract.id;
      });
  }

  ngOnInit() {
    this.populateContractUsersSelectOptions();
    if (!this.spotCheckId) {
      this._userRoleService.redirectIfUserIsMissingRoles(
        RequiredRoles.createSpotCheck,
        () => this.activeModal.dismiss('unauthorized')
      );
    }
    this._userProfileService.currentUser.subscribe(user => {
      this.isUserGlobalAdmin = (user as User).role === GlobalUserRole.GlobalAdmin;
    });
    this.getBaseData();
  }

  public printNameOfContractUser(user:ContractUserResource):string{
    if (user.firstname && user.lastname) {
        return `${user.firstname} ${user.lastname}`;
    } else {
        return user.loginEmail;
    }
  }

  private _titleNew: string;
  private _titleEdit: string;

  public get title() {
    if (this.spotCheck && this.spotCheck.contractEntityNumber) {
      return this._titleEdit;
    }
    return this._titleNew;
  }

  public get hasExistingDeficit() {
    return (
      (this.selectedDeficits &&
        this.selectedDeficits.some((deficit) => deficit.defizitExists)) ||
      null
    );
  }

  public get hasNoDeficits() {
    return (
      !this.selectedDeficits ||
      !this.selectedDeficits.some((deficit) => deficit.defizitExists) ||
      null
    );
  }

  public get fixedDisabled(): boolean {
    return (
      this.formData.rating === Rating.NoClaim ||
      this.formData.statusPhaseThree === StatusPhase.ClaimCancelled
    );
  }

  public getBaseData() {
    this.isLoading = true;
    const deficitGroups$ = this.spotCheckId
      ? forkJoin([
        this._randomSampleService.getDeficitsForSpotCheck(this.spotCheckId),
        this._randomSampleService.getDeficitGroups(),
      ]).pipe(
        map((res) => {
          res[0].forEach((group1) => {
            const group2 = res[1].find((g) => g.name === group1.name);
            if (!group2) {
              res[1] = [...res[1], group1];
            } else {
              group1.deficitConfigurations.forEach((dc1) => {
                const dc2 = group2.deficitConfigurations.find(
                  (d) => d.id === dc1.id
                );
                if (!dc2) {
                  group2.deficitConfigurations = [
                    ...group2.deficitConfigurations,
                    dc1,
                  ];
                }
              });
            }
          });
          return res[1];
        })
      )
      : this._randomSampleService.getDeficitGroups();

    forkJoin([
      this._randomSampleService.getTradeConfigurations(),
      this._randomSampleService.getTypes(),
      deficitGroups$,
      this._randomSampleService.getContractPositions(),
    ]).subscribe(
      (response) => {
        this._tradeConfigs = response[0].items;
        this._checkTypeConfigs = response[1];
        this.deficitGroups = response[2];
        this.deficitGroups.forEach((group) => {
          group.deficitConfigurations = this.sortAndFilterDeficits(
            group.deficitConfigurations
          );
        });
        this._contractPositionConfigs = response[3].items;

        this.handleForm();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  private sortAndFilterDeficits(
    configs: DeficitConfiguration[]
  ): DeficitConfiguration[] {
    configs = configs.filter(
      (v, i, a) => a.indexOf(a.find((t) => t.id === v.id)) === i
    );
    return configs.sort((n1, n2) => {
      if (n1.deficit.title > n2.deficit.title) {
        return 1;
      }
      if (n1.deficit.title < n2.deficit.title) {
        return -1;
      }
      return 0;
    });
  }

  public handleForm() {
    if (this.spotCheckId) {
      this.showNewTitle = false;
      this.showEditTitle = true;
      this.loadRandomSampleById(this.spotCheckId);
    } else {
      this.initNewForm();
    }
  }

  initNewForm() {
    this.selectedDeficits = [];

    this.assignGewerkFromFilter();
    this.assignContractPositionFromFilter();
    this.assignStichprobenartFromFilter();

    this.formData.statusPhaseOne = StatusPhase.InProgress;
    this.formData.statusPhaseTwo = StatusPhase.None;
    this.formData.statusPhaseThree = StatusPhase.None;
    this.formData.deficitFixed = DeficitFixed.Nein;

    this.formData.ticketDescription = this.ticketDescription;
    this.formData.plant = this.plant;
    this.formData.ticketNumber = this.ticketNumber;
    this.formData.roomNumber = this.roomNumber;
    this.formData.tenderPosition = this.tenderPosition;
    this.formData.attachementLink = this.attachementLink;

    if (this.controlId) {
      this.assignMasterdtaById();
    } else {
      this.assignMasterdataByName();
    }
    this.updateDefizite();
  }

  private assignMasterdtaById() {
    this.formData.controlId = this.controlId;
    if (this.ticketNumber) {
      this.formData.ticketNumber = this.ticketNumber;
    }
    if (this.tradeId) {
      const tradeConfig = this.tradeConfigs.find((t) => t.id === this.tradeId);
      if (tradeConfig) {
        this.formData.tradeConfigurationId = tradeConfig.id;
        this.tradeDisabled = true;
      }
    }
    if (this.contractPositionId) {
      const contractPositionConfig = this.contractPositionConfigs.find(
        (t) => t.id === this.contractPositionId
      );
      if (contractPositionConfig) {
        this.formData.contractPositionConfigurationId =
          contractPositionConfig.id;
        this.contractPositionDisabled = true;
      }
    }
    if (this.checkTypeId) {
      const checkTypeConfig = this.checkTypeConfigs.find(
        (t) => t.id === this.checkTypeId
      );
      if (checkTypeConfig) {
        this.formData.checkTypeConfigurationId = checkTypeConfig.id;
        this.sampleTypeDisabled = true;
      }
    }
    this.createStatusTransitionModelForNewSpotcheck();
  }

  private createControlUrl() {
    if (!this.formData.controlId) return;
    this._contractService.selectedContract
      .pipe(take(1))
      .subscribe((contract) => {
        this.controlUrl = `${window.location.origin}/contract/${contract.urlPath}/control/edit-form?id=${this.formData.controlId}`;
      });
  }

  private assignMasterdataByName() {
    if (this.tradeName) {
      const tradeConfig = this.tradeConfigs.find((t) =>
        t.trade.name.toUpperCase().includes(this.tradeName.toUpperCase())
      );
      if (tradeConfig) {
        this.formData.tradeConfigurationId = tradeConfig.id;
      }
    }
    if (this.contractPositionName) {
      const contractPositionConfig = this.contractPositionConfigs.find((t) =>
        t.contractPosition.name
          .toUpperCase()
          .includes(this.contractPositionName.toUpperCase())
      );
      if (contractPositionConfig) {
        this.formData.contractPositionConfigurationId =
          contractPositionConfig.id;
      }
    }
    if (this.checkTypeName) {
      const checkTypeConfig = this.checkTypeConfigs.find((t) =>
        t.checkType.name
          .toUpperCase()
          .includes(this.checkTypeName.toUpperCase())
      );
      if (checkTypeConfig) {
        this.formData.checkTypeConfigurationId = checkTypeConfig.id;
      }
    }
    this.createStatusTransitionModelForNewSpotcheck();
  }

  private createStatusTransitionModelForNewSpotcheck() {
    this._userRoleService.ContractRole.subscribe((role) => {
      this.statusTransitionModel = new SpotCheckStatusTransitionModel(
        new SpotCheckResponse(),
        role
      );
      if (!this.statusTransitionModel.canEdit) {
        this.disableAllFormInputFields(false);
      }
    });
  }

  public loadRandomSampleById(randomSampleId: string) {
    forkJoin([
      this._randomSampleService.getSpotCheckById(randomSampleId),
      this._userRoleService.ContractRole,
    ]).subscribe(
      ([randomSample, role]) => {
        this.spotCheck = randomSample;
        if (this.spotCheck.isWithdrawn) {
          this._translate
          .get('spot-checks.form.title-withdrawn', {
            number: this.spotCheck.contractEntityNumber,
          })
          .subscribe((t) => {
            this._titleEdit = t;
          });
        } else {
            this._translate
            .get('spot-checks.form.title-edit', {
              number: this.spotCheck.contractEntityNumber,
            })
            .subscribe((t) => {
              this._titleEdit = t;
            });  
        }
        
        
        this.statusTransitionModel = new SpotCheckStatusTransitionModel(
          this.spotCheck,
          role
        );
        this.currentState = this._statusCalculator.getFinalStatusForSpotCheck(
          this.spotCheck
        );
        if (!this.statusTransitionModel.canEdit) {
          this.disableAllFormInputFields(false);
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.assignDataFromRandomSampleToFormData();
        this.handleFormState();
        this.updateDefizite();
        this.createControlUrl();
      }
    );
  }

  public updateDefizite() {
    if (
      !this.formData.contractPositionConfigurationId ||
      !this.formData.checkTypeConfigurationId
    ) {
      return;
    }
    let filteredDeficitGroups = this.deficitGroups.map((deficitGroup) => {
      const filteredDeficits = deficitGroup.deficitConfigurations.filter(
        (deficit) => {
          return deficit.isActive && this.isDeficitMatchingCheckTypeAndContractPosition(
            deficit.deficit.mappings,
            this.formData.checkTypeConfigurationId,
            this.formData.contractPositionConfigurationId
          ) || this.selectedDeficits.find(sd => sd.deficitId === deficit.id);
        }
      );
      const filteredDeficitsWithValue = this.assignValueToDeficit(
        this.spotCheck,
        filteredDeficits
      );
      const newGroup: DeficitGroupViewModel = {
        name: deficitGroup.name,
        deficits: filteredDeficitsWithValue,
      };
      return newGroup;
    });

    filteredDeficitGroups = this.filterEmptyGroups(filteredDeficitGroups);

    if (filteredDeficitGroups && filteredDeficitGroups.length > 0) {
      this.deficiteExistInSelectedCombination = true;
    }
    this.filteredDeficitGroups = filteredDeficitGroups;
  }

  private isDeficitMatchingCheckTypeAndContractPosition(
    deficitMappings: DeficitMapping[],
    checkTypeConfigId: string,
    contractPositionConfigId: string
  ): boolean {
    const checkType = this.checkTypeConfigs.find(
      (c) => c.id == checkTypeConfigId
    );
    const contractPosition = this.contractPositionConfigs.find(
      (c) => c.id == contractPositionConfigId
    );
    return (
      checkType &&
      contractPosition &&
      deficitMappings.some(
        (map) =>
          map.checkTypeId === checkType.checkTypeId &&
          map.contractPositionId === contractPosition.contractPositionId &&
          map.deficitVariant === DeficitVariant.SpotCheck
      )
    );
  }

  private assignValueToDeficit(
    randomSample: SpotCheckResponse,
    filteredDeficits: DeficitConfiguration[]
  ): DeficitWithValue[] {
    return filteredDeficits.map((defizit) => {
      const defizitWithValue = new DeficitWithValue();
      defizitWithValue.id = defizit.id;
      defizitWithValue.title = defizit.deficit.title;
      defizitWithValue.description = defizit.deficit.description;
      defizitWithValue.tags = defizit.deficit.tags;

      if (!randomSample || !randomSample.deficitValues) {
        defizitWithValue.defizitExists = null;
        return defizitWithValue;
      }

      const value = randomSample.deficitValues.find(
        (deficitValue) => deficitValue.deficitId === defizit.id
      );
      if (value) {
        defizitWithValue.defizitExists = value.defizitExists;
      } else {
        defizitWithValue.defizitExists = null;
      }

      return defizitWithValue;
    });
  }

  private filterEmptyGroups(filteredDeficitGroups: DeficitGroupViewModel[]) {
    return filteredDeficitGroups.filter((group) => {
      return group.deficits && group.deficits.length > 0;
    });
  }

  public onSelectedDefiziteChanged(selectedDefizite: DeficitValueResponse[]) {
    this.selectedDeficits = selectedDefizite;
    if (this.hasAtLeastOneSelectedDefizit(this.selectedDeficits)) {
      this.noDefiziteSelected = false;
      if (
        this.formData.rating === Rating.NoClaim &&
        this.selectedDeficits.some((deficit) => deficit.defizitExists)
      ) {
        this.formData.rating = Rating.None;
      }
    }
    if (
      this.formData.rating === Rating.Claim &&
      !this.selectedDeficits.some((deficit) => deficit.defizitExists)
    ) {
      this.formData.rating = Rating.None;
    }
  }

  public getStichprobenName(stichprobenartId): string {
    const foundStichprobenart = this.checkTypeConfigs.find(
      (stichprobenArt) => stichprobenArt.id === stichprobenartId
    );
    if (foundStichprobenart && foundStichprobenart.checkType.name) {
      return foundStichprobenart.checkType.name;
    }
    return 'Unbekannte Stichprobenart';
  }

  private assignDataFromRandomSampleToFormData() {
    this.formData.contractPositionConfigurationId = this.spotCheck.contractPositionConfigurationId;
    this.formData.checkTypeConfigurationId = this.spotCheck.checkTypeConfigurationId;
    this.formData.tradeConfigurationId = this.spotCheck.tradeConfigurationId;
    this.formData.ticketNumber = this.spotCheck.ticketNumber;
    this.formData.roomNumber = this.spotCheck.roomNumber;
    this.formData.ticketDescription = this.spotCheck.ticketDescription;
    this.formData.id = this.spotCheck.id;
    this.formData.plant = this.spotCheck.plant;
    this.formData.appendix = this.spotCheck.appendix;
    this.formData.rating = this.spotCheck.rating;
    this.formData.tenderPosition = this.spotCheck.tenderPosition;
    this.selectedDeficits = this.spotCheck.deficitValues;
    this.formData.statusPhaseOne = this.spotCheck.statusPhaseOne;
    this.formData.statusPhaseTwo = this.spotCheck.statusPhaseTwo;
    this.formData.statusPhaseThree = this.spotCheck.statusPhaseThree;
    this.formData.comments = this.spotCheck.comments;
    this.formData.deficitFixed = this.spotCheck.deficitFixed;
    this.formData.createdUtc = this.spotCheck.createdUtc;
    this.formData.modifiedUtc = this.spotCheck.modifiedUtc;
    this.formData.createdBy = this.spotCheck.createdBy;
    this.formData.modifiedBy = this.spotCheck.modifiedBy;
    this.formData.attachementLink = this.spotCheck.attachementLink;
    this.formData.controlId = this.spotCheck.controlId;
    this.formData.attachments = this.spotCheck.attachments;
    this.formData.isWithdrawn = this.spotCheck.isWithdrawn;
    this.formData.dedicatedUser = this.spotCheck.dedicatedUser;
    //new field DedicatedUser
    if (this.spotCheck.dedicatedUser) {
      try {
        this.assignedUser = transformUserResponseToDedicatedUser(this.spotCheck.dedicatedUser);
        this.assignedUserGuid = this.spotCheck.dedicatedUser.id;
        this.setSelectedValueInSelectElementRef();
      } catch (error) {
        console.log(error.message);
        console.log("dedicated user not found or inactve, setting to default null user");
        this.assignedUser = transformNullToDedicatedUser();
        this.assignedUserGuid = "00000000-0000-0000-0000-000000000000";
        this.setSelectedValueInSelectElementRef();
      }
    } else {
      this.assignedUser = transformNullToDedicatedUser();
      this.assignedUserGuid = "00000000-0000-0000-0000-000000000000";
      this.setSelectedValueInSelectElementRef();
    }
    //for use in template
    this.withdrawnSpotCheck = this.spotCheck.isWithdrawn;
  }

  private assignDedicatedUser() {
    try {
      this.assignedUser = this.dedicatedUsers.find(item => item.id === this.assignedUserGuid);  
    } catch (error) {
      console.log("could not find user in list of contract users");
      console.log(error);
    }
  }

  private setSelectedValueInSelectElementRef(){
    this.select.nativeElement.options[this.dedicatedUsers.findIndex(item => item.id == this.assignedUser.id)].selected = true;
  }

  private setAssignedUserOnPostFormData():void {
    this.assignDedicatedUser();
    if (this.assignedUser && this.assignedUser.id != "00000000-0000-0000-0000-000000000000") {
      if (this.assignedUser.isActive) {
        this.formData.dedicatedUser = this.assignedUser.toUserResponse();
      } else {
        throw "Dedicated User is Inactive"; 
      }
    } else {
      this.formData.dedicatedUser = null;
    }
  }

  private populateContractUsersSelectOptions():void {
    this._contractUsersSub = this._contractUserService.getFilteredContractUsersForCurrentContract().subscribe(response => {
      response.items.map(item => {
        this.dedicatedUsers.push(transformContractUserToDedicatedUser(item));
      });
    }, err => {
      console.log(err);
    },
    ()=>{
      this.dedicatedUsers.push(transformNullToDedicatedUser());
    })
  }

  private setCurrentStatusPhase() {
    if (
      !this.formData.statusPhaseOne ||
      this.formData.statusPhaseOne === StatusPhase.InProgress
    ) {
      this.currentStatusPhase = 1;
    } else if (
      this.formData.statusPhaseTwo === StatusPhase.InProgress ||
      this.formData.statusPhaseTwo === StatusPhase.None
    ) {
      this.currentStatusPhase = 2;
    } else {
      this.currentStatusPhase = 3;
    }
  }

  private isSpotCheckWithdrawn(): boolean{
    return this.spotCheck ? this.spotCheck.isWithdrawn : this.withdrawnSpotCheck;
  }

  public setFormFields() {
    if(this.isSpotCheckWithdrawn()){
      this.disableDefaultInputFields();
      this.disableAllFormInputFields(true);
      return;
    }
    if (this.spotCheck.isEditable) {
      this.disableDefaultInputFields();
      if (this.currentStatusPhase == 1) {
        this.sampleRatingDisabled = false;
        this.defiziteDisabled = false;
      }
    } else {
      this.disableAllFormInputFields(true);
    }
  }

  private handleFormState() {
    this.setCurrentStatusPhase();
    this.setFormFields();
  }

  private disableDefaultInputFields() {
    this.tradeDisabled = true;
    this.sampleTypeDisabled = true;
    this.contractPositionDisabled = true;
    this.defiziteDisabled = true;
    this.sampleRatingDisabled = true;
  }

  disableAllFormInputFields(includeStatus: boolean) {
    this.disableDefaultInputFields();
    this.descriptionDisabled = true;
    this.lvPositionDisabled = true;
    this.requirementsDisabled = true;
    this.anlageDisabled = true;
    this.raumDisabled = true;
    this.commentDisabled = true;
    if (includeStatus) {
      this.sampleStateDisabled = true;
    }
    this.anlageDefaultDisabled = true;
  }

  

  public onSubmit() {
    if (!this.isSpotCheckWithdrawn()) {
      this.isSaving = true;
      if (
        this.formData.isFormDataValid() &&
        (this.formData.statusPhaseOne === StatusPhase.InProgress ||
          this.isValidDeficite())
      ) {
        try {
          this.formData.deficitValues = this.selectedDeficits;
          this.setAssignedUserOnPostFormData();
          this.saveStichprobenData(this.formData);
        } catch (error) {
          this.isSaving = false;
          this._toaster.show({
            heading: 'errors.heading',
            description: 'spot-checks.form.dedicated-user-spotcheck-error',
            type: 'error',
          });
        }
      } else {
        this.isSaving = false;
      }
    } else {
      this._toaster.show({
        heading: 'errors.heading',
        description: 'spot-checks.form.withdrawn-spotcheck-error',
        type: 'error',
      });
    }
  }
  public withdrawSpotCheck() {
    this.isSaving = true;
    this.saveWithdrawnSpotCheck(this.formData);
    this.isSaving = false;
  }

  private isValidDeficite(): boolean {
    if (
      this.deficiteExistInSelectedCombination &&
      !this.hasAtLeastOneSelectedDefizit(this.selectedDeficits)
    ) {
      this.noDefiziteSelected = true;
      return false;
    }
    return true;
  }

  private saveWithdrawnSpotCheck(spotCheckFormData: SpotCheckData) {
    if (this._onlineOfflineService.isOnline) {
      this._stichprobenFormularService
        .withdrawSpotCheck(spotCheckFormData)
        .subscribe(
          () => {
            this.activeModal.close();
          },
          (err) => {
            console.log(err);
            this.isSaving = false;
            this.activeModal.close();
          }
        );
    } else {
      //TODO: How to test the below ?
      this._offlineSyncService.saveSpotCheckToCache(
        spotCheckFormData,
        this.comment,
        this.filesToUpload,
        this.getAttachmentsToDelete(),
        this._contractId
      );
      this.isSaving = false;
      this.activeModal.close();
    }
  }
  private saveStichprobenData(stichprobenFormData: SpotCheckData) {
    this.isSaving = true;
    if (this.spotCheck && this.spotCheck.id) {
      if (this._onlineOfflineService.isOnline) {
        this._stichprobenFormularService
          .updateSpotCheck(stichprobenFormData)
          .subscribe(
            () => {
              if (this.comment) {
                this.saveDependentObjects([
                  this.createComment(this.spotCheck.id),
                  ...this.updateAttachments(),
                ]);
              } else {
                this.saveDependentObjects(this.updateAttachments());
              }
            },
            (err) => {
              console.log(err);
              this.isSaving = false;
            }
          );
      } else {
        this._offlineSyncService.saveSpotCheckToCache(
          stichprobenFormData,
          this.comment,
          this.filesToUpload,
          this.getAttachmentsToDelete(),
          this._contractId
        );
        this.isSaving = false;
        this.activeModal.close();
      }
    } else {
      if (this._onlineOfflineService.isOnline) {
        this._stichprobenFormularService
          .createSpotCheck(stichprobenFormData)
          .subscribe(
            (spotCheck) => {
              this.spotCheck = new SpotCheckResponse();
              this.spotCheck.id = spotCheck.id;
              if (this.comment) {
                this.saveDependentObjects([
                  this.createComment(spotCheck.id),
                  ...this.updateAttachments(),
                ]);
              } else {
                this.saveDependentObjects(this.updateAttachments());
              }
            },
            (err) => {
              console.log(err);
              this.isSaving = false;
            }
          );
      } else {
        this._offlineSyncService.saveSpotCheckToCache(
          stichprobenFormData,
          this.comment,
          this.filesToUpload,
          this.getAttachmentsToDelete(),
          this._contractId
        );
        this.isSaving = false;
        this.activeModal.close();
      }
    }
  }

  saveDependentObjects(callbacks$: (Observable<object> | Observable<void>)[]) {
    this.isSaving = true;
    forkJoin(callbacks$).subscribe(
      () => {
        this.activeModal.close();
      },
      (err) => {
        this.isSaving = false;
        console.log(err);
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  private getAttachmentsToDelete() {
    return this.spotCheck && this.spotCheck.attachments
      ? this.spotCheck.attachments
        .filter(
          (a) =>
            this.formData.attachments.findIndex((f) => f.id === a.id) === -1
        )
        .map((a) => a.id)
      : [];
  }

  private updateAttachments(): (Observable<object> | Observable<void>)[] {
    this.isSaving = true;
    this.fileError = false;
    const attachmentsToDelete = this.getAttachmentsToDelete();
    const deleteAttachments$ = attachmentsToDelete.map((a) =>
      this._stichprobenFormularService.deleteSpotCheckAttachment(
        a,
        this.spotCheck.id
      )
    );
    if (this.filesToUpload.length === 0) {
      if (attachmentsToDelete.length === 0) {
        return [of({})];
      }
      return deleteAttachments$;
    }
    const createAttachments$ = this._stichprobenFormularService
      .createspotCheckAttachments(this.filesToUpload, this.spotCheck.id)
      .pipe(
        map((event) => this.reportFileProgress(event)),
        catchError((err) => this.catchFileError(err))
      );

    return [createAttachments$, ...deleteAttachments$];
  }

  reportFileProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      this.progress = Math.round((event.loaded / event.total) * 100);
    }
  }

  catchFileError(error: HttpErrorResponse) {
    this.fileError = true;
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    this._toaster.show({
      heading: 'files.upload-failed',
      description: 'files.upload-failed-detail',
      type: 'error',
    });
    return throwError(errorMessage);
  }

  private createComment(spotCheckId: string) {
    return this._stichprobenFormularService.createSpotCheckComment(
      this.comment,
      spotCheckId
    );
  }

  private hasAtLeastOneSelectedDefizit(
    defiziteResults: DeficitValueResponse[]
  ) {
    if (!Array.isArray(defiziteResults)) {
      return false;
    }
    const index = defiziteResults.findIndex((result) => {
      return (
        (result && result.defizitExists === true) ||
        (result && result.defizitExists === false)
      );
    });
    return index > -1;
  }

  private assignGewerkFromFilter() {
    if (this.filterData && this.filterData.selectedTrade.id !== allId) {
      this.formData.tradeConfigurationId = this.filterData.selectedTrade.id;
      this.tradeDisabled = true;
    }
  }

  private assignContractPositionFromFilter() {
    if (
      this.filterData &&
      this.filterData.selectedContractPosition.id !== allId
    ) {
      this.formData.contractPositionConfigurationId = this.filterData.selectedContractPosition.id;
      this.contractPositionDisabled = true;
    }
  }

  private assignStichprobenartFromFilter() {
    if (this.filterData && this.filterData.selectedCheckType.id !== allId) {
      this.formData.checkTypeConfigurationId = this.filterData.selectedCheckType.id;
      this.sampleTypeDisabled = true;
    }
  }

  public copyLinkToClipboard() {
    this._contractService.selectedContract
      .pipe(take(1))
      .subscribe((contract) => {
        let url = `${window.location.origin}/contract/${contract.urlPath}/spot-check/edit-form`;
        if (this.spotCheckId) {
          url = `${url}?id=${this.spotCheckId}`;
        }
        const selBox = document.createElement('textarea');
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
      });
  }

  removeAttachment(attachment: FileAttachment) {
    this.formData.attachments = this.formData.attachments.filter(
      (a) => a.id !== attachment.id
    );
  }

  removeFile(file: File) {
    this.filesToUpload = this.filesToUpload.filter((f) => f.name !== file.name);
  }

  selectFile(files: FileList) {
    this.filesToUpload = [...this.filesToUpload, ...Array.from(files)];
  }

  openFileSelect(event$: MouseEvent) {
    this.fileInput.nativeElement.click();
  }

  getDeficitStateName(state: string) {
    if (
      this.formData.rating === Rating.NoClaim ||
      this.formData.statusPhaseThree === StatusPhase.ClaimCancelled
    ) {
      return '-';
    }
    return this._translate.instant(state);
  }

  ngOnDestroy(): void {
    this._contractUsersSub.unsubscribe();
  }
}
