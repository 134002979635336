export class FilterSettings {
    constructor(private _showAlleGewerke: boolean,
        private _showContractPosition: boolean,
        private _showContractPositionAll: boolean,
        private _showArt: boolean, 
        private _showUser: boolean,
        private _filterCheckTypes?: 'Spotcheck' | 'Control') {
        if (this._showContractPosition === false && this._showContractPositionAll === true) {
            throw new Error('Invalid combination:  Couldn\'t show service \'all\' if show service is set to false.');
        }
    }

    get showUser() {
        return this._showUser;
    }
    get showArt() {
        return this._showArt;
    }
    get showContractPosition() {
        return this._showContractPosition;
    }
    get showContractPositionAll() {
        return this._showContractPositionAll;
    }
    get showAlleGewerke() {
        return this._showAlleGewerke;
    }

    get filterCheckTypes() {
        return this._filterCheckTypes;
    }
}
