import { DeficitTag } from './masterData/deficit/deficit-tag.resource';
import { Deficit } from './masterData/deficit/deficit.resource';

export class DeficitWithValue {
    id: string;
    title: string;
    description: string;
    defizitExists?: boolean;
    tags: DeficitTag[] = [];

    constructor(deficit?: Deficit) {
        if (deficit) {
            this.id = deficit.id;
            this.title = deficit.title;
            this.description = deficit.description;
            this.tags = deficit.tags;
            // defiziteExists kann in der DB gesetzt werden,
            // sonst müsste an dieser Stelle über alle Random samples und control samples iteriert werden und nach der ID gesucht
        }
    }
}
