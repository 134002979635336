import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContractService } from '../../contract/contract.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ContractPositionConfiguration } from './contract-position-configuration.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';

@Injectable({
    providedIn: 'root'
})

export class ContractPositionConfigurationService {

    constructor(private _http: HttpClient, private contractService: ContractService) { }

    getContractPositionConfigurationsForCurrentContract(active?: boolean): Observable<PagedResultResponse<ContractPositionConfiguration>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                let url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations`;
                if (contract.contractLanguageId) {
                    url = `${url}?languageId=${contract.contractLanguageId}`;
                }
                if (active != undefined) {
                    url = `${url}${url.indexOf('?') > -1 ? '&' : '?'}configurationActive=${active}`;
                }
                return this._http.get<PagedResultResponse<ContractPositionConfiguration>>(url)
            }
            )
        )
    }

    createContractPositionConfiguration(positionId: string): Observable<ContractPositionConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations`;
                return this._http.post<ContractPositionConfiguration>(url, { contractPositionId: positionId, isActive: true });
            }));
    }

    deleteContractPositionConfiguration(positionId: string): Observable<any> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations/${positionId}`;
                return this._http.delete(url);
            }));
    }

    setTradeConfigurationActive(positionConfigurationId: string, isActive: boolean): Observable<ContractPositionConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations/${positionConfigurationId}/active`;
                return this._http.patch<ContractPositionConfiguration>(url, { isActive });
            }));
    }

    public getContractPositionConfigurationHistory(id: string): Observable<ContractPositionConfiguration[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                return this._http.get<ContractPositionConfiguration[]>(`${environment.apiUrl}/api/${contract.id}/contractpositionconfigurations/${id}/history`);
            }));
    }
}