import { EInterval } from './interval';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class ControlTarget {
    id: string;
    contractPositionConfigurationId: string;
    checkTypeConfigurationId: string;
    tradeConfigurationId: string;
    interval: EInterval;
    amount: number;
    createdUtc: string;
    modifiedUtc: string;
    createdBy: UserResponse;
    modifiedBy: UserResponse;
}
