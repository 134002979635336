import { TradeConfigurationResponse } from './models/trade-configuration-response';
import { CheckTypeConfiguration } from './masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from './masterData/contract-position/configuration/contract-position-configuration.resource';

export abstract class BaseSampleCreator {
    constructor(
        private realEstates: TradeConfigurationResponse[],
        private types: CheckTypeConfiguration[],
        private contractPositions: ContractPositionConfiguration[]) { }

    protected tryParseDate(date: string): Date {
        try {
            return new Date(date);
        } catch (error) {
            console.error('Date is missing');
        }
    }

    protected getRealEstateName(realEstateId: string, unknownText: string) {
        const foundRealEstate = this.realEstates.find(c => c.id === realEstateId);
        if (foundRealEstate) {
            return foundRealEstate.trade.name;
        }
        return unknownText;
    }

    protected getStichprobenName(stichprobenartId, unknownText: string): string {
        const foundStichprobenart = this.types.find(stichprobenArt => stichprobenArt.id === stichprobenartId);
        if (foundStichprobenart && foundStichprobenart.checkType.name) {
            return foundStichprobenart.checkType.name;
        }
        return unknownText;
    }

    protected getContractPositionName(contractPositionId, unknownText: string): string {
        const foundPosition = this.contractPositions.find(cp => cp.id === contractPositionId);
        if (foundPosition && foundPosition.contractPosition.name) {
            return foundPosition.contractPosition.name;
        }
        return unknownText;
    }
}
