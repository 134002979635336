import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckType } from 'app/shared/masterData/check-type/check-type.resource';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { Language } from 'app/shared/masterData/language/language.resource';


@Component({
  templateUrl: './modal-check-type-edit.component.html',
  styleUrls: ['./modal-check-type-edit.component.scss']
})
export class ModalCheckTypeEditComponent {
  public checkType: CheckType;
  public inNewMode: boolean;
  languages: Language[];
  translations: TranslationViewModel[];

  constructor(public activeModal: NgbActiveModal, private languageService: LanguageService) { }

  ngOnInit() {
    const entityTranslations = this.checkType !== undefined ? this.checkType.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = entityTranslations.find(t => t.language.id === lang.id);
        if (translation) {
          return new TranslationViewModel(translation, lang);
        } else {
          return new TranslationViewModel({
            language: lang,
            languageId: lang.id,
            name: ''
          }, lang);
        }
      });
    });
  }

  canSave() {
    return this.checkType && this.checkType.nameDefault && this.translationsValid();
  }

  private translationsValid() {
    return this.translations && !this.translations.find(t => !t.isValid);
  }

  save() {
    this.checkType.translations = this.translations.filter(t => t.name).map(t => t.toResource());
    this.activeModal.close(this.checkType);
  }
}
