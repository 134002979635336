import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContractPositionViewModel } from './contract-position-view-model';
import { ContractPositionService } from 'app/shared/masterData/contract-position/contract-position.service';
import { ContractPositionConfigurationService } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { ConfigLoadFilter } from 'app/admin/allgemein/shared/config-load-filter';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';
import { PropertyDisplayMapping } from 'app/shared/models/property-display-mapping';
import { ConfigHistoryHelper } from 'app/admin/shared/config-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epo-contract-position-configuration',
  templateUrl: './contract-position-configuration.component.html',
  styleUrls: ['./contract-position-configuration.component.scss']
})
export class ContractPositionConfigurationComponent extends BaseFilterPagingComponent<ContractPositionConfiguration> implements OnInit, OnDestroy {

  public loadFilter: ConfigLoadFilter = new ConfigLoadFilter();
  private subscription: Subscription;
  public isSaving = false;
  public contractPositions: ContractPositionViewModel[] = [];

  constructor(
    private contractPositionService: ContractPositionService,
    private contractPositionConfigurationService: ContractPositionConfigurationService,
    private contractService: ContractService,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _modalService: NgbModal
  ) {
    super();
  }

  isSelectedFilterChanged(value) {
    this.loadFilter.isSelected = value;
    this.onFilterChange();
  }

  isActiveConfigSelected(value) {
    this.loadFilter.configurationActive = value;
    this.onFilterChange();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public loadData() {
    const $configs = this.contractPositionConfigurationService.getContractPositionConfigurationsForCurrentContract();
    this.subscription = $configs.subscribe(configs => {
      this.items = configs.items;
      this.loadContractPositions();
    });
  }

  private loadContractPositions() {
    this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
      this.loadFilter.contractId = contract.id;
      this.loadFilter.languageId = contract.contractLanguageId;
      this.load();
    });
  }

  private load() {
    this.isLoading = true;
    const $contractPositions = this.contractPositionService.getContractPositions(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    $contractPositions.pipe(take(1)).subscribe(globalPositions => {
      this.pagingGlobalCount = globalPositions.count;
      this.contractPositions = globalPositions.items.map(globalPosition => {
        const config = this.items.find(config => config.contractPosition.id === globalPosition.id);
        return {
          id: globalPosition.id,
          name: globalPosition.name,
          isSelected: (config && config.isActive) ? true : false,
          isActive: config ? config.isActive : globalPosition.isActive,
          contractPositionConfigurationId: config ? config.id : null
        };
      });
      this.isLoading = false;
    });
  }

  saveConfiguration(position: ContractPositionViewModel) {
    if (position.isSelected) {
      this.isSaving = true;
      this.contractPositionConfigurationService.createContractPositionConfiguration(position.id).subscribe((r) => {
        const cPos = this.contractPositions?.find(c => c.id === r.contractPositionId);
        if (cPos) {
          cPos.contractPositionConfigurationId = r.id;
        }
        this.contractPositionConfigurationService.getContractPositionConfigurationsForCurrentContract().subscribe((res) => {
          this.items = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err)
        this.isSaving = false;
      })
    } else {
      this.isSaving = true;
      this.contractPositionConfigurationService.deleteContractPositionConfiguration(position.contractPositionConfigurationId).subscribe(() => {
        this.contractPositionConfigurationService.getContractPositionConfigurationsForCurrentContract().subscribe((res) => {
          this.items = res.items;
          this.isSaving = false;
        });
      }, err => {
        console.log(err)
        this.isSaving = false;
      })
    }
  }

  setIsActiveConfig(position: ContractPositionViewModel) {
    if (!position.isSelected) {
      return;
    }
    this.isSaving = true;
    this.contractPositionConfigurationService.setTradeConfigurationActive(position.contractPositionConfigurationId, position.isActive).subscribe(() => {
      this.isSaving = false;
    }, err => {
      console.log(err);
      this.isSaving = false;
    })
  }

  public showHistory(position: ContractPositionViewModel) {
    this.contractPositionConfigurationService.getContractPositionConfigurationHistory(position.contractPositionConfigurationId).subscribe(history => {
      const config: PropertyDisplayMapping[] = [
        { propertyName: 'isActive', displayName: 'shared.active', type: 'boolean' }
      ]
      const changeHistory = new ConfigHistoryHelper(history, config).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: position?.name };
    });
  }
}
