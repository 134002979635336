import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StatisticFigures } from 'app/core/statistics/statistic-figures';
import { FilterSettings } from 'app/shared/components/filter/filter-settings';
import { RandomSampleViewModel } from '../shared/random-sample';
import { HeaderColumn } from 'app/shared/models/header-column';
import { SpotCheckResponse } from '../shared/spot-check-response';
import { StatisticsComponent } from 'app/core/statistics/statistics.component';
import { BaseFilterData, allId } from 'app/shared/components/filter/filter';
import { Subscription, forkJoin } from 'rxjs';
import { SpotCheckService } from '../shared/spot-check.service';
import { UserProfileService } from 'app/core/user-profile.service';
import { FilterService } from 'app/shared/components/filter/filter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatisticType } from 'app/core/statistics/statistic-type.enum';
import { RandomSampleCreator } from '../shared/random-sample-creator';
import { EpoHelper } from 'app/shared/epo-helper';
import { DeficitGroupConfiguration } from 'app/shared/masterData/deficit/configuration/deficit-group-configuration.resource';
import { SpotCheckFormComponent } from '../spot-check-form/spot-check-form.component';
import { SpotCheckHistoryHelper } from '../history/random-sample-history.helper';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { RequiredRoles } from 'app/shared/helper/required-roles';
import { environment } from 'environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'epo-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonitoringComponent implements OnInit, OnDestroy {
  public requiredRolesForCreation = RequiredRoles.createSpotCheck;
  public isLoading = true;
  public pagingGlobalCount = 0;
  public pageSize: number = 65535;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadData(true);
  }
  get page() {
    return this.currentPage;
  }

  statsHeader: string;
  figures: StatisticFigures;
  public filterSettings = new FilterSettings(true, true, true, true, true, 'Spotcheck');
  public stichproben: RandomSampleViewModel[];
  public contractPositions: ContractPositionConfiguration[];
  public currentHeaderColumns: HeaderColumn[];
  public checkTypes: CheckTypeConfiguration[];
  public deficitGroupConfigurations: DeficitGroupConfiguration[];
  public tradeConfigurations: TradeConfigurationResponse[];
  public showFilterPanel = false;
  public showGewerk = false;
  public showAllStichprobenarten = false;
  private vmCreator: RandomSampleCreator;

  public randomSamplesEdit: SpotCheckResponse[];
  @ViewChild('stats')
  private _statsComponent: StatisticsComponent;
  private _currentFilter: BaseFilterData;
  private filterSubscription: Subscription;
  private _selectedStatsType: StatisticType;

  constructor(
    private spotCheckService: SpotCheckService,
    private _changeDetector: ChangeDetectorRef,
    private userService: UserProfileService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    this.pageSize = environment.pageSize;
  }

  ngOnInit() {
    this.filterSubscription = this.filterService.getFilter().subscribe((filter) => {
      this.filterChanged(filter);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  private filterChanged(filter: BaseFilterData) {
    this._currentFilter = filter as BaseFilterData;
    this.currentPage = 1;
    this._statsComponent.resetSelection();
    this.loadData(true);
  }

  public statsChanged(statsType: StatisticType) {
    this.isLoading = true;
    this._selectedStatsType = statsType;
    this.getData().subscribe((responses) => {
      this.pagingGlobalCount = responses[0].count;
      const randomSamples = responses[0].items;
      const tradeConfigurations = responses[1];
      const types = responses[2];
      const contractPositions = responses[4];

      this.vmCreator = new RandomSampleCreator(randomSamples, tradeConfigurations.items, types, contractPositions.items, this.translate);
      this.stichproben = this.vmCreator.createRandomSamples();

      this._changeDetector.detectChanges();
    }, (err) => {
      this.currentHeaderColumns = [];
      this.stichproben = [];
    }, () => {
      this.isLoading = false;
    });
  }

  public editStichprobeClicked(stichprobenId: string) {
    const modalRef = this.modalService.open(SpotCheckFormComponent, { size: 'lg' });
    modalRef.componentInstance.spotCheckId = stichprobenId;

    modalRef.result.then((result) => {
      if (result === true) {
        this.loadData(false);
      }
      else {
        this.updateDeficit(stichprobenId);
      }
    },
      () => { });
  }

  public versionStichprobeClicked(spotcheck: SpotCheckResponse) {
    this.spotCheckService.getSpotCheckHistory(spotcheck.id).subscribe(history => {
      let changeHistory = new SpotCheckHistoryHelper(history, this.checkTypes, this.tradeConfigurations,
        this.contractPositions, this.deficitGroupConfigurations).generateHistoryEntries();
      const modalRef = this.modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: spotcheck.contractEntityNumber };
    })
  }

  public addStichprobe() {
    const modalRef = this.modalService.open(SpotCheckFormComponent, { size: 'lg' });
    modalRef.componentInstance.filterData = this._currentFilter;

    modalRef.result.then((result) => {
      this.loadData(false);
    },
      () => { });
  }

  private getData() {
    return forkJoin([
      this.spotCheckService.getSpotChecks(this.buildLoadFilter()),
      this.spotCheckService.getTradeConfigurations(),
      this.spotCheckService.getTypes(),
      this.spotCheckService.getDeficitGroups(),
      this.spotCheckService.getContractPositions()
    ]);
  }

  private loadData(forceReload: boolean, manualRunChangeDetection?: boolean) {
    this.isLoading = true;
    if (forceReload) {
      this.currentHeaderColumns = [];
      this.spotCheckService.cleanCache();
    }

    this.getData().subscribe((responses) => {
      this.pagingGlobalCount = responses[0].count;
      const randomSamples = responses[0].items;
      this.tradeConfigurations = responses[1].items;
      this.checkTypes = responses[2];
      this.deficitGroupConfigurations = responses[3];
      this.contractPositions = responses[4].items;

      const filteredColumns =
        this.filterDistinctContentSamplesByNameOfStichprobenart(this.deficitGroupConfigurations, this._currentFilter.selectedCheckType.checkTypeId);
      this.currentHeaderColumns = EpoHelper.sortBy(filteredColumns, 'inhaltsgruppe');

      this.vmCreator = new RandomSampleCreator(randomSamples, this.tradeConfigurations, this.checkTypes, this.contractPositions, this.translate);
      this.stichproben = this.vmCreator.createRandomSamples();

      if (this._currentFilter.selectedUser.id === allId) {
        this.statsHeader = 'spot-checks.all-spot-checks';

      } else {
        this.statsHeader = 'spot-checks.my-spot-checks';
      }

      this.spotCheckService.getStatistics(this.buildLoadFilter()).subscribe(res => {
        const fig = new StatisticFigures(
          false,
          res.noClaimCount,
          res.claimCount,
          res.reclamationCount,
          res.reclamationCancelledCount,
          res.claimCancelledCount,
          res.conflictCount,
          res.inProgressCount,
          0,
          res.noReactionCount
        );
        this.figures = fig;
      });
      this._statsComponent.resetSelection();

      if (manualRunChangeDetection) {
        this._changeDetector.detectChanges();
      }
      this.isLoading = false;
    }, (err) => {
      console.error('Erorr in getting data: ');
      console.error(err);
      this.currentHeaderColumns = [];
      this.stichproben = [];
    });
  }

  private filterDistinctContentSamplesByNameOfStichprobenart(
    deficitGroups: DeficitGroupConfiguration[], stichprobenArtId: string): HeaderColumn[] {
    const columns: HeaderColumn[] = [];

    deficitGroups.forEach(deficitGroup => {
      deficitGroup.deficitConfigurations.forEach(deficit => {
        // Zuerst die richtige Stichprobenart finden.
        if (deficit.deficit.mappings.some(map => map.checkTypeId === stichprobenArtId)) {
          // Finde Duplikat anhand von Titel.
          const duplicate = columns.find((uniqueColumn) => {
            return uniqueColumn.title === deficit.deficit.title;
          });
          // Prüfe auf Duplikat.
          if (!duplicate) {
            columns.push(new HeaderColumn(deficit.deficit.title, deficit.deficit.description, deficit.id, deficitGroup.name));
          }
        }
      });
    });

    return columns;
  }

  private buildLoadFilter() {
    return {
      selectedDateRange: this._currentFilter.selectedDateRange,
      contractPositionId: this._currentFilter.selectedContractPosition ? this._currentFilter.selectedContractPosition.id : null,
      typeId: this._currentFilter.selectedCheckType ? this._currentFilter.selectedCheckType.id : null,
      tradeId: this._currentFilter.selectedTrade ? this._currentFilter.selectedTrade.id : null,
      userId: this._currentFilter.selectedUser ? this._currentFilter.selectedUser.id : null,
      skipNr: this.pageSize * (this.currentPage - 1),
      takeNr: this.pageSize,
      statsType: this._selectedStatsType
    };
  }

  private updateDeficit(deficitId: string) {
    const deficit = this.stichproben.find(s => s.id == deficitId);
    if (deficit) {
      this.spotCheckService.getSpotCheckById(deficitId).subscribe(res => {
        this.vmCreator.updateDeficitViewModel(deficit, res);
      }, () => { })
    }
  }
}
