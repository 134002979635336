import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Params } from '@angular/router';

export class ArchibusParameterMapper {
    public static setParametersToDialog(modalRef: NgbModalRef, queryParams: Params) {
        modalRef.componentInstance.ticketNumber = queryParams['wr_id'];
        modalRef.componentInstance.ticketDescription = queryParams['description'];
        modalRef.componentInstance.plant = queryParams['eq_id'];
        modalRef.componentInstance.attachementLink = queryParams['eq_std'];
        modalRef.componentInstance.roomNumber = queryParams['rm_id'];
        modalRef.componentInstance.tenderPosition = queryParams['tender_position'];
        modalRef.componentInstance.deviceUsageKind = queryParams['use1'];
        this.setCheckTypeName(queryParams, modalRef);
        this.setTradeName(queryParams, modalRef);
        this.setContractPositionName(queryParams, modalRef);
    }

    private static setContractPositionName(queryParams: Params, modalRef: NgbModalRef) {
        const subtype = queryParams['sub_type'];

        switch (subtype) {
            case 'P':
            case 'SKP':
                modalRef.componentInstance.contractPositionName = 'Sachverständigenprüfungen';
                break;
            case 'I':
                modalRef.componentInstance.contractPositionName = 'Betriebsführung';
                break;
            case 'W':
                modalRef.componentInstance.contractPositionName = 'Wartung';
                break;
            default: modalRef.componentInstance.contractPositionName = 'Instandsetzung';
        }
    }

    private static setTradeName(queryParams: Params, modalRef: NgbModalRef) {
        let probType = queryParams['prob_type'];
        const site = queryParams['bl_id'];
        if (probType && probType.indexOf('PREVENTIVE') >= 0) {
            probType = queryParams['eq_type'];
        }
        if (!probType || !site) {
            return;
        }
        if (probType.indexOf('MECH') >= 0) {
            if (site.indexOf('PH') >= 0 || site.indexOf('P7') || site.indexOf('P8')) {
                modalRef.componentInstance.tradeName = 'Mechanik PH';
            }
            if (site.indexOf('MI') >= 0) {
                modalRef.componentInstance.tradeName = 'Mechanik Isar';
            }
        }
        if (probType.indexOf('ELT') >= 0) {
            if (site.indexOf('PH') >= 0 || site.indexOf('P7') || site.indexOf('P8')) {
                modalRef.componentInstance.tradeName = 'ELT PH';
            }
            if (site.indexOf('MI') >= 0) {
                modalRef.componentInstance.tradeName = 'ELT Isar';
            }
        }
        if (probType.indexOf('HKLS') >= 0) {
            if (site.indexOf('PH') >= 0 || site.indexOf('P7') || site.indexOf('P8')) {
                modalRef.componentInstance.tradeName = 'HKLS PH';
            }
            if (site.indexOf('MI') >= 0) {
                modalRef.componentInstance.tradeName = 'HKLS Isar';
            }
        }
    }

    private static setCheckTypeName(queryParams: Params, modalRef: NgbModalRef) {
        const status = queryParams['status'];
        if (status) {
            switch (status) {
                case 'R':
                case 'Rev':
                case 'A':
                case 'AA':
                    modalRef.componentInstance.checkTypeName = 'ArbeitsVORBEREITUNG';
                    break;
                case 'I':
                case 'HP':
                case 'HA':
                case 'HL':
                    modalRef.componentInstance.checkTypeName = 'LeistungsERBRINGUNG';
                    break;
                case 'Com':
                case 'Clo':
                    modalRef.componentInstance.checkTypeName = 'LeistungsERGEBNIS';
                    break;
            }
        }
    }
}
