<div class="header">
    <span *ngIf="title" class="title">{{ 'shared.history-for' | translate:title }}</span>
    <span *ngIf="!title" class="title">{{ 'shared.history-title' | translate }}</span>
    <a (click)="modal.close()"><i class="fa fa-times fa-2x p-2"></i></a>
</div>
<ul class="timeline">
    <li class="timeline-item" *ngFor="let entry of history">
        <div>
            <div class="timeline-modifier">
                <div>{{entry.updatedBy}}</div>
                <div>{{entry.updatedDate | date:'dd.MM.yyyy HH:mm'}}</div>
            </div>
        </div>
        <div class="timeline-body">
            <changed-property *ngFor="let property of entry.changedProperties" [changedProperty]="property">
            </changed-property>
        </div>
    </li>
</ul>
