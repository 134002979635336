
import { DisplayStatus } from 'app/shared/components/display-status/display-status';
import { StatusPhase } from 'app/shared/models/status-phase';
import { Injectable } from '@angular/core';

@Injectable()
export class AcceptanceStatusCalculator {
    private inProgress = 'status.in-progress';
    getDisplayStatusForPhase1(statusPhase1: StatusPhase, statusPhase2: StatusPhase, statusPhase3: StatusPhase): DisplayStatus {
        if(statusPhase2 === StatusPhase.Conflict){
            if(statusPhase3 === StatusPhase.None){
                return DisplayStatus.createBadRedoIcon('status.prompted-for-improvement');
            }if(statusPhase3 === StatusPhase.InProgress){
                return DisplayStatus.createWaitingIcon('status.improvement-started');
            }
        }
        if(statusPhase3 === StatusPhase.Done){
            return DisplayStatus.createGoodRedoIcon('status.improvement-finished');
        }
        if (statusPhase1 === StatusPhase.InProgress) {
            return DisplayStatus.createWaitingIcon(this.inProgress);
        } else if (statusPhase1 === StatusPhase.Done) {
            return DisplayStatus.createAllrightIcon('status.ready-for-acceptance');
        }
        return DisplayStatus.createWaitingIcon(this.inProgress);
    }

    getDisplayStatusForPhase2(statusPhase1: StatusPhase, statusPhase2: StatusPhase): DisplayStatus {
        if ((statusPhase1 !== StatusPhase.None && statusPhase1 !== StatusPhase.InProgress) &&
            (statusPhase2 === StatusPhase.None || statusPhase2 === StatusPhase.InProgress)) {
            return DisplayStatus.createWaitingIcon(this.inProgress);
        } else if (statusPhase2 === StatusPhase.Accepted) {
            return DisplayStatus.createAllrightIcon('status.acceptance-granted');
        } else if (statusPhase2 === StatusPhase.Conflict) {
            return DisplayStatus.createMediumConflictIcon('status.prompted-for-improvement');
        } else if (statusPhase2 === StatusPhase.Claim) {
            return DisplayStatus.createBadNotOkIcon('status.acceptance-denied');
        }
    }
}
