import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Components.
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';

// Services.
import { UserProfileService } from './user-profile.service';

// Interceptor.
import { ErrorInterceptor } from '../shared/error.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SharedModule } from 'app/shared/shared.module';
import { ToasterService } from 'app/shared/components/toaster/toaster.service';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    LayoutComponent,
    NavigationComponent,
    HeaderComponent,
    UnauthorizedComponent,
    ClickOutsideDirective
  ],
  providers: [
   {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [ToasterService]
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
