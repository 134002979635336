import { TargetResponse } from 'app/random-sample/shared/target-response';
import { Karte } from 'app/shared/components/card/card';
import { ExtrapolationHelper } from './extrapolation-helper';

import { EInterval } from './models/interval';
import { BaseSampleResponse } from './base-sample-response';
import { CheckTypeConfiguration } from './masterData/check-type/configuration/check-type-configuration.resource';

export abstract class BaseCardCreator {
    constructor(protected type: CheckTypeConfiguration[],
        protected targets: TargetResponse[],
        protected samples: BaseSampleResponse[],
        protected selectedIntervalType: EInterval) { }

    public createCards(): Karte[] {
        const karten = new Array<Karte>();

        let currentColorIndex = 0;
        this.type.forEach((stichprobenart) => {
            const card = this.createCard(this.targets, stichprobenart, this.samples, currentColorIndex);
            karten.push(card);

            if (currentColorIndex < this._colors.length) {
                currentColorIndex++;
            }
        });

        return karten;
    }

    protected createCard(targets: TargetResponse[], type: CheckTypeConfiguration, samples: BaseSampleResponse[], currentColorIndex: number) {
        const filteredTargets = this.findTargetsByTypeOrReturnEmptyTargets(targets, type);
        const currentStichprobenCount = this.getCountOfSamplesByType(samples, type.id);
        let sollWert = 0;
        filteredTargets.forEach(target => {
            sollWert += ExtrapolationHelper.extrapolateValueByIntervalType(target.amount, target.interval, this.selectedIntervalType);
        });

        const card: Karte = {
            id: type.id,
            name: type.checkType.name,
            backgroundColor: this._colors[currentColorIndex],
            interval: this.selectedIntervalType,
            sollWert: sollWert,
            istWert: currentStichprobenCount,
        };
        return card;
    }


    abstract getCountOfSamplesByType(samples: BaseSampleResponse[], typeId: string): number;

    protected findTargetsByTypeOrReturnEmptyTargets(targets: TargetResponse[], type: CheckTypeConfiguration): TargetResponse[] {
        const foundTargets = targets.filter((target) => {
            if (target.checkTypeConfigurationId === type.id || ((target) as any).checkTypeConfigurationId === type.id) {
                return true;
            }
        });

        if (foundTargets && foundTargets.length > 0) {
            return foundTargets;
        }
        // Erstelle leeres Target für diese Stichprobenart, damit dennoch eine (leere) Karte erstellt werden kann.
        return [TargetResponse.createEmptyTarget()];
    }

    private _colors = [
        '#17a2b8',
        '#795548',
        '#ce93d8',
        '#ec6f6f',
        '#03a9f4',
        '#ffc107',
        // Fallback.
        '#677593'
    ];
}
