import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CheckType } from './check-type.resource';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { CheckTypeLoadFilter } from 'app/admin/allgemein/check-types/check-type-load-filter';
import { LoadFilterHelper } from 'app/shared/helper/load-filter-helper';

@Injectable({
    providedIn: 'root'
})
export class CheckTypeService {

    private url = `${environment.apiUrl}/api/checktypes`;

    private _typeMap: Map<string, CheckType> = new Map<string, CheckType>();
    private _types: CheckType[];
    private newValues = new ReplaySubject(1);

    constructor(private _http: HttpClient) {
        this.buildCache()
    }

    private buildCache() {
        this._http.get<PagedResultResponse<CheckType>>(this.url)
            .pipe(
                take(1)
            ).subscribe((checkTypes) => {
                checkTypes.items.map((checkType) => this._typeMap.set(checkType.id, checkType));
                this._types = checkTypes.items;
                this.newValues.next(true);
            });
    }

    public get checkTypesObservable(): Observable<CheckType[]> {
        return this.newValues.pipe(
            map(() => this._types)
        )
    }

    public getCheckTypes(skipNr?: number, takeNr?: number, filter?: CheckTypeLoadFilter): Observable<PagedResultResponse<CheckType>> {
        var url = `${this.url}?skip=${skipNr > 0 ? skipNr : 0}`;
        if (takeNr) {
            url = `${url}&take=${takeNr}`;
        }
        if (filter) {
            if (filter.isSpotCheck !== undefined) {
                url = `${url}&isSpotCheck=${filter.isSpotCheck}`;
            }
            if (filter.isControl !== undefined) {
                url = `${url}&isControl=${filter.isControl}`;
            }
            if (filter['isSelected'] !== undefined) {
                url = `${url}&isselected=${filter['isSelected']}`;
            }
            url = LoadFilterHelper.addBaseFilterValues(url, filter);
        }
        return this._http.get<PagedResultResponse<CheckType>>(url);
    }

    public getCheckTypeHistory(id: string): Observable<CheckType[]> {
        return this._http.get<CheckType[]>(`${this.url}/${id}/history`);
    }

    public getCheckType(id: string): Observable<CheckType> {
        return this.newValues.pipe(
            map(() => this._typeMap.get(id))
        )
    }

    public createCheckType(checkType: CheckType) {
        return this._http.post<CheckType>(this.url, checkType);
    }

    public updateCheckType(checkType: CheckType) {
        return this._http.put<CheckType>(`${this.url}/${checkType.id}`, checkType);
    }

    public deleteCheckType(checkTypeId: string) {
        return this._http.delete(`${this.url}/${checkTypeId}`);
    }

    
}