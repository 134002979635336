<div class="container">
  <!-- <div class="img-container">
    <img src="../../../assets/giphy.gif">
  </div>
  <div class="message">
    <h1>Can't touch this!</h1>
  </div> -->
  <div class="message">
    <h1>Sie sind nicht berechtigt, diese Seite zu sehen</h1>
  </div>
</div>