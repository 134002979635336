import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'toBoolSymbol'
})
export class BoolSymbolPipe implements PipeTransform {

  transform(value: any, args?: any, transformProperty?:'check' | 'default'): any {
  if (transformProperty === undefined || transformProperty === null) {
    transformProperty = 'default';
  }
  if(transformProperty === 'check'){
    if (value == true) {
      return `fa ${args ? 'fa-' + args + 'x' : ''} fa-check red`;
    }
    if (value == false) {
      return `fa ${args ? 'fa-' + args + 'x' : ''} fa-check green`;
    }
  } else {
    if (value == false) {
      return `fa ${args ? 'fa-' + args + 'x' : ''} fa-times red`;
    }
    if (value == true) {
      return `fa ${args ? 'fa-' + args + 'x' : ''} fa-check green`;
    }
    return `fa ${args ? 'fa-' + args + 'x' : ''} fa-question`;
  }
}
}
