import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserViewModel } from './user-view-model';
import { ContractUserService } from 'app/shared/masterData/user/contract-user.service';
import { UserService } from 'app/admin/allgemein/users/user.service';
import { ContractUserResource } from 'app/core/contract-user.resource';
import { ContractUserRole } from 'app/shared/masterData/user/contract-user-role';
import { environment } from 'environments/environment';
import { ContractUserLoadFilter } from './contract-user-load-filter';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take } from 'rxjs/operators';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';

@Component({
  selector: 'epo-contract-user-configuration',
  templateUrl: './contract-user-configuration.component.html',
  styleUrls: ['./contract-user-configuration.component.scss']
})
export class ContractUserConfigurationComponent implements OnInit, OnDestroy {

  public pagingGlobalCount = 0;
  public pageSize: number;
  public currentPage = 1;
  set page(val) {
    if (val == this.currentPage) return;
    this.currentPage = val;
    if (this.currentPage)
      this.loadUsers();
  }
  get page() {
    return this.currentPage;
  }

  public isLoading = false;
  public isSaving = false;
  public users: UserViewModel[] = [];
  public contractUsers: ContractUserResource[] = [];
  public roleSelection: ContractUserRole[] = [
    undefined,
    ContractUserRole.Admin,
    ContractUserRole.ContractorManager,
    ContractUserRole.ContractorEmployee,
    ContractUserRole.OrdererManager,
    ContractUserRole.OrdererEmployee
  ];
  public loadFilter: ContractUserLoadFilter = {};

  constructor(
    private _userService: UserService,
    private _contractUserService: ContractUserService,
    private contractService: ContractService,
    public golbalAutoCompleteService: GolbalAutoCompleteService
  ) {
  }

  ngOnInit() {
    this.pageSize = environment.pageSize;
    this.getData();
  }

  onFilterChange() {
    this.currentPage = 1;
    this.loadUsers();
  }

  filterRoleSelected(role) {
    this.loadFilter.contractRole = role;
    this.onFilterChange();
  }

  ngOnDestroy() {
  }

  public getData() {
    const contractUsers$ = this._contractUserService.getContractUsersForCurrentContract();

    contractUsers$.subscribe((contractUsers) => {
      this.contractUsers = contractUsers.items;
      this.loadUsers();
    }, err => {
      console.log(err);
    }, () => { this.isLoading = false; });
  }

  private loadUsers() {
    this.contractService.selectedContract.pipe(take(1)).subscribe((contract) => {
      this.loadFilter.contractId = contract.id;
      this.load();
    });

  }

  private load() {
    this.isLoading = true;
    const users$ = this._userService.getUsers(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
    users$.subscribe(users => {
      this.pagingGlobalCount = users.count;
      this.users = users.items.map(u => {
        const contractUser = this.contractUsers.find(r => r.userId === u.id);
        return {
          id: u.id,
          firstname: u.firstname,
          lastname: u.lastname,
          loginEmail: u.loginEmail,
          contractUserId: contractUser !== undefined ? contractUser.id : undefined,
          contractRole: contractUser !== undefined ? contractUser.contractRole : undefined
        };
      });
      this.isLoading = false;
    });
  }

  setUserRole(user: UserViewModel, role: ContractUserRole) {
    this.isSaving = true;
    if (role === undefined) {
      if (user.contractUserId) {
        this._contractUserService.deleteContractUser(user.contractUserId).subscribe(() => {
          this.contractUsers = this.contractUsers.filter(t => t.id !== user.contractUserId);
          user.contractRole = undefined;
          user.contractUserId = undefined;
        }, err => {
          console.log(err);
        }, () => this.isSaving = false)
      }
    } else if (user.contractUserId) {
      this._contractUserService.updateContractUser(user.contractUserId, role).subscribe(contractUser => {
        const userToUpdate = this.contractUsers.find(u => u.id === contractUser.id);
        userToUpdate.contractRole = contractUser.contractRole;
        user.contractRole = contractUser.contractRole;
      }, err => {
        console.log(err);
      }, () => this.isSaving = false)
    } else {
      this._contractUserService.createContractUser({ userId: user.id, contractRole: role }).subscribe(contractUser => {
        this.contractUsers.push(contractUser);
        user.contractRole = contractUser.contractRole;
        user.contractUserId = contractUser.id;
      }, err => {
        console.log(err);
      }, () => this.isSaving = false)
    }
  }
}
