export class TargetResponse {
    contractPositionConfigurationId: string;
    checkTypeConfigurationId: string;
    tradeConfigurationId: string;
    interval: number;
    amount: number;

    public static createEmptyTarget() {
        const t = new TargetResponse();
        t.amount = 0;
        return t;
    }
}
