import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Language } from 'app/shared/masterData/language/language.resource';

@Component({
  templateUrl: './modal-language-edit.component.html',
  styleUrls: ['./modal-language-edit.component.scss']
})
export class ModalLanguageEditComponent {
  public inNewMode: boolean;
  public language: Language;

  constructor(public activeModal: NgbActiveModal) { }
}
