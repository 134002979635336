import { Component } from '@angular/core';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TradeService } from 'app/shared/masterData/trade/trade.service';
import { Trade } from 'app/shared/masterData/trade/trade.resource';
import { ModalTradeEditComponent } from './modal-trade-edit/modal-trade-edit.component';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { TradeHistoryHelper } from './history/trade-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';

@Component({
  selector: 'epo-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent extends BaseFilterPagingComponent<Trade> {

  constructor(private _tradeService: TradeService,
    private _modalService: NgbModal,
    public globalAutocompleteService: GolbalAutoCompleteService,
    private _languageService: LanguageService) {
    super();
  }

  loadData() {
    this.isLoading = true;
    const langName = localStorage.getItem('lang') || 'en';
    this._languageService.languagesObservable.pipe(take(1)).subscribe(l => {
      const lang = l.find(l => l.shortName === langName.toUpperCase());
      if (lang) {
        this.languageId = lang.id;
        this.loadFilter.languageId = lang.id;
      } else {
        this.languageId = null;
        this.loadFilter.languageId = null;
      }
      const trades$ = this._tradeService.getTrades(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
      const languages$ = this._languageService.languagesObservable.pipe(take(1));
      forkJoin([trades$, languages$]).subscribe(([positions, languages]) => {
        this.items = positions.items;
        this.pagingGlobalCount = positions.count;
        this.languages = languages;
        this.isLoading = false;
      }, err => {
        console.log(err);
      });
    });
  }

  public openCreateTradeModal() {
    const trade = new Trade();
    trade.isActive = true;
    const modalRef = this._modalService.open(ModalTradeEditComponent, { centered: true });
    modalRef.componentInstance.trade = trade;
    modalRef.componentInstance.inNewMode = true;

    modalRef.result.then((result) => {
      this._tradeService.createTrade(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openEditTradeModal(trade: Trade) {
    const modalRef = this._modalService.open(ModalTradeEditComponent, { centered: true });
    modalRef.componentInstance.trade = Object.assign({}, trade);
    modalRef.componentInstance.inNewMode = false;

    modalRef.result.then((result) => {
      this._tradeService.updateTrade(result).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    }, () => { });
  }

  public openDeleteModal(trade: Trade) {
    const modalRef = this._modalService.open(ModalDeleteComponent, { centered: true });
    modalRef.componentInstance.objectRef = trade;

    modalRef.result.then((trade) => {
      this._tradeService.deleteTrade(trade.id).subscribe(res => {
        this.loadData();
      }, err => {
        console.log(err);
      })
    });
  }

  public showHistory(trade: Trade) {
    this._tradeService.getTradeHistory(trade.id).subscribe(history => {
      const changeHistory = new TradeHistoryHelper(history, this.languages).generateHistoryEntries();
      const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
      modalRef.componentInstance.history = changeHistory;
      modalRef.componentInstance.title = { number: trade.name };
    });
  }
}
