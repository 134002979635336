import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCheckTypeEditComponent } from 'app/admin/allgemein/check-types/modal-edit/modal-check-type-edit.component';
import { ModalDeleteComponent } from 'app/shared/components/modal-delete/modal-delete.component';
import { CheckTypeService } from 'app/shared/masterData/check-type/check-type.service';
import { CheckType } from 'app/shared/masterData/check-type/check-type.resource';
import { GolbalAutoCompleteService } from 'app/shared/services/global-autocomplete.service';
import { CheckTypeLoadFilter } from './check-type-load-filter';
import { CheckTypeHistoryHelper } from './history/check-type-history.helper';
import { EntityHistoryComponent } from 'app/shared/components/entity-history/entity-history.component';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BaseFilterPagingComponent } from 'app/shared/components/base-filter-paging.component';

@Component({
    selector: 'epo-check-types',
    templateUrl: './check-types.component.html',
    styleUrls: ['./check-types.component.scss']
})

export class CheckTypesComponent extends BaseFilterPagingComponent<CheckType> {
    public loadFilter: CheckTypeLoadFilter = new CheckTypeLoadFilter();

    constructor(private _modalService: NgbModal,
        public _checkTypeService: CheckTypeService,
        public globalAutocompleteService: GolbalAutoCompleteService,
        private _languageService: LanguageService) {
        super();
    }

    isControlSelected(value) {
        this.loadFilter.isControl = value;
        this.onFilterChange();
    }

    isSpotCheckSelected(value) {
        this.loadFilter.isSpotCheck = value;
        this.onFilterChange();
    }

    loadData() {
        this.isLoading = true;
        const langName = localStorage.getItem('lang') || 'en';
        this._languageService.languagesObservable.pipe(take(1)).subscribe(l => {
            const lang = l.find(l => l.shortName === langName.toUpperCase());
            if (lang) {
                this.languageId = lang.id;
                this.loadFilter.languageId = lang.id;
            } else {
                this.languageId = null;
                this.loadFilter.languageId = null;
            }
            const checkTypes$ = this._checkTypeService.getCheckTypes(this.pageSize * (this.currentPage - 1), this.pageSize, this.loadFilter);
            const languages$ = this._languageService.languagesObservable.pipe(take(1));
            forkJoin([checkTypes$, languages$]).subscribe(([checkTypes, languages]) => {
                this.items = checkTypes.items;
                this.pagingGlobalCount = checkTypes.count;
                this.languages = languages;
                this.isLoading = false;
            }, err => {
                console.log(err);
            });
        });
    }

    public openCreateCheckTypeModal() {
        const checkType = new CheckType();
        checkType.isActive = true;
        const modalRef = this._modalService.open(ModalCheckTypeEditComponent, { centered: true });
        modalRef.componentInstance.checkType = checkType;
        modalRef.componentInstance.inNewMode = true;

        modalRef.result.then((result) => {
            this._checkTypeService.createCheckType(result).subscribe(res => {
                this.loadData();
            }, err => {
                console.log(err);
            })
        }, () => { });
    }

    public openEditCheckTypeModal(checkType: CheckType) {
        const modalRef = this._modalService.open(ModalCheckTypeEditComponent, { centered: true });
        modalRef.componentInstance.checkType = Object.assign({}, checkType);

        modalRef.result.then((result) => {
            this._checkTypeService.updateCheckType(result).subscribe(res => {
                this.loadData();
            }, err => {
                console.log(err);
            })
        }, () => { });
    }

    public openDeleteModal(checkType: CheckType) {
        const modalRef = this._modalService.open(ModalDeleteComponent, { centered: true });
        modalRef.componentInstance.objectRef = checkType;
        modalRef.result.then((checkType) => {
            this._checkTypeService.deleteCheckType(checkType.id).subscribe(res => {
                this.loadData();
            }, err => {
                console.log(err);
            })
        });
    }

    public showHistory(checkType: CheckType) {
        this._checkTypeService.getCheckTypeHistory(checkType.id).subscribe(history => {
            const changeHistory = new CheckTypeHistoryHelper(history, this.languages).generateHistoryEntries();
            const modalRef = this._modalService.open(EntityHistoryComponent, { size: 'lg', windowClass: 'history-modal' });
            modalRef.componentInstance.history = changeHistory;
            modalRef.componentInstance.title = { number: checkType.name };
        });
    }
}






