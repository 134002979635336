import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserResponse } from './user-response';
import { CreateUserResource } from 'app/shared/masterData/user/create-user-resource';
import { GlobalUserRole } from 'app/shared/masterData/user/global-user-role';
import { Observable } from 'rxjs';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { UserLoadFilter } from './user-load-filter';
import { ContractUserLoadFilter } from 'app/admin/contract/contract-user-configuration/contract-user-load-filter';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient) { }

  public getUsers(skipNr?: number, takeNr?: number, filter?: UserLoadFilter | ContractUserLoadFilter): Observable<PagedResultResponse<UserResponse>> {
    let url = `${this.createEpoUrl('api/users')}?skip=${skipNr > 0 ? skipNr : 0}`;
    if (takeNr) {
      url = `${url}&take=${takeNr}`;
    }
    if (filter) {
      if (filter.firstname) {
        url = `${url}&firstname=${filter.firstname}`;
      }
      if (filter.lastname) {
        url = `${url}&lastname=${filter.lastname}`;
      }
      if (filter.email) {
        url = `${url}&email=${filter.email}`;
      }
      if (filter['role'] !== undefined) {
        url = `${url}&role=${filter['role']}`;
      }
      if (filter['contractRole'] !== undefined) {
        url = `${url}&contractrole=${filter['contractRole']}`;
      }
      if (filter['contractId'] !== undefined) {
        url = `${url}&contractid=${filter['contractId']}`;
      }
      if (filter.isActive !== undefined) {
        url = `${url}&isactive=${filter.isActive}`;
      }
    }
    return this._httpClient.get<PagedResultResponse<UserResponse>>(url);
  }

  public updateUserOnLogin(): Observable<UserResponse> {
    const url = this.createEpoUrl(`api/users/login`);
    return this._httpClient.post<UserResponse>(url, {});
  }

  private createEpoUrl(relativePath: string) {
    return `${environment.apiUrl}/${relativePath}`;
  }
}
