import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeficitGroup } from 'app/shared/masterData/deficit/deficit-group.resource';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';
import { Language } from 'app/shared/masterData/language/language.resource';

@Component({
  selector: 'epo-modal-deficit-groups-edit',
  templateUrl: './modal-deficit-groups-edit.component.html',
  styleUrls: ['./modal-deficit-groups-edit.component.scss']
})
export class ModalDeficitGroupsEditComponent implements OnInit {
  public inNewMode: boolean;
  public deficitGroup: DeficitGroup;
  languages: Language[];
  translations: TranslationViewModel[];

  constructor(public activeModal: NgbActiveModal, private languageService: LanguageService) { }

  ngOnInit() {
    const entityTranslations = this.deficitGroup !== undefined ? this.deficitGroup.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = entityTranslations.find(t => t.language.id === lang.id);
        if (translation) {
          return new TranslationViewModel(translation, lang);
        } else {
          return new TranslationViewModel({
            language: lang,
            languageId: lang.id,
            name: ''
          }, lang);
        }
      });
    });
  }

  canSave() {
    return this.deficitGroup && this.deficitGroup.nameDefault && this.translationsValid();
  }

  private translationsValid() {
    return this.translations && !this.translations.find(t => !t.isValid);
  }

  save() {
    this.deficitGroup.translations = this.translations.filter(t => t.name).map(t => t.toResource());
    this.activeModal.close(this.deficitGroup);
  }
}
