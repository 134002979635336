import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContractService } from '../../contract/contract.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DeficitGroupConfiguration } from './deficit-group-configuration.resource';
import { mergeMap, take } from 'rxjs/operators';
import { PagedResultResponse } from 'app/random-sample/shared/paged-result.response';
import { DeficitConfiguration } from './deficit-configuration.resource';

@Injectable({
    providedIn: 'root'
})

export class DeficitConfigurationService {
    constructor(private _http: HttpClient, private contractService: ContractService) { }

    getDeficitConfigurationsForCurrentContract(active: boolean = true): Observable<PagedResultResponse<DeficitGroupConfiguration>> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations?isactive=${active}`;
                return this._http.get<PagedResultResponse<DeficitGroupConfiguration>>(url)
            }
            )
        )
    }
    getById(id:string, active: boolean = true): Observable<DeficitGroupConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations/${id}?isactive=${active}`;
                return this._http.get<DeficitGroupConfiguration>(url)
            }
            )
        )
    }

    createDeficitConfiguration(deficitId: string, description: string): Observable<string> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations`;
                return this._http.post<string>(url, { deficitId: deficitId, description: description, isActive: true });
            })
        )
    }

    updateDeficitConfiguration(configId: string, deficitId: string, description: string): Observable<DeficitConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations/${configId}`;
                return this._http.put<DeficitConfiguration>(url, { deficitId: deficitId, description: description, isActive: true });
            })
        )
    }

    deleteDeficitConfiguration(deficitId: string): Observable<any> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations/${deficitId}`;
                return this._http.delete(url);
            })
        )
    }

    setDeficitonfigurationActive(tradeConfigurationId: string, isActive: boolean): Observable<DeficitConfiguration> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                const url = `${environment.apiUrl}/api/${contract.id}/deficitconfigurations/${tradeConfigurationId}/active`;
                return this._http.patch<DeficitConfiguration>(url, { isActive });
            }));
    }

    public getDeficitConfigurationHistory(id: string): Observable<DeficitConfiguration[]> {
        return this.contractService.selectedContract.pipe(
            take(1),
            mergeMap((contract) => {
                return this._http.get<DeficitConfiguration[]>(`${environment.apiUrl}/api/${contract.id}/deficitconfigurations/${id}/history`);
            }));
    }
}