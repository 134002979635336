import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Trade } from 'app/shared/masterData/trade/trade.resource';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { Language } from 'app/shared/masterData/language/language.resource';
import { TranslationViewModel } from 'app/admin/shared/translation-view-model';

@Component({
  templateUrl: './modal-trade-edit.component.html',
  styleUrls: ['./modal-trade-edit.component.scss']
})
export class ModalTradeEditComponent {
  public inNewMode: boolean;
  public trade: Trade;
  languages: Language[] = [];
  translations: TranslationViewModel[] = [];

  constructor(public activeModal: NgbActiveModal, private languageService: LanguageService) { }

  ngOnInit() {
    const entityTranslations = this.trade !== undefined ? this.trade.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = entityTranslations.find(t => t.language.id === lang.id);
        if (translation) {
          return new TranslationViewModel(translation, lang);
        } else {
          return new TranslationViewModel({
            language: lang,
            languageId: lang.id,
            name: ''
          }, lang);
        }
      });
    });
  }

  canSave() {
    return this.trade && this.trade.nameDefault && this.translationsValid();
  }

  private translationsValid() {
    return this.translations && !this.translations.find(t => !t.isValid);
  }

  save() {
    this.trade.translations = this.translations.filter(t => t.name).map(t => t.toResource());
    this.activeModal.close(this.trade);
  }
}
