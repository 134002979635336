import { TargetResponse } from 'app/random-sample/shared/target-response';
import { ControlResponse } from './shared/control-response';
import { BaseCardCreator } from 'app/shared/base-card-creator';
import { EInterval } from 'app/shared/models/interval';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';

export class ControlCardCreator extends BaseCardCreator {
    constructor(type: CheckTypeConfiguration[], targets: TargetResponse[], samples: ControlResponse[], selectedIntervalType: EInterval) {
        super(type, targets, samples, selectedIntervalType);
    }

    getCountOfSamplesByType(samples: ControlResponse[], typeId: string): number {
        const stichprobenFilterByStichprobenart = samples.filter((sample) => {
            if (sample.checkTypeConfigurationId === typeId || ((sample) as any).controlSampleTypeId === typeId) {
                return true;
            } return false;
        });

        return stichprobenFilterByStichprobenart.length;
    }
}
