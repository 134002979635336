<div class="epoNav">
  <div class="children-container">
    <ul *ngIf="contractService.selectedContract | async as contract else globalAdminMenu">
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/contract/{{contract.urlPath}}">
          <span class="logo-container">
            <i class="fa fa-home fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">Dashboard</div>
          </div>
        </a>
      </li>
      <li routerLinkActive="active">
        <a>
          <span class="logo-container" routerLink="/contract/{{contract.urlPath}}/spot-check/overview">
            <i class="fa fa-random fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.spot-checks.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/overview">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/scheduling">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/spot-check-targets">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last">
        <a>
          <span class="logo-container" routerLink="/contract/{{contract.urlPath}}/control/overview">
            <i class="fa fa-bullseye fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.controls.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/control/overview">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.controls.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/control/scheduling">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i> {{ 'nav.controls.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/control/control-targets">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i>{{ 'nav.controls.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last">
        <a>
          <span class="logo-container" routerLink="/contract/{{contract.urlPath}}/acceptance/overview">
            <i class="fa fa-tasks fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.acceptances.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/overview">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.acceptances.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/scheduling">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i>{{ 'nav.acceptances.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/acceptance-targets">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i>{{ 'nav.acceptances.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last">
        <a>
          <span class="logo-container" routerLink="/contract/{{contract.urlPath}}/deficit-proposals">
            <i class="fa fa-user-edit fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">Proposals</div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last" *ngIf="isUserGlobalAdmin || isUserContractAdmin">
        <a>
          <span class="logo-container">
            <i class="fa fa-cogs fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.contract-configuration' | translate }}</div>
            <div class="sub-nav-menu">
              <a *ngFor="let config of contractConfig"
                routerLink="/contract/{{contract.urlPath}}/admin/{{config.linkName}}">
                <div class="sub-nav-item child">
                  <span>{{ config.displayName | translate }}</span>
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>
      <li routerLinkActive="active" class="last" *ngIf="isUserGlobalAdmin">
        <a routerLink="/contracts" (click)="deselectContract()">
          <span class="logo-container">
            <i class="fa fa-arrow-circle-left fa-2x" aria-hidden="true"></i>
          </span>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.to-contracts' | translate }}</div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>

<nav class="navbar navbar-light fixed-top bg-light epo-hidden-md-up" (clickOutside)="closeNavbar()">
  <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="toggleNavbar()"
    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0"
      *ngIf="contractService.selectedContract | async as contract else globalAdminMenuMobile">
      <li routerLinkActive="active">
        <a>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.spot-checks.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/overview" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/scheduling" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/spot-check/spot-check-targets" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i> {{ 'nav.spot-checks.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last">
        <a>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.controls.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/control/overview" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.controls.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/control/scheduling" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i> {{ 'nav.controls.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/control/control-targets" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i>{{ 'nav.controls.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last">
        <a>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.acceptances.title' | translate }}</div>
            <div class="sub-nav-menu">
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/overview" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i> {{ 'nav.acceptances.overview' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/scheduling" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-calendar fa-fw" aria-hidden="true"></i>{{ 'nav.acceptances.planing' | translate }}
                </div>
              </a>
              <a routerLink="/contract/{{contract.urlPath}}/acceptance/acceptance-targets" (click)="toggleNavbar()">
                <div class="sub-nav-item">
                  <i class="fa fa-dot-circle fa-fw" aria-hidden="true"></i>{{ 'nav.acceptances.targets' | translate }}
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last" *ngIf="isUserGlobalAdmin || isUserContractAdmin">
        <a>
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.contract-configuration' | translate }}</div>
            <div class="sub-nav-menu">
              <a *ngFor="let config of contractConfig" (click)="toggleNavbar()"
                routerLink="/contract/{{contract.urlPath}}/admin/{{config.linkName}}">
                <div class="sub-nav-item child">
                  <span>{{ config.displayName | translate }}</span>
                </div>
              </a>
            </div>
          </div>
        </a>
      </li>

      <li routerLinkActive="active" class="last" *ngIf="isUserGlobalAdmin" (click)="toggleNavbar()">
        <a routerLink="/contracts" (click)="deselectContract()">
          <div class="sub-nav-first-level">
            <div class="sub-nav-name">{{ 'nav.to-contracts' | translate }}</div>
          </div>
        </a>
      </li>

    </ul>
  </div>
</nav>

<ng-template #globalAdminMenu>
  <ul *ngIf="isUserGlobalAdmin">
    <li *ngFor="let allgemein of baseDataAllgemein" title="{{allgemein.displayName | translate}}"
      routerLinkActive="active" class="last" routerLink="/admin/{{allgemein.linkName}}">
      <a>
        <span class="logo-container">
          <i class="fa {{allgemein.icon}} fa-2x" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #globalAdminMenuMobile>
  <ul *ngIf="isUserGlobalAdmin">
    <li *ngFor="let allgemein of baseDataAllgemein" title="{{allgemein.displayName | translate}}"
      routerLinkActive="active" class="last" routerLink="/admin/{{allgemein.linkName}}">
      <a (click)="toggleNavbar()">
        <span class="logo-container">
          {{allgemein.displayName | translate}}
        </span>
      </a>
    </li>
  </ul>
</ng-template>