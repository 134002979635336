<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "shared.confirm-deletion" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close click')">
    <i class="fas fa-ban"></i>
    {{ "shared.dialog.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close(objectRef)">
    <i class="fa fa-trash" aria-hidden="true"></i>
    {{ "shared.buttons.delete" | translate }}
  </button>
</div>