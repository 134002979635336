import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeficitService } from 'app/shared/masterData/deficit/deficit.service';
import { CheckTypeService } from 'app/shared/masterData/check-type/check-type.service';
import { ContractPositionService } from 'app/shared/masterData/contract-position/contract-position.service';
import { ContractPosition } from 'app/shared/masterData/contract-position/contract-position.resource';
import { DeficitMappingBase } from 'app/shared/masterData/deficit/deficitMapping';
import { LanguageService } from 'app/shared/masterData/language/language.service';
import { Language } from 'app/shared/masterData/language/language.resource';
import { DeficitTranslationViewModel } from '../view-model/deficit-translation-view-model';
import { DeficitModel } from 'app/shared/masterData/deficit/deficit.model';
import { DeficitGroup } from 'app/shared/masterData/deficit/deficit-group.resource';
import { take } from 'rxjs/operators';
import { CheckType } from 'app/shared/masterData/check-type/check-type.resource';
import { DeficitTag } from 'app/shared/masterData/deficit/deficit-tag.resource';
import { DeficitTagViewModel } from '../view-model/deficit-tag-view-model';
import { DeficitTagsService } from 'app/shared/masterData/deficit-tags/deficit-tags.service';
import { isProtractorLocator } from 'protractor/built/locators';
import { NameLoadFilter } from '../../shared/name-load-filter';

@Component({
  selector: 'epo-modal-deficit-edit',
  templateUrl: './modal-deficit-edit.component.html',
  styleUrls: ['./modal-deficit-edit.component.scss']
})
export class ModalDeficitEditComponent implements OnInit {
  public inNewMode: boolean;
  public inEditMode: boolean;
  public deficit: DeficitModel;
  public contractPositions: ContractPosition[] = [];
  public checkTypes: CheckType[] = [];
  public deficitGroups: DeficitGroup[] = [];
  public languages: Language[];
  public tags: DeficitTagViewModel[];
  public translations: DeficitTranslationViewModel[];
  public newMapping: DeficitMappingBase = this.createEmptyMapping();
  public languageId: string;

  public descriptionConfig = {
    placeholder: '',
    tabsize: 2,
    height: 300,
    toolbar: [
      ['misc', ['undo', 'redo', 'codeview']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['insert', ['picture', 'link', 'hr']]
    ]
  }

  get canAddMapping() {
    return this.newMapping.contractPositionId && this.newMapping.checkTypeId
      && !this.deficit.commonMappings.some(m => m.checkTypeId === this.newMapping.checkTypeId
        && m.contractPositionId === this.newMapping.contractPositionId);
  }

  get selectedTags() {
    return this.tags?.filter(t => t.isSelected) ?? [];
  }

  constructor(public activeModal: NgbActiveModal, public deficitService: DeficitService,
    private checkTypeService: CheckTypeService, private contractPositionService: ContractPositionService,
    private languageService: LanguageService, private deficitTagService: DeficitTagsService) { }

  ngOnInit() {
    this.loadData();
  }

  public getCheckTypeName(checkType: CheckType) {
    if (this.languageId) {
      return checkType.translations?.find(t => t.languageId === this.languageId)?.name ?? checkType.name;
    }
    return checkType.name;
  }

  public getContractPositionName(contractPosition: ContractPosition) {
    if (this.languageId) {
      return contractPosition.translations?.find(t => t.languageId === this.languageId)?.name ?? contractPosition.name;
    }
    return contractPosition.name;
  }

  public getDeficitGroupName(deficitGroup: DeficitGroup) {
    if (this.languageId) {
      return deficitGroup.translations?.find(t => t.languageId === this.languageId)?.name ?? deficitGroup.name
    }
    return deficitGroup.name;
  }

  setDeficitGroupId(id: string) {
    this.deficit.deficitGroupId = id;
  }

  mapContractPosition(contractPosition: ContractPosition) {
    this.newMapping.contractPosition = contractPosition;
    this.newMapping.contractPositionId = contractPosition.id;
  }

  mapCheckType(checkType: CheckType) {
    this.newMapping.checkType = checkType;
    this.newMapping.checkTypeId = checkType.id;
  }

  addMapping() {
    this.deficit.commonMappings = [...this.deficit.commonMappings, this.newMapping];
    this.newMapping = this.createEmptyMapping();
  }

  removeMapping(mapping: DeficitMappingBase) {
    this.deficit.commonMappings = this.deficit.commonMappings.filter(m => !(m.checkTypeId === mapping.checkTypeId
      && m.contractPositionId === mapping.contractPositionId));
  }

  isValid() {
    return this.deficit.deficitGroupId !== undefined &&
      this.deficit.titleDefault !== undefined &&
      this.deficit.commonMappings.length > 0 &&
      (this.deficit.isControlDeficit || this.deficit.isSpotCheckDeficit) &&
      this.translationsValid();
  }

  private translationsValid() {
    return this.translations && !this.translations.find(t => !t.isValid);
  }

  private createEmptyMapping(): DeficitMappingBase {
    const mapping = new DeficitMappingBase();
    return mapping;
  }

  save() {
    this.deficit.translations = this.translations.filter(t => t.title && t.description).map(t => t.toResource());
    this.deficit.tags = this.tags.filter(t => t.isSelected).map(t => t.tag);
    this.activeModal.close(this.deficit);
  }

  private loadData() {
    const deficitTranslations = this.deficit !== undefined ? this.deficit.translations : [];
    this.languageService.languagesObservable.subscribe((langs) => {
      this.languages = langs;
      this.translations = this.languages.map(lang => {
        const translation = deficitTranslations.find(t => t.languageId === lang.id);
        if (translation) {
          return new DeficitTranslationViewModel(lang, translation);
        } else {
          return new DeficitTranslationViewModel(lang);
        }
      });
    });

    const langName = localStorage.getItem('lang') || 'en';
    this.languageService.languagesObservable.pipe(take(1)).subscribe(l => {
      const lang = l.find(l => l.shortName === langName.toUpperCase());
      if (lang) {
        this.languageId = lang.id;
      } else {
        this.languageId = null;
      }
    });
    this.checkTypeService.checkTypesObservable.subscribe(res => {
      this.checkTypes = res.filter(r => r.isActive).sort((a, b) => {
        const nameA = a.translations?.find(t => t.languageId === this.languageId)?.name ?? a.name;
        const nameB = b.translations?.find(t => t.languageId === this.languageId)?.name ?? b.name;
        return nameA > nameB ? 1 : -1;
      });
    });

    this.contractPositionService.contractPositionsObservable.subscribe(res => {
      this.contractPositions = res.filter(r => r.isActive).sort((a, b) => {
        const nameA = a.translations?.find(t => t.languageId === this.languageId)?.name ?? a.name;
        const nameB = b.translations?.find(t => t.languageId === this.languageId)?.name ?? b.name;
        return nameA > nameB ? 1 : -1;
      });
    });

    this.deficitService.deficitGroupsObservable.subscribe(res => {
      this.deficitGroups = res.filter(r => r.isActive).sort((a, b) => {
        const nameA = a.translations?.find(t => t.languageId === this.languageId)?.name ?? a.name;
        const nameB = b.translations?.find(t => t.languageId === this.languageId)?.name ?? b.name;
        return nameA > nameB ? 1 : -1;
      });
    });

    this.deficitTagService.getAllDeficitTags().subscribe(res => {
      this.tags = res.items.map(i => new DeficitTagViewModel(i, this.deficit.tags.find(t => t.id === i.id) != null));
      if (this.inNewMode === true || this.inEditMode === true) {
        return this.tags = this.tags.filter(element => element.tag.isActive === true);
      } else {
        return this.tags;
      }
    })
  }
}