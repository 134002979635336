import { ConfigLoadFilter } from '../shared/config-load-filter';

export class DeficitLoadFilter extends ConfigLoadFilter {
    deficitGroup?: string;
    checkType?: string;
    contractPosition?: string;
    isSpotCheck?: boolean;
    isControl?: boolean;
    description?: string;
    isActive?: boolean = true;
    entityNumber?: number;
    tag?: string;
}