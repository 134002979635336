import { Translation } from '../language/translation';
import { BaseExistingResource } from 'app/shared/models/base-existing.resource';

export class CheckType extends BaseExistingResource {
    id: string;
    name: string;
    nameDefault?: string;
    isSpotCheck: boolean;
    isControl: boolean;
    isActive?: boolean;
    translations?: Translation[] = [];
}