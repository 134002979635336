import { Pipe, PipeTransform } from '@angular/core';
import { ContractUserRole } from '../masterData/user/contract-user-role';

@Pipe({
    name: 'contractRoleToString'
})
export class ContractRoleToStringPipe implements PipeTransform {

    transform(value: ContractUserRole, args?: any): any {
        if (value == ContractUserRole.Admin) {
            return 'shared.roles.admin';
        } else if (value == ContractUserRole.ContractorManager) {
            return 'shared.roles.contractor-manager';
        } else if (value == ContractUserRole.ContractorEmployee) {
            return 'shared.roles.contractor-employee';
        } else if (value == ContractUserRole.OrdererEmployee) {
            return 'shared.roles.orderer-employee';
        } else if (value == ContractUserRole.OrdererManager) {
            return 'shared.roles.orderer-manager';
        } else if (value === undefined) {
            return args;
        }
        else {
            return value;
        }

    }
}
