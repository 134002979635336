import { EInterval } from 'app/shared/models/interval';
import { UserResponse } from 'app/admin/allgemein/users/user-response';

export class AcceptanceTargetViewModel {

    tradeName: string;
    checkTypeName: string;
    contractPositionName: string;
    intervalName: string;

    interval: EInterval;
    amount: number;
    id: string;
    contractPositionConfigurationId: string;
    checkTypeConfigurationId: string;
    tradeConfigurationId: string;
    createdUtc: string;
    modifiedUtc: string;
    createdBy: UserResponse;
    modifiedBy: UserResponse;
}
