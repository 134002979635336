import { BaseSampleCreator } from 'app/shared/base-sample-creator';
import { ControlSampleViewModel } from './control-sample-view-model';
import { ControlResponse } from 'app/control/shared/control-response';
import { Rating } from 'app/shared/models/rating';
import { TradeConfigurationResponse } from 'app/shared/models/trade-configuration-response';
import { CheckTypeConfiguration } from 'app/shared/masterData/check-type/configuration/check-type-configuration.resource';
import { ContractPositionConfiguration } from 'app/shared/masterData/contract-position/configuration/contract-position-configuration.resource';
import { TranslateService } from '@ngx-translate/core';

export class ControlSampleCreator extends BaseSampleCreator {
    constructor(private controlSampleResponses: ControlResponse[],
        tradeConfigs: TradeConfigurationResponse[],
        types: CheckTypeConfiguration[],
        contractPositions: ContractPositionConfiguration[],
        private translate: TranslateService) {
        super(tradeConfigs, types, contractPositions);
    }

    public create(): ControlSampleViewModel[] {
        const controlSamples = [];
        for (const controlSampleResponse in this.controlSampleResponses) {
            if (this.controlSampleResponses.hasOwnProperty(controlSampleResponse)) {
                const response = this.controlSampleResponses[controlSampleResponse];
                const newControlSample = new ControlSampleViewModel();
                newControlSample.id = response.id;
                newControlSample.createdDateTimeUtc = this.tryParseDate(response.createdUtc);
                newControlSample.contractPositionId = response.contractPositionConfigurationId;
                newControlSample.contractPositionName = this.getContractPositionName(response.contractPositionConfigurationId, this.translate.instant('shared.unknown-contract-position'));
                newControlSample.realEstateId = response.tradeConfigurationId;
                newControlSample.realEstateName = this.getRealEstateName(response.tradeConfigurationId, this.translate.instant('shared.unknown-trade'));
                newControlSample.typeId = response.checkTypeConfigurationId;
                newControlSample.typeName = this.getStichprobenName(response.checkTypeConfigurationId, this.translate.instant('shared.unknown-type'));
                newControlSample.ticketNumber = response.ticketNumber;
                newControlSample.appendix = response.appendix;
                newControlSample.rating = response.rating;
                newControlSample.ratingName = Rating[response.rating];
                newControlSample.deficitValues = response.deficitValues;
                newControlSample.status = response.statusPhaseOne;
                newControlSample.deficitFixed = response.deficitFixed;
                newControlSample.contractEntityNumber = response.contractEntityNumber;
                newControlSample.isWithdrawn = response.isWithdrawn;

                controlSamples.push(newControlSample);
            }
        }

        return controlSamples;
    }
}
