import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, forkJoin } from 'rxjs';
import { ContractService } from 'app/shared/masterData/contract/contract.service';
import { take } from 'rxjs/operators';
import { KontrollenFormularComponent } from '../control-form/control-form.component';
import { Contract } from 'app/shared/masterData/contract/contract.resource';
import { ArchibusParameterMapper } from 'app/shared/helper/archibus-parameter-mapper';

@Component({
    selector: 'epo-control-check-edit-entry',
    template: '<div class="loader" style="margin: auto;"></div>'
})

export class ControlEditEntryComponent implements OnInit, OnDestroy {

    private subscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        private router: Router,
        private contractService: ContractService) {
    }

    public ngOnInit() {
        const contract$ = this.contractService.selectedContract.pipe(take(1));
        const queryParams$ = this.activatedRoute.queryParams.pipe(take(1));
        const routeParams$ = this.activatedRoute.paramMap.pipe(take(1));
        forkJoin(contract$, queryParams$, routeParams$).subscribe(([contract, queryParams, routeParams]) => {
            setTimeout(() => { // timeout: workaround for ExpressionChangedAfterItHasBeenCheckedError
                const externalSystem = routeParams.get('systemname');
                if (externalSystem == 'archibus') {
                    this.openEditDialogFromArchibus(queryParams, contract);
                } else {
                    this.openEditDialogFromWithin(queryParams, contract);
                }
            }, 1);
        })
    }

    private openEditDialogFromWithin(queryParams: Params, contract: Contract) {
        const modalRef = this.modalService.open(KontrollenFormularComponent, { size: 'lg' });
        if (queryParams['id']) {
            modalRef.componentInstance.controlId = queryParams['id'];
        }
        modalRef.result.then((result) => {
            this.navigateToOverview(contract.urlPath);
        }, (reason) => {
            if(!reason){
                this.navigateToOverview(contract.urlPath);
            }
        });
    }


    private openEditDialogFromArchibus(queryParams: Params, contract: Contract) {
        const modalRef = this.modalService.open(KontrollenFormularComponent, { size: 'lg' });
        ArchibusParameterMapper.setParametersToDialog(modalRef, queryParams);
        
        modalRef.result.then((result) => {
            this.navigateToOverview(contract.urlPath);
        }, (reason) => {
            if(!reason){
                this.navigateToOverview(contract.urlPath);
            }
        });
    }

    private navigateToOverview(contractUrl: string) {
        this.router.navigate([`/contract/${contractUrl}/control/overview`]);
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}